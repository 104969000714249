import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';
import { AccountService } from '../../services/account.service';
import { QuoteService } from '../../services/quote.service';
import { ACCOUNT_META } from '../../pages/meta';
import { ForgotPasswordModalComponent } from '../forgot-password-modal/forgot-password-modal.component';
import { Location, OrderDateTime, OrderDateTimeType } from '../../models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var AccountProfileComponent = /** @class */ (function () {
    function AccountProfileComponent(route, accountService, quoteService, meta, metaService, title, modalService) {
        var _this = this;
        this.route = route;
        this.accountService = accountService;
        this.quoteService = quoteService;
        this.meta = meta;
        this.metaService = metaService;
        this.title = title;
        this.modalService = modalService;
        this.subscriptions = [];
        route.data.subscribe(function (data) {
            _this.headerBg = data.headerImage;
        });
        this.subscriptions.push(this.accountService.user$
            .subscribe(function (u) { return _this.user = u; }));
        this.subscriptions.push(this.accountService.address$
            .subscribe(function (a) { return _this._addresses = a; }));
        this.quoteService.loadQuotes()
            .then(function (res) { return _this.history = res; });
    }
    AccountProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.metaService.createCanonicalURL();
        this.title.setTitle(ACCOUNT_META.title);
        this.metaService.removeItemprop();
        ACCOUNT_META.meta.forEach(function (m) { return _this.meta.updateTag(m); });
    };
    AccountProfileComponent.prototype.getOriginAddress = function (s) {
        return (s.Shipper && s.Shipper.City && s.Shipper.StateOrProvince)
            ? s.Shipper.City + ', ' + s.Shipper.StateOrProvince + ' ' + s.Shipper.PostalCode
            : s.Shipper.PostalCode;
    };
    AccountProfileComponent.prototype.getDestinationAddress = function (s) {
        return (s.Consignee && s.Consignee.City && s.Consignee.StateOrProvince)
            ? s.Consignee.City + ', ' + s.Consignee.StateOrProvince + ' ' + s.Consignee.PostalCode
            : s.Consignee.PostalCode;
    };
    AccountProfileComponent.prototype.getOrderDate = function (s) {
        return (s.DateTimes && s.DateTimes.find(function (d) { return d.TypeID === OrderDateTimeType.OrderDate; }))
            ? OrderDateTime.toShortDateString(s.DateTimes.find(function (d) { return d.TypeID === OrderDateTimeType.OrderDate; }).DateUnix)
            : '';
    };
    Object.defineProperty(AccountProfileComponent.prototype, "location", {
        get: function () {
            return new Location();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountProfileComponent.prototype, "addresses", {
        get: function () {
            if (!Array.isArray(this._addresses))
                return null;
            return this._addresses.filter(function (a) { return a.IsDefaultBillTo; });
        },
        enumerable: true,
        configurable: true
    });
    AccountProfileComponent.prototype.open = function (target) {
        if (typeof target === 'string' && target === 'password') {
            var modalRef = this.modalService.open(ForgotPasswordModalComponent);
            // modalRef
            //   .result.then((result) => {
            //     this.handleModalConfirm(result);
            //   }, (reason) => {
            //     console.log(reason);
            //   });
        }
    };
    AccountProfileComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    return AccountProfileComponent;
}());
export { AccountProfileComponent };
