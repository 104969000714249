/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./item-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../freight-item-detail/freight-item-detail.component.ngfactory";
import * as i3 from "../freight-item-detail/freight-item-detail.component";
import * as i4 from "../../services/quote.service";
import * as i5 from "@angular/common";
import * as i6 from "./item-details.component";
import * as i7 from "../../services/account.service";
import * as i8 from "../../services/authentication.service";
import * as i9 from "@angular/router";
import * as i10 from "../../shared/flash/flash.service";
var styles_ItemDetailsComponent = [i0.styles];
var RenderType_ItemDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItemDetailsComponent, data: {} });
export { RenderType_ItemDetailsComponent as RenderType_ItemDetailsComponent };
function View_ItemDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-freight-item-detail", [], null, [[null, "validationResult"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("validationResult" === en)) {
        var pd_0 = (_co.handleValidationResults($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FreightItemDetailComponent_0, i2.RenderType_FreightItemDetailComponent)), i1.ɵdid(1, 245760, null, 0, i3.FreightItemDetailComponent, [i4.QuoteService], { commodity: [0, "commodity"], id: [1, "id"], handlingUnits: [2, "handlingUnits"], isSubmitting: [3, "isSubmitting"], commodities: [4, "commodities"], _clientCommodities: [5, "_clientCommodities"] }, { validationResult: "validationResult" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.index; var currVal_2 = _co.handlingUnits; var currVal_3 = _co.submission$; var currVal_4 = _co.quote.Commodities; var currVal_5 = _co.clientCommodities; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_ItemDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "section", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "col-12 pb-3 pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-primary float-right btn-mini"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Continue"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Book Your Shipment"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Item Details"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemDetailsComponent_1)), i1.ɵdid(10, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "col-6 pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary w-100"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Enter Shipper Details"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.quote.Commodities; _ck(_v, 10, 0, currVal_0); }, null); }
export function View_ItemDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-item-details", [], null, null, null, View_ItemDetailsComponent_0, RenderType_ItemDetailsComponent)), i1.ɵdid(1, 180224, null, 0, i6.ItemDetailsComponent, [i4.QuoteService, i7.AccountService, i8.AuthenticationService, i9.Router, i10.FlashService], null, null)], null, null); }
var ItemDetailsComponentNgFactory = i1.ɵccf("rtrt-item-details", i6.ItemDetailsComponent, View_ItemDetailsComponent_Host_0, {}, {}, []);
export { ItemDetailsComponentNgFactory as ItemDetailsComponentNgFactory };
