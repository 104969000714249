import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs'
import { Location } from "../../models";
import { AccountService } from "../../services/account.service";
import { FormGroup } from '@angular/forms';

@Component({
  selector: "rtrt-autocomplete",
  templateUrl: "./autocomplete.component.html",
  styleUrls: ["./autocomplete.component.scss"],
  exportAs: "rtrtAutocomplete"
})
export class AutocompleteComponent implements OnInit {
  @Input() placeholderText: any;
  @Input() locationType: any;
  @Input() formGroup: FormGroup;
  @Input() controlName: any;
  @Input() quotedLocation: Location;
  matchedLocations$: Location[] = [];
  subscriptions: Subscription[] = [];

  private _addresses: Location[] = [];

  constructor(private accountService: AccountService) { }
  
  ngOnInit() {
    this.accountService.refreshAddresses();
    this.subscriptions.push(
      this.accountService.address$.subscribe(a => {
        if (typeof a !== 'object' || typeof a.filter !== 'function')
          this._addresses = null;
        else if (this.locationType == "shipper")
          this._addresses = 
            a.filter(
              address => 
              address.IsShipper 
              && address.City == this.quotedLocation.City 
              && address.StateOrProvince == this.quotedLocation.StateOrProvince 
              && address.PostalCode == this.quotedLocation.PostalCode
            );
        else if(this.locationType == "destination")
          this._addresses =
            a.filter(
              address =>
                address.IsConsignee
                && address.City == this.quotedLocation.City
                && address.StateOrProvince == this.quotedLocation.StateOrProvince
                && address.PostalCode == this.quotedLocation.PostalCode
            );
      })
    );
  }

  search(locationText: string) {
    if(this.controlName == "name")
      this.matchedLocations$ = this._addresses.filter(a => a.Name.toLowerCase().includes(locationText.toLowerCase()));
    else if (this.controlName == "address1")
      this.matchedLocations$ = this._addresses.filter(a => a.AddressLineOne.toLowerCase().includes(locationText.toLowerCase()));
  }
  fillAddress(selectedLocation: Location) {
    this.formGroup.get('name').setValue(selectedLocation.Name);
    this.formGroup.get('phone').setValue(selectedLocation.PhoneOne);
    this.formGroup.get('address1').setValue(selectedLocation.AddressLineOne);
    this.formGroup.get('address2').setValue(selectedLocation.AddressLineTwo);
  }
}
