import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthGuard } from '../../core/auth.guard';

import { AccountService } from '../../services/account.service';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';

@Component({
  selector: 'rtrt-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  @Input() username: string;
  password: string;
  message: string;
  spinner = false;

  @Input() title: string;

  @Output() onLogin: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private as: AccountService,
    private authGuard: AuthGuard) { 
  }

  ngOnInit() {
    this.as.loginExpired.subscribe(() => {
      // Don't run if not past carrier pricing in an order
      var loc = document.location.pathname;
      // if (loc.substr(0,19) !== '/ltl-shipping-quote' &&
      //     loc.substr(0,20) !== '/final-mile-delivery' &&
      //     loc.substr(0,17) !== '/freight-tracking')
      if (loc.substr(0,19) !== '/ltl-shipping-quote' &&
          loc.substr(0,17) !== '/freight-tracking')
        return;
      // if (loc === '/ltl-shipping-quote' ||
      //     loc === '/ltl-shipping-quote/carrier' ||
      //     loc === '/final-mile-delivery' ||
      //     loc === '/final-mile-delivery/start' ||
      //     loc === '/final-mile-delivery/carrier')
      if (loc === '/ltl-shipping-quote' ||
          loc === '/ltl-shipping-quote/carrier')
        return;

      console.log("login-form.component see's we're not logged in.");
      this.authGuard.openLogin();
    });
  }

  login() {
    this.message = null;
    this.spinner = true;
    this.as.login(this.username, this.password)
      .subscribe(result => {
        this.spinner = false;
        if (result === true) {
          this.message = null;
          this.onLogin.emit(true);
        } else {
          this.message = 'There was a problem logging in.';
          this.onLogin.emit(false);
        }
      });
  }

}
