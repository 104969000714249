import { EventEmitter, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { QuoteService } from "../../services/quote.service";
import { PaymentService } from "../../services/payment.service";
import { AuthenticationService } from "../../services/authentication.service";
import { AccountService } from '../../services/account.service';
var ConfirmationComponent = /** @class */ (function () {
    function ConfirmationComponent(quoteService, paymentService, authService, accountService, router) {
        var _this = this;
        this.quoteService = quoteService;
        this.paymentService = paymentService;
        this.authService = authService;
        this.accountService = accountService;
        this.router = router;
        this.onEdit = new EventEmitter();
        this.subscriptions = [];
        this.isAuthenticated = false;
        this.loading = false;
        this.paid = false;
        this.subscriptions = this.subscriptions.concat([
            this.authService.loggedIn.subscribe(function (r) { return (_this.isAuthenticated = r); }),
            this.quoteService.quote$.subscribe(function (q) { return (_this.quote = q); }),
            this.accountService.user$.subscribe(function (u) { return (_this.user = u); })
        ]);
    }
    Object.defineProperty(ConfirmationComponent.prototype, "buttonText", {
        get: function () {
            return "Confirmed";
        },
        enumerable: true,
        configurable: true
    });
    ConfirmationComponent.prototype.confirmCompleted = function () {
        if (!this.authService.isLoggedIn())
            return;
        this.router.navigate(['ltl-shipping-quote', 'payment']);
    };
    // chargeCard() {
    //   this.loading = true;
    //   if (this.quote.ChargeAccount) {
    //     //this.makeOrder();
    //     return;
    //   }
    //   var card = elements.create("card", {
    //     hidePostalCode: true
    //   });
    // }
    ConfirmationComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    return ConfirmationComponent;
}());
export { ConfirmationComponent };
