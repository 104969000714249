import * as tslib_1 from "tslib";
import { throwError, BehaviorSubject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Shipment
// Carrier,
// Commodity,
// Accessorial,
// OrderDateTime,
// OrderDateTimeType
 } from "../models";
import { ConfigService } from "./config.service";
// import { User } from '../account/user.model';
// import { resolve } from "dns";
var QuoteService = /** @class */ (function () {
    function QuoteService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
        this._temp = null;
        this._handlingunits = [];
        this.handlingUnits$ = new BehaviorSubject(this._handlingunits);
        this._references = [];
        this.references$ = new BehaviorSubject(this._references);
        this.api = this.config.getConfig("api");
        var quoteStr = localStorage.getItem("quote");
        var quote = quoteStr === null ? new Shipment() : JSON.parse(quoteStr);
        //if (quote.Shipper.Name === "") quote.Shipper.Name = "Shipper";
        this.quote$ = new BehaviorSubject(quote);
        this._loadStoredValues();
    }
    QuoteService.prototype.setQuote = function (s) {
        if (this.config.getEnv() !== "production")
            localStorage.setItem("quote", JSON.stringify(s));
        this.quote$.next(s);
    };
    QuoteService.prototype.getQuote = function () {
        return this.quote$.getValue();
    };
    QuoteService.prototype.clearQuote = function () {
        this.setQuote(new Shipment());
    };
    QuoteService.prototype.createQuote = function (s) {
        var _this = this;
        if (s === void 0) { s = null; }
        // console.log("createQuote");
        // console.log(Error().stack);
        var shipment = s === null ? this.quote$.getValue() : s;
        if (shipment.QueueStatus !== 0)
            shipment.QueueStatus = 0;
        console.log("shipment to save", shipment);
        return this.httpClient
            .post(this.api + "api/orders/save", { order: shipment, saveShipper: false, saveConsignee: false })
            .pipe(map(function (res) {
            if (typeof res === "string") {
                _this.updateQuote(JSON.parse(res));
            }
        }))
            .toPromise();
    };
    QuoteService.prototype.loadQuote = function (jls) {
        var _this = this;
        return this.httpClient
            .get(this.api + "api/orders/load/" + jls)
            .pipe(catchError(function (err) {
            console.log(err);
            return err;
        }), map(function (res) {
            var q;
            if (typeof res === "string") {
                _this.clearQuote();
                q = JSON.parse(res);
            }
            else {
                q = res;
            }
            _this.updateQuote(q);
        }))
            .toPromise();
    };
    QuoteService.prototype.savePayment = function () {
        return this.httpClient
            .post(this.api + "api/payment/data/save", tslib_1.__assign({}, this.quote$.getValue().PaymentData, { 'isFinalMile': false }))
            .pipe(catchError(function (err) { return throwError(err); }))
            .toPromise();
    };
    QuoteService.prototype.orderQuote = function (promoCode) {
        if (promoCode === void 0) { promoCode = null; }
        // const q = this.quote$.getValue();
        // q.eQuoteNumber = q.JlsNo;
        // q.JlsNo = '';
        return this.httpClient
            .post(this.api + "api/orders/create", { order: this.quote$.getValue(), promoCode: promoCode })
            .pipe(map(function (res) { return JSON.parse(res).JlsNo; }))
            .toPromise();
    };
    QuoteService.prototype.updateQuote = function (s, save, saveShipper, saveConsignee, generateIntent) {
        var _this = this;
        if (save === void 0) { save = false; }
        if (saveShipper === void 0) { saveShipper = false; }
        if (saveConsignee === void 0) { saveConsignee = false; }
        if (generateIntent === void 0) { generateIntent = false; }
        // console.log("updateQuote");
        // console.trace();
        this.setQuote(tslib_1.__assign({}, this.quote$.getValue(), s));
        if (save || generateIntent) {
            return new Promise(function (resolve, reject) {
                return _this.saveQuote(s, saveShipper, saveConsignee, generateIntent)
                    .then(function (res) {
                    var intent = JSON.parse(res).PaymentIntentSecret;
                    _this.quote$.getValue().PaymentIntentSecret = intent;
                    resolve(intent);
                });
            });
        }
        else {
            return new Promise(function (resolve, reject) {
                resolve(null);
            });
        }
    };
    QuoteService.prototype.saveCommodity = function (Com) {
        return this.httpClient
            .post(this.api + "api/orders/saveCom", { Com: Com })
            .pipe(map(function (res) { return JSON.parse(res); }))
            .toPromise();
    };
    QuoteService.prototype.saveQuote = function (s, saveShipper, saveConsignee, generateIntent) {
        if (s === void 0) { s = null; }
        if (saveShipper === void 0) { saveShipper = false; }
        if (saveConsignee === void 0) { saveConsignee = false; }
        if (generateIntent === void 0) { generateIntent = false; }
        var shipment = s === null ? this.quote$.getValue() : s;
        return this.httpClient
            .post(this.api + "api/orders/save", { order: shipment, saveShipper: saveShipper, saveConsignee: saveConsignee, generateIntent: generateIntent })
            .toPromise();
    };
    QuoteService.prototype.checkPromoCode = function (code, clientId) {
        if (clientId === void 0) { clientId = null; }
        return this.httpClient
            .post(this.api + "api/orders/promo", {
            clientId: clientId,
            code: code
        })
            .toPromise();
    };
    QuoteService.prototype.checkAccountPromoCode = function (clientId) {
        return this.httpClient
            .post(this.api + "api/orders/promo", {
            clientId: clientId
        })
            .toPromise();
    };
    QuoteService.prototype._fetchHandlingUnits = function () {
        return this.httpClient
            .get(this.api + "api/quotes/handlingtypes")
            .pipe(map(function (res) { return JSON.parse(res); }))
            .toPromise();
    };
    QuoteService.prototype._fetchReferences = function () {
        return this.httpClient
            .get(this.api + "api/quotes/references")
            .pipe(map(function (res) { return JSON.parse(res); }))
            .toPromise();
    };
    QuoteService.prototype._loadStoredValues = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // Handling Units 
                    return [4 /*yield*/, this._fetchHandlingUnits().then(function (res) {
                            _this._handlingunits = res;
                            localStorage.setItem("handlingunits", JSON.stringify({ expiry: new Date(), data: _this._handlingunits }));
                        })];
                    case 1:
                        // Handling Units 
                        _a.sent();
                        this.handlingUnits$.next(this._handlingunits);
                        // References
                        return [4 /*yield*/, this._fetchReferences().then(function (res) {
                                _this._references = res;
                                localStorage.setItem("references", JSON.stringify({ expiry: new Date(), data: _this._references }));
                            })];
                    case 2:
                        // References
                        _a.sent();
                        this.references$.next(this._references);
                        return [2 /*return*/];
                }
            });
        });
    };
    QuoteService.prototype._dateDiff = function (d1, d2) {
        return Math.ceil(Math.abs(d2.getTime() - d1.getTime()) / (1000 * 3600 * 24));
    };
    QuoteService.prototype._loadFromStorage = function (key) {
        var raw = localStorage.getItem(key);
        if (!raw || raw === null || raw === "")
            return false;
        var json = JSON.parse(raw);
        if (!json.expiry ||
            !json.data ||
            this._dateDiff(new Date(), new Date(json.expiry)) > 1)
            return false;
        return json.data;
    };
    Object.defineProperty(QuoteService.prototype, "readyForPayment", {
        get: function () {
            var isReady = true;
            if (this.quote$ && this.quote$.getValue() !== null) {
                var q = this.quote$.getValue();
                if (q.Commodities.length === 0)
                    isReady = false;
                if (q.Carriers.length === 0)
                    isReady = false;
                if (!q.Consignee.Name ||
                    q.Consignee.Name === null ||
                    q.Consignee.Name === "")
                    isReady = false;
                if (!q.Shipper.Name || q.Shipper.Name === null || q.Shipper.Name === "")
                    isReady = false;
            }
            else {
                isReady = false;
            }
            return isReady;
        },
        enumerable: true,
        configurable: true
    });
    QuoteService.prototype.printQuote = function (jlsNo, carrierId, eSelectId) {
        return this.httpClient.get(this.api + "api/quotes/print/" + jlsNo + "/" + carrierId + "/" + eSelectId, { responseType: 'blob' })
            .pipe(catchError(function (err) {
            console.log('Error getting document ... ', err);
            return throwError(err);
        }), map(function (res) {
            var file = new Blob([res], { type: 'application/pdf' });
            return file;
        }))
            .toPromise();
    };
    QuoteService.prototype.loadQuotes = function (limit) {
        if (limit === void 0) { limit = 3; }
        return this.httpClient
            .get(this.api + "api/quotes/history/" + limit)
            .pipe(map(function (res) { return JSON.parse(res); }))
            .toPromise();
    };
    QuoteService.prototype.loadAllQuotes = function () {
        return this.httpClient
            .get(this.api + "api/quotes/history")
            .pipe(map(function (res) { return JSON.parse(res); }))
            .toPromise();
    };
    return QuoteService;
}());
export { QuoteService };
