import {
  throwError as observableThrowError,
  BehaviorSubject,
  Observable
} from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Location } from "../models";

import { ConfigService } from "./config.service";
import { QuoteService } from '../services/quote.service';
import { FinalMileService } from '../services/final-mile.service';
import { AuthenticationService } from '../services/authentication.service';
// import { resolve } from "dns";

@Injectable()
export class PaymentService implements OnInit {
  private _api: string;
  private token: any;
  private charge: number;
  private attempts: number;
  private attemptsKey = "charge_attempts";
  private isError = false;
  private errorMessage: string;

  constructor(
    private httpClient: HttpClient, 
    private config: ConfigService,
    private quoteService: QuoteService,
    private quoteServiceFinalMile: FinalMileService,
    private auth: AuthenticationService
  ) {
    this._api = this.config.getConfig("api");
  }

  ngOnInit() {
    const attempts = localStorage.getItem(this.attemptsKey);
    if (attempts) {
      this.attempts = JSON.parse(attempts).attempts;
    } else {
      this.attempts = 0;
    }
  }

  getErrors() {
    if (this.isError) {
      return { error: this.isError, message: this.errorMessage };
    } else {
      return { error: this.isError, message: null };
    }
  }

  getAttempts() {
    return this.attempts;
  }

  setCharge(n: number) {
    this.charge = n;
  }

  clearCharge() {
    this.charge = null;
  }

  clearToken() {
    this.token = null;
  }

  // NEW PAYMENT INTENT METHODS
  makeCardPayment(quote: any, c: any, cData: Location) {
    // Create the payment intent
    var paymentIntentSecret: string;
      
    return new Promise<string>((resolve) => {
      if ((quote.Vendors && quote.Vendors[0] && quote.Vendors[0].FinalMileShipment) || quote.FinalMileOnly) {
        // console.log('Payment.service makeCardPayment updateQuote');
        // console.log("=======================================================");
        console.log("QUOTE:", quote);
        // console.log("QUOTE SERVICE: ", this.quoteServiceFinalMile.getQuote())
        // console.log("=======================================================");
        if (!quote.Vendors || quote.Vendors.length < 1)
          throw new Error("The quote has no vendors, please go back and re-enter them.");

        // Save the Final Mile quote AND get the payment intent
        // console.log("*** PAYMENT STEP 2 FM ***");
        this.quoteServiceFinalMile.updateQuote(quote, true, null, null, null, true)
        .catch(err => {
          // console.log("*** PAYMENT STEP 2 FM ERR ***");
          console.log("Stripe payment error 1.a .... ", err);
          return JSON.stringify(err);
        })
        .then((res: string) => {
            paymentIntentSecret = res;
            // Call to process stripe payment
            // console.log("*** PAYMENT STEP 3 FM ***");
            this.makeCardPayment2(paymentIntentSecret, c, cData)
            .catch(err => {
              // console.log("*** PAYMENT STEP 5 FM ERR ***");
              console.log("Stripe payment error 1.b .... ", err);
              return JSON.stringify(err);
            })
            .then((res2: string) => {
                // console.log("*** PAYMENT STEP 5 FM ***");
                resolve(res2);
              });
          });
      } else {
        // console.log('Payment.service updateQuote');
        var qsQuote = this.quoteService.getQuote();
        // console.log("=======================================================");
        console.log("QUOTE:", quote);
        // console.log("QUOTE SERVICE: ", qsQuote)
        // console.log("=======================================================");
        if (!qsQuote.Shipper)
          throw new Error("The quote has no shipoer, please go back and re-enter one.");
        // Save the LTL quote AND get the payment intent
        // console.log("*** PAYMENT STEP 2 LTL ***");
        this.quoteService.updateQuote(qsQuote, true, null, null, true)
        .catch(err => {
          // console.log("*** PAYMENT STEP 2 LTL ERR ***");
          console.log("Stripe payment error 2.a .... ", err);
          return JSON.stringify(err);
        })
        .then((res: string) => {
            paymentIntentSecret = res;
            // Call to process stripe payment
            // console.log("*** PAYMENT STEP 3 LTL ***");
            this.makeCardPayment2(paymentIntentSecret, c, cData)
            .catch(err => {
              // console.log("*** PAYMENT STEP 5 LTL ERR ***");
              console.log("Stripe payment error 2.b .... ", err);
              return JSON.stringify(err);
            })
            .then((res2: string) => {
              // console.log("*** PAYMENT STEP 5 LTL ***");
              resolve(res2);
            });
          });
      }
    });
  }

  makeCardPayment2(paymentIntentSecret: any, c: any, cData: Location) {
    let data = null;
    console.log(c);
    console.log(cData);

    // Complete the payment
    if (cData) {
      data = {
        address: {
          city: cData.City ? cData.City : "",
          line1: cData.AddressLineOne ? cData.AddressLineOne : "",
          line2: cData.AddressLineTwo ? cData.AddressLineTwo : "",
          postal_code: cData.PostalCode ? cData.PostalCode : "",
          state: cData.StateOrProvince ? cData.StateOrProvince : "",
        },
        name: cData.ContactName ? cData.ContactName : "",
      };
    }
    console.log(data);
    // console.log("*** PAYMENT STEP 4 ***");
    return new Promise<any>((resolve, reject) => {
      const { token, error } = stripe
      .confirmCardPayment(paymentIntentSecret, { payment_method: { card: c, billing_details: data }})
        .catch(err => {
          // console.log("*** PAYMENT STEP 4 ERR ***");
          console.log("Stripe payment error .... ", err);
          reject(err);
        })
        .then(res => {
          if (!res.error) {
            // console.log("*** PAYMENT STEP 4 SUCCESS ***");
            console.log("Stripe payment success .... ", res);
            resolve(res);
          } else {
            // console.log("*** PAYMENT STEP 4 ERR2 ***");
            console.log("Stripe payment error .... ", res.error);
            reject(res);
          }
        });
    });
  }

  // OLD METHODS
  getToken(c: any, cData: Location): Promise<any> {
    let data = null;
    console.log(c);
    console.log(cData);

    if (cData) {
      data = {
        address_city: cData.City ? cData.City : "",
        address_line1: cData.AddressLineOne ? cData.AddressLineOne : "",
        address_line2: cData.AddressLineTwo ? cData.AddressLineTwo : "",
        address_state: cData.StateOrProvince ? cData.StateOrProvince : "",
        address_zip: cData.PostalCode ? cData.PostalCode : "",
        name: cData.ContactName ? cData.ContactName : "",
        currency: "usd"
      };
    }
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      const { token, error } = stripe
        .createToken(c, data)
        .catch(err => {
          console.log("Stripe token error .... ", err);
          reject(err);
          this.token = null;
        })
        .then(res => {
          this.token = res.token;
          resolve(res);
        });
    });
  }

  makePayment(jlsNo: string, email: string, description: string, isFinalMile: boolean = false): Promise<any> {
    this.attempts++;
    return new Promise<any>((resolve, reject) => {
      if (this.token && this.token !== null && this.charge) {
        const data = {
          token: this.token.id,
          charge: this.charge,
          recipient: email,
          jlsNo: jlsNo,
          description: description,
          isFinalMile: isFinalMile
        };
        this.httpClient
          .post(`${this._api}api/payment/charge`, data)
          .pipe(
            catchError(err => {
              this.isError = true;
              this.errorMessage = err.error;
              reject();
              return observableThrowError(err);
            })
          )
          .toPromise()
          .then((res: string) => {
            this.isError = false;
            this.errorMessage = null;
            this.token = null;
            this.attempts = null;
            localStorage.removeItem(this.attemptsKey);
            const results = JSON.parse(res);
            resolve(results);
          });
      } else {
        localStorage.setItem(
          this.attemptsKey,
          JSON.stringify({ attempts: this.attempts })
        );
        this.isError = true;
        this.errorMessage =
          "There was a problem charging your card. Please re-enter your payment information.";
        reject();
      }
    });
  }
}
