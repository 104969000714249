import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

import { LoginFormComponent } from '../login-form/login-form.component';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rtrt-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
  @Input() target: any;
  @Input() hideLinks = false;
  username: string;
  @Output() onLoggedIn: EventEmitter<boolean> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    
    
    if (this.target && this.target.username) {
      this.username = this.target.username;
    }
  }

  handleLogin(result: boolean) {
    if (result) {
      this.activeModal.close('loggedIn');
    }
  }
}
