import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
var AccessorialService = /** @class */ (function () {
    function AccessorialService(config, httpClient) {
        var _this = this;
        this.config = config;
        this.httpClient = httpClient;
        this.accessorials$ = new BehaviorSubject([]);
        this.api = this.config.getConfig('api');
        if (this.accessorials$.getValue().length === 0) {
            this.loadAccessorials()
                .then(function (res) { return _this.accessorials$.next(res); });
        }
    }
    AccessorialService.prototype.loadAccessorials = function () {
        return this.httpClient.get(this.api + "api/quotes/accessorials")
            .pipe(catchError(function (err) { return observableThrowError(err); }), map(function (res) { return JSON.parse(res).quote; }))
            .toPromise();
    };
    Object.defineProperty(AccessorialService.prototype, "accessorials", {
        get: function () {
            return this.accessorials$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    return AccessorialService;
}());
export { AccessorialService };
