<article class="page page-about">
  <header [style.background-image]="'url(' + headerBg + ')'">
    <div class="container">
      <h1 class="text-light">Less Than Truckload</h1>
    </div>
  </header>
  <section class="container-fluid">
    <div class="row">
      <div class="container">
        <div class="row pt-5">
          <div class="col-sm-10" [innerHtml]="content.copy | safeHtml"></div>
          <div class="col-sm-2">
            <img class="img-fluid rounded-circle" src="https://via.placeholder.com/480x480?text=ICON">
          </div>
        </div>
      </div>
    </div>
  </section>
</article>