<div class="modal-body">
    <rtrt-login-form [title]="'RiteRouting Account Login'" (onLogin)="handleLogin($event)" [username]="username"></rtrt-login-form>
    <div class="container" *ngIf="!hideLinks">
      <div class="row pt-0">
          <p><button type="button" class="btn btn-link" (click)="activeModal.close('password')">Forget password?</button></p>
      </div>
      <div class="row">
        <div class="col text-center modal-footer-text">
          <p>Don't have a RiteRouting account yet?<br /><button type="button" class="btn btn-link" (click)="activeModal.close('account')">Create a RiteRouting Account</button></p>
        </div>
      </div>
    </div>
</div>