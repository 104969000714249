<section class="container-fluid">
  <div class="row">
    <div class="col-12 pb-3 pt-4">
        <button type="submit" class="btn btn-primary btn-mini float-right" (click)="onSubmit()">Continue</button>
      <h3>Book Your Shipment<span>Origin Details</span></h3>
    </div>
  </div>
  <div class="row" *ngIf="addresses && addresses.length > 0">
    <div class="col-sm-4 col-md-10 pb-3 pt-3">
      <div ngbDropdown class="d-inline-block w-100">
        <button class="btn btn-outline-primary w-100" id="addressSelector" ngbDropdownToggle>Use Address from Address Book</button>
        <div ngbDropdownMenu aria-labelledby="addressSelector" id="addressDropdown">
          <button class="dropdown-item" *ngFor="let address of addresses" (click)="selectAddress(address)">
            <strong>{{ address.Name }}</strong>, {{ address.AddressLineOne }}, {{ address.City }}, {{ address.StateOrProvince }}, {{ address.PostalCode }}<strong *ngIf="address.IsDefaultBillTo"> (Bill To)</strong>
          </button>
        </div>
      </div>
    </div>
    <div class="col-sm-2 col-md-2 pt-3 pb-3">
      <button class="btn btn-link mt-3" *ngIf="showShipperClear" (click)="selectAddress(originalShipper)">Clear</button>
    </div>
  </div>
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
    <div class="row pt-3">
      <div class="col-12 form-group required">
        <label for="originName">Company Name</label>
        <rtrt-autocomplete controlName="name" [formGroup]="form" locationType="shipper" [quotedLocation]="quote.Shipper"></rtrt-autocomplete>
        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="form-helpers">
          <small *ngIf="name.errors.required" class="text-danger">Company is required.</small>
        </div>
      </div>
      <div class="col-12 required">
        <label for="originAddress1">Address</label>
        <rtrt-autocomplete controlName="address1" [formGroup]="form" locationType="shipper" placeholderText="Address 1" [quotedLocation]="quote.Shipper"></rtrt-autocomplete>

        <div *ngIf="(address1.invalid && (address1.dirty || address1.touched))" class="form-helpers">
          <small *ngIf="address1.errors.required" class="text-danger">Address is required.</small>
        </div>
        <div *ngIf="!this.quote.Shipper.Validated" class="form-helpers">
          <small *ngIf="!this.quote.Shipper.Validated" class="text-danger">Invalid address.</small>
        </div>

        <rtrt-address-validator
          [show]="!quote.Shipper.Validated"
          [postal]="quote.Shipper.PostalCode"
          [street]="quote.Shipper.AddressLineOne"
          [addressChange]="onAddressChange"
          (select)="handleAddressValidation($event)"></rtrt-address-validator>

      </div>
      <div class="col-12 form-group">
        <input type="text" class="form-control" placeholder="Address 2" formControlName="address2" id="originAddress2" />
      </div>
      <div class="col-6" *ngIf="cities.length <= 1">
        <label>City</label>
        <p>{{quote.Shipper.City}}</p>
      </div>
      <div class="col-6" *ngIf="cities.length > 1">
        <label>City</label>
        <div ngbDropdown>
            <button type="button" class="btn btn-link pl-0" id="citySelector" style="color: black; font-size: 1.8em;"
            ngbDropdownToggle>{{quote.Shipper.City}}</button>
          <div ngbDropdownMenu aria-labelledby="citySelector">
            <button type="button" class="dropdown-item" *ngFor="let city of cities" (click)="selectCity(city)">
              {{ city }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-2">
        <label>State</label>
        <p>{{quote.Shipper.StateOrProvince}}</p>
      </div>
      <div class="col-2">
        <label>Zip</label>
        <p>{{quote.Shipper.PostalCode}}</p>
      </div>
      <div class="col-12 form-group required">
        <label for="originContact">Contact Name</label>
        <input type="text" class="form-control" formControlName="contact" id="originContact" required />
        <div *ngIf="contact.invalid && (contact.dirty || contact.touched)" class="form-helpers">
          <small *ngIf="contact.errors.required" class="text-danger">Contact is required.</small>
        </div>
      </div>
      <div class="col-6 form-group required">
        <label for="originPhone">Phone</label>
        <input type="text" placeholder="1112223333" class="form-control" formControlName="phone" id="originPhone" required />
        <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="form-helpers">
          <small *ngIf="phone.errors.required" class="text-danger">Phone number is required.</small>
          <small *ngIf="phone.errors.phone" class="text-danger">Please provide a valid phone number.</small>
        </div>
      </div>
      <div class="col-6 form-group">
        <label for="originFax">Fax</label>
        <input type="text" placeholder="1112223333" class="form-control" formControlName="fax" id="originFax" />
        <div *ngIf="fax.invalid && (fax.dirty || fax.touched)" class="form-helpers">
          <small *ngIf="fax.errors.phone" class="text-danger">Please provide a valid fax number.</small>
        </div>
      </div>
      <div class="col-12 form-group required">
        <label for="originEmail">Email</label>
        <input type="text" class="form-control" formControlName="email" id="originEmail" required />
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="form-helpers">
          <small *ngIf="email.errors.required" class="text-danger">Email is required.</small>
          <small *ngIf="email.errors.email" class="text-danger">Please provide a valid email address.</small>
        </div>
      </div>
      <div class="col-12 col-md-5 form-group">
        <label for="bolToShipperEmail" class="form-check-label non-primary-label">
          <input type="checkbox" formControlName="bolToShipperEmail" id="bolToShipperEmail">
          <span class="control"></span>
          Send bill of lading to shipper?
        </label>
      </div>
      <div class="col-12 col-md-7 form-group">
        <label for="additionalEmails" class="form-check-label non-primary-label">
          <input type="checkbox" id="additionalEmails" formControlName="additionalEmail" (change)="handleAdditionalToggle()">
          <span class="control"></span>
          Send bill of lading to additional recipients?
        </label>
      </div>
      <ng-container formArrayName="emails" *ngIf="showAdditionalEmails">
        <ng-container *ngFor="let extraEmail of emails.controls; let i = index;" [formGroupName]="i">
          <div class="col-12 form-group">
            <div>
              <label for="originEmail">Email Address</label>
              <button type="button" class="mt-2 btn-link float-right" (click)="removeEmail(i)" *ngIf="emails.length > 1">Remove</button>
            </div>
            <input type="text" class="form-control" formControlName="email" [id]="'extraEmail'+i" required />
            <button type="button" class="btn-link float-right" (click)="addEmail()" *ngIf="i + 1 === emails.length">Add</button>
          </div>
        </ng-container>
      </ng-container>
      <div class="col-12 form-group required">
        <label for="originPickupDate">Pickup Date</label>
        <div class="input-group" [ngClass]="{'show': datepickerOpen}">
          <input type="text" class="form-control" placeholder="MM/DD/YYYY" formControlName="pickupDate" id="originPickupDate" ngbDatepicker placement="bottom-right" [minDate]="today" #d="ngbDatepicker" (dateSelect)="onDateSelect($event)" required />
          <button class="input-group-addon" style="min-width:70px" (click)="toggleDatepicker(d)" type="button"><img class="icon" alt="calendarIcon" name="calendarIcon" src="assets/images/icon-color-calendar.svg" /></button>
        </div>
        <div *ngIf="pickupDate.invalid && (pickupDate.dirty || pickupDate.touched)" class="form-helpers">
          <small *ngIf="pickupDate.errors.required" class="text-danger">Pickup date is required.</small>
          <small *ngIf="pickupDate.errors.ngbDate.requiredBefore && !pickupDate.errors.required" class="text-danger">Pickup date cannot be before today's date.</small>
        </div>
      </div>
    </div>
      <div class="col-12 required">
        <label>Loading Hours</label>
        <span class="help-text"> (Allow at least 4 hours)</span>
      </div>
      <div class="row">
        <div class="col-6">
        <label class="non-primary-label">Start</label>
        <!-- <div class="container-fluid">
          <div class="row">
            <div class="col-4">
              <input type="text" placeholder="HH" class="form-control" formControlName="loadingStartHour" id="loadingStartHour">
            </div>
            <div class="col-4">
                <input type="text" placeholder="MM" class="form-control" formControlName="loadingStartMin" id="loadingStartMin">
            </div>
            <div class="col-4">

            </div>
          </div>
        </div> -->
        <ngb-timepicker formControlName="loadingStartHour" [meridian]="true"></ngb-timepicker>

        <div *ngIf="loadingStartHour.invalid && (loadingStartHour.dirty || loadingStartHour.touched)" class="form-helpers">
          <small *ngIf="loadingStartHour.errors.required" class="text-danger">Start time is required.</small>
        </div>
      </div>
      <div class="col-6 required">
        <label class="non-primary-label">End</label>
        <!-- <div class="container-fluid">
          <div class="row">
            <div class="col-4">
              <input type="text" placeholder="HH" class="form-control" formControlName="loadingStopHour" id="loadingStartHour">
            </div>
            <div class="col-4">
                <input type="text" placeholder="MM" class="form-control" formControlName="loadingStopMin" id="loadingStartMin">
            </div>
            <div class="col-4">

            </div>
          </div>
        </div> -->
        <ngb-timepicker formControlName="loadingStopHour" [meridian]="true"></ngb-timepicker>
        <div *ngIf="loadingStopHour.invalid && (loadingStopHour.dirty || loadingStopHour.touched)" class="form-helpers">
          <small *ngIf="loadingStopHour.errors.required" class="text-danger">End time is required.</small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 form-group">
        <label for="pickupInstructions">Special Instructions for RiteRouting (Pickup)</label>
        <input type="text" class="form-control non-primary-label" formControlName="pickupInstructions" id="pickupInstructions"  [(ngModel)]="pickupNotes"/>
      </div>

      <div class="col-12 form-group">
        <label for="printedOnBOL">Special Instructions to Carrier (Printed on the BOL)</label>
        <input type="text" class="form-control non-primary-label" formControlName="printedOnBOL" id="printedOnBOL"  [(ngModel)]="bolNotes"/>
      </div>
    </div>
    <div class="row">
      <div class="col-6 pt-4">
        <button type="submit" class="btn btn-primary w-100">Add Destination</button>
      </div>
    </div>
  </form>
</section>
