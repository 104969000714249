import { Component, Output, EventEmitter, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { QuoteService } from "../../services/quote.service";
import { PaymentService } from "../../services/payment.service";
import { AuthenticationService } from "../../services/authentication.service";
import { Shipment } from "../../models";
import { AccountService } from '../../services/account.service';
import { User } from '../../account/user.model';

@Component({
  selector: "rtrt-confirmation",
  templateUrl: "./confirmation.component.html",
  styleUrls: ["./confirmation.component.scss"]
})
export class ConfirmationComponent implements OnDestroy {
  @Output() onEdit: EventEmitter<boolean> = new EventEmitter();
  subscriptions: Subscription[] = [];
  isAuthenticated = false;
  loading = false;
  quote: Shipment;
  order: any;
  paid = false;
  charge: any;
  user: User;

  constructor(
    private quoteService: QuoteService,
    private paymentService: PaymentService,
    private authService: AuthenticationService,
    private accountService: AccountService,
    private router: Router
  ) {
    this.subscriptions = [
      ...this.subscriptions,
      ...[
        this.authService.loggedIn.subscribe(r => (this.isAuthenticated = r)),
        this.quoteService.quote$.subscribe(q => (this.quote = q)),
        this.accountService.user$.subscribe(u => (this.user = u))
      ]
    ];
  }

  get buttonText() {
    return "Confirmed";
  }

  confirmCompleted() {
    if (!this.authService.isLoggedIn())
      return;
    this.router.navigate(['ltl-shipping-quote', 'payment']);
  }

  // chargeCard() {
  //   this.loading = true;
  //   if (this.quote.ChargeAccount) {
  //     //this.makeOrder();
  //     return;
  //   }
    
  //   var card = elements.create("card", {
  //     hidePostalCode: true
  //   });
  // }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
