<article class="page page-account">
  <header [style.background-image]="'url(' + headerBg + ')'">
    <div class="container">
      <h1 class="text-light">My Account</h1>
    </div>
  </header>
  <section class="container-fluid">
    <div class="row" *ngIf="!loading">
      <div class="col-12">
        <div class="container">
          <div class="row b-bottom-secondary pt-5">
            <div class="col-12 d-flex align-items-center justify-content-between">
              <h2>Address Book</h2>
              <a class="btn btn-primary btn-mini" routerLink="/account/profile">Manage Profile</a>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-lg-3 pt-4" id="addressTypes">
              <nav class="tabs d-flex flex-column justify-content-start align-items-start ">
                <button type="button" (click)="switchView('shipper')" [ngClass]="{'active': view === 'shipper'}">Shippers</button>
                <button type="button" (click)="switchView('consignee')" [ngClass]="{'active': view === 'consignee'}">Destinations</button>
              </nav>
            </div>
            <div class="col-sm-12 col-lg-9 b-left-secondary pl-5 pr-5 pt-5" id="addressColumn">
              <div class="row">
                <div class="col-12 d-flex align-items-center justify-content-between mb-5">
                  <p class="mb-0 pr-5 flex-grow" id="addressSearch">
                    <input type="text" [(ngModel)]="search" class="form-control w100" placeholder="Search" />
                  </p>
                  <p *ngIf="view === 'shipper'">
                    <rtrt-address-modal [type]="'shipper'"></rtrt-address-modal>
                  </p>
                  <p *ngIf="view === 'consignee'">
                    <rtrt-address-modal [type]="'consignee'"></rtrt-address-modal>
                  </p>
                </div>
              </div>
              <div class="row" *ngIf="shippers.length > 0 && view === 'shipper'">
                <div class="col-12 mb-5">
                  <rtrt-address-card *ngFor="let address of shippers | objectFilter: addressFilterProps : search"
                    [address]="address" (delete)="onDelete($event)"></rtrt-address-card>
                </div>
              </div>
              <div class="row" *ngIf="destinations.length > 0 && view === 'consignee'">
                <div class="col-12 mb-5">
                  <rtrt-address-card *ngFor="let address of destinations | objectFilter: addressFilterProps : search" [address]="address" (delete)="onDelete($event)"></rtrt-address-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>