import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';

import { ToolsComponent } from './dev/tools.component';
import { routerTransition } from './router.animations';
import { ConfigService } from './services/config.service';
import { AnalyticsService } from './services/analytics.service';
import { FlashComponent } from './shared/flash/flash.component';
import { environment } from '../environments/environment';
import { AccountService } from './services/account.service';

declare let gtag: Function;
declare global {
  interface Window { _hsq: any; }
}

@Component({
  selector: 'rtrt-root',
  animations: [ routerTransition ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Rite Routing';

  constructor(
    private config: ConfigService
    , private router: Router
    , private location: Location
    , private analytics: AnalyticsService
    , private as: AccountService
  ) {
    config.getConfig('api');
      }

  ngOnInit() {
    // Make sure we scroll to top of page on router change
    this.router.events.subscribe((evt) => {
      const flag = true;
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      
      console.log("Routing Event Before HubSpot Tracking Data");

      var theEmail = this.as ? this.as.getUserEmail() : null;
      // var userFullName = this.as.getUserName();
      // var firstSpace = userFullName.indexOf(' ');
      // var theFirstname = firstSpace == -1 ? '' : userFullName.substr(0,firstSpace);
      // var theLastname = firstSpace == -1 ? userFullName : userFullName.substr(firstSpace + 1);
      // var acct = this.as ? this.as.getUserAccount() : null;
      // var theCompany = acct ? acct.businessName : null;
      // var thePhone = this.as ? this.as.getUserPhone() : null;
      var theWindow = window;
      var theDocument = document;

      // We add a delay to the calls since the page's title gets set in javascript for the blog pages
      // setTimeout(function(theWindow, theDocument, theEmail, theFirstname, theLastname, theCompany, thePhone, evt) { 
      setTimeout(function(theWindow, theDocument, theEmail, evt) { 
        // HubSpot update, use try/catch so GTM runs regardless
        try {
          console.log("SENDING HubSpot Tracking Data");
          var _hsq = theWindow._hsq = theWindow._hsq || [];
          _hsq.push(['setPath', theWindow.location]);
          if (theEmail)
            _hsq.push(['identify', {
              email: theEmail
            }]);
            // _hsq.push(['identify', {
            //     email: theEmail, 
            //     firstname: theFirstname,
            //     lastname: theLastname,
            //     company: theCompany,
            //     phone: thePhone
            //   }]);
          _hsq.push(['trackPageView']);
        } catch {
          console.log("Error in Hubspot integration.");
        }
  
        // Google gtag
        // console.log('page_title: ' + document.title + '    page_location: ' + window.location + '     page_path: ' + evt.urlAfterRedirects);
        // gtag('config', 'UA-116238460-1', 
        // {
        //   'page_title': theDocument.title,
        //   'page_location': theWindow.location,
        //   'page_path': evt.urlAfterRedirects
        // });
      }, 500, theWindow, theDocument, theEmail, evt);
      // }, 500, theWindow, theDocument, theEmail, theFirstname, theLastname, theCompany, thePhone, evt);
      if (flag) {
        window.scrollTo(0, 0);
      }
    });
  }

  isDev(): boolean {
    return !(this.config.getConfig('env') === 'production');
  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }
}
