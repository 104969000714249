<div class="container-fluid">
  <div class="row">
    <div class="col-12 pb-3 pt-4">
      <h3>Purchase Your Shipment<span>Payment Information</span></h3>
    </div>
  </div>
  <div class="row" *ngIf="message">
    <div class="col-12">
      <p class="text-danger">{{message}}</p>
    </div>
  </div>
  <form #typeForm="ngForm">
    <div class="row pb-3">
      <div class="col-12 col-md-5" *ngIf="accountUser">
        <label for="accountPayment" class="form-check-label non-primary-label">
          <input type="radio" name="payment" [value]="'account'" [(ngModel)]="paymentType" id="accountPayment">
          <span class="control"></span>
          Charge My Account
        </label>
      </div>
      <div class="col-12 col-md-7">
        <label for="ccPayment" class="form-check-label non-primary-label">
          <input type="radio" name="payment" [value]="'credit-card'" [(ngModel)]="paymentType" id="ccPayment">
          <span class="control"></span>
          Charge Credit Card
        </label>
      </div>
      
    </div>
    <div class="row pb-3">

        <div class="col-6">
        
          <label for="promoCode">Promo Code</label>
          <input type="text" class="form-control" name="promoCodeInput" #promoCodeInput="ngModel" [(ngModel)]="quote.PromoCode.PromoCodeText" (blur)="handlePromoCode()">
          <div *ngIf="!promoCodeIsEmpty && !promoCodeValid && (promoCodeInput.dirty || promoCodeInput.touched)" class="form-helpers">
            <small class="text-danger">The promo code is either invalid or expired.</small>
          </div>
          <div *ngIf="!promoCodeIsEmpty && promoCodeValid && (promoCodeInput.dirty || promoCodeInput.touched)" class="form-helpers">
            <small class="text-success">Promo code was successfully applied!</small>
          </div>
        </div>
    </div>
  </form>
  <div class="row" *ngIf="paymentType === 'account'">
    <div class="col-6 pt-4">
      <button type="button" class="btn btn-primary w-100" [disabled]="loading" 
          (click)="handleAccountPayment()">Place Order</button>
    </div>
    <div class="col-6 pt-4">
      <rtrt-spinner [visible]="loading" message="Making sure you're good for it..."></rtrt-spinner>
    </div>
  </div>
</div>
<rtrt-stripe *ngIf="paymentType === 'credit-card'" (onPaymentSuccess)="handleSuccessfulPayment($event)" 
      (onPaymentError)="handleErrorPayment($event)" [contact]="quote.Shipper" [charge]="PaymentDue" 
      [paymentIntentSecret]="PaymentIntentSecret"  [quote]="quote"
      [buttonText]="buttonText" [verifyTokenNotExpired]="verifyTokenNotExpiredFunc"></rtrt-stripe>
