<div *ngIf="!shipment">
  <div class="row">
    <div class="col-12 text-center"><rtrt-spinner [visible]="!shipment"></rtrt-spinner></div>
  </div>
</div>
<div class="row b-bottom-secondary pt-5" *ngIf="!!shipment">
  <div class="col-12 d-flex align-items-center justify-content-between">
    <h2>Shipment: {{shipment.JlsNo}}</h2>
    <button type="button" class="btn btn-primary btn-mini" (click)="goBack()">Go back</button>
  </div>
</div>
<div>
  <div class="row">
    <div class="col-12 col-md-3 pt-5">
      <rtrt-shipment-summary [shipment]="shipment"></rtrt-shipment-summary>
    </div>
    <div class="col-12 col-md-9 b-left-md-secondary pl-md-5 pr-md-5 pt-md-5 shipment-history-pane" *ngIf="tracking && tracking.Statuses && tracking.Statuses.length > 0">
      <div class="rt-card dark">
        <header id="trackHeader">
          <p class="col-12 col-sm-6 col-md-8 mb-0"><strong>Current Status</strong></p>
          <p class="col-12 col-sm-6 col-md-4 mb-0">Date</p>
        </header>
        <div class="rt-card-body">
          <h4 class="col-12 col-sm-6 col-md-8 mb-0">{{tracking.Statuses[0].Status}}</h4>
          <p class="col-12 col-sm-6 col-md-4 mb-0">
            {{tracking.Statuses[0].StatusDate | date: 'shortDate'}}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{{tracking.Statuses[0].StatusDate | date: 'shortTime'}}
          </p>
        </div>
      </div>
      <ng-container *ngIf="remainingTracking.length > 0">
        <div class="row pt-5 pb-2 mt-5">
          <div class="col-6">
            <h3><strong>Shipment Status History</strong></h3>
          </div>
          <div class="col-12 col-sm-6 text-right">
              <button type="button" class="btn btn-secondary btn-mini mr-3" (click)="getBOL()">Print BOL</button>
              <button type="button" class="btn btn-secondary btn-mini mr-3" (click)="getLabel()">Print Label</button>
              <button type="button" class="btn btn-secondary btn-mini" (click)="getBoth()">Print Both</button>
          </div>
        </div>
        <div class="row pt-3 pb-2">
          <div class="col">
              <div class="table-grid table-grid--2cols offset break-lg">
                <h5 class="table-grid-head">
                  Status
                </h5>
                <h5 class="table-grid-foot">
                  Date
                </h5>

                <ng-container *ngFor="let status of remainingTracking; last as isLast">
                  <div class="table-grid-head" [ngClass]="{'table-foot': isLast}"><strong>{{status.Status}}</strong></div>
                  <div class="table-grid-foot" [ngClass]="{'table-foot': isLast}">{{status.StatusDate | date: 'shortDate'}}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{{status.StatusDate | date: 'shortTime'}}</div>
                </ng-container>
              </div>
          </div>
        </div> 
      </ng-container>   
    </div>
  </div>
</div>
