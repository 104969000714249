<div class="container-fluid">
  <div class="row">
    <div class="col-12 pb-3 pt-4">
      <h3>Purchase Your Shipment<span>Confirm Shipment Information</span></h3>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p>Please confirm the shipment details.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-6 pt-3">
      <p>By clicking the {{ buttonText }} button you are agreeing to the <a target="_blank"
          routerLink="/terms-conditions">Terms and Conditions</a></p>

    </div>
  </div>
  <div class="row">
    <div class="col-6">
        <button type="button" class="btn btn-primary w-100" (click)="confirmCompleted()" [disabled]="loading">{{ buttonText }}</button>
    </div>
  </div>
</div>
