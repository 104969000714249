import { throwError, BehaviorSubject, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { OnDestroy, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ConfigService } from "./config.service";
import { AuthenticationService } from "./authentication.service";
var AccountService = /** @class */ (function () {
    function AccountService(http, config, auth, window) {
        var _this = this;
        this.http = http;
        this.config = config;
        this.auth = auth;
        this.window = window;
        this.api = "";
        this.__user = null;
        this.user$ = new BehaviorSubject(this._user);
        this._address = [];
        this.address$ = new BehaviorSubject(this._address);
        this._commodity = [];
        this.commodity$ = new BehaviorSubject(this._commodity);
        this.isAuthenticated = false;
        this._isLoginExpired = true;
        this._subscriptions = [];
        this.loginExpired = new EventEmitter();
        this.api = config.getConfig("api");
        this.clientId = this.config.getConfig("client_id");
        this.clientSecret = this.config.getConfig("client_secret");
        this._subscriptions.push(this.auth.loggedIn.subscribe(function (res) {
            // console.log("*** account.service auth.loggedIn subscriber _isLoginExpired=!" + res);
            _this.isAuthenticated = res;
            if (res) {
                _this._isLoginExpired = false;
                if (_this._user === null)
                    _this.getUser();
            }
            else {
                _this._isLoginExpired = true;
                _this._user = null;
                _this.loginExpired.emit();
                // this.user$.next(this._user);
            }
        }));
        this._subscriptions.push(this.auth.loginExpired.subscribe(function (res) {
            // console.log("*** account.service auth.loginExpired subscriber _isLoginExpired=" + res);
            _this._isLoginExpired = typeof res == 'undefined' ? true : !res;
            ;
            if (_this._isLoginExpired) {
                _this._user = null;
                _this.loginExpired.emit();
            }
        }));
        this.loadAddresses();
        this.loadCommodities();
    }
    Object.defineProperty(AccountService.prototype, "_user", {
        get: function () {
            return this.__user;
        },
        set: function (val) {
            this.__user = val;
            this.user$.next(this.__user);
        },
        enumerable: true,
        configurable: true
    });
    AccountService.prototype.create = function (obj) {
        return this.http.post(this.api + "api/account/add", obj)
            .pipe(catchError(function (err) { return throwError(err); }), map(function (res) { return res; }));
    };
    AccountService.prototype.saveSurvey = function (userid, arr) {
        var savedata = { userid: userid, data: arr };
        return this.http
            .post(this.api + "api/account/survey/save", savedata)
            .pipe(catchError(function (err) { return throwError(err); }), map(function (res) { return res; }))
            .toPromise();
    };
    AccountService.prototype.login = function (username, password) {
        var _this = this;
        return this.http
            .post(this.api + "oauth2/token", "username=" +
            encodeURIComponent(username) +
            "&password=" +
            encodeURIComponent(password) +
            "&grant_type=password", {
            headers: new HttpHeaders()
                .set("Accept", "application/json")
                .set("Content-Type", "application/x-www-form-urlencoded")
                .set("Authorization", "Basic " + btoa(this.clientId + ":" + this.clientSecret))
        })
            .pipe(catchError(function (err) { return of(false); }), map(function (res) {
            console.log(res);
            if (!res) {
                return false;
            }
            _this.auth.login(res);
            return true;
        }));
    };
    AccountService.prototype.forgotPassword = function (email) {
        return this.http
            .post(this.api + "api/account/password/trigger", {
            email: email,
            scheme: this.window.location.protocol,
            host: this.window.location.hostname,
            port: this.window.location.port
        })
            .pipe(catchError(function (err) { return err; }))
            .toPromise()
            .then(function (res) { return res; });
    };
    AccountService.prototype.resetPassword = function (pw, id, token) {
        return this.http
            .post(this.api + "api/account/password/reset", {
            id: id,
            token: token,
            password: pw
        })
            .pipe(catchError(function (err) { return err; }))
            .toPromise()
            .then(function (res) { return res; });
    };
    AccountService.prototype.getUser = function () {
        var _this = this;
        if ((typeof this._isLoginExpired == "undefined") || this._isLoginExpired == true)
            return;
        this.http
            .get(this.api + "api/account/user")
            .pipe(map(function (res) { return JSON.parse(res); }))
            .toPromise()
            .then(function (res) {
            _this._user = res;
            // this.user$.next(this._user); 
            _this.refreshAddresses().then(function () {
                console.log('sending user update');
                // this.user$.next(this._user); 
            });
        });
    };
    AccountService.prototype.getUserEmail = function () {
        if (this._user === null)
            return null;
        return this._user.email;
    };
    AccountService.prototype.getUserName = function () {
        if (this._user === null)
            return null;
        return this._user.name;
    };
    AccountService.prototype.getUserUsername = function () {
        if (this._user === null)
            return null;
        return this._user.username;
    };
    AccountService.prototype.getUserAccount = function () {
        if (this._user === null)
            return null;
        return this._user.account;
    };
    AccountService.prototype.getUserPhone = function () {
        if (this._user === null)
            return null;
        return this._user.phone;
    };
    AccountService.prototype.getUserOnAccount = function () {
        if (this._user === null) {
            this.getUser();
        }
        if (this._user === null ||
            this._user.account === null ||
            this._user.account.onAccount === null ||
            this._user.account.onAccount !== true) {
            return false;
        }
        else {
            return true;
        }
    };
    AccountService.prototype.getClientOrderStatus = function () {
        if (this._user === null) {
            this.getUser();
        }
        if (this._user === null ||
            this._user.account === null ||
            this._user.account.newAccount === null ||
            this._user.account.newAccount !== true) {
            return false;
        }
        else {
            return true;
        }
    };
    AccountService.prototype.getisLoginExpired = function () {
        return ((typeof this._isLoginExpired === "undefined") ? true : this._isLoginExpired);
    };
    AccountService.prototype.loadAddresses = function () {
        this.fetchAddress();
        return;
    };
    AccountService.prototype.loadCommodities = function () {
        this.fetchCommodity();
        return;
    };
    AccountService.prototype.addAddress = function (a) {
        var _this = this;
        this.http
            .post(this.api + "api/location/save", a)
            .pipe(catchError(function (err) {
            console.log(err);
            return throwError(err);
        }))
            .toPromise()
            .then(function (res) { return _this.fetchAddress(); });
    };
    AccountService.prototype.updateAddress = function (a) {
        var _this = this;
        this.http
            .put(this.api + "api/location/update", a)
            .pipe(catchError(function (err) {
            console.log(err);
            return throwError(err);
        }))
            .toPromise()
            .then(function (res) { return _this.fetchAddress(); });
    };
    AccountService.prototype.deleteAddress = function (id) {
        var _this = this;
        this.http
            .delete(this.api + "api/location/delete/" + id)
            .pipe(catchError(function (err) {
            console.log(err);
            return throwError(err);
        }))
            .toPromise()
            .then(function (res) { return _this.fetchAddress(); });
    };
    AccountService.prototype.refreshAddresses = function () {
        return this.fetchAddress();
    };
    AccountService.prototype.refreshCommodities = function () {
        console.log("refreshing comms");
        this.fetchCommodity();
    };
    AccountService.prototype.flushAddresses = function () {
        this.handleNewAddress([]);
        localStorage.removeItem("addressExpiry");
    };
    AccountService.prototype.fetchAddress = function () {
        var _this = this;
        if (this._user && this._user !== null && this.auth.isLoggedIn()) {
            return new Promise(function (resolve, reject) {
                _this.http
                    .get(_this.api + "api/location/load")
                    .pipe(map(function (res) { return JSON.parse(res); }))
                    .toPromise()
                    .catch(function (err) {
                    // console.log("*** account.service fetchAddress api catch _isLoginExpired=true");
                    _this._isLoginExpired = true;
                    _this.loginExpired.emit();
                    return throwError(err);
                })
                    .then(function (res) {
                    _this.handleNewAddress(res);
                    resolve(true);
                });
            });
        }
        else {
            console.log('flushing addresses');
            this.flushAddresses();
            return new Promise(function (resolve, reject) {
                resolve(false);
            });
        }
    };
    AccountService.prototype.fetchCommodity = function () {
        var _this = this;
        try {
            if (this._user && this._user !== null && this.auth.isLoggedIn()) {
                this.http
                    .get(this.api + "api/commodity/load")
                    .pipe(map(function (res) { return JSON.parse(res); }))
                    .toPromise()
                    .then(function (res) { return _this.handleNewCommodity(res); });
            }
            else {
                console.log("no user context");
                this.flushCommodity();
            }
        }
        catch (_a) {
            // console.log("*** account.service fetchCommodity catch _isLoginExpired=true");
            this._isLoginExpired = true;
            this.loginExpired.emit();
        }
    };
    AccountService.prototype.flushCommodity = function () {
        this.handleNewCommodity([]);
    };
    AccountService.prototype.searchAddresses = function (searchText) {
        searchText = searchText.trim();
        var options = searchText ?
            { params: new HttpParams().set('searchText', searchText) } : {};
        return this.http
            .get(this.api + "api/location/search", options);
    };
    AccountService.prototype.handleNewAddress = function (a) {
        if (a === void 0) { a = []; }
        // localStorage.setItem('addresses', JSON.stringify(a));
        // localStorage.setItem('addressExpiry', JSON.stringify(new Date()));
        this._address = a;
        console.log('done fetching addresses');
        this.address$.next(this._address);
    };
    AccountService.prototype.handleNewCommodity = function (a) {
        if (a === void 0) { a = []; }
        // localStorage.setItem('addresses', JSON.stringify(a));
        // localStorage.setItem('addressExpiry', JSON.stringify(new Date()));
        this._commodity = a;
        this.commodity$.next(this._commodity);
    };
    AccountService.prototype.ngOnDestroy = function () {
        this._subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    return AccountService;
}());
export { AccountService };
