import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(@Inject(DOCUMENT) private dom) { }
   
  createCanonicalURL() {
    let oldlink: HTMLLinkElement = this.dom.querySelector('link[rel=canonical]');
    if (oldlink) {
      oldlink.remove();
    }
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', this.dom.URL);
  }

  overrideCanonicalURL(correctURL) {
    let oldlink: HTMLLinkElement = this.dom.querySelector('link[rel=canonical]');
    if (oldlink) {
      oldlink.remove();
    }
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', correctURL);
  }

  /* The meta.updateTag method doesn't update existing itemprop 
     tags, it adds new ones. This method should be ran to
     remove old ones first to remove the 3 values we use. It
     should also be run after the blog page properties are 
     added because we use an LD+JSON script with more data
     for those. 
  */
  removeItemprop() {
    document.querySelectorAll("script[type='application/ld+json']").forEach(s => s.remove());
    //this.meta.removeTag("itemprop='name'");
    document.querySelectorAll("meta[itemprop=name]").forEach(m => m.remove());
    //this.meta.removeTag("itemprop='description'");
    document.querySelectorAll("meta[itemprop=description]").forEach(m => m.remove());
    //this.meta.removeTag("itemprop='image'");
    document.querySelectorAll("meta[itemprop=image]").forEach(m => m.remove());
  }
}
