<div class="row">
    <div class="col-12 pt-4">
        <button type="button" class="btn btn-link btn-mini pl-0" (click)="confirm(confirmContent)">Start Over</button>
    </div>
</div>
<rtrt-shipment-summary [shipment]="quote" [isEditable]="true" (onEdit)="edit($event)"></rtrt-shipment-summary>
    <!-- <div class="col-12 d-flex justify-content-between align-items-center">
        <h4 class="text-uppercase mb-0">Quote Details</h4>
        <button type="button" class="btn btn-secondary btn-mini" (click)="edit('start')">Edit</button>
    </div>
    <div class="col-12">
        <p>
            <strong>Zip Codes</strong><br />
            <span *ngIf="quote?.Shipper?.PostalCode">Shipper: <strong>{{quote.Shipper.PostalCode}}</strong><br /></span>
            <span *ngIf="quote?.Consignee?.PostalCode">Consignee: <strong>{{quote.Consignee.PostalCode}}</strong><br /></span>
        </p>
    </div>
    <div class="col-12" *ngIf="quote?.Commodities?.length > 0">
        <p>
            Items: <strong>{{totalItems}}</strong><br />
            Handling Units: <strong>{{totalUnits}}</strong><br />
            Gross Weight: <strong>{{grossWeight}}</strong>
        </p>
    </div>
    <div class="col-12" *ngIf="quote?.AccessorialAR?.length > 0">
        <p>
            <strong>Services</strong>
            <span *ngFor="let accessorial of quote.AccessorialAR"><br />{{accessorial.Text}}</span>
        </p>
    </div>

    <div class="col-12 pt-4 d-flex justify-content-between align-items-center"
        *ngIf="quote?.Carriers?.length > 0 && quote?.Carriers[0]?.CarrierName">
        <h4 class="text-uppercase mb-0">Carrier</h4>
        <button type="button" class="btn btn-secondary btn-mini" (click)="edit('carrier')">Edit</button>
    </div>
    <div class="col-12" *ngIf="quote?.Carriers?.length > 0 && quote?.Carriers[0]?.CarrierName">
        <p>
            {{quote.Carriers[0].CarrierName}}
        </p>
    </div>

    <div class="col-12 pt-4 d-flex justify-content-between align-items-center"
        *ngIf="quote?.Commodities.length > 0 && quote?.Commodities[0]?.CommodityDescription">
        <h4 class="text-uppercase mb-0">Commodities</h4>
        <button type="button" class="btn btn-secondary btn-mini" (click)="edit('item-details')">Edit</button>
    </div>
    <div class="col-12" *ngIf="quote?.Commodities.length > 0 && quote?.Commodities[0]?.CommodityDescription">
        <p *ngFor="let commodity of quote?.Commodities; let i = index">
            <strong *ngIf="commodity.CommodityDescription">{{commodity.CommodityDescription}}<br /></strong>
            <span *ngIf="commodity.HandlingUnits && commodity.HandlingType"><strong>{{commodity.HandlingUnits}} {{commodity.HandlingType}}</strong><br /></span>
            <span *ngIf="commodity.NMFC">NMFC: <strong>{{commodity.NMFC}}</strong><br /></span>
            <span *ngIf="commodity.FreightClass">Class: <strong>{{commodity.FreightClass}}</strong></span>
        </p>
    </div>

    <div class="col-12 pt-4 d-flex justify-content-between align-items-center" *ngIf="quote?.Shipper?.Name">
        <h4 class="text-uppercase mb-0">Origin</h4>
        <button type="button" class="btn btn-secondary btn-mini" (click)="edit('origin')">Edit</button>
    </div>
    <div class="col-12" *ngIf="quote?.Shipper?.Name">
        <p>
            <strong *ngIf="quote?.Shipper?.Name">{{quote.Shipper.Name}}</strong>
            <span *ngIf="quote?.Shipper?.AddressLineOne"><br />{{quote.Shipper.AddressLineOne}}</span>
            <span *ngIf="quote?.Shipper?.AddressLineTwo"><br />{{quote.Shipper.AddressLineTwo}}</span>
            <span *ngIf="quote?.Shipper?.PhoneOne"><br />{{quote.Shipper.PhoneOne}}</span>
            <span *ngIf="quote?.Shipper?.Fax"><br />{{quote.Shipper.Fax}}</span>
        </p>
        <p>
            <span *ngIf="quote?.Shipper?.ContactName">{{quote.Shipper.ContactName}}<br /></span>
            <span *ngIf="quote?.Shipper?.ContactEmail">{{quote.Shipper.ContactEmail}}</span>
        </p>
        <p>
            <span *ngIf="pickupDate">Pickup: <strong>{{pickupDate}}</strong></span>
        </p>
    </div>

    <div class="col-12 pt-4 d-flex justify-content-between align-items-center" *ngIf="quote?.Consignee?.Name">
        <h4 class="text-uppercase mb-0">Destination</h4>
        <button type="button" class="btn btn-secondary btn-mini" (click)="edit('destination')">Edit</button>
    </div>
    <div class="col-12" *ngIf="quote?.Consignee?.Name">
        <p>
            <strong *ngIf="quote?.Consignee?.Name">{{quote.Consignee.Name}}</strong>
            <span *ngIf="quote?.Consignee?.AddressLineOne"><br />{{quote.Consignee.AddressLineOne}}</span>
            <span *ngIf="quote?.Consignee?.AddressLineTwo"><br />{{quote.Consignee.AddressLineTwo}}</span>
            <span *ngIf="quote?.Consignee?.PhoneOne"><br />{{quote.Consignee.PhoneOne}}</span>
            <span *ngIf="quote?.Consignee?.Fax"><br />{{quote.Consignee.Fax}}</span>
        </p>
        <p>
            <span *ngIf="quote?.Consignee?.ContactName">{{quote.Consignee.ContactName}}<br /></span>
            <span *ngIf="quote?.Consignee?.ContactEmail">{{quote.Consignee.ContactEmail}}</span>
        </p>
    </div>

    <div class="col-12 pt-4 d-flex justify-content-between align-items-center"
        *ngIf="quote?.References.length > 0">
        <h4 class="text-uppercase mb-0">References</h4>
        <button type="button" class="btn btn-secondary btn-mini" (click)="edit('references')">Edit</button>
    </div>
    <div class="col-12" *ngIf="quote?.References.length > 0">
        <p *ngFor="let ref of quote?.References; let i = index">
            <span *ngIf="ref.ReferenceType && ref.TypeID && ref.TypeID === '101'">Quote #</span>
            <span *ngIf="ref.ReferenceType && ref.TypeID && ref.TypeID !== '101'">{{ref.ReferenceType}}</span>
            <strong *ngIf="ref.ReferenceValue">{{ref.ReferenceValue}}</strong>
        </p>
    </div>

    <div class="col-12 pt-4 d-flex justify-content-between align-items-center"
        *ngIf="quote?.Payment">
        <h4 class="text-uppercase mb-0">Payment</h4>
        <button type="button" class="btn btn-secondary btn-mini" (click)="edit('payment')">Edit</button>
    </div>
    <div class="col-12">
        <p>
            <span *ngIf="quote?.Payment?.brand">Card Type <strong>{{quote.Payment.brand}}</strong></span>
            <span *ngIf="quote?.Payment?.last4digits"><br />Last 4 Digits <strong>{{quote.Payment.last4digits}}</strong></span>
            <span *ngIf="quote?.Payment?.amount"><br />Total <strong>{{quote.Payment.amount | currency}}</strong> <small *ngIf="quote?.Payment?.currency">{{quote.Payment.currency}}</small></span>
        </p>
    </div> -->

<ng-template #confirmContent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Start New Quote</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('cancel')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="container-fluid pt-3">
        <div class="col-12">
            <p>Please confirm you would like to abandon your current quote and start over.</p>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-between align-items-center p-3">
        <button type="button" class="btn btn-secondary" (click)="d('cancel')">Never Mind</button>
        <button type="button" class="btn btn-primary" (click)="c('confirm')">Confirm</button>
    </div>
</ng-template>
