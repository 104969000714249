import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import {
  Shipment,
  OrderDateTimeType,
  Accessorial
} from '../../models';
import { QuoteService } from '../../services/quote.service';

@Component({
  selector: 'rtrt-shipment-summary',
  templateUrl: './shipment-summary.component.html',
  styleUrls: ['./shipment-summary.component.scss']
})
export class ShipmentSummaryComponent {
  // @Input() shipment: Shipment = new Shipment();
  private _shipment: Shipment;
  public show: string = "collapse";
  public detailStatus: string = "Show";
  loading = false;

  @Input() isEditable = false;
  @Input() processLocation = '';
  @Output() onEdit: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private router: Router,
    private quoteService: QuoteService
  ) {
  }

  @Input() set shipment(s: Shipment) {
    this._shipment = s;
  }

  get shipment() {
    return this._shipment;
  }

  private sum = (accumulator, val) => {
    if (!isNaN(val))
      return accumulator + val;
    else
      return accumulator;
  }

  get grossWeight() {
    const gw = this.shipment.Commodities.map(w => !isNaN(w.GrossWeight) ? parseFloat(w.GrossWeight.toString()) : 0).reduce(this.sum);
    if (gw && gw > 0)
      return (gw === 1) ? gw + ' lb' : gw + ' lbs';
    else
      return '';
  }

  get totalItems() {
    return this.shipment.Commodities.length;
  }

  get totalUnits() {
    return this.shipment.Commodities.map(w => w.HandlingUnits).reduce(this.sum);
  }

  get pickupDate() {
    const pdate = this.shipment.DateTimes.find(dt => dt.TypeID === OrderDateTimeType.ShipDate);
    if (pdate)
      return pdate.ShortDateString;
  }

  get deliveryDate() {
    const ddate = this.shipment.DateTimes.find(dt => dt.TypeID === OrderDateTimeType.RequestedDeliveryDate);
    if (ddate)
      return ddate.ShortDateString;
  }

  get allAccessorials() {
    const accs: Accessorial[] = 
      this.shipment.AccessorialAR.concat(this.shipment.Carriers[0].AccessorialAR)
      .filter((obj, pos, arr) => 
      {
        return arr.map(mapObj => mapObj["Type"]).indexOf(obj["Type"]) === pos;
      });

    if(accs)
      return accs;
  }

  edit(dest: string) {
    this.onEdit.emit(dest);
  }

  showToggle() {
    if (this.show == "") {
      this.show = "d-none";
      this.detailStatus = "Show";
    }
    else {
      this.show = "";
      this.detailStatus = "Hide";
    }
    
  }

  printQuote() {
    this.loading = true;
    this.quoteService.printQuote(this.shipment.JlsNo, this.shipment.Carriers[0].CarrierID.toString(), this.shipment.eQuoteNumber)
      .then((file: Blob) => {
        this.loading = false;
        this.serveFile(file);
      })
      .catch(err => {
        this.loading = false;
        console.log(err);
      });
  }

  private serveFile(file: Blob) {
    const fileUrl = window.URL.createObjectURL(file);
    window.open(fileUrl);
  }
}
