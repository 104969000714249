import { HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
var ConfigService = /** @class */ (function () {
    function ConfigService(http) {
        this.http = http;
        this.config = null;
        this.env = null;
        this.logging = true;
    }
    ConfigService.prototype.getConfig = function (key) {
        if (this.config !== null) {
            return this.config[key];
        }
        else {
            return null;
        }
    };
    ConfigService.prototype.setLogging = function (b) {
        this.logging = b;
    };
    ConfigService.prototype.log = function (arg) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (this.logging) {
            if (args.length > 1) {
                console.log(arg, args);
            }
            else {
                console.log(arg, args[0]);
            }
        }
    };
    ConfigService.prototype.force = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        console.log(args);
    };
    ConfigService.prototype.getEnv = function () {
        return this.env;
    };
    ConfigService.prototype.load = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http
                .get("config/rtrt.env.json")
                .pipe(catchError(function (error) {
                console.log("Environment file could not be read.");
                reject(false);
                return throwError(error.json().error || "Server error");
            }))
                .subscribe(function (envResponse) {
                _this.env = envResponse.env;
                var request = null;
                switch (envResponse.env) {
                    case "production":
                    case "beta":
                    case "test":
                    case "dev":
                    case "local":
                        {
                            request = _this.http.get("config/rtrt.config." + envResponse.env + ".json");
                        }
                        break;
                    case "default":
                        {
                            console.error("Environment file not set or invalid.");
                        }
                        break;
                }
                if (request) {
                    request
                        .pipe(catchError(function (error) {
                        console.log("Error reading " + envResponse.env + " configuration file.");
                        reject(false);
                        return throwError(error.json().error || "Server error");
                    }))
                        .subscribe(function (responseData) {
                        _this.config = responseData;
                        resolve(true);
                    });
                }
                else {
                    console.error("Env config file is not valid.");
                    reject(false);
                }
            });
        });
    };
    return ConfigService;
}());
export { ConfigService };
