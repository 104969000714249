import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { validatePhone } from '../../validators/phone.directive';

import { Email } from '../../models/email.model';
import { EmailService } from '../../services/email.service';
import { FlashService } from '../flash/flash.service';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'rtrt-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactFormComponent implements OnInit {
  form: FormGroup;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private emailService: EmailService,
    private flashService: FlashService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['jlsNo']) {
        this.instantiateForm(`Ref: ${params['jlsNo']}\n\n`);
      } else {
        this.instantiateForm();
      }
    });
  }

  private instantiateForm(message: string = null) {
    const msg =  (message === null) ? '' : message;
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      company: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, validatePhone]],
      message: [msg]
    });
  }

  get name() {
    return this.form.get('name');
  }
  get company() {
    return this.form.get('company');
  }
  get email() {
    return this.form.get('email');
  }
  get phone() {
    return this.form.get('phone');
  }

  get message() {
    return this.form.get('message');
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.form.valid) {
      this.flashService.flashMessage('Please fill out all required fields.', '', 'danger');
      return false;
    }
    const e = new Email();
    e.recipient = 'info@riterouting.com';
    e.bcc = '';
    e.subject = 'New RiteRouting Contact Form Submission';
    e.email = 'ContactUs';
    e.params = {
      name: this.name.value,
      company: this.company.value,
      email: this.email.value,
      phone: this.phone.value,
      message: this.message.value
    };

    this.loading = true;
    this.emailService.sendEmail(e)
      .then(res => {
        this.loading = false;
        if ( res.status && res.status !== 200) {
          this.flashService.flashMessage('We were unable to send your message.', 'There was a problem!', 'danger');
          return false;
        }
        this.flashService.flashMessage('Thank you for contacting us.', 'Your message was sent.', 'success');
        this.instantiateForm();
      });
    return false;
  }

}
