import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';

import { ObjectFilterPipe } from '../../pipes/object-filter.pipe';
import { ObjectDateFilterPipe } from '../../pipes/object-date-filter.pipe';

import { QuoteService } from '../../services/quote.service';
import { QUOTEHISTORY_META } from '../../pages/meta';
import { Shipment, OrderDateTime, OrderDateTimeType } from '../../models';

import { NgbDateENParserFormatter } from './../../helpers/ngb-date-parser-formatter';
import { NgbDatePipe } from './../../helpers/ngb-date.pipe';
import { NgbDateStruct, NgbDatepickerConfig, NgbInputDatepicker , NgbDateParserFormatter, NgbPagination } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rtrt-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit, OnDestroy {
  headerBg: string;
  subscriptions: Subscription[] = [];
  private _history: any[];
  searchDate: NgbDateStruct = null;
  search: string = null;
  // Pagination  //
  page = 1;

  constructor(
    private route: ActivatedRoute,
    private quoteService: QuoteService,
    private objectFilter: ObjectFilterPipe,
    private objectDateFilter: ObjectDateFilterPipe,
    private title: Title,
    private meta: Meta,
    private metaService: MetaService
  ) {
    route.data.subscribe((data) => {
      this.headerBg = data.headerImage;
    });

    this.quoteService.loadAllQuotes()
      .then((res: any[]) => this._history = res);
  }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.title.setTitle(QUOTEHISTORY_META.title);
    this.metaService.removeItemprop();
    QUOTEHISTORY_META.meta.forEach(m => this.meta.updateTag(m));
  }

  clearFilters() {
    this.search = null;
    this.searchDate = null;
  }

  get location() {
    return new Location();
  }

  // Pagination / Filtering //
  get history(): any[] {
    return this.dateFilteredHistory.filter((q, idx) => idx >= ((this.page - 1) * 10) && idx < this.page * 10);
  }

  get searchFilteredHistory() {
    return this.objectFilter.transform(this._history, ['jlsNo', 'shipperZipCode', 'consigneeZipCode', 'mrNo'], this.search);
  }

  get dateFilteredHistory() {
    return this.objectDateFilter.transform(this.searchFilteredHistory, ['orderDate'], this.searchDate);
  }

  get collectionSize(): number {
    return this.dateFilteredHistory.length;
  }
  // End Pagination / Filtering //

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
