/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./about.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../helpers/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./about.component";
import * as i7 from "../../services/meta-service.service";
var styles_AboutComponent = [i0.styles];
var RenderType_AboutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AboutComponent, data: {} });
export { RenderType_AboutComponent as RenderType_AboutComponent };
export function View_AboutComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 17, "article", [["class", "page page-about"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [["class", "text-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["About Us"])), (_l()(), i1.ɵeld(6, 0, null, null, 12, "section", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [["class", "row pt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "col-sm-10 col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "aboutHeading"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(12, 1), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["For Information and Quotes:"])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "a", [["routerLink", "/contact-us"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Contact Us"]))], function (_ck, _v) { var currVal_3 = "/contact-us"; _ck(_v, 17, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), _co.content.copy)); _ck(_v, 11, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 17).target; var currVal_2 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_1, currVal_2); }); }
export function View_AboutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-about", [], null, null, null, View_AboutComponent_0, RenderType_AboutComponent)), i1.ɵdid(1, 114688, null, 0, i6.AboutComponent, [i4.ActivatedRoute, i3.Title, i3.Meta, i7.MetaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AboutComponentNgFactory = i1.ɵccf("rtrt-about", i6.AboutComponent, View_AboutComponent_Host_0, {}, {}, []);
export { AboutComponentNgFactory as AboutComponentNgFactory };
