<section class="container-fluid">
  <div class="row">
    <div class="col-12 pb-3 pt-4">
        <button type="submit" class="btn btn-primary float-right btn-mini" (click)="handleReferenceCompletion()">Continue</button>
      <h3>Book Your Shipment<span>Reference Numbers</span></h3>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col-sm-12 col-md-5 form-group">
      <label>Reference Number</label>
      <input type="text" class="form-control w-100" [(ngModel)]="value" />
    </div>
    <div class="col-sm-12 col-md-4 form-group">
      <ng-template #rt let-r="result" let-t="term">
        <ngb-highlight [result]="r.Value" [term]="t"></ngb-highlight>
      </ng-template>
      
      <label for="typeahead-focus">Type</label>
      <input id="typeahead-focus" type="text" class="form-control" [(ngModel)]="selected" required [ngbTypeahead]="search"
        #ref="ngModel" name="ref" (focus)="focus$.next($any($event).target.value)"
        (click)="click$.next($any($event).target.value)" (blur)="validateRefType($any($event).target.value)"
        #instance="ngbTypeahead" placeholder="Please Select" [resultTemplate]="rt" [inputFormatter]="formatter" />
      <div *ngIf="ref.invalid && (ref.dirty || ref.touched)" class="form-helpers">
        <small *ngIf="ref.errors.required" class="text-danger">Reference type is required.</small>
        <small *ngIf="!ref.errors.required" class="text-danger">Reference type is not valid.</small>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 form-group text-right">
      <label class="w-100">&nbsp;</label>
      <button type="button" class="btn btn-primary" (click)="addReference()" [disabled]="isAddReferenceDisabled || ref.invalid" id="addRefButton">Add Reference Number</button>
    </div>
    
    <div class="col-12">
      <h4>Current Reference Numbers</h4>
      <div class="row pt-3 pb-2 b-bottom-secondary" *ngFor="let ref of quote.References">
        <div *ngIf="ref.TypeID === '101'" class="col-sm-9 col-md-9"><p class="pt-2 m-0">Quote #: <strong>{{ref.ReferenceValue}}</strong></p></div>
        <div *ngIf="ref.TypeID !== '101'" class="col-sm-9 col-md-9"><p class="pt-2 m-0">{{ref.ReferenceType}}: <strong>{{ref.ReferenceValue}}</strong></p></div>
        <div class="col-sm-3 col-md-3 text-right" *ngIf="ref.TypeID !== '101'">
            <button type="button" class="btn btn-secondary btn-mini" (click)="removeReference(ref.TypeID)">Remove</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4 pt-4">
      <button type="button" class="btn btn-primary w-100" (click)="handleReferenceCompletion()">Continue with Quote</button>
    </div>
  </div>
</section>

