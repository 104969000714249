import { Component, EventEmitter, OnChanges, Input, Output, ViewChild } from '@angular/core';

// import { FreightItem } from '../freight-item.model';
import { Commodity } from '../../models';
import { BugComponent } from '../../shared/bug/bug.component';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'rtrt-freight-item',
  templateUrl: './freight-item.component.html',
  styleUrls: ['./freight-item.component.scss']
})
export class FreightItemComponent implements OnChanges {
  @Input() id: number;
  @Input()
  item: Commodity;
  @Input()
  freightClasses: number[];
  @Input()
  addInsurance: boolean;
  @Input()
  touched: boolean;
  @Input() commodities: Commodity[];
  @Output()
  onRemoveItem: EventEmitter<Commodity> = new EventEmitter<Commodity>();
  @ViewChild('class', { static: false })
  class: any;
  @ViewChild('weight', { static: false })
  weight: any;
  copyID: number;
  @ViewChild('valueofgoods', { static: false })
  valueofgoods: any;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focusFreight$ = new Subject<string>();
  clickFreight$ = new Subject<string>();

  searchFreight = (textFreight$: Observable<string>) => {
    const debouncedText$ = textFreight$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickFreight$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focusFreight$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => this.freightClasses.filter(f => new RegExp(term, 'mi').test(f.toString())))
    );
  }
  CheckFreightClass(input: any) {
    if(input == 0 || input == '0') {
      this.item.FreightClass = null;
      this.focusFreight$.next(null);
    }
    else {
      this.focusFreight$.next(input);
    }
  }

  constructor() {}

  ngOnChanges() {
    this.item.Density = (!this.item.Density) ? 0 : this.item.Density;
    if (this.touched) {
      this.class.control.markAsTouched();
      this.valueofgoods.control.markAsTouched();
      this.weight.control.markAsTouched();
    }
  }

  get displayID() {
    return this.id + 1;
  }
  getID() {
    return this.id;
  }

  calcPCF() {
    const pcf
      = Number.parseFloat((this.item.GrossWeight / (((this.item.Length * this.item.Width * this.item.Height) / (12 * 12 * 12)) * this.item.HandlingUnits)).toFixed(2));
    this.item.Density = (!Number.isNaN(pcf)) ? pcf : 0;
  }

  get suggestedClass() {
    return this.item.Density;
  }

  selectClass(val: any) {
    this.item.FreightClass = val;
  }

  validateClass(input: any) {
    this.class.control.markAsTouched();
    if (input !== '') {
      if (this.freightClasses.map(f => f.toString()).indexOf(this.item.FreightClass.toString()) === -1) {
        this.class.control.setErrors({ 'invalid': true });
      }
      else
        this.class.control.setErrors(null);
    }
  }
    

  removeItem() {
    this.onRemoveItem.emit(this.item);
  }
  selectCopy(commoditiy, i) {
    this.item.GrossWeight = commoditiy.GrossWeight;
    this.item.FreightClass = commoditiy.FreightClass;
    this.item.Length = commoditiy.Length;
    this.item.Width = commoditiy.Width;
    this.item.Height = commoditiy.Height;
    this.item.Stackable = commoditiy.Stackable;
    this.item.Density = commoditiy.Density;
    this.item.ValueOfGoods = commoditiy.ValueOfGoods;

    this.copyID = i+1;
  }
}
