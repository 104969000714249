import { throwError as observableThrowError } from "rxjs";
import { catchError } from "rxjs/operators";
import { OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "./config.service";
import { QuoteService } from '../services/quote.service';
import { FinalMileService } from '../services/final-mile.service';
import { AuthenticationService } from '../services/authentication.service';
// import { resolve } from "dns";
var PaymentService = /** @class */ (function () {
    function PaymentService(httpClient, config, quoteService, quoteServiceFinalMile, auth) {
        this.httpClient = httpClient;
        this.config = config;
        this.quoteService = quoteService;
        this.quoteServiceFinalMile = quoteServiceFinalMile;
        this.auth = auth;
        this.attemptsKey = "charge_attempts";
        this.isError = false;
        this._api = this.config.getConfig("api");
    }
    PaymentService.prototype.ngOnInit = function () {
        var attempts = localStorage.getItem(this.attemptsKey);
        if (attempts) {
            this.attempts = JSON.parse(attempts).attempts;
        }
        else {
            this.attempts = 0;
        }
    };
    PaymentService.prototype.getErrors = function () {
        if (this.isError) {
            return { error: this.isError, message: this.errorMessage };
        }
        else {
            return { error: this.isError, message: null };
        }
    };
    PaymentService.prototype.getAttempts = function () {
        return this.attempts;
    };
    PaymentService.prototype.setCharge = function (n) {
        this.charge = n;
    };
    PaymentService.prototype.clearCharge = function () {
        this.charge = null;
    };
    PaymentService.prototype.clearToken = function () {
        this.token = null;
    };
    // NEW PAYMENT INTENT METHODS
    PaymentService.prototype.makeCardPayment = function (quote, c, cData) {
        var _this = this;
        // Create the payment intent
        var paymentIntentSecret;
        return new Promise(function (resolve) {
            if ((quote.Vendors && quote.Vendors[0] && quote.Vendors[0].FinalMileShipment) || quote.FinalMileOnly) {
                // console.log('Payment.service makeCardPayment updateQuote');
                // console.log("=======================================================");
                console.log("QUOTE:", quote);
                // console.log("QUOTE SERVICE: ", this.quoteServiceFinalMile.getQuote())
                // console.log("=======================================================");
                if (!quote.Vendors || quote.Vendors.length < 1)
                    throw new Error("The quote has no vendors, please go back and re-enter them.");
                // Save the Final Mile quote AND get the payment intent
                // console.log("*** PAYMENT STEP 2 FM ***");
                _this.quoteServiceFinalMile.updateQuote(quote, true, null, null, null, true)
                    .catch(function (err) {
                    // console.log("*** PAYMENT STEP 2 FM ERR ***");
                    console.log("Stripe payment error 1.a .... ", err);
                    return JSON.stringify(err);
                })
                    .then(function (res) {
                    paymentIntentSecret = res;
                    // Call to process stripe payment
                    // console.log("*** PAYMENT STEP 3 FM ***");
                    _this.makeCardPayment2(paymentIntentSecret, c, cData)
                        .catch(function (err) {
                        // console.log("*** PAYMENT STEP 5 FM ERR ***");
                        console.log("Stripe payment error 1.b .... ", err);
                        return JSON.stringify(err);
                    })
                        .then(function (res2) {
                        // console.log("*** PAYMENT STEP 5 FM ***");
                        resolve(res2);
                    });
                });
            }
            else {
                // console.log('Payment.service updateQuote');
                var qsQuote = _this.quoteService.getQuote();
                // console.log("=======================================================");
                console.log("QUOTE:", quote);
                // console.log("QUOTE SERVICE: ", qsQuote)
                // console.log("=======================================================");
                if (!qsQuote.Shipper)
                    throw new Error("The quote has no shipoer, please go back and re-enter one.");
                // Save the LTL quote AND get the payment intent
                // console.log("*** PAYMENT STEP 2 LTL ***");
                _this.quoteService.updateQuote(qsQuote, true, null, null, true)
                    .catch(function (err) {
                    // console.log("*** PAYMENT STEP 2 LTL ERR ***");
                    console.log("Stripe payment error 2.a .... ", err);
                    return JSON.stringify(err);
                })
                    .then(function (res) {
                    paymentIntentSecret = res;
                    // Call to process stripe payment
                    // console.log("*** PAYMENT STEP 3 LTL ***");
                    _this.makeCardPayment2(paymentIntentSecret, c, cData)
                        .catch(function (err) {
                        // console.log("*** PAYMENT STEP 5 LTL ERR ***");
                        console.log("Stripe payment error 2.b .... ", err);
                        return JSON.stringify(err);
                    })
                        .then(function (res2) {
                        // console.log("*** PAYMENT STEP 5 LTL ***");
                        resolve(res2);
                    });
                });
            }
        });
    };
    PaymentService.prototype.makeCardPayment2 = function (paymentIntentSecret, c, cData) {
        var data = null;
        console.log(c);
        console.log(cData);
        // Complete the payment
        if (cData) {
            data = {
                address: {
                    city: cData.City ? cData.City : "",
                    line1: cData.AddressLineOne ? cData.AddressLineOne : "",
                    line2: cData.AddressLineTwo ? cData.AddressLineTwo : "",
                    postal_code: cData.PostalCode ? cData.PostalCode : "",
                    state: cData.StateOrProvince ? cData.StateOrProvince : "",
                },
                name: cData.ContactName ? cData.ContactName : "",
            };
        }
        console.log(data);
        // console.log("*** PAYMENT STEP 4 ***");
        return new Promise(function (resolve, reject) {
            var _a = stripe
                .confirmCardPayment(paymentIntentSecret, { payment_method: { card: c, billing_details: data } })
                .catch(function (err) {
                // console.log("*** PAYMENT STEP 4 ERR ***");
                console.log("Stripe payment error .... ", err);
                reject(err);
            })
                .then(function (res) {
                if (!res.error) {
                    // console.log("*** PAYMENT STEP 4 SUCCESS ***");
                    console.log("Stripe payment success .... ", res);
                    resolve(res);
                }
                else {
                    // console.log("*** PAYMENT STEP 4 ERR2 ***");
                    console.log("Stripe payment error .... ", res.error);
                    reject(res);
                }
            }), token = _a.token, error = _a.error;
        });
    };
    // OLD METHODS
    PaymentService.prototype.getToken = function (c, cData) {
        var _this = this;
        var data = null;
        console.log(c);
        console.log(cData);
        if (cData) {
            data = {
                address_city: cData.City ? cData.City : "",
                address_line1: cData.AddressLineOne ? cData.AddressLineOne : "",
                address_line2: cData.AddressLineTwo ? cData.AddressLineTwo : "",
                address_state: cData.StateOrProvince ? cData.StateOrProvince : "",
                address_zip: cData.PostalCode ? cData.PostalCode : "",
                name: cData.ContactName ? cData.ContactName : "",
                currency: "usd"
            };
        }
        console.log(data);
        return new Promise(function (resolve, reject) {
            var _a = stripe
                .createToken(c, data)
                .catch(function (err) {
                console.log("Stripe token error .... ", err);
                reject(err);
                _this.token = null;
            })
                .then(function (res) {
                _this.token = res.token;
                resolve(res);
            }), token = _a.token, error = _a.error;
        });
    };
    PaymentService.prototype.makePayment = function (jlsNo, email, description, isFinalMile) {
        var _this = this;
        if (isFinalMile === void 0) { isFinalMile = false; }
        this.attempts++;
        return new Promise(function (resolve, reject) {
            if (_this.token && _this.token !== null && _this.charge) {
                var data = {
                    token: _this.token.id,
                    charge: _this.charge,
                    recipient: email,
                    jlsNo: jlsNo,
                    description: description,
                    isFinalMile: isFinalMile
                };
                _this.httpClient
                    .post(_this._api + "api/payment/charge", data)
                    .pipe(catchError(function (err) {
                    _this.isError = true;
                    _this.errorMessage = err.error;
                    reject();
                    return observableThrowError(err);
                }))
                    .toPromise()
                    .then(function (res) {
                    _this.isError = false;
                    _this.errorMessage = null;
                    _this.token = null;
                    _this.attempts = null;
                    localStorage.removeItem(_this.attemptsKey);
                    var results = JSON.parse(res);
                    resolve(results);
                });
            }
            else {
                localStorage.setItem(_this.attemptsKey, JSON.stringify({ attempts: _this.attempts }));
                _this.isError = true;
                _this.errorMessage =
                    "There was a problem charging your card. Please re-enter your payment information.";
                reject();
            }
        });
    };
    return PaymentService;
}());
export { PaymentService };
