import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  Component,
  OnInit,
  OnDestroy
} from "@angular/core";

import { EstimateService } from "../../services/estimate.service";
import { QuoteService } from "../../services/quote.service";
import { LoginModalComponent } from "../../account/login-modal/login-modal.component";
import { AccountCreateModalComponent } from "../../account/account-create-modal/account-create-modal.component";
import { ForgotPasswordModalComponent } from "../../account/forgot-password-modal/forgot-password-modal.component";
import { AuthenticationService } from "../../services/authentication.service";

import { Shipment, Carrier, Estimate, Accessorial, Location } from "../../models";

import {
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import { User } from "../../account/user.model";
import { AccountService } from "../../services/account.service";

@Component({
  selector: "rtrt-carrier-selection",
  templateUrl: "./carrier-selection.component.html",
  styleUrls: ["./carrier-selection.component.scss"]
})
export class CarrierSelectionComponent implements OnInit, OnDestroy {
  quote: Shipment;
  subscriptions: Subscription[] = [];
  estimates: Estimate[];
  isAuthenticated: boolean;
  loading = false;
  estimatesLoaded = false;
  user: User = null;
  private _addresses: Location[] = [];

  constructor(
    private quoteService: QuoteService,
    private accountService: AccountService,
    private estimateService: EstimateService,
    public authService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router
  ) {
    // Wasn't in final mile?
    this.subscriptions.push(
      this.accountService.address$.subscribe(a => {
        console.log('address updating');
        console.log(a);
        this._addresses = a;
      })
    );
    this.subscriptions.push(
      this.accountService.user$.subscribe(u => {
        this.user = u;
        this.loginStatusChanged(u !== null);
      })
    );
    // Wasn't in final mile?
    this.subscriptions.push(
      this.accountService.loginExpired.subscribe(x => 
        (this.loginStatusChanged(typeof x == 'undefined' ? false : !x))
      )
    );
    this.subscriptions.push(
      this.quoteService.quote$.subscribe(q => {
        this.setQuote(q).then(() => this.loadEstimates());
      })
    );
    this.subscriptions.push(
      this.estimateService.estimates$.subscribe(e => 
        (this.estimates = e)
      )
    );
    // Not in final mile?
    this.subscriptions.push(
      this.estimateService.eQuoteID$.subscribe(
        e => (this.quote.eQuoteNumber = e)
      )
    );
  }

  ngOnInit() {
    this.loading = true;
    this.estimatesLoaded = false;
  }

  loginStatusChanged(loggedIn: boolean) {
    this.isAuthenticated = loggedIn;

    if (loggedIn && this.quote !== null && 
        this.quote !== undefined) {
      this.loading = true;
      this.estimatesLoaded = false;

      // set default bill to on login
      console.log('setting after login');
      this.setDefaultBillingAddress()
      .then(() => {
        console.log('fetching after login'); 
        this.loadEstimates(); 
      });
    }
  }

  setQuote(q: Shipment) {
    return new Promise((resolve, reject) => {
      this.quote = q;
      console.log(this.quote.Carriers);

      if (this.quote.Carriers.length === 0)
        this.quote.Carriers.push(new Carrier());

      resolve(null);
    });
  }

  loadEstimates() {
    if (
      !this.estimatesLoaded &&
      this.quote !== null &&
      this.quote.Carriers.length !== 0
    ) {
      this.loading = true;
      document.querySelector('article').style.cursor = "wait";
      this.estimateService.loadEstimates(this.quote)
      .then(() => {
        this.loading = false;
        this.estimatesLoaded = true;
        document.querySelector('article').style.cursor = "";
      });
    }
  }
  open(target: string) {
    if (typeof target === "string" && target === "account") {
      const modalRef = this.modalService
        .open(AccountCreateModalComponent, { size: "lg" })
        .result.then(
          result => {
            this.handleModalConfirm(result);
          },
          reason => {
            console.log(reason);
          }
        );
    } else if (typeof target === "string" && target === "password") {
      const modalRef = this.modalService.open(ForgotPasswordModalComponent);
      modalRef.result.then(
        result => {
          this.handleModalConfirm(result);
        },
        reason => {
          console.log(reason);
        }
      );
    } else {
      if (this.modalService.hasOpenModals())
        return false;
      const modalRef = this.modalService.open(LoginModalComponent).result.then(
        result => {
          this.handleModalConfirm(result);
        },
        reason => {
          console.log(reason);
        }
      );
    }
  }

  handleModalConfirm(target: any) {
    if (
      (typeof target === "string" &&
        (target === "account" ||
          target === "login" ||
          target === "password")) ||
      (target && target.username)
    ) {
      this.open(target);
    }
  }

  itemAccessorials(idx: number): any[] {
    return [];
  }

  select(e: any) {
    if (!this.authService.isLoggedIn()) {
      this.open('login');
      return false;
    }
    const c = this.quote.Carriers[0];
    console.log(e);
    c.CarrierName = e.CarrierName;
    c.CarrierID = e.CarrierID;
    c.ModeText = e.Mode;
    c.CarrierQuoteNumber = e.QuoteNumber;
    c.APLineHaul = e.APLineHaul;
    c.ARLineHaul = e.ARLineHaul;
    c.QuoteType = e.QuoteType;

    e.Accessorials.forEach(acc => {
      // Charge: 50
      // ChargeAP: 50
      // ChargeAR: 50
      // Description: "High Cost Delivery Area"
      // ID: "HighCost"
      if (acc.ChargeAP != 0) {
        c.AccessorialAP.push(
          new Accessorial(acc.ChargeAP, acc.Description, acc.ID)
        );
      }
      if (acc.ChargeAR != 0) {
        c.AccessorialAR.push(
          new Accessorial(acc.ChargeAR, acc.Description, acc.ID)
        );
      }
    });

    const regQuoteNumber = /[*]{3}CARRIER[\s]{1}QUOTE#[^*]*[*]{3}/g

    if (e.QuoteNumber != null && e.QuoteNumber != "" ) {
      c.CarrierQuoteNumber = e.QuoteNumber;
    if (e.QuoteType != "eQuote" )
    {
      if (regQuoteNumber.test(this.quote.CarrierNotes) === true) {
        this.quote.CarrierNotes = this.quote.CarrierNotes.replace(regQuoteNumber, "***CARRIER QUOTE#" + e.QuoteNumber + "***");
      }
      else if (this.quote.CarrierNotes != null && this.quote.CarrierNotes != "") {
        this.quote.CarrierNotes += "  ***CARRIER QUOTE#" + e.QuoteNumber + "***";
      }
      else {
        this.quote.CarrierNotes = "***CARRIER QUOTE#" + e.QuoteNumber + "***";
      }
    }}
    else {
      this.quote.CarrierNotes = this.quote.CarrierNotes.replace(regQuoteNumber, "").trim();
    }

    this.quote.TotalAR = e.TotalCost;
    
    if (e.InsuranceCarrier.CarrierName !== "") {
      if(this.quote.Carriers.length > 1)
        this.quote.Carriers.pop();
        
      this.quote.Carriers.push(e.InsuranceCarrier);
    }

    if (
      !this.quote.JlsNo ||
      this.quote.JlsNo === "" ||
      this.quote.JlsNo === null
    ) {
      this.quoteService.createQuote(this.quote).then(() => {
        this.router.navigate(["quote", "item-details"]);
      });
    } else {
      this.quoteService.updateQuote(this.quote, true);
      this.router.navigate(["quote", "item-details"]);
    }
    console.log(this.quote);
  }

  setDefaultBillingAddress() {
    return new Promise((resolve, reject) => {
      if (this._addresses.findIndex(a => a.IsDefaultBillTo) > -1) {
        this.quote.BillToLocation = Object.assign(
          {},
          this._addresses.find(a => a.IsDefaultBillTo)
        );
        console.log(this.quote.BillToLocation);
      }
      console.log('set');

      resolve(null);

    });
  }
  round(value: number, decimalPlaces: number) {
    return Number(Math.round(parseFloat(value + 'e' + decimalPlaces)) + 'e-' + decimalPlaces).toFixed(decimalPlaces)
  }
  openReviews(carrierName: string) {
    var url = "reviews/" + encodeURIComponent(carrierName) + "/";
    window.open(url, "_blank");
  }
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.estimatesLoaded = true;
  }
}
