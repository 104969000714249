import { Routes } from '@angular/router';
import { QuoteComponent } from './quote.component';
import { AuthGuard } from '../core/auth.guard';
var ɵ0 = {
    state: 'quote',
    headerImage: 'assets/images/banners/feature-header-request.jpg'
}, ɵ1 = {
    state: 'quote',
    headerImage: 'assets/images/banners/feature-header-request.jpg'
};
var routes = [
    {
        path: 'initial',
        redirectTo: 'start'
    },
    {
        path: 'ltl-shipping-quote/start',
        redirectTo: 'ltl-shipping-quote'
    },
    {
        path: 'ltl-shipping-quote',
        component: QuoteComponent,
        data: ɵ0
    },
    {
        path: 'ltl-shipping-quote/:id',
        component: QuoteComponent,
        canActivate: [AuthGuard],
        data: ɵ1
    }
    // {
    //   path: 'start/:id',
    //   component: QuoteComponent,
    //   data: {
    //     state: 'quote',
    //     headerImage: 'assets/images/banners/RiteQuote-lo.jpg'
    //   }
    // },
];
var QuoteRoutingModule = /** @class */ (function () {
    function QuoteRoutingModule() {
    }
    return QuoteRoutingModule;
}());
export { QuoteRoutingModule };
export { ɵ0, ɵ1 };
