/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../helpers/safe-style.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../helpers/safe-html.pipe";
import * as i5 from "@angular/common";
import * as i6 from "./faq.component";
import * as i7 from "@angular/router";
var styles_FaqComponent = [i0.styles];
var RenderType_FaqComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FaqComponent, data: {} });
export { RenderType_FaqComponent as RenderType_FaqComponent };
function View_FaqComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [["class", "faq pt-2 pb-2 pointer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "faq-title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [], [[8, "style", 2], [8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(4, 1), i1.ɵppd(5, 1)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), (_v.context.$implicit.visible ? "height: auto; overflow: hidden; margin-bottom: 16px;" : "height: 0px; overflow: hidden; margin-bottom: 0px;"))); var currVal_2 = i1.ɵunv(_v, 3, 1, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 1), _v.context.$implicit.content)); _ck(_v, 3, 0, currVal_1, currVal_2); }); }
export function View_FaqComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeStylePipe, [i3.DomSanitizer]), i1.ɵpid(0, i4.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [["class", "list-unstyled"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqComponent_1)), i1.ɵdid(4, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.faqs; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_FaqComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-faq", [], null, null, null, View_FaqComponent_0, RenderType_FaqComponent)), i1.ɵdid(1, 114688, null, 0, i6.FaqComponent, [i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FaqComponentNgFactory = i1.ɵccf("rtrt-faq", i6.FaqComponent, View_FaqComponent_Host_0, { singleOnly: "singleOnly" }, {}, []);
export { FaqComponentNgFactory as FaqComponentNgFactory };
