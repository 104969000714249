<div class="col-12 mb-1">
    <div>
        <h4 class="d-inline-block mr-2">{{review.name}}</h4>
        <span id="ratingStarContainer">
            <rtrt-carrier-review-stars [rating]="review.rating"></rtrt-carrier-review-stars>
        </span>
    </div>
    <span class="subText">{{review.formattedDate}}</span>
</div>
<div class="col-12">
    <p>{{review.comments}}</p>
</div>
