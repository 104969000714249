export enum OpenHoursType {
  Shipping,
  Receiving
}

export enum DaysOfTheWeek {
  None = 0,
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Every
}

export class LocationOpenTime {
  Day: DaysOfTheWeek;
  Type: OpenHoursType;
  FromHour: number;
  FromMinute: number;
  ToHour: number;
  ToMinute: number;
  constructor() {}
}

export class Location {
  JlsNo: string;
  ID: number;
  Name: string = "";
  AddressLineOne: string;
  AddressLineTwo?: string;
  City: string;
  StateOrProvince: string;
  PostalCode: string;
  PhoneOne: string;
  PhoneTwo: string;
  Fax: string;
  ContactName: string = "";
  ContactEmail: string;
  Comments: string;
  BlindShipName: string;
  BlindShipAddress: string;
  CountryString: string;
  IBCollect: string;
  IsDefaultBillTo: boolean;
  IsConsignee: boolean;
  IsShipper: boolean;
  OpenHours: LocationOpenTime[];
  IsPostalCodeValid: boolean;
  Validated: boolean;
  InsideDeliveryCarrier: string;
  constructor() {
    this.Name = "";
    this.ContactName = "";
    this.OpenHours = [];
    this.CountryString = "USA";
    this.AddressLineOne = "";
    this.IsDefaultBillTo = false;
    this.IsConsignee = false;
    this.IsShipper = false;
    this.IsPostalCodeValid = false;
    this.PostalCode = "";
    this.Validated = false;
    this.InsideDeliveryCarrier = "";
  }
}
export interface ILocationResponse {
  total: number;
  results: Location[];
}
