<footer class="container-fluid">
  <section class="row no-padding bg-dark text-light sub-footer">
    <div class="container">
      <div class="row no-padding padded-children">
        <div class="col-xs-12 col-sm-6 col-md-3 pt-7 pb-4">
          <h4 class="mb-4">RiteRouting</h4>
          <p>
            RiteRouting provides transactional freight brokerage services.
          </p>
          <p>
            RiteRouting.com is a registered trade name of Jarrett Logistics Systems, Inc. MC#369240, DOT#2225601
          </p>
          <p>
            1347 N. Main Street
            <br />
            Orrville, OH 44667
          </p>
          <p>
            Phone: 833-725-RITE (7483)
          </p>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 pt-7 pb-4">
          <h4 class="mb-4">Quick Links</h4>
          <nav>
            <a routerLink="/">Home</a>
            <a routerLink="/ltl-freight-shipping">LTL Freight Shipping</a>
            <a routerLink="/about-us">About Us</a>
            <a routerLink="/blog/page/1">Blog</a>
            <a routerLink="/resources/faq">FAQ</a>
            <a routerLink="/resources/shipping-terms-glossary">Shipping Terms Glossary</a>
            <a routerLink="/how-to-ship-freight">How to Ship Freight</a>
            <a routerLink="/contact-us">Contact Us</a>
          </nav>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 pt-7 pb-4">
          <h4 class="mb-4">Account</h4>
          <nav>
            <button
              (click)="open('login')"
              *ngIf="!authService.isAuthenticated"
              class="btn btn-link float-left"
            >
              Login
            </button>
            <button
              (click)="open('account')"
              *ngIf="!authService.isAuthenticated"
              class="btn btn-link float-left"
            >
              Create My Account
            </button>
            <a
              routerLink="/account"
              routerLinkActive="active"
              *ngIf="authService.isAuthenticated"
              class="d-none d-md-inline-block"
              >Account</a
            >
            <button
              (click)="logout()"
              *ngIf="authService.isAuthenticated"
              class="btn btn-link"
            >
              Logout
            </button>
            <a routerLink="/account/quotes" *ngIf="authService.isAuthenticated"
              >Previous Quotes</a
            >
            <a routerLink="/freight-tracking" *ngIf="authService.isAuthenticated"
              >Freight Tracking</a
            >
          </nav>
        </div>
        <!-- <div class="col-xs-12 col-sm-6 col-md-3 bg-light-faded pt-7 pb-1 pr-4 pl-4">
          <h4 class="mb-4">Request A RiteQuote</h4>
          <rtrt-quote-initiate-form></rtrt-quote-initiate-form>
        </div> -->
      </div>
    </div>
  </section>
  <section class="site-footer row bg-primary text-light b-top-heavy-light">
    <div class="container">
      <div class="footer-content">
        <div class="icon-wrapper">
          <span class="icon">
            <a href="https://www.facebook.com/RiteRouting/" target="_blank" rel="noopener"
              ><div><img width="36px" height="36px" alt="Facebook Logo"
                class="img-fluid rounded-circle"
                src="assets/images/icon-reversed-facebook.svg"
            /></div></a>
          </span>
          <span class="icon">
            <a href="https://www.linkedin.com/company/rite-routing" target="_blank" rel="noopener"
              ><div><img width="36px" height="36px" alt="LinkedIn Logo"
                class="img-fluid rounded-circle"
                src="assets/images/icon-reversed-linkedin.svg"
            /></div></a>
          </span>
          <span class="icon">
            <a href="https://twitter.com/RiteRouting" target="_blank" rel="noopener"
              ><div><img width="36px" height="36px" alt="Twitter Logo"
                class="img-fluid rounded-circle"
                src="assets/images/icon-reversed-twitter.svg"
            /></div></a>
          </span>
        </div>
        <nav>
          <span>&copy; RiteRouting. All Rights Reserved</span>
          <a routerLink="/privacy-policy" routerLinkActive="active"
            >Privacy Policy</a
          >
          <a routerLink="/terms-conditions" routerLinkActive="active"
            >Terms &amp; Conditions</a
          >
        </nav>
        <div class="logo-wrapper">
          <img width="200px" height="39px" alt="Rite Routing Logo"
            class="footer-logo img-fluid"
            src="assets/images/riterouting-logo-reversed.svg"
          />
        </div>
      </div>
    </div>
  </section>
</footer>
