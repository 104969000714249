import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from './config.service';

@Injectable()
export class ZipService {
  api: string;

  constructor(
    private httpClient: HttpClient,
    private config: ConfigService
  ) {
    this.api = this.config.getConfig('api');
  }


  checkZip(z: string) {
    return this.httpClient.get(this.api + 'api/validate/postalcode/' + z);
  }
  checkFinalMileOriginZip(z: string) {
    return this.httpClient.get(this.api + 'api/validate/postalcode/finalmile/' + 'origin' + '/' + z);
  }
  checkFinalMileDestinationZip(z: string) {
    return this.httpClient.get(this.api + 'api/validate/postalcode/finalmile/' + 'destination' + '/' + z);
  }
}
