import { Component, Input } from '@angular/core';

@Component({
  selector: 'rtrt-quote-history',
  templateUrl: './quote-history.component.html',
  styleUrls: ['./quote-history.component.scss']
})
export class QuoteHistoryComponent {
  @Input() history: any[];

  constructor() { }

}
