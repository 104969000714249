import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, FormControl, PatternValidator } from '@angular/forms';

const INT_REGEX: RegExp = /^[0-9]([1-9]+)?$/i;

export function validateInteger(c: AbstractControl) {
  return INT_REGEX.test(c.value) ? null : {
    integer: {
      valid: false
    }
  };
}

@Directive({
    selector: '[validateInteger][formControlName],[validateInteger][formControl],[validateInteger][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => PatternValidator), multi: true }
    ]
})
export class IntegerValidator implements Validator {
  constructor() {}

  validate(c: AbstractControl) {
    return validateInteger(c);
  }
}
