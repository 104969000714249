import { Component, Input, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Subscription ,  Subject ,  Observable } from 'rxjs';

import {
  Shipment, Commodity, ClientCommodity
} from '../../models';
import { QuoteService } from '../../services/quote.service';
import { FreightItem } from '../freight-item.model';
import { FlashService } from '../../shared/flash/flash.service';
import { AccountService } from '../../services/account.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'rtrt-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent implements OnDestroy {
  subscriptions: Subscription[] = [];
  handlingUnits: any[] = [];
  quote: Shipment;
  submission$:  Observable<any>;
  private items: any[] = [];
  private isSubmitted: Subject<any> = new Subject<any>();
  private _clientCommodities: ClientCommodity[] = [];
  isAuthenticated: boolean;

  constructor(
    private quoteService: QuoteService,
    private accountService: AccountService,
    private authService: AuthenticationService,
    private router: Router,
    private flash: FlashService
  ) {
    this.subscriptions.push(
      this.authService.loggedIn.subscribe(res => (this.isAuthenticated = res))
    );
    this.subscriptions.push(
      this.quoteService.quote$ 
        .subscribe(q => {
          this.quote = q;
          this.quote.Commodities.forEach((c, idx) => this.items.push({ item: idx, isValid: false }));
        })
    );
    this.subscriptions.push(
      this.quoteService.handlingUnits$
        .subscribe(hu => this.handlingUnits = hu)
    );
    this.accountService.refreshCommodities();
    this.subscriptions.push(
      this.accountService.commodity$.subscribe(c => {
        this._clientCommodities = c.map(c1 => Object.assign(new ClientCommodity(), c1));
      })
    );
    this.submission$ = this.isSubmitted.asObservable();
  }
  get clientCommodities() {
    return this._clientCommodities;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onSubmit() {
    if (!this.authService.isLoggedIn())
      return;
    // this.estimateService.updateQuote(this.quote);
    this.isSubmitted.next(true);
    const promises = []
    if (this.items.filter(vi => !vi.isValid).length > 0){
      return false;
    } else {
      this.quote.Commodities.map((comm) => {
        promises.push(this.quoteService.saveCommodity(comm).then(res => {
          comm.CommodityID = res.CommodityID;
        }));
      });

      Promise.all(promises)
        .then(response => {
          // console.log('item-details updateQuote');
          this.quoteService.updateQuote(this.quote, true);
        });
    }

    this.router.navigate(['/quote', 'origin']);
  }

  handleValidationResults(event: any) {
    if (event.isValid) {
      const idx = this.items.findIndex(vi => vi.item === event.item);
      if (idx > -1)
        this.items[idx].isValid = true;
    }
  }

}
