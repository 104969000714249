import { EventEmitter, OnChanges } from '@angular/core';
// import { FreightItem } from '../freight-item.model';
import { Commodity } from '../../models';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
var FreightItemComponent = /** @class */ (function () {
    function FreightItemComponent() {
        var _this = this;
        this.onRemoveItem = new EventEmitter();
        this.focusFreight$ = new Subject();
        this.clickFreight$ = new Subject();
        this.searchFreight = function (textFreight$) {
            var debouncedText$ = textFreight$.pipe(debounceTime(200), distinctUntilChanged());
            var clicksWithClosedPopup$ = _this.clickFreight$.pipe(filter(function () { return !_this.instance.isPopupOpen(); }));
            var inputFocus$ = _this.focusFreight$;
            return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(map(function (term) { return _this.freightClasses.filter(function (f) { return new RegExp(term, 'mi').test(f.toString()); }); }));
        };
    }
    FreightItemComponent.prototype.CheckFreightClass = function (input) {
        if (input == 0 || input == '0') {
            this.item.FreightClass = null;
            this.focusFreight$.next(null);
        }
        else {
            this.focusFreight$.next(input);
        }
    };
    FreightItemComponent.prototype.ngOnChanges = function () {
        this.item.Density = (!this.item.Density) ? 0 : this.item.Density;
        if (this.touched) {
            this.class.control.markAsTouched();
            this.valueofgoods.control.markAsTouched();
            this.weight.control.markAsTouched();
        }
    };
    Object.defineProperty(FreightItemComponent.prototype, "displayID", {
        get: function () {
            return this.id + 1;
        },
        enumerable: true,
        configurable: true
    });
    FreightItemComponent.prototype.getID = function () {
        return this.id;
    };
    FreightItemComponent.prototype.calcPCF = function () {
        var pcf = Number.parseFloat((this.item.GrossWeight / (((this.item.Length * this.item.Width * this.item.Height) / (12 * 12 * 12)) * this.item.HandlingUnits)).toFixed(2));
        this.item.Density = (!Number.isNaN(pcf)) ? pcf : 0;
    };
    Object.defineProperty(FreightItemComponent.prototype, "suggestedClass", {
        get: function () {
            return this.item.Density;
        },
        enumerable: true,
        configurable: true
    });
    FreightItemComponent.prototype.selectClass = function (val) {
        this.item.FreightClass = val;
    };
    FreightItemComponent.prototype.validateClass = function (input) {
        this.class.control.markAsTouched();
        if (input !== '') {
            if (this.freightClasses.map(function (f) { return f.toString(); }).indexOf(this.item.FreightClass.toString()) === -1) {
                this.class.control.setErrors({ 'invalid': true });
            }
            else
                this.class.control.setErrors(null);
        }
    };
    FreightItemComponent.prototype.removeItem = function () {
        this.onRemoveItem.emit(this.item);
    };
    FreightItemComponent.prototype.selectCopy = function (commoditiy, i) {
        this.item.GrossWeight = commoditiy.GrossWeight;
        this.item.FreightClass = commoditiy.FreightClass;
        this.item.Length = commoditiy.Length;
        this.item.Width = commoditiy.Width;
        this.item.Height = commoditiy.Height;
        this.item.Stackable = commoditiy.Stackable;
        this.item.Density = commoditiy.Density;
        this.item.ValueOfGoods = commoditiy.ValueOfGoods;
        this.copyID = i + 1;
    };
    return FreightItemComponent;
}());
export { FreightItemComponent };
