import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ReviewService } from '../../services/review.service';
import { CarrierReview } from '../../models';

@Component({
  selector: 'rtrt-carrier-reviews',
  templateUrl: './carrier-reviews.component.html',
  styleUrls: ['./carrier-reviews.component.scss']
})
export class CarrierReviewsComponent implements OnInit {
  subscriptions: Subscription[] = [];
  carrierName: string;
  carrierReviews: CarrierReview[] = [];

  constructor(
    private route: ActivatedRoute,
    private reviewService: ReviewService
  ) {
    this.subscriptions.push(this.route.params.subscribe(params => {
      if (params['carrierName']) {
        this.carrierName = decodeURIComponent(params['carrierName']);
      }
    }));
   }

  ngOnInit() {
    console.log(this.carrierName);
    this.reviewService.loadReviews(this.carrierName)
      .then((results: any) => {
        if (
          typeof results === "undefined" ||
          results === null ||
          typeof results.data === "undefined" ||
          results.data === null
        )
          this.carrierReviews = [];
        else
          this.carrierReviews = results.data.map(e => Object.assign(new CarrierReview(), e));
      });
  }
  get ReviewCount(): number {
    return this.carrierReviews.length;
  }
  get AverageRating(): string {
    return this.ReviewCount > 0 ? (Math.round((this.carrierReviews.map(r => parseFloat(r.rating.toString())).reduce(this.sum) / this.carrierReviews.length) * 10) / 10).toFixed(1) : '0';
  }

  sum(accumulator, val) {
    if (!isNaN(val))
      return accumulator + val;
    else
      return accumulator;
  }
  getRatingPercentage(rating): string {
    return (this.ReviewCount > 0 ? (Math.round((this.carrierReviews.filter(r => r.rating == rating).length / this.ReviewCount) * 100)).toString() : '0') + '%';
  }
}
