/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./carrier-review-stars.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./carrier-review-stars.component";
var styles_CarrierReviewStarsComponent = [i0.styles];
var RenderType_CarrierReviewStarsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarrierReviewStarsComponent, data: {} });
export { RenderType_CarrierReviewStarsComponent as RenderType_CarrierReviewStarsComponent };
function View_CarrierReviewStarsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "ratingStar"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background": 0 }), (_l()(), i1.ɵted(-1, null, ["\u2605"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.getGradient(_co.rating, _v.context.$implicit)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CarrierReviewStarsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_CarrierReviewStarsComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(2, 5)], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, 1, 2, 3, 4, 5); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CarrierReviewStarsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-carrier-review-stars", [], null, null, null, View_CarrierReviewStarsComponent_0, RenderType_CarrierReviewStarsComponent)), i1.ɵdid(1, 114688, null, 0, i3.CarrierReviewStarsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CarrierReviewStarsComponentNgFactory = i1.ɵccf("rtrt-carrier-review-stars", i3.CarrierReviewStarsComponent, View_CarrierReviewStarsComponent_Host_0, { rating: "rating" }, {}, []);
export { CarrierReviewStarsComponentNgFactory as CarrierReviewStarsComponentNgFactory };
