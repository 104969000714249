import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { AboutComponent } from './about/about.component';
import { BannerComponent } from './home/banner/banner.component';
// import { QuoteComponent } from './quote/quote.component';
import { HelpComponent } from './help/help.component';
import { FaqComponent } from './help/faq/faq.component';
import { GlossaryComponent } from './help/glossary/glossary.component';

import { CoreModule } from './../core/core.module';
import { SharedModule } from './../shared/shared.module';
import { AccountModule } from './../account/account.module';
import { FinalMileModule } from './../final-mile/final-mile.module';

import { QuoteInitiateFormComponent } from './quote-initiate-form/quote-initiate-form.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ContactComponent } from './contact/contact.component';
import { AccountComponent } from './account/account.component';
import { OurProcessComponent } from './our-process/our-process.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TrackingComponent } from './tracking/tracking.component';
// import { TLQuoteComponent } from './tlquote/tlquote.component';
import { ShipmentTrackingComponent } from './tracking/shipment-tracking.component';
import { TruckloadComponent } from './truckload/truckload.component';
import { LessThanTruckloadComponent } from './less-than-truckload/less-than-truckload.component';
import { PasswordResetComponent } from './account/password-reset.component';
import { OrderHistoryComponent } from './tracking/order-history.component';
import { QuoteHistoryComponent } from './quote-history/quote-history.component';

// import { ObjectFilterPipe } from '../pipes/object-filter.pipe';
// import { ObjectDateFilterPipe } from '../pipes/object-date-filter.pipe';
// import { NgbDateENParserFormatter } from '../core/ngb-date-en-parser-formatter.model';
import { ProfileComponent } from './account/profile.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { BlogComponent } from './blog/blog.component';
import { BlogPostComponent } from './blog/blog-post/blog-post.component';
import { BlogPostDetailComponent } from './blog/blog-post-detail/blog-post-detail.component';
import { CarrierReviewsComponent } from './carrier-reviews/carrier-reviews.component';
import { CarrierReviewDetailComponent } from './carrier-reviews/carrier-review-detail/carrier-review-detail.component';
import { FinalMileTrackingComponent } from './tracking/final-mile-tracking/final-mile-tracking.component';
import { FinalMileVendorTrackingComponent } from './tracking/final-mile-tracking/final-mile-vendor-tracking.component';
import { FinalMileAboutComponent } from './final-mile-about/final-mile-about.component';
import { QuotingServicesComponent } from './quoting-services/quoting-services.component';
import { BlogTagSummaryComponent } from './blog/blog-tag-summary/blog-tag-summary.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FinalMileModule,
    AccountModule,
    CoreModule,
    // QuoteModule,
    FormsModule,
    NgbModule,
    RouterModule,
    ReactiveFormsModule 
  ],
  declarations: [
    HomeComponent,
    ServicesComponent,
    AboutComponent,
    BannerComponent,
    // QuoteComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    ContactComponent,
    AccountComponent,
    OurProcessComponent,
    HelpComponent,
    FaqComponent,
    GlossaryComponent,
    TrackingComponent,
    // TLQuoteComponent,
    TruckloadComponent,
    LessThanTruckloadComponent,
    PasswordResetComponent,
    OrderHistoryComponent,
    QuoteHistoryComponent,
    // ObjectFilterPipe,
    // ObjectDateFilterPipe,
    QuoteInitiateFormComponent,
    ShipmentTrackingComponent,
    ProfileComponent,
    StarRatingComponent,
    BlogComponent,
    BlogPostComponent,
    BlogPostDetailComponent,
    CarrierReviewsComponent,
    CarrierReviewDetailComponent,
    FinalMileTrackingComponent,
    FinalMileVendorTrackingComponent,
    FinalMileAboutComponent,
    QuotingServicesComponent,
    BlogTagSummaryComponent
  ],
  exports: [
    AboutComponent,
    AccountComponent,
    HomeComponent,
    OurProcessComponent,
    PrivacyPolicyComponent,
    ServicesComponent,
    TermsConditionsComponent,
    HelpComponent
    , TrackingComponent
    // , TLQuoteComponent
    , PasswordResetComponent
    , QuoteHistoryComponent
    , QuoteInitiateFormComponent,
    CarrierReviewDetailComponent
  ],
  // providers: [
  //   {
  //     provide: NgbDateParserFormatter,
  //     useFactory: () => new NgbDateENParserFormatter()
  //   }
  // ]
})
export class PagesModule { }
