import { throwError as observableThrowError, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable()
export class ReviewService {
  api: string;

  constructor(
    private config: ConfigService,
    private httpClient: HttpClient
  ) {
    this.api = this.config.getConfig('api');
  }

  loadReviews(carrierName: string) {
    return this.httpClient.get(`${this.api}api/reviews/load/${this.utoa(carrierName)}`)
      .pipe(catchError(err => throwError(err)))
      .toPromise();
  }
   utoa(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }
}
