export class ClientCommodity {
    ID: string;
    Description: string;
    NMFC: string;
    Class: string;

    get FullDescription(): string {
        return `${this.Description} | NMFC: ${this.NMFC} | Class: ${this.Class}`;
    }
    get MatchString(): string {
        return `${this.Description} ${this.NMFC} ${this.Class}`;
    }
    constructor() {
        this.ID = "";
        this.Description = "";
        this.NMFC = "";
        this.Class = "";
    }

    
}