/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../login-form/login-form.component.ngfactory";
import * as i3 from "../login-form/login-form.component";
import * as i4 from "../../services/account.service";
import * as i5 from "../../core/auth.guard";
import * as i6 from "@angular/common";
import * as i7 from "./login-modal.component";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
var styles_LoginModalComponent = [i0.styles];
var RenderType_LoginModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginModalComponent, data: {} });
export { RenderType_LoginModalComponent as RenderType_LoginModalComponent };
function View_LoginModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row pt-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-link"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close("password") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Forget password?"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "col text-center modal-footer-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Don't have a RiteRouting account yet?"])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-link"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close("account") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Create a RiteRouting Account"]))], null, null); }
export function View_LoginModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "rtrt-login-form", [], null, [[null, "onLogin"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLogin" === en)) {
        var pd_0 = (_co.handleLogin($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LoginFormComponent_0, i2.RenderType_LoginFormComponent)), i1.ɵdid(2, 114688, null, 0, i3.LoginFormComponent, [i4.AccountService, i5.AuthGuard], { username: [0, "username"], title: [1, "title"] }, { onLogin: "onLogin" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginModalComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.username; var currVal_1 = "RiteRouting Account Login"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_co.hideLinks; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_LoginModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-login-modal", [], null, null, null, View_LoginModalComponent_0, RenderType_LoginModalComponent)), i1.ɵdid(1, 114688, null, 0, i7.LoginModalComponent, [i8.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginModalComponentNgFactory = i1.ɵccf("rtrt-login-modal", i7.LoginModalComponent, View_LoginModalComponent_Host_0, { target: "target", hideLinks: "hideLinks" }, { onLoggedIn: "onLoggedIn" }, []);
export { LoginModalComponentNgFactory as LoginModalComponentNgFactory };
