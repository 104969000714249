import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';

import { CATEGORIES_META } from '../meta';

@Component({
  selector: 'rtrt-quoting-services',
  templateUrl: './quoting-services.component.html',
  styleUrls: ['./quoting-services.component.scss']
})
export class QuotingServicesComponent implements OnInit {
  showLTL: boolean = false;
  showFinalMile: boolean = false;

  constructor(private router: Router,
    private title: Title,
    private meta: Meta,
    private metaService: MetaService) { }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.title.setTitle(CATEGORIES_META.title);
    this.metaService.removeItemprop();
    CATEGORIES_META.meta.forEach(m => this.meta.updateTag(m));
  }
  
  routeTo(path: string) {
    this.router.navigate([path]);
  }
}
