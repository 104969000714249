import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FlashService } from '../../shared/flash/flash.service';
import { AccountService } from '../../services/account.service';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';

@Component({
  selector: 'rtrt-password-reset-form',
  templateUrl: './password-reset-form.component.html',
  styleUrls: ['./password-reset-form.component.scss']
})
export class PasswordResetFormComponent {
  @Input() id: string;
  @Input() token: string;
  @Output() onReset: EventEmitter<boolean> = new EventEmitter<boolean>();
  password: string;
  password2: string;
  message: string;
  spinner = false;

  constructor(
      private as: AccountService,
      private flashService: FlashService
    ) { }

  reset(e) {
    e.preventDefault();
    if (this.password !== this.password2) {
      this.throwResetError('Passwords do not match');
      return false;
    }

    this.message = null;
    this.spinner = true;
    this.as.resetPassword(this.password, this.id, this.token)
      .catch(err => {
        console.log('Password reset error ... ', err);
        this.throwResetError();
        this.onReset.emit(false);
        return;
      })
      .then(res => {
        this.spinner = false;
        if (res && res !== 'Success') {
          try {
            const err = JSON.parse(res.error);
            this.throwResetError(err.reason);
          } catch (ex) {
            this.throwResetError();
          }
          this.onReset.emit(false);
          return;
        }
        this.flashService.flashMessage('Your password has successfully been reset.', '', 'success');
        this.onReset.emit(true);
        return;
      });
  }

  throwResetError(err: any = 'There was a problem resetting your password') {
    this.flashService.flashMessage(err, '', 'danger');
  }

}
