import { Component, Input } from '@angular/core';

@Component({
  selector: 'rtrt-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  @Input() visible = false;
  @Input() message: string;
  @Input() display = 'inline-block';
  @Input() width = 'auto';
  @Input() class = '';

  constructor() { }

}
