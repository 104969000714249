import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { FlashMessage } from './flash.model';

@Injectable()
export class FlashService {
  private _flash: FlashMessage = new FlashMessage();
  public flash$: BehaviorSubject<FlashMessage> = new BehaviorSubject<FlashMessage>(this._flash);

  constructor() { }

  flashMessage(m: string = '', t: string = '', type: string = 'info', v: boolean = true) {
    this._flash.message = m;
    this._flash.type = type;
    this._flash.visible = v;
    this._flash.title = t;
    this.flash$.next(this._flash);
    setTimeout(() => {
      this.dismissMessage();
    }, 30000);
  }

  dismissMessage() {
    this.flashMessage('', '', 'info', false);
  }

}
