import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LTL } from '../content';

@Component({
  selector: 'rtrt-less-than-truckload',
  templateUrl: './less-than-truckload.component.html',
  styleUrls: ['./less-than-truckload.component.scss']
})
export class LessThanTruckloadComponent implements OnInit {
  headerBg: string;
  content = LTL;

  constructor(
    route: ActivatedRoute
  ) {
    route.data.subscribe((data) => {
      this.headerBg = data.headerImage;
    });
  }

  ngOnInit() {
  }

}
