import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, ViewChild } from '@angular/core';
import { Observable ,  Subject ,  Subscription, merge } from 'rxjs';
import { AccountService } from "../../services/account.service";


import { BugComponent } from '../../shared/bug/bug.component';
import { Commodity, ClientCommodity } from '../../models';
import { QuoteService } from '../../services/quote.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'rtrt-freight-item-detail',
  templateUrl: './freight-item-detail.component.html',
  styleUrls: ['./freight-item-detail.component.scss']
})
export class FreightItemDetailComponent implements OnInit, OnDestroy {
  @Input() commodity: Commodity;
  @Input() id: number;
  @Input() handlingUnits: any[] = [];
  @Input() isSubmitting: Observable<any>;
  @Input() commodities: Commodity[] = [];
  @Input() _clientCommodities: ClientCommodity[] = [];
  @Output() validationResult: EventEmitter<any> = new EventEmitter<any>();
  copyID: number;
  private subscriptions: Subscription[] = [];
  private controls = {
    errors: {
      desc: true,
      hu: true,
      pieces: true
    },
    touched: {
      desc: false,
      hu: false,
      pieces: false
    }
  };

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @ViewChild('instance2', { static: true }) instance2: NgbTypeahead;
  clickHU$ = new Subject<string>();
  focusHU$ = new Subject<string>();
  clickComm$ = new Subject<string>();
  focusComm$ = new Subject<string>();


  searchHandlingUnit = (textHU$: Observable<string>) => {
    const debouncedText$ = textHU$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickHU$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focusHU$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => this.handlingUnits.filter(f => new RegExp(term, 'mi').test(f.Value.toString())))
    );
  }
  searchCommodity = (textComm$: Observable<string>) => {
    const debouncedText$ = textComm$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickComm$.pipe(filter(() => !this.instance2.isPopupOpen()));
    const inputFocus$ = this.focusComm$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => this.clientCommodities.filter(f => new RegExp(term, 'mi').test(f.MatchString.toString())))
    );
  }
  formatter = (x: any) => x.Value;
  formatter1 = (x: any) => x.FullDescription;
  commHU: { Value: '', ID: '' };

  constructor(
    private quoteService: QuoteService
  ) { }

  ngOnInit() {
    if (this.commodity.HandlingTypeID && this.commodity.HandlingTypeID !== null)
      this.selectHU({ID: this.commodity.HandlingTypeID, Value: this.commodity.HandlingType});
    this.subscriptions.push(
      this.isSubmitting.subscribe((submitting: boolean) => {
        if (submitting)
          this.validate();
      })
    );
  }

  get clientCommodities() {
    return this._clientCommodities.filter(
      c =>
        c.Class == this.commodity.FreightClass.toString()
    );
  }
  get handlingUnit() {
    const hu = (
      !this.commodity
      || !this.commodity.HandlingTypeID
      || this.commodity.HandlingTypeID === null
      || !this.handlingUnits
      || this.handlingUnits.length === 0)
        ? null
        : this.handlingUnits.find(h => h.ID === this.commodity.HandlingTypeID);

    return  (!hu || hu === null)
        ? 'Please Select'
        : hu.Value;
  }

  get displayID() {
    return this.id + 1;
  }

  get touched() {
    return this.controls.touched;
  }

  get errors() {
    return this.controls.errors;
  }

  touch(idx: string, val: string = null) {
    this.controls.touched[idx] = true;
    if (idx !== 'hu') {
      if (!this.commodity[val] || this.commodity[val] === '' || this.commodity[val] === null || this.commodity[val] <= 0)
        this.controls.errors[idx] = true;
      else
        this.controls.errors[idx] = false;
    }
    else {
        if ((!this.commHU || this.commHU.Value == null || this.commHU.Value == '') || (this.commHU.Value != '' && this.handlingUnits.map(hu => hu.Value).indexOf(this.commHU.Value) === -1)) {
          this.controls.errors[idx] = true;
        }
        else
          this.controls.errors[idx] = false;
    }
  }

  touchAll() {
    this.touch('hu', "HandlingType");
    this.touch('desc', 'CommodityDescription');
    this.touch('nmfc', 'NMFC');
    this.touch('pieces', 'Pieces');
  }

  selectHU(hu: any) {
    this.commHU = hu;

    this.commodity.HandlingTypeID = hu.ID;
    this.commodity.HandlingType = hu.Value;
    this.controls.errors.hu = false;
    //this.touch('hu');
  }
  selectComm($event: any, input) {
    $event.preventDefault();

    var comm = $event.item;
    
    this.commodity.CommodityDescription = comm.Description;
    this.commodity.NMFC = comm.NMFC;

    input.value = '';
  }

  validate() {
    this.touchAll();
    const isValid = !(this.errors.hu || this.errors.desc || this.errors.pieces);
    this.validationResult.emit({ item: this.id, isValid: isValid });
  }

  selectCopy(commoditiy, i) {
    this.commodity.CommodityDescription = commoditiy.CommodityDescription;
    this.commodity.NMFC = commoditiy.NMFC;
    this.commodity.Pieces = commoditiy.Pieces;
    this.commodity.HandlingType = commoditiy.HandlingType;
    this.commodity.HandlingTypeID = commoditiy.HandlingTypeID;
    this.selectHU({ ID: this.commodity.HandlingTypeID, Value: this.commodity.HandlingType });
    this.copyID = i + 1;  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onSubmit(e) {
    console.log(e);
  }

}
