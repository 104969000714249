import { Injectable, Optional } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ConfigService } from "./config.service";
import { User } from "../account/user.model";

@Injectable()
export class UserService {
  private api = "";
  constructor(private http: HttpClient, private config: ConfigService) {
    this.api = config.getConfig("api");
  }

  create(u: User): Observable<boolean> {
    return this.http.post(this.api + "api/users/add", u).pipe(
      catchError(err => {
        return throwError(err);
      }),
      map(res => {
        return res as boolean;
      })
    );
  }
}
