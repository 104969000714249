import { NavigationEnd, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(router) {
        this.router = router;
        this.router.events.subscribe(function (event) {
            try {
                if (typeof ga === 'function') {
                    if (event instanceof NavigationEnd) {
                        ga('set', 'page', event.urlAfterRedirects);
                        ga('send', 'pageview');
                    }
                }
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    AnalyticsService.prototype.emitEvent = function (eventCategory, eventAction, eventLabel, eventValue) {
        if (eventLabel === void 0) { eventLabel = null; }
        if (eventValue === void 0) { eventValue = null; }
        if (typeof ga === 'function') {
            ga('send', 'event', {
                eventCategory: eventCategory,
                eventLabel: eventLabel,
                eventAction: eventAction,
                eventValue: eventValue
            });
        }
    };
    AnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.ɵɵinject(i1.Router)); }, token: AnalyticsService, providedIn: "root" });
    return AnalyticsService;
}());
export { AnalyticsService };
