<form #forgotForm="ngForm" class="container" (ngSubmit)="onSubmit()" *ngIf="!resetSuccess">
    <div *ngIf="title" class="row">
        <div class="col-12">
        <h4 class="modal-title">{{title}}</h4>
        </div>
        <div class="col-12" *ngIf="message">
        <div class="alert alert-danger">{{message}}</div>
        </div>
    </div>
    <div class="row" *ngIf="spinner">
        <div class="col-12 pt-4 pb-4 text-center">
            <rtrt-spinner [visible]="spinner"></rtrt-spinner>
        </div>
    </div>
    <div class="form-fields" *ngIf="!spinner">
        <div class="row">
            <div class="form-group col">
                <label class="form-control-label required" for="email">Email Address</label>
                <input type="text" class="form-control" id="email" [(ngModel)]="email" name="email" required>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
        <button type="submit" class="btn btn-primary w-100" [disabled]="spinner">Reset Password</button>
        </div>    
    </div>
</form>
<p *ngIf="resetSuccess">
    A request has been submitted to reset your password. You should receive an email from 'noreply@riterouting.com' with further instructions.
</p>