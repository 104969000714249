import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { User } from '../account/user.model';
import { AuthenticationService } from '../services/authentication.service';
import { LoginModalComponent } from '../account/login-modal/login-modal.component';
import {NgbModal, NgbActiveModal, NgbModalRef, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

const unrestrictedParams = [
  {
    page: 'quote',
    params: [
      'start',
      'carrier'
    ]
  },
  {
    page: 'final-mile',
    params: [
      'start',
      'carrier'
    ]
  }
];

@Injectable()
export class AuthGuard implements CanActivate {
  private next: ActivatedRouteSnapshot;
  private state: RouterStateSnapshot;
  private statusChecked = 0;

  constructor(
    private auth: AuthenticationService,
    public router: Router,
    private modalService: NgbModal
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.next = next;
    this.state = state;
    console.log(this.next);
    console.log(this.state);
    try {
      if (this.checkStatus())
        return true;

      this.openLogin()
        .then(() => {
          return true;
        });
    } catch {}
  }

  private checkStatus(): boolean {
    this.statusChecked++;
    if (this.auth.isLoggedIn()) {
      return true;
    } else {
      let isRestricted = true;
      if (this.next.params && this.next.params.id) {
        isRestricted = !this.hasFreeParam(this.next.data.state, this.next.params.id);
      }

      return (!isRestricted);
    }
  }

  private hasFreeParam(page: string, param: string) {
    const free = unrestrictedParams.find(r => r.page === page);
    if (!free || free === null)
      return false;

    return (free.params.findIndex(p => p === param) > -1);
  }

  openLogin() {
    if (this.modalService.hasOpenModals()) {
      return;
    }
    const modalOpts: NgbModalOptions = {};
    const modalRef = this.modalService.open(LoginModalComponent);
    modalRef.componentInstance.hideLinks = true;
    return modalRef
      .result.then((result) => {
        try {
          if (this.checkStatus()) {
            this.router.navigate([this.state.url]);
          } else {
            this.router.navigate(['/']);
          }
        } catch {
          this.router.navigate(['/']);
        }
      }, (reason) => {
        this.router.navigate(['/']);
      });
  }
}
