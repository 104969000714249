import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ShipmentService } from '../../services/shipment.service';
import { LoginModalComponent } from "../../account/login-modal/login-modal.component";
import { ForgotPasswordModalComponent } from "../../account/forgot-password-modal/forgot-password-modal.component";
import { User } from "../../account/user.model";
import { AccountService } from "../../services/account.service";
import { AuthenticationService } from "../../services/authentication.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, Observable, Subscription } from "rxjs";

@Component({
  selector: 'rtrt-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})

export class StarRatingComponent implements OnInit {
  highlightedStars: boolean[] = [];
  rating: number;
  carrierName: string;
  form: FormGroup;
  reviewComments: string = "";
  jlsNo: string;
  error = false;
  submitted = false;
  submitDisabled = false;

  user: User = null;
  isAuthenticated: boolean;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute, 
    private fb: FormBuilder, 
    private shipmentService: ShipmentService,
    private accountService: AccountService,
    public authService: AuthenticationService,
    private modalService: NgbModal,
  ) 
  {
    this.subscriptions.push(
      this.authService.loggedIn.subscribe(r => this.loginStatusChanged(r))
    );
    this.subscriptions.push(
      this.accountService.user$.subscribe(u => {
        this.user = u;
      })
    );
  }

  ngOnInit() {
    this.rating = Number.parseInt(this.route.snapshot.queryParamMap.get("stars"));
    this.jlsNo = this.route.snapshot.queryParamMap.get("jlsNo");
    this.carrierName = this.route.snapshot.queryParamMap.get("carrier");

    this.setRating(this.rating);
    this.instantiateForm();

    if(!this.isAuthenticated) {
      this.open('login');
    }
  }
  instantiateForm() {
    this.form = this.fb.group({
      comments: this.reviewComments
    });
  }
  onSubmit() {
    this.submitDisabled = true;

    const reviewForm = this.form;
    this.reviewComments = reviewForm.get("comments").value;

    this.shipmentService.submitReview(this.jlsNo, this.rating, this.reviewComments)
      .catch(err => {
        this.error = true;
      })
      .then(res => {
        if(!this.error)
          this.submitted = true;

        this.submitDisabled = false;
      });
  }
  setRating(rating: number) {
    if(rating > 0)
      this.rating = rating;

    this.highlightedStars.length = 0;

    for (let i = 0; i < 5; i++) {
      if (i < (5 - this.rating))
        this.highlightedStars.push(false);
      else
        this.highlightedStars.push(true);
    }
  }
  clearRating() {
    this.highlightedStars.length = 0;
    for (let i = 0; i < 5; i++) {
      this.highlightedStars.push(false);
    }
  }
  open(target: string) {
    if (typeof target === "string" && target === "password") {
      const modalRef = this.modalService.open(ForgotPasswordModalComponent);
      modalRef.result.then(
        result => {
          this.handleModalConfirm(result);
        },
        reason => {
          console.log(reason);
        }
      );
    } else {
      if (this.modalService.hasOpenModals())
        return false;
      const modalRef = this.modalService.open(LoginModalComponent).result.then(
        result => {
          this.handleModalConfirm(result);
        },
        reason => {
          console.log(reason);
        }
      );
    }
  }

  handleModalConfirm(target: any) {
    if (
      (typeof target === "string" &&
        (target === "login" ||
          target === "password")) ||
      (target && target.username)
    ) {
      this.open(target);
    }
  }
  loginStatusChanged(loggedIn: boolean) {
    this.isAuthenticated = loggedIn;
  }
}