<button type="button" class="btn btn-primary btn-mini d-lg-none" (click)="showToggle()">{{detailStatus}} Quote Details</button>
<div class="row">
    <div class="{{ show }} d-lg-block w-100" id="collapseDetails">
        <div class="col-12 d-flex justify-content-between align-items-center">
            <h4 class="text-uppercase mb-0">Quote Details</h4>
            <button type="button" class="btn btn-secondary btn-mini" (click)="edit('')" *ngIf="isEditable">Edit</button>
        </div>
        <div class="col-12">
            <p>
                <strong>Zip Codes</strong>
                <br />
                <span *ngIf="shipment?.Shipper?.PostalCode">Shipper:
                    <strong>{{shipment.Shipper.PostalCode}}</strong>
                    <br />
                </span>
                <span *ngIf="shipment?.Consignee?.PostalCode">Consignee:
                    <strong>{{shipment.Consignee.PostalCode}}</strong>
                    <br />
                </span>
            </p>
        </div>
        <div class="col-12" *ngIf="shipment?.Commodities?.length > 0">
            <p>
                Handling Units:
                <strong>{{totalUnits}}</strong>
                <br /> Gross Weight:
                <strong>{{grossWeight}}</strong>
            </p>
        </div>
        <div class="col-12" *ngIf="shipment?.AccessorialAR && allAccessorials?.length > 0">
            <p>
                <strong>Services</strong>
                    <span *ngFor="let accessorial of allAccessorials">
                        <br />{{accessorial.Text}}</span>
            </p>
        </div>
        <div class="col-12 pt-4 d-flex justify-content-between align-items-center" *ngIf="shipment?.Carriers?.length > 0 && shipment?.Carriers[0]?.CarrierName">
            <h4 class="text-uppercase mb-0">Carrier</h4>
            <button type="button" class="btn btn-secondary btn-mini" (click)="edit('carrier')" *ngIf="isEditable">Edit</button>
        </div>
        <div class="col-12" *ngIf="shipment?.Carriers?.length > 0 && shipment?.Carriers[0]?.CarrierName">
            <p>
                {{shipment.Carriers[0].CarrierName}}
                <br />
                <span *ngIf="!shipment.PaymentData">
                    Quote: <strong>{{shipment.TotalAR | currency}}</strong>
                    <br />
                </span>
                <span *ngIf="shipment.PromoCode && shipment.PromoCode.ArFlatCharge > 0">
                    You will save <strong>{{shipment.PromoCode.ArFlatCharge | currency}}</strong> with promo code <strong>{{shipment.PromoCode.PromoCodeText}}</strong>
                    <br />
                </span>
                <span *ngIf="shipment.PromoCode && shipment.PromoCode.ArDiscount > 0">
                    You will save <strong>{{(shipment.TotalAR * shipment.PromoCode.ArDiscount) | currency}}</strong> with promo code <strong>{{shipment.PromoCode.PromoCodeText}}</strong>
                    <br />
                </span>
            </p>
            <button type="button" *ngIf="!loading && !shipment.FinalMileShipment && shipment?.Carriers[0]?.CarrierID && shipment?.eQuoteNumber" class="btn btn-primary btn-mini" (click)="printQuote()">Print Quote</button>
            <rtrt-spinner [visible]="loading" [width]="'100%'"></rtrt-spinner>
        </div>
        <div class="col-12 pt-4 d-flex justify-content-between align-items-center" *ngIf="shipment?.Commodities?.length > 0 && shipment?.Commodities[0]?.CommodityDescription">
            <h4 class="text-uppercase mb-0">Commodities</h4>
            <button type="button" class="btn btn-secondary btn-mini" (click)="edit('item-details')" *ngIf="isEditable">Edit</button>
        </div>
        <div class="col-12" *ngIf="shipment?.Commodities?.length > 0 && shipment?.Commodities[0]?.CommodityDescription">
            <p *ngFor="let commodity of shipment?.Commodities; let i = index">
                <strong *ngIf="commodity.CommodityDescription">{{commodity.CommodityDescription}}
                    <br />
                </strong>
                <span *ngIf="commodity.HandlingUnits && commodity.HandlingType">
                    <strong>{{commodity.HandlingType}}</strong>
                    <br />
                </span>
                <span *ngIf="commodity.NMFC">NMFC:
                    <strong>{{commodity.NMFC}}</strong>
                    <br />
                </span>
                <span *ngIf="commodity.FreightClass">Class:
                    <strong>{{commodity.FreightClass}}</strong>
                </span>
            </p>
        </div>
        <div class="col-12 pt-4 d-flex justify-content-between align-items-center" *ngIf="shipment?.Shipper?.Name">
            <h4 class="text-uppercase mb-0">Origin</h4>
            <button type="button" class="btn btn-secondary btn-mini" (click)="edit('origin')" *ngIf="isEditable">Edit</button>
        </div>
        <div class="col-12" *ngIf="shipment?.Shipper?.Name">
            <p>
                <strong *ngIf="shipment?.Shipper?.Name">{{shipment.Shipper.Name}}</strong>
                <span *ngIf="shipment?.Shipper?.AddressLineOne">
                    <br />{{shipment.Shipper.AddressLineOne}}</span>
                <span *ngIf="shipment?.Shipper?.AddressLineTwo">
                    <br />{{shipment.Shipper.AddressLineTwo}}</span>
                <span *ngIf="shipment?.Shipper?.City || shipment?.Shipper?.StateOrProvince">
                    <br />
                </span>
                <span *ngIf="shipment?.Shipper?.City">{{shipment.Shipper.City}}</span>
                <span *ngIf="shipment?.Shipper?.City && shipment?.Shipper?.StateOrProvince">, </span>
                <span *ngIf="shipment?.Shipper?.StateOrProvince">{{shipment.Shipper.StateOrProvince}}</span>
                <span *ngIf="shipment?.Shipper?.City || shipment?.Shipper?.StateOrProvince">
                    <br />
                </span>
                <span *ngIf="shipment?.Shipper?.PhoneOne">{{shipment.Shipper.PhoneOne}}</span>
                <span *ngIf="shipment?.Shipper?.Fax">
                    <br />{{shipment.Shipper.Fax}}</span>
            </p>
            <p>
                <span *ngIf="shipment?.Shipper?.ContactName">{{shipment.Shipper.ContactName}}
                    <br />
                </span>
                <span *ngIf="shipment?.Shipper?.ContactEmail">{{shipment.Shipper.ContactEmail}}</span>
            </p>
            <p>
                <span *ngIf="pickupDate">Pickup:
                    <strong>{{pickupDate}}</strong>
                </span>
            </p>
        </div>
        <div class="col-12 pt-4 d-flex justify-content-between align-items-center" *ngIf="shipment?.Consignee?.Name">
            <h4 class="text-uppercase mb-0">Destination</h4>
            <button type="button" class="btn btn-secondary btn-mini" (click)="edit('destination')" *ngIf="isEditable">Edit</button>
        </div>
        <div class="col-12" *ngIf="shipment?.Consignee?.Name">
            <p>
                <strong *ngIf="shipment?.Consignee?.Name">{{shipment.Consignee.Name}}</strong>
                <span *ngIf="shipment?.Consignee?.AddressLineOne">
                    <br />{{shipment.Consignee.AddressLineOne}}</span>
                <span *ngIf="shipment?.Consignee?.AddressLineTwo">
                    <br />{{shipment.Consignee.AddressLineTwo}}</span>
                <span *ngIf="shipment?.Consignee?.City || shipment?.Consignee?.StateOrProvince">
                    <br />
                </span>
                <span *ngIf="shipment?.Consignee?.City">{{shipment.Consignee.City}}</span>
                <span *ngIf="shipment?.Consignee?.City && shipment?.Consignee?.StateOrProvince">, </span>
                <span *ngIf="shipment?.Consignee?.StateOrProvince">{{shipment.Consignee.StateOrProvince}}</span>
                <span *ngIf="shipment?.Consignee?.City || shipment?.Consignee?.StateOrProvince">
                    <br />
                </span>
                <span *ngIf="shipment?.Consignee?.PhoneOne">{{shipment.Consignee.PhoneOne}}</span>
                <span *ngIf="shipment?.Consignee?.Fax">
                    <br />{{shipment.Consignee.Fax}}</span>
            </p>
            <p>
                <span *ngIf="shipment?.Consignee?.ContactName">{{shipment.Consignee.ContactName}}
                    <br />
                </span>
                <span *ngIf="shipment?.Consignee?.ContactEmail">{{shipment.Consignee.ContactEmail}}</span>
            </p>
            <p>
                <span *ngIf="deliveryDate">Req. Delivery:
                    <strong>{{deliveryDate}}</strong>
                </span>
            </p>
        </div>
        <div class="col-12 pt-4 d-flex justify-content-between align-items-center" *ngIf="shipment?.References?.length > 0">
            <h4 class="text-uppercase mb-0">Reference Numbers</h4>
            <button type="button" class="btn btn-secondary btn-mini" (click)="edit('references')" *ngIf="isEditable">Edit</button>
        </div>
        <div class="col-12" *ngIf="shipment?.References?.length > 0">
            <p *ngFor="let ref of shipment?.References; let i = index">
                <span *ngIf="ref.ReferenceType && ref.TypeID && ref.TypeID === '101'">Quote #</span>
                <span *ngIf="ref.ReferenceType && ref.TypeID && ref.TypeID !== '101'">{{ref.ReferenceType}}</span>
                <strong *ngIf="ref.ReferenceValue">{{ref.ReferenceValue}}</strong>
            </p>
        </div>
        <div class="col-12 pt-4 d-flex justify-content-between align-items-center" *ngIf="shipment?.PaymentData">
            <h4 class="text-uppercase mb-0">Payment</h4>
            <button type="button" class="btn btn-secondary btn-mini" (click)="edit('payment')" *ngIf="isEditable">Edit</button>
        </div>
        <div class="col-12">
            <p>
                <span *ngIf="shipment?.PaymentData?.Brand">Card Type
                    <strong>{{shipment.PaymentData.Brand}}</strong>
                    <br />
                </span>
                <span *ngIf="shipment?.PaymentData?.Last4Digits">
                    Last 4 Digits
                    <strong>{{shipment.PaymentData.Last4Digits}}</strong>
                    <br />
                </span>
                <span *ngIf="shipment?.PaymentData?.Amount">
                    Total
                    <strong>{{shipment.PaymentData.Amount | currency}}</strong>
                    <small *ngIf="shipment?.PaymentData?.Currency">{{shipment.PaymentData.Currency}}</small>
                    <br />
                </span>
                <span *ngIf="shipment?.PaymentData?.PromoCode">
                    <small>
                        You saved
                        <strong>{{shipment.PaymentData.DiscountAmount | currency}}</strong>
                        with promo code
                        <strong>{{shipment.PaymentData.PromoCode.PromoCodeText}}</strong>
                        <br />
                    </small>
                </span>
            </p>
        </div>
    </div>
</div>
