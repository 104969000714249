<div class="rt-card mb-3">
  <header class="align-items-center justify-content-between">
    <h4 class="flex-grow">{{name}}</h4>
    <ng-container *ngIf="defaults !== ''">
      <h6 class="mr-1">Default: </h6>
      <h6 style="font-weight: 400;" class="mr-3">{{ defaults }}</h6>
    </ng-container>
    <button *ngIf="!noDelete" type="button" class="btn btn-link btn-mini" (click)="open(deleteConfirm)">Delete</button>
    <rtrt-address-modal [address]="address" [type]="type"></rtrt-address-modal>
  </header>
  <div class="rt-body container-fluid pt-3 pb-3">
    <div class="row">
      <div class="col-12" *ngIf="address.City || address.StateOrProvince">
        <p class="mb-0">
            <span *ngIf="address.AddressLineOne">{{address.AddressLineOne}}</span><br />
            <span *ngIf="address.City || address.StateOrProvince">{{address.City}}</span>
            <span *ngIf="address.City && address.StateOrProvince" class="d-none d-md-inline">, </span>
            <span *ngIf="address.StateOrProvince">{{address.StateOrProvince}}</span>
            <span *ngIf="address.PostalCode"> {{address.PostalCode}}</span>
        </p>
      </div>
      <div class="col-12" *ngIf="address.PhoneOne || address.PhoneTwo || address.Fax">
          <p class="mb-0">
            <span class="mr-4" *ngIf="address.PhoneOne">p. {{address.PhoneOne}}</span>
            <span class="mr-4" *ngIf="address.PhoneTwo">m. {{address.PhoneTwo}}</span>
            <span class="mr-4" *ngIf="address.Fax">f. {{address.Fax}}</span>
          </p>
      </div>
    </div>
    <div class="row mt-0 pt-0" *ngIf="address.ContactName || address.ContactEmail">
      <div class="col-12 pt-2">
        <p class="mb-0">
          <strong>Contact</strong><br />
          <span class="mr-4" *ngIf="address.ContactName">{{address.ContactName}}</span>
          <span class="mr-4" *ngIf="address.ContactEmail">{{address.ContactEmail}}</span>
        </p>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteConfirm let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Delete address</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="row">
      <div class="col-12">
        <p>Please confirm you would like to delete <strong>{{address.Name}}</strong> from your address book. </p>
      </div>
    </div>
    <div class="row p-2">
      <div class="col-4">
          <button type="button" class="btn btn-secondary w-100" (click)="d('cancel')">Cancel</button>
      </div>
      <div class="col-4 push-4">
          <button type="submit" class="btn btn-primary w-100" (click)="c('delete')">Delete</button>
      </div>
    </div>
  </div>
</ng-template>