<article class="page page-about">
  <header>
    <div class="container">
      <h1 class="text-light">How to Ship Freight</h1>
    </div>
  </header>
  <section class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="container">
          <div class="row pb-5 pt-5">
            <div class="col-12">
                <h3>RiteRouting is a freight brokerage platform that provides LTL freight quotes and shipping to improve your company’s
                  shipping costs and efficiency.</h3>
                <p>To have your Shipping Done Rite with RiteRouting is as easy as 1, 2, 3.</p>
            </div>

            <div class="d-none d-md-block col-md-4 pr-0">

              <nav class="step-nav">
                <a
                  *ngFor="let link of stepLinks"
                  [ngClass]="{ 'active first last': link === activeLink }"
                  (click)="handleStepClick(link)"
                >
                  <span class="circle"></span>
                  <span class="text" id="desktopText">{{ link.display }}</span>
                </a>
              </nav>
            </div>
            <div class="col-12 col-md-8 b-left-md-secondary" id="content">
              <div
                *ngFor="let link of stepLinks; let i = index;"
                [ngClass]="{ active: link === activeLink }"
                class="step-content"
              >
                <h3>{{(i+1)}} - {{ link.display }}</h3>
                <div [innerHtml]="link.body | safeHtml"></div>
                <p *ngIf="i == 0">Don’t have a RiteRouting account? Create one <a id="quoteLink" name="quoteLink" (click)="open('account')">here</a>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="container">
          <div class="row pt-f pb-f b-top-secondary">
            <div class="col">
              <h2>Why Use RiteRouting?</h2>
              <ul>
                <li>
                  <strong>Easy to use.</strong>
                  One-stop shop for your shipping needs, providing multiple carrier options for LTL freight.
                </li>
                <li>
                  <strong>Best Cost, Best Service – Every Time.</strong> You’ll have access to multiple LTL rate quotes to compare price and transit time.
                </li>
                <li>
                  <strong>24/7/365.</strong>
                  Logistics never stops and neither does RiteRouting. Our professional, experienced customer support team is available 24
                  hours a day, every day.
                </li>
                <li>
                  <strong>No Obligation.</strong>
                  Whether you ship one LTL shipment per year or 50 shipments each day, RiteRouting provides Shipping Done Rite!
                </li>
                <li>
                  <strong>Customer Reviews.</strong>
                  Shippers can share their experiences with carriers and rate them on a 5-star scale.
                </li>
                <li>
                  <strong>Learn from the past.</strong>
                  You have access to historical shipment information at your fingertips.
                </li>
                <li>
                  <strong>Peace-of-Mind</strong>
                  RiteRouting only works with quality, reliable LTL carriers and handles carrier onboarding, safety ratings and compliance
                  and carrier performance monitoring.
                </li>
                <!-- <li>
                  <strong>RiteRouting Rewards</strong>
                  Sign up for the RiteRouting Rewards program and earn points every time you execute a shipment.  Every dollar you spend earns you X points, and every xxxx points gets you a $x reward.  Plus, you get XXXX bonus points when you sign up.
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
