import { Account } from './account.model';

export class User {
    title = '';
    username = '';
    name = '';
    firstname = '';
    lastname = '';
    email = '';
    phone? = '';
    password? = '';
    passwordConfirm? = '';
    accountId = '';
    account: Account = new Account();
    roles: string[] = [];
}
