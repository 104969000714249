import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { validatePhone } from '../../validators/phone.directive';
import { Email } from '../../models/email.model';
import { EmailService } from '../../services/email.service';
import { FlashService } from '../flash/flash.service';
var ContactFormComponent = /** @class */ (function () {
    function ContactFormComponent(fb, emailService, flashService, route) {
        this.fb = fb;
        this.emailService = emailService;
        this.flashService = flashService;
        this.route = route;
        this.loading = false;
    }
    ContactFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            if (params['jlsNo']) {
                _this.instantiateForm("Ref: " + params['jlsNo'] + "\n\n");
            }
            else {
                _this.instantiateForm();
            }
        });
    };
    ContactFormComponent.prototype.instantiateForm = function (message) {
        if (message === void 0) { message = null; }
        var msg = (message === null) ? '' : message;
        this.form = this.fb.group({
            name: ['', [Validators.required]],
            company: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required, validatePhone]],
            message: [msg]
        });
    };
    Object.defineProperty(ContactFormComponent.prototype, "name", {
        get: function () {
            return this.form.get('name');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactFormComponent.prototype, "company", {
        get: function () {
            return this.form.get('company');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactFormComponent.prototype, "email", {
        get: function () {
            return this.form.get('email');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactFormComponent.prototype, "phone", {
        get: function () {
            return this.form.get('phone');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactFormComponent.prototype, "message", {
        get: function () {
            return this.form.get('message');
        },
        enumerable: true,
        configurable: true
    });
    ContactFormComponent.prototype.handleSubmit = function (event) {
        var _this = this;
        event.preventDefault();
        if (!this.form.valid) {
            this.flashService.flashMessage('Please fill out all required fields.', '', 'danger');
            return false;
        }
        var e = new Email();
        e.recipient = 'info@riterouting.com';
        e.bcc = '';
        e.subject = 'New RiteRouting Contact Form Submission';
        e.email = 'ContactUs';
        e.params = {
            name: this.name.value,
            company: this.company.value,
            email: this.email.value,
            phone: this.phone.value,
            message: this.message.value
        };
        this.loading = true;
        this.emailService.sendEmail(e)
            .then(function (res) {
            _this.loading = false;
            if (res.status && res.status !== 200) {
                _this.flashService.flashMessage('We were unable to send your message.', 'There was a problem!', 'danger');
                return false;
            }
            _this.flashService.flashMessage('Thank you for contacting us.', 'Your message was sent.', 'success');
            _this.instantiateForm();
        });
        return false;
    };
    return ContactFormComponent;
}());
export { ContactFormComponent };
