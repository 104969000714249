import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceless'
})
export class NoSpacesPipe implements PipeTransform {
  constructor() {}
  transform(style: string): any {
    return style.replace(/\W/g, '');
  }

}
