import { Component, OnInit, Input } from '@angular/core';
import { CarrierReview } from '../../../models';

@Component({
  selector: 'rtrt-carrier-review-detail',
  templateUrl: './carrier-review-detail.component.html',
  styleUrls: ['./carrier-review-detail.component.scss']
})
export class CarrierReviewDetailComponent implements OnInit {
  @Input() public review: CarrierReview;

  constructor() { }

  ngOnInit() {
  }

}
