/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../forgot-password-form/forgot-password-form.component.ngfactory";
import * as i2 from "../forgot-password-form/forgot-password-form.component";
import * as i3 from "../../services/account.service";
import * as i4 from "./forgot-password-modal.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_ForgotPasswordModalComponent = [];
var RenderType_ForgotPasswordModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ForgotPasswordModalComponent, data: {} });
export { RenderType_ForgotPasswordModalComponent as RenderType_ForgotPasswordModalComponent };
export function View_ForgotPasswordModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "rtrt-forgot-password-form", [], null, null, null, i1.View_ForgotPasswordFormComponent_0, i1.RenderType_ForgotPasswordFormComponent)), i0.ɵdid(2, 114688, null, 0, i2.ForgotPasswordFormComponent, [i3.AccountService], { title: [0, "title"] }, null)], function (_ck, _v) { var currVal_0 = "RiteRouting Forgotten Password"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ForgotPasswordModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "rtrt-forgot-password-modal", [], null, null, null, View_ForgotPasswordModalComponent_0, RenderType_ForgotPasswordModalComponent)), i0.ɵdid(1, 49152, null, 0, i4.ForgotPasswordModalComponent, [i5.NgbActiveModal], null, null)], null, null); }
var ForgotPasswordModalComponentNgFactory = i0.ɵccf("rtrt-forgot-password-modal", i4.ForgotPasswordModalComponent, View_ForgotPasswordModalComponent_Host_0, {}, {}, []);
export { ForgotPasswordModalComponentNgFactory as ForgotPasswordModalComponentNgFactory };
