import { Component, OnInit } from '@angular/core';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { LoginModalComponent } from '../../account/login-modal/login-modal.component';
import { AccountCreateModalComponent } from '../../account/account-create-modal/account-create-modal.component';
import { QuoteInitiateFormComponent } from '../../pages/quote-initiate-form/quote-initiate-form.component';
import { AuthenticationService } from '../../services/authentication.service';
import { ConfigService } from "../../services/config.service";

import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rtrt-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isAuthenticated: Observable<boolean>;
  private tokenExpirationCheckTimer;

  constructor(
    private config: ConfigService, 
    public router: Router,
    public route: ActivatedRoute,
    private modalService: NgbModal,
    public authService: AuthenticationService
  ) {
    this.isAuthenticated = this.authService.loggedIn;

    var tokenExpirationDurationSeconds = this.config.getConfig("token_expiration_duration_seconds");
    if (tokenExpirationDurationSeconds > 600)
      this.tokenExpirationCheckTimer = 15 * 1000;
    else
      this.tokenExpirationCheckTimer = 5 * 1000;
  }

  ngOnInit() {
    try {
      // Not sure why we check to see if we're logged in but let's not let the error role to the console
      this.authService.isLoggedIn();
      setInterval(this.checkLoginStatus, this.tokenExpirationCheckTimer, this);
    } catch (ex) {

    }
  }

  checkLoginStatus(ref) {
    // Don't run if modal open
    if (ref.modalService.hasOpenModals())
      return;
    // Don't run if not past carrier pricing in an order
    var loc = document.location.pathname;
    // if (loc.substr(0,19) !== '/ltl-shipping-quote' &&
    //     loc.substr(0,20) !== '/final-mile-delivery' &&
    //     loc.substr(0,17) !== '/freight-tracking')
    if (loc.substr(0,19) !== '/ltl-shipping-quote' &&
        loc.substr(0,17) !== '/freight-tracking')
      return;
    // if (loc === '/ltl-shipping-quote' ||
    //     loc === '/ltl-shipping-quote/carrier' ||
    //     loc === '/final-mile-delivery' ||
    //     loc === '/final-mile-delivery/start' ||
    //     loc === '/final-mile-delivery/carrier')
    if (loc === '/ltl-shipping-quote' ||
        loc === '/ltl-shipping-quote/carrier')
      return;
    // Check for expired token
    var ret = ref.authService.isLoggedIn();
  }

  open(target: string) {
    if (target === 'account') {
      const modalRef = this.modalService.open(AccountCreateModalComponent, { size: 'lg' })
        .result.then((result) => {
          this.handleModalConfirm(result);
        }, (reason) => {
          console.log(reason);
        });
    } else if (target === 'login') {
      if (this.modalService.hasOpenModals())
        return false;
      const modalRef = this.modalService.open(LoginModalComponent)
        .result.then((result) => {
          this.handleModalConfirm(result);
        }, (reason) => {
          console.log(reason);
        });
    }
  }

  handleModalConfirm(target: any) {
    if (target === 'account' || target === 'login') {
      this.open(target);
    }
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/']);
  }


}
