import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';

import { SERVICES_META } from '../meta';


@Component({
  selector: 'rtrt-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  headerBg: string;

  constructor(
    route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private metaService: MetaService
  ) {
    route.data.subscribe((data) => {
      this.headerBg = data.headerImage;
    });
  }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.title.setTitle(SERVICES_META.title);
    this.metaService.removeItemprop();
    SERVICES_META.meta.forEach(m => this.meta.updateTag(m));
  }

  routeTo(path: string) {
    this.router.navigate([path]);
  }

}
