import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';

import { CONTACT_META } from '../meta';

import { AuthenticationService } from '../../services/authentication.service';
// import { QuoteService } from '../../services/quote.service';
import { ContactFormComponent } from '../../shared/contact-form/contact-form.component';

@Component({
  selector: 'rtrt-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  headerBg: string;

  constructor(
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private metaService: MetaService
  ) {
    route.data.subscribe((data) => {
      this.headerBg = data.headerImage;
    });
  }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.title.setTitle(CONTACT_META.title);
    this.metaService.removeItemprop();
    CONTACT_META.meta.forEach(m => this.meta.updateTag(m));
  }

  checkAuthentication() {
    // console.log(this.auth.getFullToken());
  }

  // getQuotes() {
  //   this.qs.getQuotes();
  // }

}
