import { Location } from './location.model';
import { Accessorial } from './accessorial.model';
import { CarrierReview } from './carrier-review.model';

export enum ContractType {
    NewContract,
    OldContract,
    Canada
}

export class Failure {
    Type: string;
    Category: string;
    CategoryID: string;
    Text: string;
    Date: string;
    CorrectiveAction: string;
    FailureNotes: string;
    ClaimNotes: string;

    AdditionalCost: boolean;
    MissedRDD: boolean;

    constructor() {}
}

export class Invoice {
    InvoiceNumber: string;
    InvoiceDate: Date;
    Status: string;

    constructor() {
        this.InvoiceDate = new Date();
    }
}

export class MarkUp {
    BillToLocationID: number;
    BillToLocation: string;
    CarrierID: number;
    CarrierName: string;
    CarrierContractID: number;
    ContractType: ContractType;
    Descriptions: string[];

    constructor() {}
}

export class Carrier {
    LineNumber: number;
    CarrierAddress: Location;
    IsMinimumCharge: boolean;
    MarkUp: MarkUp;

    TransactionalTL: boolean;
    InvoiceSent: boolean;
    Audited: boolean;
    AfterTheFact: boolean;

    FreightBillReceived: boolean;
    AccessorialAP: Accessorial[];
    AccessorialAR: Accessorial[];
    CarrierInvoice: Invoice;

    Failures: Failure[];

    CustomerCollectCarrier: string;

    JlsNo: string;
    CarrierName: string;
    CarrierID: number;
    CarrierSCAC: string;
    CarrierContractID: string;
    CarrierContractType: ContractType;
    ConfirmationNumber: string;
    ServiceType: string;
    ModeText: string;
    Mode: number;
    ProNo: string;
    InvoiceNumber: string;
    CarrierQuoteNumber: string;
    ClaimStatusText: string;
    BrokerRep: string;
    BrokerRepName: string;
    BillingNotes: string;
    OriginTerminalPhone: string;
    DestinationTerminalPhone: string;
    AutorateLowestRateCarrier: string;
    AutorateLowestDiscount: number;
    AutorateLowestMinimumCharge: number;

    SpotMarketRate: number;
    ApiCost: number;

    JtsRescue: boolean;

    AfterTheFactName: string;
    DivertedCostNotes: string;
    DivertedCostType: string;
    FreightBillLocation: string;
    FreightBillNotes: string;
    FreightBillReceivedDate: Date;

    TruckloadDispatcher: string;
    TruckloadDriver: string;
    TruckloadDriverCell: string;
    TruckloadTrailer: string;
    TruckloadTruck: string;

    APLineHaul: number;
    ARLineHaul: number;

    CarrierReviews: CarrierReview[];
    InsideDelivery: boolean;
    ARTotal: number;
    CarrierLogo: Int8Array;
    CarrierLogoFilename: string;

    QuoteType: string;
    StripePayment: boolean;

    get Logo(): string {
        // return "data:image/jpg;base64," + this.CarrierLogo.toString();
        return this.CarrierLogoFilename.toString();
    }
    constructor() {
        this.CarrierAddress = new Location();
        this.AccessorialAP = [];
        this.AccessorialAR = [];
        this.CarrierInvoice = new Invoice();
        this.Failures = [];
        this.MarkUp = new MarkUp();
        this.CarrierReviews = [];
        this.InsideDelivery = false;
        this.StripePayment = false;
    }
}
