import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, NgZone, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { QuoteService } from "../../services/quote.service";
import { AuthenticationService } from "../../services/authentication.service";
import { ConfigService } from "../../services/config.service";
import { FlashService } from "../../shared/flash/flash.service";
import { AccountService } from "../../services/account.service";
import { Shipment, Location, OrderDateTime, OrderDateTimeType, LocationOpenTime, OpenHoursType } from "../../models";
import { RtValidators } from "../../validators/rt-validators";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ZipService } from '../../services/zip.service';
var OriginFormComponent = /** @class */ (function () {
    function OriginFormComponent(fb, quoteService, authService, config, router, flash, accountService, zone, zipService) {
        var _this = this;
        this.fb = fb;
        this.quoteService = quoteService;
        this.authService = authService;
        this.config = config;
        this.router = router;
        this.flash = flash;
        this.accountService = accountService;
        this.zone = zone;
        this.zipService = zipService;
        this.subscriptions = [];
        this.today = null;
        this.states = [];
        this.searchResults = [];
        this._addresses = [];
        this.showAdditionalEmails = false;
        this.notesArr = [];
        this.bolNotesArr = [];
        this.datepickerOpen = false;
        this.onAddressChange = new Subject();
        this.cities = [];
        this.subscriptions.push(this.authService.loggedIn.subscribe(function (res) { return (_this.isAuthenticated = res); }));
        this.subscriptions.push(this.quoteService.quote$.subscribe(function (q) {
            _this.quote = q;
            if (_this.quote &&
                _this.quote.Shipper &&
                _this.quote.Shipper.OpenHours &&
                _this.quote.Shipper.OpenHours.length === 0) {
                var oh = new LocationOpenTime();
                oh.Type = OpenHoursType.Shipping;
                _this.quote.Shipper.OpenHours.push(oh);
                _this.originalShipper = _this.quote.Shipper;
            }
        }));
        var dt = new Date();
        this.today = {
            year: dt.getFullYear(),
            month: dt.getMonth() + 1,
            day: dt.getDate()
        };
        this.states = config.getConfig("states").filter(function (s) { return s.country == _this.quote.Shipper.CountryString; });
    }
    // Goal is to not get the toggle out of whack when the
    // user clicks outside the datepicker to close it
    OriginFormComponent.prototype.onDocumentClick = function (ev) {
        if (this.datepickerOpen && ev.target.name !== "calendarIcon") {
            this.datepickerOpen = !this.datepickerOpen;
        }
    };
    OriginFormComponent.prototype.ngAfterViewInit = function () {
        if (this.quote &&
            this.quote.Shipper &&
            this.quote.Shipper.AddressLineOne &&
            !this.quote.Shipper.Validated) {
            this.onAddressChange.next();
        }
    };
    OriginFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.accountService.refreshAddresses();
        this.subscriptions.push(this.accountService.address$.subscribe(function (a) {
            _this._addresses = a;
            if (!(_this.quote && _this.isFilledLocation(_this.quote.Shipper))) {
                _this.quote.Shipper = new Location();
            }
            _this.instantiateForm();
            _this.onFormChanges();
        }));
        this.zipService
            .checkZip(this.quote.Shipper.PostalCode)
            .pipe()
            .subscribe(function (res) {
            res = JSON.parse(res);
            _this.cities = res.cities;
            console.log(_this.cities);
        });
    };
    OriginFormComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    OriginFormComponent.prototype.handleAddressValidation = function (street) {
        if (street) {
            this.address1.setValue(street);
            this.quote.Shipper.Validated = true;
        }
        else {
            this.quote.Shipper.Validated = false;
        }
    };
    Object.defineProperty(OriginFormComponent.prototype, "startTime", {
        get: function () {
            if (!(this.quote && this.quote.Shipper && this.quote.DateTimes))
                return null;
            var val = this.quote.DateTimes.find(function (o) { return o.TypeID === OrderDateTimeType.ReadyDate; });
            if (!val)
                return null;
            var dt = new Date(val.DateValue);
            return {
                hour: dt.getHours(),
                minute: dt.getMinutes(),
                second: dt.getSeconds()
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "stopTime", {
        get: function () {
            if (!(this.quote && this.quote.Shipper && this.quote.DateTimes))
                return null;
            var val = this.quote.DateTimes.find(function (o) { return o.TypeID === OrderDateTimeType.CloseDate; });
            if (!val)
                return null;
            var dt = new Date(val.DateValue);
            return {
                hour: dt.getHours(),
                minute: dt.getMinutes(),
                second: dt.getSeconds()
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "puDate", {
        get: function () {
            var dt = null;
            if (this.quote &&
                this.quote.DateTimes &&
                this.quote.DateTimes.find(function (d) { return d.TypeID === OrderDateTimeType.ShipDate; })) {
                dt = new Date(this.quote.DateTimes.find(function (d) { return d.TypeID === OrderDateTimeType.ShipDate; }).ShortDateString);
                return {
                    year: dt.getFullYear(),
                    month: dt.getMonth() + 1,
                    day: dt.getDate()
                };
            }
            dt = new Date();
            return {
                year: dt.getFullYear(),
                month: dt.getMonth() + 1,
                day: dt.getDate()
            };
        },
        enumerable: true,
        configurable: true
    });
    OriginFormComponent.prototype.instantiateForm = function () {
        var _this = this;
        if (this.quote.JlsNotes.search(" ---- Delivery Notes:") > -1) {
            this.notesArr = this.quote.JlsNotes.split(" ---- Delivery Notes:");
            this.notesArr[0] = this.notesArr[0].replace("Pickup Notes: ", "");
        }
        else if (this.quote.JlsNotes.search("Pickup Notes: ") > -1) {
            this.notesArr[0] = this.quote.JlsNotes.replace("Pickup Notes: ", "");
            this.notesArr[1] = "";
        }
        else {
            this.notesArr[0] = "";
            this.notesArr[1] = "";
        }
        this.bolNotesArr[0] = this.quote.CarrierNotes;
        this.form = this.fb.group({
            name: [this.quote.Shipper.Name, [Validators.required]],
            address1: [this.quote.Shipper.AddressLineOne, [Validators.required]],
            address2: [this.quote.Shipper.AddressLineTwo],
            contact: [this.quote.Shipper.ContactName, [Validators.required]],
            phone: [
                this.quote.Shipper.PhoneOne,
                [Validators.required, RtValidators.phone]
            ],
            fax: [this.quote.Shipper.Fax || ""],
            emails: this.fb.array([]),
            email: [
                this.quote.Shipper.ContactEmail,
                [Validators.required, Validators.email]
            ],
            pickupDate: [this.puDate, [Validators.required]],
            loadingStartHour: [this.startTime, [Validators.required]],
            loadingStopHour: [this.stopTime, [Validators.required]],
            bolToShipperEmail: [this.quote.EmailBolTo.length > 0],
            additionalEmail: [this.showAdditionalEmails],
            pickupInstructions: [this.notesArr[0]],
            pickupReferenceNo: [""],
            printedOnBOL: [this.bolNotesArr[0]]
        });
        this.form.get("fax").valueChanges.subscribe(function (fax) {
            if (fax !== "" && fax !== null) {
                _this.form.get("fax").setValidators([RtValidators.phone]);
            }
            else {
                _this.form.get("fax").clearValidators();
            }
            _this.form.get("fax").updateValueAndValidity({ emitEvent: false });
        });
    };
    OriginFormComponent.prototype.handleAdditionalToggle = function () {
        var newVal = this.form.get("additionalEmail").value;
        if (newVal && this.emails.length === 0)
            this.addEmail();
        else
            while (this.emails.length !== 0) {
                this.emails.removeAt(0);
            }
        this.showAdditionalEmails = newVal;
    };
    OriginFormComponent.prototype.createEmail = function () {
        return this.fb.group({
            email: ["", [Validators.email]]
        });
    };
    OriginFormComponent.prototype.addEmail = function () {
        this.emails.push(this.createEmail());
    };
    OriginFormComponent.prototype.removeEmail = function (idx) {
        if (this.emails.length > 1)
            this.emails.removeAt(idx);
    };
    OriginFormComponent.prototype.isFilledLocation = function (l) {
        return !!(l && l.PostalCode && l.PostalCode !== "");
    };
    Object.defineProperty(OriginFormComponent.prototype, "showShipperClear", {
        get: function () {
            return (this.isFilledLocation(this.originalShipper) &&
                this.originalShipper !== this.quote.Shipper);
        },
        enumerable: true,
        configurable: true
    });
    OriginFormComponent.prototype.selectAddress = function (a) {
        var _this = this;
        this.zone.run(function () {
            _this.quote.Shipper = Object.assign({}, a);
            _this.instantiateForm();
            _this.onFormChanges();
            _this.onAddressChange.next();
        });
    };
    Object.defineProperty(OriginFormComponent.prototype, "addresses", {
        get: function () {
            var _this = this;
            if (!Array.isArray(this._addresses))
                return null;
            return this._addresses
                .filter(function (a) {
                return ((_this.isFilledLocation(_this.quote.Shipper) &&
                    a.PostalCode === _this.quote.Shipper.PostalCode) ||
                    (_this.isFilledLocation(_this.originalShipper) &&
                        a.PostalCode === _this.originalShipper.PostalCode)) &&
                    a.IsDefaultBillTo &&
                    a.IsShipper;
            })
                .concat(this._addresses
                .filter(function (a) {
                return ((_this.isFilledLocation(_this.quote.Shipper) &&
                    a.PostalCode === _this.quote.Shipper.PostalCode) ||
                    (_this.isFilledLocation(_this.originalShipper) &&
                        a.PostalCode === _this.originalShipper.PostalCode)) &&
                    !a.IsDefaultBillTo &&
                    a.IsShipper;
            })
                .sort(function (n1, n2) {
                if (n1.Name.toUpperCase() > n2.Name.toUpperCase()) {
                    return 1;
                }
                if (n1.Name.toUpperCase() < n2.Name.toUpperCase()) {
                    return -1;
                }
                return 0;
            }));
        },
        enumerable: true,
        configurable: true
    });
    OriginFormComponent.prototype.onFormChanges = function () {
        var _this = this;
        this.form.get("loadingStartHour").valueChanges.subscribe(function (val) {
            if (val !== null &&
                (!_this.loadingStopHour.value ||
                    _this.loadingStopHour.value === null ||
                    _this.loadingStopHour.value === "")) {
                _this.loadingStopHour.setValue(tslib_1.__assign({}, val, { hour: val.hour + 4 }));
            }
        });
        this.subscriptions.push(this.name.valueChanges.subscribe(function (val) {
            _this.quote.Shipper.Name = val;
        }));
        this.subscriptions.push(this.address1.valueChanges.subscribe(function (val) {
            if (_this.quote.Shipper.AddressLineOne !== val) {
                _this.quote.Shipper.Validated = false;
                _this.quote.Shipper.AddressLineOne = val;
                _this.onAddressChange.next();
            }
        }));
        this.subscriptions.push(this.address2.valueChanges.subscribe(function (val) {
            _this.quote.Shipper.AddressLineTwo = val;
        }));
        this.subscriptions.push(this.phone.valueChanges.subscribe(function (val) {
            _this.quote.Shipper.PhoneOne = val;
        }));
        this.subscriptions.push(this.fax.valueChanges.subscribe(function (val) {
            _this.quote.Shipper.Fax = val;
        }));
        this.subscriptions.push(this.contact.valueChanges.subscribe(function (val) {
            _this.quote.Shipper.ContactName = val;
        }));
        this.subscriptions.push(this.email.valueChanges.subscribe(function (val) {
            _this.quote.Shipper.ContactEmail = val;
        }));
        this.subscriptions.push(this.pickupDate.valueChanges.pipe(debounceTime(500)).subscribe(function (val) {
            if (typeof val !== "string" && val !== null)
                _this._pushPickupDate();
        }));
    };
    OriginFormComponent.prototype.resetForm = function () {
        this.instantiateForm();
    };
    OriginFormComponent.prototype.handleAddresChange = function (e) {
        console.log("Address changed ... ", e);
    };
    Object.defineProperty(OriginFormComponent.prototype, "name", {
        get: function () {
            return this.form.get("name");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "address1", {
        get: function () {
            return this.form.get("address1");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "address2", {
        get: function () {
            return this.form.get("address2");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "contact", {
        get: function () {
            return this.form.get("contact");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "phone", {
        get: function () {
            return this.form.get("phone");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "fax", {
        get: function () {
            return this.form.get("fax");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "emails", {
        get: function () {
            return this.form.get("emails");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "email", {
        get: function () {
            return this.form.get("email");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "pickupDate", {
        get: function () {
            return this.form.get("pickupDate");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "loadingStartHour", {
        get: function () {
            return this.form.get("loadingStartHour");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "loadingStopHour", {
        get: function () {
            return this.form.get("loadingStopHour");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "bolToShipperEmail", {
        get: function () {
            return this.form.get("bolToShipperEmail");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "pickupInstructions", {
        get: function () {
            return this.form.get("pickupInstructions");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "saveInstructions", {
        get: function () {
            return this.form.get("saveInstructions");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "pickupReferenceNo", {
        get: function () {
            return this.form.get("pickupReferenceNo");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "pickedUp", {
        get: function () {
            return this.form.get("pickedUp");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "pickupNotes", {
        get: function () {
            return this.notesArr[0];
        },
        set: function (val) {
            this.notesArr[0] = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OriginFormComponent.prototype, "bolNotes", {
        get: function () {
            return this.bolNotesArr[0];
        },
        set: function (val) {
            this.bolNotesArr[0] = val;
        },
        enumerable: true,
        configurable: true
    });
    OriginFormComponent.prototype.toggleDatepicker = function (el) {
        el.toggle();
        this.datepickerOpen = !this.datepickerOpen;
    };
    OriginFormComponent.prototype.onDateSelect = function (ev) {
        this.datepickerOpen = !this.datepickerOpen;
    };
    OriginFormComponent.prototype._fillQuote = function () {
        var _this = this;
        var originForm = this.form;
        this.quote.Shipper.Name = originForm.get("name").value;
        this.quote.Shipper.AddressLineOne = originForm.get("address1").value;
        this.quote.Shipper.AddressLineTwo =
            originForm.get("address2").value === ""
                ? null
                : originForm.get("address2").value;
        this.quote.Shipper.ContactName = originForm.get("contact").value;
        this.quote.Shipper.PhoneOne = originForm.get("phone").value;
        this.quote.Shipper.Fax = originForm.get("fax").value;
        this.quote.Shipper.ContactEmail = originForm.get("email").value;
        // this.quote.EmailBolTo = originForm.get('bolToShipperEmail').value;
        this.quote.JlsNotes = "";
        this.quote.CarrierNotes = "";
        if (originForm.get("pickupInstructions").value !== "")
            this.quote.JlsNotes +=
                "Pickup Notes: " + originForm.get("pickupInstructions").value;
        if (this.notesArr[1] !== "")
            this.quote.JlsNotes += " ---- Delivery Notes:" + this.notesArr[1];
        if (originForm.get("printedOnBOL").value !== "")
            this.quote.CarrierNotes += originForm.get("printedOnBOL").value;
        if (originForm.get("bolToShipperEmail").value)
            this.quote.EmailBolTo = this.quote.Shipper.ContactEmail;
        else
            this.quote.EmailBolTo = "";
        // Add additional emails if checked
        if (this.showAdditionalEmails && this.emails.length > 0) {
            this.emails.controls.forEach(function (control) {
                if (!control.invalid) {
                    _this.quote.EmailBolTo += _this.quote.EmailBolTo === "" ? "" : ";";
                    _this.quote.EmailBolTo += control.get("email").value;
                }
            });
        }
        var loading = new LocationOpenTime();
        loading.FromHour = originForm.get("loadingStartHour").value.hour;
        loading.ToHour = originForm.get("loadingStopHour").value.hour;
        loading.FromMinute = originForm.get("loadingStartHour").value.minute;
        loading.ToMinute = originForm.get("loadingStopHour").value.minute;
        loading.Type = OpenHoursType.Shipping;
        if (!this.quote.Shipper.OpenHours ||
            this.quote.Shipper.OpenHours.length > 0)
            this.quote.Shipper.OpenHours = [];
        this.quote.Shipper.OpenHours.push(loading);
        this._pushPickupDate();
    };
    OriginFormComponent.prototype._pushPickupDate = function () {
        var pDateVal = this.form.get("pickupDate").value;
        var dString = pDateVal.month + "/" + pDateVal.day + "/" + pDateVal.year;
        // Requested Delivery Date
        var pdate = new OrderDateTime(dString);
        pdate.TypeID = OrderDateTimeType.ShipDate;
        pdate.Description = "Ship Date";
        pdate.JlsNo = this.quote.JlsNo;
        this.quote.DateTimes = this.quote.DateTimes.filter(function (dt) {
            return dt.TypeID !== OrderDateTimeType.ShipDate &&
                dt.TypeID !== OrderDateTimeType.ReadyDate &&
                dt.TypeID !== OrderDateTimeType.CloseDate;
        }).slice();
        this.quote.DateTimes.push(pdate);
        var startHour = this.form.get("loadingStartHour").value.hour;
        var stopHour = this.form.get("loadingStopHour").value.hour;
        var startMin = this.form.get("loadingStartHour").value.minute;
        var stopMin = this.form.get("loadingStopHour").value.minute;
        // Del Ready Time
        var openDate = new OrderDateTime(dString + " " + startHour + ":" + startMin);
        openDate.TypeID = OrderDateTimeType.ReadyDate;
        openDate.JlsNo = this.quote.JlsNo;
        openDate.Description = "Ready Date";
        this.quote.DateTimes.push(openDate);
        // Del Close Time
        var closeDate = new OrderDateTime(dString + " " + stopHour + ":" + stopMin);
        closeDate.TypeID = OrderDateTimeType.CloseDate;
        closeDate.JlsNo = this.quote.JlsNo;
        closeDate.Description = "Close Date";
        this.quote.DateTimes.push(closeDate);
    };
    OriginFormComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!this.authService.isLoggedIn())
            return;
        if (!this.form.valid || !this.quote.Shipper.Validated) {
            Object.keys(this.form.controls)
                .map(function (x) { return _this.form.controls[x]; })
                .forEach(function (control) {
                control.markAsTouched();
            });
            this.flash.flashMessage("Please fill out all required fields.", "", "danger");
            return false;
        }
        this.flash.dismissMessage();
        this._fillQuote();
        this.quoteService.updateQuote(this.quote, true, true, false);
        this.router.navigate(["quote", "destination"]);
    };
    OriginFormComponent.prototype.selectCity = function (city) {
        this.quote.Shipper.City = city;
    };
    return OriginFormComponent;
}());
export { OriginFormComponent };
