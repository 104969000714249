<div class="post-header">
  <h3>
    <a routerLink="{{formatTag(post.BlogTitle)}}"
        routerLinkActive="active">
      {{ post.BlogTitle }}
    </a>
  </h3>
  <div>
    <span *ngIf="post.AuthorName"
      >Posted by {{ post.AuthorName }} on {{ post.BlogDate }}</span
    >
    <span *ngIf="!post.AuthorName">Posted on {{ post.BlogDate }}</span>
  </div>
  <br clear="all" />
</div>
<br clear="all" />
<div class="post-body clearfix">
  <!--post summary-->
  <div *ngIf="post.BlogImage" class="hs-featured-image-wrapper">
    <a routerLink="{{formatTag(post.BlogTitle)}}" title="" class="hs-featured-image-link">
      <img [src]="post.BlogImage" class="blog-image" width="320px" height="200px" alt="{{post.BlogTitle}}" />
      <!-- <img [src]="post.Image" class="blog-image" /> -->
    </a>
  </div>

  <p style="text-align: justify;" class="blog-content">
    <span [innerHTML]="post.BlogSummary | safeHtml"></span>
  </p>
      <p>
        Tags: <span *ngFor="let tag of post.BlogTags; let i = index"><a [routerLink]="'/blog/tag/' + formatTagList(tag) + '/page/1'"> {{tag}}</a><span *ngIf="i + 1 < post.BlogTags.length">, </span></span>
      </p>
</div>

<div style="text-align:right;clear:both;">
  <p><a class="more-link" routerLink="{{formatTag(post.BlogTitle)}}">Read More</a></p>
</div>
