import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

const faqs = [
  {
    title: "Is RiteRouting a broker or carrier?",
    content: `RiteRouting is a licensed freight broker, working with a network of LTL carriers across North America, to negotiate better rates for our customers. `,
    visible: false
  },
  {
    title: "How can RiteRouting help me save on my LTL freight shipments?",
    content: `RiteRouting works with the best LTL freight carriers in the business, and since we do so much business with them, 
    they provide us very competitive discounts on shipping. RiteRouting then passes the savings on to you. 
    In addition to the money we save you on shipping, we also save you time, stress and effort, allowing you to focus on running your business.`,
    visible: false
  },
  {
    title: "How does RiteRouting choose its LTL carriers?",
    content: `RiteRouting has gone through a thorough vetting process to offer only the most experienced and reputable LTL freight carriers across North America. 
    Each carrier is evaluated to meet the U.S. Department of Transportation’s strict <a href="https://csa.fmcsa.dot.gov/" target="_blank">Compliance, Safety and Accountability (CSA) requirements</a>. 
    RiteRouting prides itself in presenting multiple carrier options to ensure the best cost, best service carrier for your shipment.`,
    visible: false
  },
  {
    title: "Where does RiteRouting ship?",
    content: `RiteRouting ships freight throughout North American (United States, Mexico, and Canada).`,
    visible: false
  },
  {
    title: "What’s the difference between less-than-truckload (LTL) and full truckload (TL) shipping?",
    content: `LTL shipping is used for small freight shipments that are too large for parcel shipping, but not large enough to fill a truck. 
    Full truckload shipments use the entire space or weight limit of one trailer. If you have any questions on which mode to utilize for your shipment, 
    please contact a representative at RiteRouting.`,
    visible: false
  },
  {
    title:
      "What is a Bill of Lading?",
    content: `The Bill of Lading (BOL) includes important information related to the shipment such as the commodity, freight class, 
    shipper and consignee addresses, type of packaging, cost, and the weight/volume of the shipment. 
    The BOL is the contract between the carrier and the shipper and also acts as a receipt to all parties involved with a shipment.`,
    visible: false
  },
  {
    title: "How do I determine freight class?",
    content: `Freight class is determined by multiple factors, such as the commodity being shipped, weight, dimensions and ease of handling, value, 
    and liability from theft, damage, breakability, spoilage, and storage capability. 
    Every specific item has been classified and assigned a category code by the <a href="http://www.nmfta.org/" target="_blank">National Motor Freight Traffic Association</a> (NMFC). Freight class only applies only to LTL freight shipping.`,
    visible: false
  },
  {
    title: "Can I estimate the weight of my LTL shipment?",
    content: `Shippers should make every effort to determine their shipment’s actual weight to avoid an invoice adjustment. 
    Carriers have the right to re-weigh your shipment during transit and if an inaccurate weight is used on the BOL, there may be additional charges incurred on the shipment.`,
    visible: false
  },
  {
    title: "How can I avoid invoice and billing adjustments?",
    content: `The best way to avoid billing adjustments is to always have the most accurate information possible. The most common adjustments tend to be related to incorrect weight, 
    incorrect size, residential fees, lift-gates, incorrect freight class, limited delivery access, and any other changes to the Bill of Lading. 
    When you request a quote or order a shipment, make sure to provide the most accurate information possible before the Bill of Lading is printed. 
    The more accurate the information you provide, the less likely an invoice adjustment will affect the cost of your shipment.`,
    visible: false
  },
  {
    title: "We don’t ship freight, but we do have freight shipments coming in. Can RiteRouting help me?",
    content: `Yes we can. RiteRouting can save you money on your inbound freight shipments too, just tell your vendors to send your shipments “Freight Collect.”`,
    visible: false
  },
  {
    title: "What shipping option is best for me?",
    content: `LTL shipments can range in size generally from 150 to 10,000 pounds and take up less than 14 feet of trailer space. 
    Full truckload is generally best suited for those looking to move more than 10,000 pounds, or enough to justify the entire use of a dedicated semi-trailer. 
    There are times when it may make sense to consider a volume quote or a partial truckload quote when your shipments are approaching 12,000-15,000 pounds or more 
    (this general rule of thumb will differ based on commodity). Another consideration will be how quickly you will need the shipment to arrive at the destination. 
    Getting items quickly from one point to another may call for expedited LTL shipping services. 
    Intermodal shipping uses multiple modes of transportation and is great for long distances that are not time sensitive. Please contact an experienced, 
    logistics professional at RiteRouting for any questions you may have on your shipment.`,
    visible: false
  }
];

@Component({
  selector: "rtrt-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"]
})
export class FaqComponent implements OnInit {
  headerBg: string;
  faqs = faqs;
  @Input() singleOnly = false;

  constructor(route: ActivatedRoute) {
    route.data.subscribe(data => {
      this.headerBg = data.headerImage;
    });
  }
  ngOnInit() {}

  toggle(faq: any) {
    if (this.singleOnly) {
      this.faqs.forEach(faq => (faq.visible = false));
    }
    faq.visible = !faq.visible;
  }
}
