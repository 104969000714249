/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./less-than-truckload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../helpers/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./less-than-truckload.component";
import * as i5 from "@angular/router";
var styles_LessThanTruckloadComponent = [i0.styles];
var RenderType_LessThanTruckloadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LessThanTruckloadComponent, data: {} });
export { RenderType_LessThanTruckloadComponent as RenderType_LessThanTruckloadComponent };
export function View_LessThanTruckloadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 12, "article", [["class", "page page-about"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "header", [], [[4, "background-image", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [["class", "text-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Less Than Truckload"])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "section", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "row pt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "col-sm-10"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(11, 1), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "col-sm-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["class", "img-fluid rounded-circle"], ["src", "https://via.placeholder.com/480x480?text=ICON"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.headerBg) + ")"); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v, 0), _co.content.copy)); _ck(_v, 10, 0, currVal_1); }); }
export function View_LessThanTruckloadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-less-than-truckload", [], null, null, null, View_LessThanTruckloadComponent_0, RenderType_LessThanTruckloadComponent)), i1.ɵdid(1, 114688, null, 0, i4.LessThanTruckloadComponent, [i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LessThanTruckloadComponentNgFactory = i1.ɵccf("rtrt-less-than-truckload", i4.LessThanTruckloadComponent, View_LessThanTruckloadComponent_Host_0, {}, {}, []);
export { LessThanTruckloadComponentNgFactory as LessThanTruckloadComponentNgFactory };
