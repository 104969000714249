import { DateValue } from './date-value.model';

export class OrderDateTime {
    JlsNo: string;
    TypeID: number;
    Description: string;
    DateValue: string;
    ShortDateString: string;
    ShortDateStringCanada: string;
    private _DateValueObject: DateValue;

    constructor(pDate: string = null) {
        const dt = new Date(pDate);

        this.ShortDateString = OrderDateTime.toShortDateString(dt);
        this.DateValue = OrderDateTime.toLongDateString(dt);
        this.ShortDateStringCanada = OrderDateTime.toShortDateString(dt, true);
        this._DateValueObject = OrderDateTime.toDateValue(dt);
    }

    public static toShortDateString(dt: Date = new Date(), isCanada = false) {
        return _parseDateString(dt, isCanada);
    }

    public static toLongDateString(dt: Date = new Date(), isCanada = false) {
        return _parseDateString(dt, isCanada) + ' ' + _parseTimeString(dt);
    }

    public static toDateValue(dt: Date = new Date()) {
        const dv = new DateValue();
        dv.Date = _parseDateString(dt);
        dv.Time = _parseTimeString(dt);
        return dv;
    }
}

function _parseDateString(dt: Date = new Date(), isCanada = false): string {
    if (!isCanada)
        return (dt.getMonth() + 1) + '/' + dt.getDate() + '/' + dt.getFullYear();
    else
        return dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear();
}

function _parseTimeString(dt: Date = new Date()): string {
    const hour = dt.getHours();
    const minute = dt.getMinutes();
    const second = dt.getSeconds();
    return ((hour > 12) ? (hour - 12) : hour) + ':'
        + ((minute < 10) ? '0' + minute : minute) + ':'
        + ((second < 10) ? '0' + second : second)
        + ((hour > 12) ? ' PM' : ' AM');
}
