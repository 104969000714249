import {
  throwError,
  Observable,
  BehaviorSubject,
  Subscription,
  of
} from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { Injectable, Optional, OnInit, OnDestroy, Inject, EventEmitter } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from "@angular/common/http";

import { ConfigService } from "./config.service";
import { AuthenticationService } from "./authentication.service";
import { Account } from "../account/account.model";
import { User } from "../account/user.model";
import { Location, ILocationResponse, ClientCommodity } from "../models";
import { WINDOW } from "../window.provider";


@Injectable()
export class AccountService implements OnDestroy {
  private api = "";
  private clientId;
  private clientSecret;
  private __user: User = null;
  public user$: BehaviorSubject<User> = new BehaviorSubject<User>(this._user);
  private _address: Location[] = [];
  public address$: BehaviorSubject<Location[]> = new BehaviorSubject<Location[]>(this._address);
  private _commodity: ClientCommodity[] = [];
  public commodity$: BehaviorSubject<ClientCommodity[]> = new BehaviorSubject<ClientCommodity[]>(this._commodity);
  private isAuthenticated = false;
  private _isLoginExpired = true;
  private _subscriptions: Subscription[] = [];
  loginExpired: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private auth: AuthenticationService,
    @Inject(WINDOW) private window: Window
  ) {
    this.api = config.getConfig("api");
    this.clientId = this.config.getConfig("client_id");
    this.clientSecret = this.config.getConfig("client_secret");
    this._subscriptions.push(
      this.auth.loggedIn.subscribe(res => {
        // console.log("*** account.service auth.loggedIn subscriber _isLoginExpired=!" + res);
        this.isAuthenticated = res;
        if (res) {
          this._isLoginExpired = false;
          if (this._user === null) 
            this.getUser();
        } else {
          this._isLoginExpired = true;
          this._user = null;
          this.loginExpired.emit();
          // this.user$.next(this._user);
        }
      })
    );
    this._subscriptions.push(
      this.auth.loginExpired.subscribe(res => {
        // console.log("*** account.service auth.loginExpired subscriber _isLoginExpired=" + res);
        this._isLoginExpired = typeof res == 'undefined' ? true : !res;;
        if (this._isLoginExpired) {
          this._user = null;
          this.loginExpired.emit();
        }
      })
    )

    this.loadAddresses();
    this.loadCommodities();
  }

  get _user(): User {
    return this.__user;
  }

  set _user(val: User) {
    this.__user = val;
    this.user$.next(this.__user);
  }
  create(obj: any): Observable<string> {
    return this.http.post(this.api + "api/account/add", obj)
    .pipe(
      catchError(err => throwError(err)),
      map(res => res as string)
    );
  }

  saveSurvey(userid: string, arr: any[]): Promise<any> {
    const savedata = { userid: userid, data: arr };
    return this.http
      .post(this.api + "api/account/survey/save", savedata)
      .pipe(
        catchError(err => throwError(err)),
        map(res => res as string)
      )
      .toPromise();
  }

  login(username: string, password: string): Observable<boolean> {
    return this.http
      .post(
        this.api + "oauth2/token",
          "username=" +
            encodeURIComponent(username) +
            "&password=" +
            encodeURIComponent(password) +
            "&grant_type=password"
        ,
        {
          headers: new HttpHeaders()
            .set("Accept", "application/json")
            .set("Content-Type", "application/x-www-form-urlencoded")
            .set(
              "Authorization",
              "Basic " + btoa(this.clientId + ":" + this.clientSecret)
            )
        }
      )
      .pipe(
        catchError(err => of(false)),
        map(res => {
          console.log(res);
          if (!res) {
            return false;
          }
          this.auth.login(res as string);
          return true;
        })
      );
  }

  forgotPassword(email: string): Promise<any> {
    return this.http
      .post(this.api + "api/account/password/trigger", {
        email: email,
        scheme: this.window.location.protocol,
        host: this.window.location.hostname,
        port: this.window.location.port
      })
      .pipe(catchError(err => err))
      .toPromise()
      .then(res => res);
  }

  resetPassword(pw: string, id: string, token: string): Promise<any> {
    return this.http
      .post(this.api + "api/account/password/reset", {
        id: id,
        token: token,
        password: pw
      })
      .pipe(catchError(err => err))
      .toPromise()
      .then(res => res);
  }

  getUser() {
    if ((typeof this._isLoginExpired == "undefined") || this._isLoginExpired == true)
      return;
    this.http
      .get(`${this.api}api/account/user`)
      .pipe(map((res: string) => JSON.parse(res)))
      .toPromise()
      .then(res => {
        this._user = res;
        // this.user$.next(this._user); 
        this.refreshAddresses().then(() => {
          console.log('sending user update'); 
          // this.user$.next(this._user); 
        });
      });
  }

  getUserEmail() {
    if (this._user === null) return null;
    return this._user.email;
  }

  getUserName() {
    if (this._user === null) return null;
    return this._user.name;
  }

  getUserUsername() {
    if (this._user === null) return null;
    return this._user.username;
  }

  getUserAccount() {
    if (this._user === null) return null;
    return this._user.account;
  }

  getUserPhone() {
    if (this._user === null) return null;
    return this._user.phone;
  }

  getUserOnAccount(): boolean {
    if (this._user === null) {
      this.getUser();
    }
    if (
      this._user === null ||
      this._user.account === null ||
      this._user.account.onAccount === null ||
      this._user.account.onAccount !== true
    ) {
      return false;
    } else {
      return true;
    }
  }
  getClientOrderStatus(): boolean {
    if (this._user === null) {
      this.getUser();
    }
    if (
      this._user === null ||
      this._user.account === null ||
      this._user.account.newAccount === null ||
      this._user.account.newAccount !== true
    ) {
      return false;
    } else {
      return true;
    }
  }
  getisLoginExpired(): boolean {
    return ((typeof this._isLoginExpired === "undefined") ? true : this._isLoginExpired);
  }

  private loadAddresses(): void {
    this.fetchAddress();
    return;
  }

  private loadCommodities(): void {
    this.fetchCommodity();
    return;
  }

  addAddress(a: Location) {
    this.http
      .post(`${this.api}api/location/save`, a)
      .pipe(
        catchError((err: any) => {
          console.log(err);
          return throwError(err);
        })
      )
      .toPromise()
      .then(res => this.fetchAddress());
  }

  updateAddress(a: Location) {
    this.http
      .put(`${this.api}api/location/update`, a)
      .pipe(
        catchError((err: any) => {
          console.log(err);
          return throwError(err);
        })
      )
      .toPromise()
      .then(res => this.fetchAddress());
  }

  deleteAddress(id: number) {
    this.http
      .delete(`${this.api}api/location/delete/${id}`)
      .pipe(
        catchError((err: any) => {
          console.log(err);
          return throwError(err);
        })
      )
      .toPromise()
      .then(res => this.fetchAddress());
  }

  refreshAddresses() {
    return this.fetchAddress();
  }

  refreshCommodities(): void {
    console.log("refreshing comms");
    this.fetchCommodity();
  }

  flushAddresses(): void {
    this.handleNewAddress([]);
    localStorage.removeItem("addressExpiry");
  }

  private fetchAddress() {
      if (this._user && this._user !== null && this.auth.isLoggedIn()) {
        return new Promise<boolean>((resolve, reject) => {
          this.http
          .get(`${this.api}api/location/load`)
          .pipe(map((res: string) => JSON.parse(res)))
          .toPromise()
          .catch(err =>  {
            // console.log("*** account.service fetchAddress api catch _isLoginExpired=true");
            this._isLoginExpired = true;
            this.loginExpired.emit();
            return throwError(err);
          })
          .then(res => {
            this.handleNewAddress(res);
            resolve(true);
          });
      });
      } else {
        console.log('flushing addresses');
        this.flushAddresses();
        return new Promise<boolean>((resolve, reject) => {
          resolve(false);
        });
      }
  }

  private fetchCommodity() {
    try {
      if (this._user && this._user !== null && this.auth.isLoggedIn()) {
        this.http
          .get(`${this.api}api/commodity/load`)
          .pipe(map((res: string) => JSON.parse(res)))
          .toPromise()
          .then(res => this.handleNewCommodity(res));
      } else {
        console.log("no user context");
        this.flushCommodity();
      }
    } catch {
      // console.log("*** account.service fetchCommodity catch _isLoginExpired=true");
      this._isLoginExpired = true;
      this.loginExpired.emit();
    }
  }
  private flushCommodity() {
    this.handleNewCommodity([]);
  }
  searchAddresses(searchText: string): Observable<ILocationResponse> {
    searchText = searchText.trim();

    const options = searchText ?
      { params: new HttpParams().set('searchText', searchText) } : {};

    return this.http
      .get<ILocationResponse>(`${this.api}api/location/search`, options);
  }

  private handleNewAddress(a: Location[] = []) {
    // localStorage.setItem('addresses', JSON.stringify(a));
    // localStorage.setItem('addressExpiry', JSON.stringify(new Date()));
    this._address = a;
    console.log('done fetching addresses');
    this.address$.next(this._address);
  }

  private handleNewCommodity(a: ClientCommodity[] = []) {
    // localStorage.setItem('addresses', JSON.stringify(a));
    // localStorage.setItem('addressExpiry', JSON.stringify(new Date()));
    this._commodity = a;
    this.commodity$.next(this._commodity);
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }
}
