import { Router, Route, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';

import { FAQ_META, GLOSSARY_META, CONTACT_META } from '../meta';

import { ContactFormComponent } from './../../shared/contact-form/contact-form.component';
import { FaqComponent } from './faq/faq.component';
import { GlossaryComponent } from './glossary/glossary.component';

import { GLOSSARY_TERMS } from './glossary-terms';

const subLink = [
  {
    id: 'faq',
    display: 'FAQ',
    meta: FAQ_META
  },
  {
    id: 'shipping-terms-glossary',
    display: 'Shipping Terms Glossary',
    meta: GLOSSARY_META
  },
  {
    id: 'contact-us',
    display: 'Contact Us',
    meta: CONTACT_META
  }
];

@Component({
  selector: 'rtrt-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpComponent implements OnInit {
  headerBg: string;
  active = subLink[0];
  subLinks = subLink;
  subscription: any;
  terms = GLOSSARY_TERMS;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private metaService: MetaService
  ) {
    route.data.subscribe((data) => {
      this.headerBg = data.headerImage;
    });
  }

  setActiveLink(idx: number = 0) {
    this.active = this.subLinks[idx];
    this.setMeta();
  }

  ngOnInit() {
    this.subscription = this.route.params.subscribe(params => {
      const step = params['step'] || this.subLinks[0].id;
      const idx = this.subLinks.findIndex(l => l.id === step);
      this.setActiveLink(idx);
      this.setMeta();
    });
  }

  setMeta() {
    this.metaService.createCanonicalURL();
    this.title.setTitle(this.active.meta.title);
    this.metaService.removeItemprop();
    this.active.meta.meta.forEach(m => this.meta.updateTag(m));
  }
}
