import {
  throwError,
  BehaviorSubject,
  Subject,
  Subscription,
  Observable
} from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ConfigService } from "./config.service";
import { FinalMileShipment } from "../models";
import { FinalMileEstimate } from '../models/final-mile-estimate.model';

@Injectable()
export class FinalMileEstimateService implements OnInit {
  public estimates$: BehaviorSubject<FinalMileEstimate[]> = new BehaviorSubject([]);
  api: string;

  constructor(private httpClient: HttpClient, private config: ConfigService) {
    this.api = this.config.getConfig("api");
  }

  ngOnInit() { }

  loadEstimates(q: FinalMileShipment) {
    return (
      this.httpClient
        .post(`${this.api}api/final-mile/fetch`, { orders: q })
        .pipe(catchError(err => throwError(err)))
        .toPromise()
        .then((results: any) => {
          console.log(results);
          if (
            typeof results === "undefined" ||
            results === null ||
            typeof results.data === "undefined" ||
            results.data === null
          )
            this.estimates$.next([]);
          else
            this.estimates$.next(
              results.data.map(e => Object.assign(new FinalMileEstimate(), e))
            );
        })
    );
  }
}
