import { NgModule } from '@angular/core';
import { AutocompleteComponent } from "./shared/autocomplete/autocomplete.component";

import {
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule, 
    MatSelectModule 
} from '@angular/material';

@NgModule({
    imports: [MatAutocompleteModule, MatInputModule, MatFormFieldModule, MatSelectModule],
    exports: [MatAutocompleteModule, MatInputModule, MatFormFieldModule, MatSelectModule]
})

export class AngularMaterialModule { }