
import {throwError as observableThrowError,  BehaviorSubject ,  Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
    Shipment,
    Carrier,
    Commodity,
    Accessorial,
    OrderDateTime,
    OrderDateTimeType
} from '../models';
import { ConfigService } from './config.service';
import { PaymentService } from './payment.service';

@Injectable()
export class ShipmentService {
    public shipment$: BehaviorSubject<Shipment>;
    api: string;

    constructor(
        private httpClient: HttpClient,
        private config: ConfigService
    ) {
        this.api = this.config.getConfig('api');
        const shipmentStr = localStorage.getItem('shipment');
        const shipment = (shipmentStr === null)
            ? new Shipment() : JSON.parse(shipmentStr);
        this.shipment$ = new BehaviorSubject<Shipment>(shipment);
    }

    setShipment(s: Shipment): void {
        if (this.config.getEnv() !== 'production')
            localStorage.setItem('shipment', JSON.stringify(s));
        this.shipment$.next(s);
    }

    getShipment(): Shipment {
        return this.shipment$.getValue();
    }

    clearShipment(): void {
        this.setShipment(new Shipment());
    }

    updateShipment(s: Shipment): void {
        Object.apply({s, ...this.shipment$.getValue()});
        this.setShipment(s);
    }

    createOrder() {
        const s = this.shipment$.getValue();
        const dt = new OrderDateTime();
        dt.TypeID = OrderDateTimeType.OrderDate;
        dt.Description = 'Order Date';
        s.DateTimes.push(dt);
        this.setShipment(s);

        return this.httpClient.post(`${this.api}api/orders/create`, s)
            .pipe(catchError(err => {
                return observableThrowError(err);
            }))
            .toPromise();
    }

    loadOrder(jlsno: String): Promise<Shipment> {
        return this.httpClient.get(
            `${this.api}api/orders/load/${jlsno}`
        )
            .pipe(catchError((err) => {
                return observableThrowError(err);
            }),
            map((res: string) => {
                return Object.assign(new Shipment(), JSON.parse(res));
            }))
            .toPromise();
    }

    loadPayment(jlsno: String, order: Shipment): Promise<Shipment> {
        return this.httpClient.get(
            `${this.api}api/payment/data/load/${jlsno}`
        )
            .pipe(catchError((err) => {
                return observableThrowError(err);
            }),
            map((res: string) => {
                order.PaymentData = JSON.parse(res);
                return Object.assign(order);
            }))
            .toPromise();
    }

    loadOrderWithPayment(jlsno: String): Promise<Shipment> {
         return new Promise<Shipment>((resolve, reject) => this.loadOrder(jlsno)
            .then(res => {
                resolve(this.loadPayment(jlsno, res)
                    .then(pay => {
                        return { res, ...pay };
                    }));
            }));
    }



    loadTracking(jlsno: String): Promise<any> {
        return this.httpClient.get(
            `${this.api}api/information/load/${jlsno}`
        )
            .pipe(catchError((err) => {
                return observableThrowError(err);
            }),
            map((res: string) => JSON.parse(res)))
            .toPromise();
    }

    loadHistory(): Promise<any[]> {
        return this.httpClient.get(
            `${this.api}api/orders/history`
        )
        .pipe(
            catchError((err) => {
                return observableThrowError(err);
            }),
            map((res: string) => JSON.parse(res))
        )
        .toPromise();
    }

    loadTrackingCarriers(): Promise<any[]> {
        return this.httpClient.get(
            `${this.api}api/orders/carriers`
        )
        .pipe(
            catchError((err) => {
                return observableThrowError(err);
            }),
            map((res: string) => JSON.parse(res))
        )
        .toPromise();
    }

    loadDocument(jls: string, type: string = 'BillOfLadingRpt', carrierLine: string = '0', useIDCShipper = false, useIDCConsignee = false) {
        return this.httpClient.get(
            `${this.api}api/orders/document/${type}/${jls}/${carrierLine}/${useIDCShipper}/${useIDCConsignee}`,
            {responseType: 'blob'}
        )
        .pipe(
            catchError((err) => {
                console.log('Error getting document ... ', err);
                return observableThrowError(err);
            }),
            map(res => {
                const file = new Blob([res], { type: 'application/pdf' });
                return file;
            })
        )
        .toPromise<Blob>();
    }

    submitReview(jlsNo: String, rating: Number, comments: String): Promise<any> {
        return this.httpClient.post(`${this.api}api/orders/review`, { jlsNo: jlsNo, rating: rating, comments: comments })
            .pipe(catchError(err => {
                return observableThrowError(err);
            }))
            .toPromise();
    }
}
