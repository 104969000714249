import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, FormControl, PatternValidator, ValidatorFn } from '@angular/forms';

export function validatePositive(): ValidatorFn {
  return (c: AbstractControl) => {
    const isValid = (c.value > 0);
    return (isValid) ? null : {
      positive: {
        valid: false
      }
    };
  };
}

@Directive({
    selector: '[validatePositive][formControlName],[validatePositive][formControl],[validatePositive][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: PositiveValidator, multi: true }
    ]
})
export class PositiveValidator implements Validator {
  validator: ValidatorFn;

  constructor() { this.validator = validatePositive(); }

  validate(c: AbstractControl) {
    return this.validator(c);
  }
}
