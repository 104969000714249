import { Injectable, Injector } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

const unprotected_routes = [
    './'
    , '../'
    , 'rtrt.'
    , 'token'
    , 'api/account/create'
    , 'api/users/add'
];

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor () {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (unprotected_routes.findIndex(rt => req.url.indexOf(rt) > -1) > -1) {
            return next.handle(req);
        }
        let authReq: HttpRequest<any>;
        const token: any = JSON.parse(localStorage.getItem('rtrt_token'));
        if (token && token.access_token) {
            const header = 'Bearer ' + token.access_token;
            authReq = req.clone({setHeaders: {Authorization: header}});
        } else {
            authReq = req.clone();
        }
        return next.handle(authReq);
    }
}
