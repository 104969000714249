<section class="container-fluid">
  <div class="row">
    <div class="col-12 pb-3 pt-4">
        <button type="submit" class="btn btn-primary float-right btn-mini" (click)="onSubmit()">Continue</button>
      <h3>Book Your Shipment<span>Item Details</span></h3>      
    </div>
  </div>
  <rtrt-freight-item-detail
    *ngFor="let commodity of quote.Commodities; let i = index"
    [commodity]="commodity"
    [id]="i"
    [handlingUnits]="handlingUnits"
    [isSubmitting]="submission$" 
    [commodities]="quote.Commodities"
    [_clientCommodities]="clientCommodities"
    (validationResult)="handleValidationResults($event)"></rtrt-freight-item-detail>
  <div class="row">
    <div class="col-6 pt-4">
      <button type="submit" class="btn btn-primary w-100" (click)="onSubmit()">Enter Shipper Details</button>
    </div>
  </div>
</section>
