import { throwError as observableThrowError, 
  BehaviorSubject, 
  throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BlogPost } from '../models';
import { ConfigService } from './config.service';
import { BlogTag } from '../models/blog-tag.model';

@Injectable({
  providedIn: 'root'
})
export class BlogService implements OnInit {
  public posts$: BehaviorSubject<BlogPost[]> = new BehaviorSubject([]);
  public tags$: BehaviorSubject<BlogTag[]> = new BehaviorSubject([]);

  private postPages = [];
  private api: string;
  private _currentPage = 0;
  public totalPages = 0;
  private _blogTagFilter = "";

  constructor(
    private config: ConfigService,
    private httpClient: HttpClient
  ) {
    this.api = this.config.getConfig('api');

    if (this.tags$.getValue().length === 0) {
      this.loadBlogTags()
        .then((results: any) => {
          if (
            typeof results === "undefined" ||
            results === null ||
            typeof results.data === "undefined" ||
            results.data === null
          )
            this.posts$.next([]);
          else
            this.tags$.next(
              results.data.map(e => Object.assign(new BlogTag(), e))
            );
        });
    }
  }

  ngOnInit() {
    this.currentPage = 1;
  }

  get currentPage() {
    return this._currentPage;
  }

  set currentPage(newPage: number) {
    // Make sure we're not reloading the existing page
    if (newPage !== this._currentPage) {
      // See if we have the page cached and use the cached version
      if (typeof this.postPages[newPage] !== "undefined" && 
          this.postPages[newPage] !== null &&
          typeof this.postPages[newPage].data !== "undefined" &&
          this.postPages[newPage].data !== null) {
        // this._currentPage = newPage;
        let results = this.postPages[newPage];
        this._currentPage = results.CurrentPage;
        this.totalPages = results.TotalPages;
        this.posts$.next(
          results.data.map(e => Object.assign(new BlogPost(), e))
        );
      } else {
        // Get the page from the server
        this._currentPage = newPage;
        this.loadBlogPosts()
        .then((results: any) => {
          if (
            typeof results === "undefined" ||
            results === null ||
            typeof results.data === "undefined" ||
            results.data === null
          )
            this.posts$.next([]);
          else {
            this.postPages[newPage] = results;
            this._currentPage = results.CurrentPage;
            this.totalPages = results.TotalPages;
            this.posts$.next(
              results.data.map(e => Object.assign(new BlogPost(), e))
            );
          }
        });
      }
    }
  }

  get blogTagFilter() {
    return this._blogTagFilter;
  }

  set blogTagFilter(newTagFilter: string) {
    // Make sure we're not reloading the existing filter
    if (newTagFilter !== this._blogTagFilter) {
      this._blogTagFilter = newTagFilter;
      // Clear out any cached blogs since the filter changes everything
      this.postPages = [];
      this.posts$.next([]);
      // Load new blog pages
      this.loadBlogPosts()
        .then((results: any) => {
          if (
            typeof results === "undefined" ||
            results === null ||
            typeof results.data === "undefined" ||
            results.data === null
          )
            this.posts$.next([]);
          else {
            this.currentPage = results.CurrentPage;
            this.totalPages = results.TotalPages;
            this.posts$.next(
              results.data.map(e => Object.assign(new BlogPost(), e))
            );
          }
        });
    }
  }

  public startOver() {
    if (this._blogTagFilter !== "") {
      this._currentPage = 1;
      this.blogTagFilter = "";
    } else {
      this.currentPage = 1;
    }
  }

  public FilterAndPageChange(newTagFilter: string, newPage: string) {
    if (this.blogTagFilter !== newTagFilter) {
      this._currentPage = parseInt(newPage);
      this.blogTagFilter = newTagFilter;
    } else {
      this.currentPage = parseInt(newPage);
    }
    return true;
  }

  private loadBlogPosts() {
    if (this._blogTagFilter !== "") {
      return this.httpClient.get(`${this.api}api/blogs/load/${this.currentPage}/tag/${this.blogTagFilter}`)
        .pipe(catchError(err => throwError(err)))
        .toPromise();
    } else {
      return this.httpClient.get(`${this.api}api/blogs/load/${this.currentPage}`)
        .pipe(catchError(err => throwError(err)))
        .toPromise();
    }
  }

  private loadBlogTags() {
    return this.httpClient.get(`${this.api}api/blogs/tags/load`)
      .pipe(catchError(err => throwError(err)))
      .toPromise();
  }

  loadBlogPost(id: any): Promise<BlogPost> {
    return this.httpClient.get(
      `${this.api}api/blogs/search/${id}`
    )
      .pipe(catchError((err) => {
        return observableThrowError(err);
      }),
        map((res: string) => {
          return Object.assign(new BlogPost(), JSON.parse(res));
        }))
      .toPromise();
  }
}