import { OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import { Quote } from '../quote.model';
// import { EstimateService } from './../../services/estimate.service';
// import { Payment } from '../payment.model';
// import { Shipment } from '../shipment.model';
import { Payment } from '../../models';
import { QuoteService } from '../../services/quote.service';
import { AuthenticationService } from '../../services/authentication.service';
import { AccountService } from '../../services/account.service';
import { FlashService } from '../../shared/flash/flash.service';
import { PaymentService } from './../../services/payment.service';
import { PromoCode } from '../../models/promo-code.model';
var PaymentFormComponent = /** @class */ (function () {
    function PaymentFormComponent(quoteService, authService, paymentService, route, router, flash, accountService) {
        var _this = this;
        this.quoteService = quoteService;
        this.authService = authService;
        this.paymentService = paymentService;
        this.route = route;
        this.router = router;
        this.flash = flash;
        this.accountService = accountService;
        this.isError = false;
        this.subscriptions = [];
        this.loading = false;
        this.states = [];
        this.paymentType = 'credit-card';
        this.newClient = false;
        this.promoCodeIsEmpty = true;
        this.subscriptions.push(this.authService.loggedIn.subscribe(function (res) { return _this.isAuthenticated = res; }));
        this.subscriptions.push(this.quoteService.quote$
            .subscribe(function (q) {
            _this.handleQuoteUpdate(q);
        }));
        this.subscriptions.push(this.accountService.user$.subscribe(function (u) {
            _this.user = u;
        }));
        // Check if this is the first order a client has placed
        this.newClient = accountService.getClientOrderStatus();
        // Set the payment form to show the right options
        if (this.accountUser === false) {
            // alert('payment-form.component.ts = False');
            this.paymentType = 'credit-card';
        }
        else {
            // alert('payment-form.component.ts = True');
            this.paymentType = 'account';
        }
    }
    PaymentFormComponent.prototype.ngOnInit = function () {
        // if (this.route.snapshot.paramMap.get('isError'))
        //   this.message = 'There was a problem charging your card. Please re-enter your card information and try again.';
        var errors = this.paymentService.getErrors();
        if (errors.error && errors.message)
            this.message = errors.message;
        this.promoCodeValid = false;
    };
    PaymentFormComponent.prototype.ngAfterViewInit = function () {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        if (this.quote.PromoCode != null && this.quote.PromoCode.PromoCodeText != '') {
            console.log("handling quote promo");
            this.handlePromoCode();
            this.promoCodeInput.control.markAsTouched();
        }
        else if (this.newClient) {
            // check for new user promo code
            console.log("handling user promo");
            this.handlePromoCode(true);
            this.promoCodeInput.control.markAsTouched();
        }
    };
    Object.defineProperty(PaymentFormComponent.prototype, "accountUser", {
        get: function () {
            return this.accountService.getUserOnAccount();
        },
        enumerable: true,
        configurable: true
    });
    PaymentFormComponent.prototype.handleQuoteUpdate = function (q) {
        this.quote = q;
        this.paymentType = (this.quote.ChargeAccount) ? 'account' : 'credit-card';
    };
    PaymentFormComponent.prototype.handleErrorPayment = function (e) {
        if (e.error && e.errCode === 1) {
            this.flash.flashMessage(e.message, '', 'error');
            this.router.navigate(['ltl-shipping-quote', 'carrier']);
        }
    };
    Object.defineProperty(PaymentFormComponent.prototype, "verifyTokenNotExpiredFunc", {
        // Stupid trick so isLoggedIn can be called from the Stripe component
        get: function () {
            return this.verifyTokenNotExpired.bind(this);
        },
        enumerable: true,
        configurable: true
    });
    PaymentFormComponent.prototype.verifyTokenNotExpired = function (startOrStop) {
        this.authService.stripeProcessingStarted = startOrStop;
    };
    // Credit Card
    PaymentFormComponent.prototype.handleSuccessfulPayment = function (e) {
        if (!this.authService.isLoggedIn())
            return;
        this.quote.BillToLocation = e.contact;
        this.quote.PaymentData = new Payment();
        console.log(e);
        if (e.payment)
            Object.assign(this.quote.PaymentData, e.payment);
        this.quote.PaymentData.JlsNo = this.quote.JlsNo;
        if (this.quote.PaymentData.ReceiptEmail == null || this.quote.PaymentData.ReceiptEmail == "")
            this.quote.PaymentData.ReceiptEmail = this.accountService.getUserEmail();
        this.quote.PaymentData.Description = "RiteRouting Purchase-JLS# " + this.quote.JlsNo;
        if (this.promoCodeValid) {
            this.quote.PaymentData.PromoCode = this.quote.PromoCode;
            this.quote.PromoCodeApplied = true;
        }
        this.quote.Carriers.forEach(function (c) {
            c.StripePayment = true;
        });
        this.quoteService.updateQuote(this.quote, true);
        this.quoteService.savePayment()
            .catch(function (err) { return console.log("Uh-oh .... ", err); });
        // Don't run makeOrder here so the quote has a chance to save
        // Seems really weird that this creates a new order while the next line
        // saves the current order so we'll call makeOrder from continue
        // this.makeOrder(e.payment);
        this.continue(e.payment);
    };
    PaymentFormComponent.prototype.makeOrder = function (res) {
        var _this = this;
        console.log("MAKEORDER");
        var promoCode = this.quote.PromoCodeApplied ? this.quote.PromoCode : null;
        this.quoteService.orderQuote(promoCode).then(function (res) {
            _this.quoteService.clearQuote();
            _this.accountService.getUser();
            _this.loading = false;
            _this.router.navigate(["freight-tracking", res]);
            setTimeout(function () {
                if (_this.authService.stripeProcessingStarted === true) {
                    _this.authService.stripeProcessingStarted = false; // Payment is complete so allow login popup
                }
            }, 5000);
        }, function (err) {
            _this.loading = false;
            console.log("Uh-oh .... ", err);
        });
    };
    Object.defineProperty(PaymentFormComponent.prototype, "PaymentDue", {
        get: function () {
            // NOTE: This logic is a duplicate of the code in RiteRoutingAPI OderController.cs _updateOrder()
            // so any changes made here need to be made there too. It seems like it should also be in the RiteRouting
            // final-mile-payment-form.component.ts code but it isn't at this point in time (may be a bug).
            if (this.promoCodeValid && this.quote.PromoCode.ArFlatCharge > 0) {
                return this.round(this.quote.TotalAR - this.quote.PromoCode.ArFlatCharge, 2);
            }
            else if (this.promoCodeValid && this.quote.PromoCode.ArDiscount > 0) {
                return this.round(this.quote.TotalAR - (this.quote.TotalAR * this.quote.PromoCode.ArDiscount), 2);
            }
            return this.quote.TotalAR;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentFormComponent.prototype, "PaymentIntentSecret", {
        get: function () {
            return this.quote.PaymentIntentSecret;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentFormComponent.prototype, "buttonText", {
        get: function () {
            return this.quote.ChargeAccount ? "Place Order" : "Charge Card";
        },
        enumerable: true,
        configurable: true
    });
    PaymentFormComponent.prototype.handleAccountPayment = function () {
        if (!this.authService.isLoggedIn())
            return;
        this.quote.ChargeAccount = true;
        this.quote.PaymentData = new Payment();
        this.quote.PaymentData.JlsNo = this.quote.JlsNo;
        this.quote.PaymentData.ReceiptEmail = this.accountService.getUserEmail();
        this.quote.PaymentData.Description = "RiteRouting Purchase-JLS# " + this.quote.JlsNo;
        this.quote.PaymentData.Amount = this.PaymentDue;
        this.quote.PaymentData.TransactionDate = new Date().toLocaleString();
        if (this.promoCodeValid) {
            this.quote.PaymentData.PromoCode = this.quote.PromoCode;
            this.quote.PromoCodeApplied = true;
        }
        this.continue();
    };
    PaymentFormComponent.prototype.handlePromoCode = function (accountPromo) {
        var _this = this;
        if (accountPromo === void 0) { accountPromo = false; }
        if (!this.authService.isLoggedIn())
            return;
        if (accountPromo) {
            // check for account promo
            this.quoteService.checkAccountPromoCode(this.user.accountId)
                .then(function (promo) {
                if (typeof (promo) === "string") {
                    var promoCode = JSON.parse(promo);
                    _this.quote.PromoCode = promoCode;
                    _this.promoCodeValid = true;
                }
                else {
                    _this.quote.PromoCode = new PromoCode();
                    _this.promoCodeValid = false;
                }
                if (_this.quote.PromoCode != undefined && _this.quote.PromoCode.PromoCodeText != "")
                    _this.promoCodeIsEmpty = false;
                else
                    _this.promoCodeIsEmpty = true;
            })
                .catch(function (error) {
                _this.quote.PromoCode = new PromoCode();
                _this.promoCodeValid = false;
                if (_this.quote.PromoCode != undefined && _this.quote.PromoCode.PromoCodeText != "")
                    _this.promoCodeIsEmpty = false;
                else
                    _this.promoCodeIsEmpty = true;
            });
        }
        else {
            // check if entered promo is valid
            var currentPromo_1 = this.quote.PromoCode.PromoCodeText;
            if (currentPromo_1 == undefined || currentPromo_1 === '') {
                this.promoCodeIsEmpty = true;
            }
            else {
                this.quoteService.checkPromoCode(this.quote.PromoCode.PromoCodeText, this.user.accountId)
                    .then(function (promo) {
                    if (typeof (promo) === "string") {
                        var promoCode = JSON.parse(promo);
                        _this.quote.PromoCode = promoCode;
                        _this.promoCodeValid = true;
                    }
                    else {
                        _this.quote.PromoCode = new PromoCode();
                        _this.quote.PromoCode.PromoCodeText = currentPromo_1;
                        _this.promoCodeValid = false;
                    }
                    _this.promoCodeIsEmpty = false;
                })
                    .catch(function (error) {
                    _this.quote.PromoCode = new PromoCode();
                    _this.quote.PromoCode.PromoCodeText = currentPromo_1;
                    _this.promoCodeValid = false;
                    _this.promoCodeIsEmpty = false;
                });
            }
        }
    };
    PaymentFormComponent.prototype.round = function (value, decimalPlaces) {
        return Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    };
    // Non-Credit Card Payments
    PaymentFormComponent.prototype.continue = function (ccPayment) {
        var _this = this;
        if (ccPayment === void 0) { ccPayment = null; }
        this.loading = true;
        this.quoteService.updateQuote(this.quote, true)
            .then(function (ret) {
            if (_this.paymentType !== 'credit-card') {
                _this.flash.flashMessage("Payment succeeded, redirecting to summary...");
                _this.makeOrder(null);
            }
            else {
                _this.makeOrder(ccPayment);
            }
        });
        // this.router.navigate(['ltl-shipping-quote', 'confirmation']);
    };
    return PaymentFormComponent;
}());
export { PaymentFormComponent };
