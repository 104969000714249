import { Injectable } from '@angular/core';

@Injectable()
export class StatusService {

  constructor() { }
  // constructor() { localStorage.removeItem('step'); }

  getStep(): Promise<any> {
    return Promise.resolve(JSON.parse(localStorage.getItem('step')));
  }

  saveStep(obj: any): Promise<void> {
    return Promise.resolve(localStorage.setItem('step', JSON.stringify(obj)));
  }

}
