var Accessorial = /** @class */ (function () {
    function Accessorial(cost, text, type) {
        this._Cost = cost,
            this.Text = text,
            this.Type = type;
    }
    Object.defineProperty(Accessorial.prototype, "Cost", {
        get: function () {
            return Math.round(this._Cost * Math.pow(10, 2)) / Math.pow(10, 2);
        },
        set: function (c) {
            this._Cost = c;
        },
        enumerable: true,
        configurable: true
    });
    return Accessorial;
}());
export { Accessorial };
