<div class="rt-card mb-4">
  <header>
    <div class="col-12 col-md-5" id="unitHeader">
      <h4 class="d-inline-block">Handling Unit #{{displayID}}</h4>
      <rtrt-bug id="mobileBug">
        <p>
          <strong>For assistance</strong>
        </p>
        <p>Call us Toll Free at 833-725-RITE (7483)
          <br /> Email us at
          <a href="mailto:customerservice@riterouting.com">customerservice@riterouting.com</a>
        </p>
      </rtrt-bug>
    </div>
    <div class="col-12 col-md-7">
      <div class="w-100">
        <p class="d-inline-block pr-2 pb-0 mb-0" *ngIf="displayID != 1">Same Specifications as Unit</p>
        <div *ngIf="id != 0" ngbDropdown class="d-inline-block dropdown">
          <button class="form-control" type="button" id="copyDropDown" ngbDropdownToggle readonly>{{this.copyID}}</button>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="copyDropDown" ngbDropdownMenu>
            <button type="button" *ngFor="let commoditiy of commodities; let i = index" class="dropdown-item" (click)="selectCopy(commoditiy, i)">{{i+1}}</button>
          </div>
        </div>
        <rtrt-bug id="bug">
          <p>
            <strong>For assistance</strong>
          </p>
          <p>Call us Toll Free at 833-725-RITE (7483)
            <br /> Email us at
            <a href="mailto:customerservice@riterouting.com">customerservice@riterouting.com</a>
          </p>
        </rtrt-bug>
      </div>
    </div>
  </header>
  <form class="rt-card-body" novalidate (submit)="onSubmit($event)" #f="ngForm">
    <!-- <div class="col-3">
      <p><strong>Quantity:</strong> {{commodity.HandlingUnits}}</p>
    </div> -->
    <div class="col-6">
      <p><strong>Dimensions:</strong> 
        {{commodity.Length}} in.
        <span class="text-dark-faded mr-1 ml-1">x</span>
        {{commodity.Width}} in.
        <span class="text-dark-faded mr-1 ml-1">x</span>
        {{commodity.Height}} in.</p>
    </div>
    <div class="col-6">
      <p><strong>Freight Class:</strong> {{commodity.FreightClass}}</p>
    </div>


    <div class="col-8 mb-3">
      <ng-template #rt1 let-r="result" let-t="term">
        <ngb-highlight [result]="r.FullDescription" [term]="t"></ngb-highlight>
      </ng-template>

      <input id="typeahead-focus" type="text" class="form-control" [ngbTypeahead]="searchCommodity" name="class" #clientComm
        (focus)="focusComm$.next($any($event).target.value)" (selectItem)="selectComm($event, clientComm)"
        (click)="clickComm$.next($any($event).target.value)" placeholder="Search for an Existing Commodity"
        [resultTemplate]="rt1" [inputFormatter]="formatter1" #instance2="ngbTypeahead" />
    </div>


    <div class="col-8 mb-3 required">
      <label for="commodity">Commodity Description</label>
      <input type="text" name="commodity" class="form-control" [(ngModel)]="commodity.CommodityDescription" (blur)="touch('desc', 'CommodityDescription')" required />
      <div *ngIf="errors.desc && touched.desc" class="form-helpers">
        <small class="text-danger">Description is required.</small>
      </div>
    </div>
    <div class="col-4 mb-3 required">
      <label for="pieces">Number of Pieces</label>
      <input type="number" name="pieces" class="form-control text-center" [(ngModel)]="commodity.Pieces" (blur)="touch('pieces', 'Pieces')" required />
      <div *ngIf="errors.pieces && touched.pieces" class="form-helpers">
        <small class="text-danger">Number of pieces must be greater than 0.</small>
      </div>
    </div>
    <div class="col-8">
      <label for="nmfc">NMFC</label>
      <input type="text" name="nmfc" class="form-control" [(ngModel)]="commodity.NMFC" (blur)="touch('nmfc', 'NMFC')" />
    </div>
    <div class="col-4 required">




            <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.Value" [term]="t"></ngb-highlight>
            </ng-template>
            
            <label for="typeahead-focus">Handling Type</label>
            <input id="typeahead-focus" type="text" class="form-control" [ngbTypeahead]="searchHandlingUnit"
              name="handlingUnitHidden" [(ngModel)]="commHU" #handlingUnitHidden="ngModel" (selectItem)="selectHU($event.item)"
              (blur)="touch('hu', 'HandlingType')" #instance="ngbTypeahead" [resultTemplate]="rt" [inputFormatter]="formatter"
              (focus)="focusHU$.next($any($event).target.value)" (click)="clickHU$.next($any($event).target.value)"
              placeholder="Please Select" required />
            <div *ngIf="errors.hu && touched.hu" class="form-helpers">
              <small class="text-danger">Handling Unit is invalid.</small>
            </div>


    </div>
  </form>
</div>
