import { Component, Input, Output, OnInit, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormsModule, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

// import { Quote } from '../quote.model';
// import { EstimateService } from './../../services/estimate.service';
// import { Payment } from '../payment.model';
// import { Shipment } from '../shipment.model';

import {
  Shipment, Payment
} from '../../models';

import { QuoteService } from '../../services/quote.service';
import { AuthenticationService } from '../../services/authentication.service';
import { AccountService } from '../../services/account.service';
import { FlashService } from '../../shared/flash/flash.service';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { StripeComponent } from './../../shared/stripe/stripe.component';
import { PaymentService } from './../../services/payment.service';
import { User } from '../../account/user.model';
import { PromoCode } from '../../models/promo-code.model';

@Component({
  selector: 'rtrt-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit, AfterViewInit {
  quote: Shipment;
  isError = false;
  isAuthenticated: boolean;
  subscriptions: Subscription[] = [];
  loading = false;
  message: string;
  states: any[] = [];
  paymentType = 'credit-card';
  user: User;
  newClient: boolean = false;
  promoCodeValid: boolean;
  promoCodeIsEmpty: boolean = true;
  @ViewChild('promoCodeInput', { static: false }) promoCodeInput: any;

  constructor(
    private quoteService: QuoteService,
    private authService: AuthenticationService,
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private router: Router,
    private flash: FlashService,
    private accountService: AccountService
  ) {
    this.subscriptions.push(
      this.authService.loggedIn.subscribe(res => this.isAuthenticated = res)
    );
    this.subscriptions.push(
      this.quoteService.quote$
        .subscribe(q => {
          this.handleQuoteUpdate(q);
        })
    );
    this.subscriptions.push(
      this.accountService.user$.subscribe(u => {
        this.user = u;
      })
    );
    // Check if this is the first order a client has placed
    this.newClient = accountService.getClientOrderStatus();

    // Set the payment form to show the right options
    if (this.accountUser === false) {
      // alert('payment-form.component.ts = False');
      this.paymentType = 'credit-card';
    } else {
      // alert('payment-form.component.ts = True');
      this.paymentType = 'account';
    }
  }

  ngOnInit() {
    // if (this.route.snapshot.paramMap.get('isError'))
    //   this.message = 'There was a problem charging your card. Please re-enter your card information and try again.';
    const errors = this.paymentService.getErrors();
    if (errors.error && errors.message)
      this.message = errors.message;

    this.promoCodeValid = false;
  }
  
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (this.quote.PromoCode != null && this.quote.PromoCode.PromoCodeText != '') {
      console.log("handling quote promo");
      this.handlePromoCode();
      this.promoCodeInput.control.markAsTouched();
    }
    else if (this.newClient) {
      // check for new user promo code
      console.log("handling user promo");

      this.handlePromoCode(true);
      this.promoCodeInput.control.markAsTouched();
    }
  }
  
  get accountUser() {
    return this.accountService.getUserOnAccount();
  }

  handleQuoteUpdate(q: Shipment) {
    this.quote = q;
    this.paymentType = (this.quote.ChargeAccount) ? 'account' : 'credit-card';
  }

  handleErrorPayment(e: any) {
    if (e.error && e.errCode === 1) {
      this.flash.flashMessage(e.message, '', 'error');
      this.router.navigate(['ltl-shipping-quote', 'carrier']);
    }
  }

  // Stupid trick so isLoggedIn can be called from the Stripe component
  get verifyTokenNotExpiredFunc() {
    return this.verifyTokenNotExpired.bind(this);
  }
  verifyTokenNotExpired(startOrStop) {
    this.authService.stripeProcessingStarted = startOrStop;
  }

  // Credit Card
  handleSuccessfulPayment(e: any) {
    if (!this.authService.isLoggedIn())
      return;
    this.quote.BillToLocation = e.contact;
    this.quote.PaymentData = new Payment();
    console.log(e);
    if (e.payment)
      Object.assign(this.quote.PaymentData, e.payment);
    this.quote.PaymentData.JlsNo = this.quote.JlsNo;
    if (this.quote.PaymentData.ReceiptEmail == null || this.quote.PaymentData.ReceiptEmail == "")
      this.quote.PaymentData.ReceiptEmail = this.accountService.getUserEmail();
    this.quote.PaymentData.Description = `RiteRouting Purchase-JLS# ${this.quote.JlsNo}`;

    if(this.promoCodeValid) {
      this.quote.PaymentData.PromoCode = this.quote.PromoCode;
      this.quote.PromoCodeApplied = true;
    }

    this.quote.Carriers.forEach(c => {
      c.StripePayment = true;
    });
    
    this.quoteService.updateQuote(this.quote, true);
    this.quoteService.savePayment()
      .catch(err => console.log("Uh-oh .... ", err));

    // Don't run makeOrder here so the quote has a chance to save
    // Seems really weird that this creates a new order while the next line
    // saves the current order so we'll call makeOrder from continue
    // this.makeOrder(e.payment);

    this.continue(e.payment);
  }

  makeOrder(res: any) { 
    console.log("MAKEORDER");
    let promoCode = this.quote.PromoCodeApplied ? this.quote.PromoCode : null;
    this.quoteService.orderQuote(promoCode).then(
      res => {
        this.quoteService.clearQuote();
        this.accountService.getUser();
        this.loading = false;
        this.router.navigate(["freight-tracking", res]);
        setTimeout(() => {
          if (this.authService.stripeProcessingStarted === true) {
            this.authService.stripeProcessingStarted = false; // Payment is complete so allow login popup
          }
        }, 5000);
      },
      err => {
        this.loading = false;
        console.log("Uh-oh .... ", err);
      }
    );
  }

  get PaymentDue(): number {
    // NOTE: This logic is a duplicate of the code in RiteRoutingAPI OderController.cs _updateOrder()
    // so any changes made here need to be made there too. It seems like it should also be in the RiteRouting
    // final-mile-payment-form.component.ts code but it isn't at this point in time (may be a bug).
    if (this.promoCodeValid && this.quote.PromoCode.ArFlatCharge > 0) {
      return this.round(this.quote.TotalAR - this.quote.PromoCode.ArFlatCharge, 2);
    }
    else if (this.promoCodeValid && this.quote.PromoCode.ArDiscount > 0) {
      return this.round(this.quote.TotalAR - (this.quote.TotalAR * this.quote.PromoCode.ArDiscount), 2);
    }

    return this.quote.TotalAR;
  }

  get PaymentIntentSecret(): string {
    return this.quote.PaymentIntentSecret;
  }

  get buttonText(): string {
    return this.quote.ChargeAccount ? "Place Order" : "Charge Card";
  }
  
  handleAccountPayment() {
    if (!this.authService.isLoggedIn())
      return;
    this.quote.ChargeAccount = true;
    this.quote.PaymentData = new Payment();
    this.quote.PaymentData.JlsNo = this.quote.JlsNo;
    this.quote.PaymentData.ReceiptEmail = this.accountService.getUserEmail();
    this.quote.PaymentData.Description = `RiteRouting Purchase-JLS# ${this.quote.JlsNo}`;
    this.quote.PaymentData.Amount = this.PaymentDue;
    this.quote.PaymentData.TransactionDate = new Date().toLocaleString();

    if (this.promoCodeValid) {
      this.quote.PaymentData.PromoCode = this.quote.PromoCode;
      this.quote.PromoCodeApplied = true;
    }

    this.continue();
  }

  handlePromoCode(accountPromo: boolean = false) {
    if (!this.authService.isLoggedIn())
      return;
    if(accountPromo) {
      // check for account promo
      this.quoteService.checkAccountPromoCode(this.user.accountId)
      .then(promo => {
        if (typeof (promo) === "string") {
          let promoCode = JSON.parse(promo);
          this.quote.PromoCode = promoCode;
          this.promoCodeValid = true;
        }
        else {
          this.quote.PromoCode = new PromoCode();
          this.promoCodeValid = false;
        }
        if (this.quote.PromoCode != undefined && this.quote.PromoCode.PromoCodeText != "")
          this.promoCodeIsEmpty = false;
        else
          this.promoCodeIsEmpty =true;
      })
      .catch(error => {
        this.quote.PromoCode = new PromoCode();
        this.promoCodeValid = false;
        if (this.quote.PromoCode != undefined && this.quote.PromoCode.PromoCodeText != "")
          this.promoCodeIsEmpty = false;
        else
          this.promoCodeIsEmpty =true;
      });
    }
    else {
      // check if entered promo is valid
      let currentPromo = this.quote.PromoCode.PromoCodeText;
      if (currentPromo == undefined || currentPromo === '') {
        this.promoCodeIsEmpty = true;
      } else {
        this.quoteService.checkPromoCode(this.quote.PromoCode.PromoCodeText, this.user.accountId)
          .then(promo => {
            if(typeof(promo) === "string") {
              let promoCode = JSON.parse(promo);
              this.quote.PromoCode = promoCode;
              this.promoCodeValid = true;
            }
            else {
              this.quote.PromoCode = new PromoCode();
              this.quote.PromoCode.PromoCodeText = currentPromo;
              this.promoCodeValid = false;
            }
            this.promoCodeIsEmpty = false;
          })
          .catch(error => {
            this.quote.PromoCode = new PromoCode();
            this.quote.PromoCode.PromoCodeText = currentPromo;
            this.promoCodeValid = false;
            this.promoCodeIsEmpty = false;
          });
      }
    }
  }
  
  round(value: number, decimalPlaces: number) {
    return Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
  }

  // Non-Credit Card Payments
  continue(ccPayment: any = null) {
    this.loading = true;
    this.quoteService.updateQuote(this.quote, true)
      .then(ret => {
        if (this.paymentType !== 'credit-card') {
          this.flash.flashMessage("Payment succeeded, redirecting to summary...");
          this.makeOrder(null);
        } else {
          this.makeOrder(ccPayment);
        }
      });
    // this.router.navigate(['ltl-shipping-quote', 'confirmation']);
  }
}
