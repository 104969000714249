<section class="page page-quoting-services">
    <section class="container-fluid">
        <div class="row">
            <div class="container">
                <div class="row pt-5">
                    <div class="col-12 text-center">
                        <h2>Select an option to get started!</h2>
                    </div>

                </div>
                <div class="row justify-content-center pt-5">
                    <div class="col-sm-12 col-lg-6 text-center">
                        <button id="btnLtlShipping" (click)="routeTo('/ltl-shipping-quote')" (mouseover)="showLTL = true;" (mouseout)="showLTL = false;" class="mb-3 serviceButton"
                            style="box-shadow: 0 0 10px 0px #666; background-position: center; background-size: cover;"></button>
                        <div *ngIf="showLTL" class="text-left" id="desktopLTLDescription">
                            <div class="descriptionHeader p-2 m-1">
                                Shipment weighs between 150 - 19,999 lbs
                            </div>
                            <ul>
                                <li><strong>1-6 non-stackable (or up to 12 stackable) pallets</strong></li>
                            </ul>
                            <div class="descriptionHeader p-2 m-1">
                                Does not require an entire 48 ft or 53 ft dry van trailer
                            </div>
                            <ul>
                                <li><strong>Shared space helps minimize cost</strong></li>
                            </ul>
                        </div>
                        <div class="text-left d-none" id="mobileLTLDescription">
                            <div class="descriptionHeader p-2 m-1">
                                Shipment weighs between 150 - 19,999 lbs
                            </div>
                            <ul>
                                <li><strong>1-6 non-stackable (or up to 12 stackable) pallets</strong></li>
                            </ul>
                            <div class="descriptionHeader p-2 m-1">
                                Does not require an entire 48 ft or 53 ft dry van trailer
                            </div>
                            <ul>
                                <li><strong>Shared space helps minimize cost</strong></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
