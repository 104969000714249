import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var MetaService = /** @class */ (function () {
    function MetaService(dom) {
        this.dom = dom;
    }
    MetaService.prototype.createCanonicalURL = function () {
        var oldlink = this.dom.querySelector('link[rel=canonical]');
        if (oldlink) {
            oldlink.remove();
        }
        var link = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(link);
        link.setAttribute('href', this.dom.URL);
    };
    MetaService.prototype.overrideCanonicalURL = function (correctURL) {
        var oldlink = this.dom.querySelector('link[rel=canonical]');
        if (oldlink) {
            oldlink.remove();
        }
        var link = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(link);
        link.setAttribute('href', correctURL);
    };
    /* The meta.updateTag method doesn't update existing itemprop
       tags, it adds new ones. This method should be ran to
       remove old ones first to remove the 3 values we use. It
       should also be run after the blog page properties are
       added because we use an LD+JSON script with more data
       for those.
    */
    MetaService.prototype.removeItemprop = function () {
        document.querySelectorAll("script[type='application/ld+json']").forEach(function (s) { return s.remove(); });
        //this.meta.removeTag("itemprop='name'");
        document.querySelectorAll("meta[itemprop=name]").forEach(function (m) { return m.remove(); });
        //this.meta.removeTag("itemprop='description'");
        document.querySelectorAll("meta[itemprop=description]").forEach(function (m) { return m.remove(); });
        //this.meta.removeTag("itemprop='image'");
        document.querySelectorAll("meta[itemprop=image]").forEach(function (m) { return m.remove(); });
    };
    MetaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MetaService_Factory() { return new MetaService(i0.ɵɵinject(i1.DOCUMENT)); }, token: MetaService, providedIn: "root" });
    return MetaService;
}());
export { MetaService };
