<section class="page page-home">
  <section id="site-banner">
    <div class="container">
      <div class="form-container">
        <div class="col-12 text-center">
          <span style="font-size: 22px; font-weight: bold;">I Need:</span>
        </div>
        <div class="col-12 text-center">
          <button id="btnLtlShipping" (click)="routeTo('/ltl-shipping-quote')" class="mb-2 serviceButton" style="background-position: center; background-size: cover; cursor: pointer; box-shadow: 0 0 10px 0px #666;"></button>
        </div>
          <div class="form-group">
            <!-- button type="button" class="btn btn-primary btn-display bordered w-100" (click)="routeTo('/categories')">Start Your
              Quote</button -->
            <p class="text-center"><small>**Preferred Browser: Google Chrome, Firefox, iOS Safari.</small></p>
          </div>
      </div>
      <div class="billboard col-8 text-center">
        <!-- <div class="col"> -->
          <h1>
            <span class="opening red">LTL FREIGHT, </span>
            <span class="opening">DONE RITE.</span>
          </h1>
          <p></p>
          <p>RiteRouting is a transportation service provider powered by cutting-edge technology and 
            backed by a dedicated team of experienced less-than-truckload (LTL)
            shipping professionals.</p>
          <p>When you use RiteRouting to ship your LTL freight, you get multiple LTL 
            carrier options to choose from, which means you get the most competitive LTL quotes 
            for your required service level – every time.</p>
          <p><span class="closing">
            Logistics never stops... and neither does RiteRouting.
          </span></p>
          <button type="button" class="quotebutton btn btn-primary btn-display bordered w-100" (click)="routeTo('/categories')">
            Start Your Quote
          </button>
          <!-- <p><a (click)="routeTo('/ltl-shipping-quote')">Start your LTL quote</a> today for Shipping Done Rite!</p> -->
        <!-- </div> -->
      </div>
    </div>
  </section>
  <!-- <rtrt-banner></rtrt-banner> -->
  <div class="container-fluid">
    <section class="row b-bottom-heavier-primary pt-4 pb-4">
      <div class="container">
        <div class="row pt-5 pb-5">
          <div class="col">
            <h2>
              <span class="subopening darkred">RiteRouting provides less-than-truckload (LTL)
                shipping powered by our cutting-edge technology and backed by an experienced team 
                of LTL shipping professionals.</span>
            </h2>
            <p>The experienced RiteRouting LTL team helps you ship your freight 
              efficiently and cost-effectively by utilizing our large LTL carrier
              network. We negotiate lower transportation costs because of our volume
              buying power and pass the savings on to you.</p>
            <p>No more time-consuming LTL quote shopping and comparing multiple LTL 
              carriers and LTL freight quotes. When you use RiteRouting to ship your
              LTL freight, you get the most competitive LTL quotes for your 
              shipment.</p>
            <p>RiteRouting is Shipping Done Rite.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="row hero hero1 pt-0 pb-6 bg-primary text-light">
      <div class="container">
        <div class="row p-0 justify-content-md-end">
          <div class="content bg-dark-faded">
            <h4>The Benefits<br />of RiteRouting</h4>
            <ul>
              <li>
                <strong>Easy to use</strong>
              </li>
              <li>
                <strong>Best Cost, Best Service &mdash; Every Time</strong>
              </li>
              <li>
                <strong>24/7/365 access</strong>
              </li>
              <li>
                <strong>No Contracts</strong>
              </li>
              <li>
                <strong>Customer Reviews</strong>
              </li>
              <li>
                <strong>Enhanced Shipment Visibility</strong>
              </li>
              <li>
                <strong>Peace-of-Mind</strong>
              </li>
              <!-- <li>
                <strong>RiteRouting Rewards</strong>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div class="row pt-5 pb-5">
        <div class="container">
          <!-- <section class="row b-bottom-secondary pt-5 pb-f">
            <div class="col-10">
              <h2>Full Truckload Services</h2>
              <p>RiteRouting TL Services includes access to a large network of carriers that match our commitment to real time updates and next level communication.  RiteRouting utilizes dedicated Dry Vans, Reefer Vans, Flatbed, Intermodal and specialized services to meet all your shipping needs; including partial truckload services on all equipment types.  Contact our team of logistics professionals at RiteRouting today for Shipping Done Rite.</p>
              <p><button type="button" (click)="routeTo('/truckload')" class="btn btn-primary">Learn more about FTL Services</button></p>
            </div>
            <div class="col-2">
              <div class="rounded-circle">
                <img class="img-fluid" src="assets/images/icon-reversed-truckload-services.svg" />
              </div>
            </div>
          </section> -->
          <div class="row pt-f pb-0">
              <div class="col-12 text-center pl-0 pr-0">
                <h3>
                  <span class="subopening darkred">
                    We Connect You With the Best LTL Carriers in the Business
                  </span>
                </h3>
              </div>
              <div class="col-12 text-center">
                  <p>RiteRouting has gone through a thorough vetting process to offer the most experienced and reputable carriers all across
                  North America.</p>
                  <p>Experience RiteRouting for yourself – <a (click)="routeTo('/ltl-shipping-quote')">start your LTL quote</a> now!</p>
              </div>
          </div>
          <!-- <div class="row pt-0 pb-5 align-items-center">
              <div class="col text-center">
                  <img class="img-fluid" src="https://via.placeholder.com/204x60?text=LOGO" />
              </div>
              <div class="col text-center">
                  <img class="img-fluid" src="https://via.placeholder.com/180x80?text=LOGO" />
              </div>
              <div class="col text-center">
                  <img class="img-fluid" src="https://via.placeholder.com/180x60?text=LOGO" />
              </div>
              <div class="col text-center">
                  <img class="img-fluid" src="https://via.placeholder.com/180x40?text=LOGO" />
              </div>
              <div class="col text-center">
                  <img class="img-fluid" src="https://via.placeholder.com/180x90?text=LOGO" />
              </div>
          </div> -->
        </div>
    </div>    
  </div>
</section>
