<div class="row b-bottom-secondary pt-5 mb-5">
  <div class="col-12 d-flex align-items-center justify-content-between"></div>
</div>
<div class="filter-bar">
  <div class="input-group">
    <input
      type="text"
      class="form-control"
      placeholder="Search orders"
      [(ngModel)]="search"
    />
  </div>

  <ng-multiselect-dropdown class="form-control" [placeholder]="'Carriers'" [data]="carriers" [(ngModel)]="filterCarriers"
    [settings]="dropdownSettings">
  </ng-multiselect-dropdown>

  <div class="input-group" *ngIf="loggedIn">
    <input
      type="text"
      class="form-control"
      placeholder="Ship Date   (MM/DD/YYYY)"
      [(ngModel)]="searchDate"
      ngbDatepicker
      placement="bottom-right"
      #d="ngbDatepicker"
      required
    />
    <button class="input-group-addon" (click)="d.toggle()" type="button">
      <img class="icon" src="assets/images/icon-color-calendar.svg" />
    </button>
  </div>
      <div *ngIf="loggedIn"></div>

  <label
    for="saveInstructions"
    class="form-check-label non-primary-label"
    (click)="toggleDelivered()"
    *ngIf="loggedIn"
  >

    <input type="checkbox" [(ngModel)]="showDelivered" />
    <span class="control"></span>
    Show delivered orders
  </label>
  <div *ngIf="!loggedIn"></div>
  <div>
    <button type="button" class="btn btn-mini" (click)="clearFilters()">
      Clear
    </button>
  </div>
</div>

<div *ngIf="!loggedIn && !(search && filterCarriers.length > 0)">
  <p>Please select a carrier and enter an order number to track a shipment.</p>
</div>
<div *ngIf="((loggedIn && (searchDate || search) && orders.length === 0) || (!loggedIn && search && filterCarriers.length > 0 && filteredOrders.length === 0))">
  <p>We're sorry, your search yielded no results.</p>
</div>
<div *ngIf="loggedIn">
  <div *ngFor="
      let order of (carrierFilteredOrders
        | objectFilter
          : ['jlsNo', 'shipperZipCode', 'consigneeZipCode', 'proNo', 'mrNo']
          : search
        | objectDateFilter: ['shipDate']:searchDate)
    ">
    <div *ngIf="this.showDelivered || order.shipmentStatusID !== '6'" class="rt-card mt-5 mb-5">
      <header>
        <h3>{{ order.jlsNo }}</h3>
        <span class="ship-date">Ship Date:
          <strong>{{ order.shipDate | date: "fullDate" }}</strong></span>
        <nav>
          <button type="button" class="btn btn-secondary btn-mini" (click)="getBOL(order)">
            Print BOL
          </button>
          <button type="button" class="btn btn-secondary btn-mini" (click)="getLabel(order)">
            Print Label
          </button>
          <button type="button" class="btn btn-secondary btn-mini" (click)="getBoth(order)">
            Print Both
          </button>
          <button type="button" class="btn btn-primary btn-mini" (click)="viewDetails(order)">
            View details
          </button>
        </nav>
      </header>
      <div class="rt-card-body">
        <p class="mb-0 col-12 col-md-2">
          Status: <strong>{{ order.shipmentStatusText }}</strong>
        </p>
        <p class="mb-0 col-12 col-md-5">
          Origin:
          <strong>{{ order.shipperName }} {{ order.shipperZipCode }}</strong>
        </p>
        <p class="mb-0 col-12 col-md-5">
          Destination:
          <strong>{{ order.consigneeName }} {{ order.consigneeZipCode }}</strong>
        </p>
        <p class="mb-0 col-12 col-md-2">
          <span *ngIf="order.isFinalMile == 'True'">MR#: <strong>{{ order.mrNo }}</strong></span>
        </p>
        <p class="mb-0 col-12 col-md-5">
          Carrier: <strong>{{ order.carrierName }}</strong>
        </p>
        <p class="mb-0 col-12 col-md-5">
          Pro Number: <strong>{{ order.proNo }}</strong>
        </p>
        <p *ngIf="order.isFinalMile == 'True' && order.pickupNo > 0" class="mb-0 col-12 col-md-2">
          Pickup#: <strong>{{ order.pickupNo }}</strong>
        </p>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!loggedIn && search && filterCarriers.length > 0">
  <div *ngFor="let order of filteredOrders">
    <div class="rt-card mt-5 mb-5">
      <header>
        <h3>{{ order.jlsNo }}</h3>
        <span class="ship-date">Ship Date:
          <strong>{{ order.shipDate | date: "fullDate" }}</strong></span>
      </header>
      <div class="rt-card-body">
        <p class="mb-0 col-12 col-md-2">
          Status: <strong>{{ order.shipmentStatusText }}</strong>
        </p>
        <p class="mb-0 col-12 col-md-5">
          Origin:
          <strong>{{ order.shipperName }} {{ order.shipperZipCode }}</strong>
        </p>
        <p class="mb-0 col-12 col-md-5">
          Destination:
          <strong>{{ order.consigneeName }} {{ order.consigneeZipCode }}</strong>
        </p>
        <p class="mb-0 col-12 col-md-2">
          <span *ngIf="order.isFinalMile == 'True'">MR#: <strong>{{ order.mrNo }}</strong></span>
        </p>
        <p class="mb-0 col-12 col-md-5">
          Carrier: <strong>{{ order.carrierName }}</strong>
        </p>
        <p class="mb-0 col-12 col-md-5">
          Pro Number: <strong>{{ order.proNo }}</strong>
        </p>
        <p *ngIf="order.isFinalMile == 'True' && order.pickupNo > 0" class="mb-0 col-12 col-md-2">
          Pickup#: <strong>{{ order.pickupNo }}</strong>
        </p>
      </div>
    </div>
  </div>
</div>
