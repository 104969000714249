/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./flash.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./flash.component";
import * as i4 from "./flash.service";
var styles_FlashComponent = [i0.styles];
var RenderType_FlashComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FlashComponent, data: {} });
export { RenderType_FlashComponent as RenderType_FlashComponent };
function View_FlashComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "alert alert-dismissable"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["aria-hidden", "true"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵted(7, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "alert alert-dismissable"; var currVal_1 = ("alert-" + _co.f.type); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.f.title; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.f.message; _ck(_v, 7, 0, currVal_3); }); }
export function View_FlashComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlashComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.f == null) ? null : _co.f.visible); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FlashComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-flash", [], null, null, null, View_FlashComponent_0, RenderType_FlashComponent)), i1.ɵdid(1, 180224, null, 0, i3.FlashComponent, [i4.FlashService], null, null)], null, null); }
var FlashComponentNgFactory = i1.ɵccf("rtrt-flash", i3.FlashComponent, View_FlashComponent_Host_0, {}, {}, []);
export { FlashComponentNgFactory as FlashComponentNgFactory };
