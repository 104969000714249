import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, FormControl, PatternValidator } from '@angular/forms';

const PHONE_REGEX: RegExp = /\+?\(?\d{2,4}\)?[\d\s-]{3,}/i;

export function validatePhone(c: AbstractControl) {
  return PHONE_REGEX.test(c.value) ? null : {
    phone: {
      valid: false
    }
  };
}

@Directive({
    selector: '[validatePhone][formControlName],[validatePhone][formControl],[validatePhone][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => PatternValidator), multi: true }
    ]
})
export class PhoneValidator implements Validator {
  constructor() {}

  validate(c: AbstractControl) {
    return validatePhone(c);
  }
}
