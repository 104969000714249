import { formatDate } from '@angular/common';
var CarrierReview = /** @class */ (function () {
    function CarrierReview() {
        this.date = new Date();
    }
    Object.defineProperty(CarrierReview.prototype, "formattedDate", {
        get: function () {
            return formatDate(this.date, 'longDate', 'en-US');
        },
        enumerable: true,
        configurable: true
    });
    return CarrierReview;
}());
export { CarrierReview };
