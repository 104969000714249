import { BehaviorSubject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import {
  FinalMileShipment
} from "../models";
import { ConfigService } from "./config.service";

@Injectable()
export class FinalMileService {
  private _temp: FinalMileShipment = null;
  public quote$: BehaviorSubject<FinalMileShipment>;
  api: string;
  private _handlingunits: any[] = [];
  public handlingUnits$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    this._handlingunits
  );
  private _references: any[] = [];
  public references$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    this._references
  );
  public billableAddress: any;

  constructor(
    private httpClient: HttpClient, 
    private config: ConfigService
  ) {
    this.api = this.config.getConfig("api");
    const quoteStr = localStorage.getItem("final-mile-quote");
    const quote = quoteStr === null ? new FinalMileShipment() : JSON.parse(quoteStr);
    //const quote = new FinalMileShipment();
    this.quote$ = new BehaviorSubject<FinalMileShipment>(quote);
    this._loadStoredValues();
  }

  setQuote(s: FinalMileShipment): void {
    if (this.config.getEnv() !== "production")
      localStorage.setItem("final-mile-quote", JSON.stringify(s));
    this.quote$.next(s);
  }

  getQuote(): FinalMileShipment {
    return this.quote$.getValue();
  }

  clearQuote(): void {
    this.setQuote(new FinalMileShipment());
  }

  createQuote(s: FinalMileShipment = null, saveRates: boolean = false) {
    const shipment = s === null ? this.quote$.getValue() : s;
    shipment.Vendors = shipment.Vendors.map(o => ({ ...o, QueueStatus: 0 }));

    console.log("shipment to save", shipment);
    return this.httpClient
      .post(`${this.api}api/final-mile/save`, { orders: shipment, saveShipper: false, saveConsignee: false, saveRates: saveRates })
      .pipe(
        map(res => {
          if (typeof res === "string") {
            this.updateQuote(JSON.parse(res), false, false, false, saveRates);
          }
        })
      )
      .toPromise();
  }

  loadQuote(mrNo: string) {
    return this.httpClient
      .get(`${this.api}api/final-mile/load/${mrNo}`)
      .pipe(
        catchError(err => {
          console.log(err);
          return err;
        }),
        map((res: any) => {
          let q: FinalMileShipment;
          if (typeof res === "string") {
            this.clearQuote();
            q = JSON.parse(res);
          } else {
            q = res;
          }
          this.updateQuote(q);
        })
      )
      .toPromise();
  }

  savePayment() {
    return this.httpClient
      .post(
        `${this.api}api/payment/data/save`,
        {...this.quote$.getValue().PaymentData, ...{'isFinalMile': true }}
      )
      .pipe(catchError(err => throwError(err)))
      .toPromise();
  }

  orderQuote() {
    // const q = this.quote$.getValue();
    // q.eQuoteNumber = q.JlsNo;
    // q.JlsNo = '';
    return this.httpClient
      .post(`${this.api}api/final-mile/create`, { orders: this.quote$.getValue() })
      .pipe(map((res: any) => JSON.parse(res).Vendors[0].JlsNo))
      .toPromise();
  }
  
  updateQuote(s: FinalMileShipment, save = false, saveShipper: boolean = false, saveConsignee: boolean = false, saveRates: boolean = false, generateIntent: boolean = false) {
    this.setQuote({ ...this.quote$.getValue(), ...s });
    
    if (save || generateIntent) {
      return new Promise<string>((resolve) => 
        this.saveQuote(s, saveShipper, saveConsignee, saveRates, generateIntent)
          .then((res: string) => {
            // need to reset the quote if rates were applied
            if (typeof res === "string" && saveRates) {
              let shipment = JSON.parse(res);
              this.setQuote({ ...this.quote$.getValue(), ...shipment });
            }
            var intent = JSON.parse(res).Vendors[0].PaymentIntentSecret;
            this.quote$.getValue().PaymentIntentSecret = intent;
            // console.log("updateQuote after saveQuote end:", this.quote$.getValue());
            // console.log("updateQuote after saveQuote return PaymentIntentSecret:", JSON.parse(res).Vendors[0].PaymentIntentSecret);
            resolve(intent);
          })
        );
    }
  }

  saveQuote(s: FinalMileShipment = null, saveShipper: boolean = false, saveConsignee: boolean = false, saveRates: boolean = false, generateIntent: boolean = false) {
    const shipment = s === null ? this.quote$.getValue() : s;
    return this.httpClient
      .post(`${this.api}api/final-mile/save`, { orders: shipment, saveShipper: saveShipper, saveConsignee: saveConsignee, saveRates: saveRates, generateIntent: generateIntent })
      .toPromise();
  }

  checkInsurance(valueOfGoods: number) {
    return this.httpClient
      .post(`${this.api}api/validate/insurance`, { valueOfGoods: valueOfGoods })
      .pipe(catchError(err => throwError(err)))
      .toPromise();
  }

  private _fetchHandlingUnits() {
    return this.httpClient
      .get(`${this.api}api/final-mile/handlingtypes`)
      .pipe(map((res: any) => JSON.parse(res)))
      .toPromise();
  }

  private _fetchReferences() {
    return this.httpClient
      .get(`${this.api}api/quotes/references`)
      .pipe(map((res: any) => JSON.parse(res)))
      .toPromise();
  }

  private async _loadStoredValues() {
    // Handling Units
    await this._fetchHandlingUnits().then(res => {
      this._handlingunits = res;
      localStorage.setItem(
        "handlingunits",
        JSON.stringify({ expiry: new Date(), data: this._handlingunits })
      );
    });

    this.handlingUnits$.next(this._handlingunits);

    // References
    await this._fetchReferences().then(res => {
      this._references = res;
      localStorage.setItem(
        "references",
        JSON.stringify({ expiry: new Date(), data: this._references })
      );
    });

    this.references$.next(this._references);
  }
  private _dateDiff(d1, d2) {
    return Math.ceil(
      Math.abs(d2.getTime() - d1.getTime()) / (1000 * 3600 * 24)
    );
  }
  
  private _loadFromStorage(key: string) {
    const raw: string = localStorage.getItem(key);
    if (!raw || raw === null || raw === "") return false;
    const json: any = JSON.parse(raw);
    if (
      !json.expiry ||
      !json.data ||
      this._dateDiff(new Date(), new Date(json.expiry)) > 1
    )
      return false;

    return json.data;
  }

  get readyForPayment(): boolean {
    let isReady = true;
    if (this.quote$ && this.quote$.getValue() !== null) {
      const q = this.quote$.getValue();
      if (q.Vendors.length === 0) isReady = false;
      if (q.Vendors.some(v => v.Commodities.length === 0)) isReady = false;
      if (q.Vendors.some(v => v.Carriers.length === 0)) isReady = false;
      if (
        !q.Consignee.Name ||
        q.Consignee.Name === null ||
        q.Consignee.Name === ""
      )
        isReady = false;
      if (q.Vendors.some(v => (!v.Shipper.Name || v.Shipper.Name === null || v.Shipper.Name === "")))
        isReady = false;
    } else {
      isReady = false;
    }
    return isReady;
  }

  loadOrder(jlsno: String): Promise<FinalMileShipment> {
    return this.httpClient.get(
      `${this.api}api/final-mile/load/${jlsno}`
    )
      .pipe(catchError((err) => {
        return throwError(err);
      }),
        map((res: string) => {
          return Object.assign(new FinalMileShipment(), JSON.parse(res));
        }))
      .toPromise();
  }

  loadTracking(jlsno: String): Promise<any> {
    return this.httpClient.get(
      `${this.api}api/information/final-mile/load/${jlsno}`
    )
      .pipe(catchError((err) => {
        return throwError(err);
      }),
        map((res: string) => JSON.parse(res)))
      .toPromise();
  }

  loadHistory(): Promise<any[]> {
    return this.httpClient.get(
      `${this.api}api/orders/history`
    )
      .pipe(
        catchError((err) => {
          return throwError(err);
        }),
        map((res: string) => JSON.parse(res))
      )
      .toPromise();
  }

  loadDocument(jls: string, type: string = 'BillOfLadingRpt', carrierLine: string = '0', useIDCShipper = false, useIDCConsignee = false) {
    return this.httpClient.get(
      `${this.api}api/orders/document/${type}/${jls}/${carrierLine}/${useIDCShipper}/${useIDCConsignee}`,
      { responseType: 'blob' }
    )
      .pipe(
        catchError((err) => {
          console.log('Error getting document ... ', err);
          return throwError(err);
        }),
        map(res => {
          const file = new Blob([res], { type: 'application/pdf' });
          return file;
        })
      )
      .toPromise<Blob>();
  }
}
