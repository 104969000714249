/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./spinner.component";
var styles_SpinnerComponent = [i0.styles];
var RenderType_SpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpinnerComponent, data: {} });
export { RenderType_SpinnerComponent as RenderType_SpinnerComponent };
function View_SpinnerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }); }
function View_SpinnerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "spinner-container"]], [[4, "width", null], [4, "display", null]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["src", "assets/images/icon-color-loader.svg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpinnerComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "spinner-container"; var currVal_3 = _co.class; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = (_co.message && (_co.message != "")); _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = _co.display; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_SpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpinnerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-spinner", [], null, null, null, View_SpinnerComponent_0, RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], null, null)], null, null); }
var SpinnerComponentNgFactory = i1.ɵccf("rtrt-spinner", i3.SpinnerComponent, View_SpinnerComponent_Host_0, { visible: "visible", message: "message", display: "display", width: "width", class: "class" }, {}, []);
export { SpinnerComponentNgFactory as SpinnerComponentNgFactory };
