import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { User } from '../user.model';
import { RtValidators, PasswordMatchValidation } from '../../validators/rt-validators';

@Component({
  selector: 'rtrt-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  @Input()
  user: User = new User();

  @Input()
  saveText = 'Submit';

  @Output()
  onSubmission = new EventEmitter();

  userForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.userForm = this.fb.group({
      title: [this.user.title],
      username: [this.user.username, [Validators.required]],
      name: [this.user.name, []],
      firstname: [this.user.firstname, [Validators.required]],
      lastname: [this.user.lastname, [Validators.required]],
      email: [this.user.email, [Validators.required, Validators.email]],
      phone: [this.user.phone, [Validators.required, RtValidators.phone]],
      password: ['', [Validators.required]],
      passwordConfirm: ['', [Validators.required]],
    },
    {
      validator: PasswordMatchValidation.validate
    });
  }

  onSubmit() {
    this.user.name = (this.firstname + ' ' + this.lastname).trim();
    if (this.userForm.valid) {
      this.onSubmission.emit(this.userForm.value);
    } else {
      this.onSubmission.emit(null);
    }
  }

  onNameChange() {
    console.log("onNameChange");
    this.user.name = (this.user.firstname + ' ' + this.user.lastname).trim();
  }

  get title() { return this.userForm.get('title'); }
  get username() { return this.userForm.get('username'); }
  get name() { return this.userForm.get('name'); }
  get firstname() { return this.userForm.get('firstname'); }
  get lastname() { return this.userForm.get('lastname'); }
  get email() { return this.userForm.get('email'); }
  get phone() { return this.userForm.get('phone'); }
  get password() { return this.userForm.get('password'); }
  get passwordConfirm() { return this.userForm.get('passwordConfirm'); }
}
