<form #loginForm="ngForm" class="container" (ngSubmit)="login()">
  <div *ngIf="title" class="row">
    <div class="col-12">
      <h4 class="modal-title">{{title}}</h4>
    </div>
    <div class="col-12" *ngIf="message">
      <div class="alert alert-danger">{{message}}</div>
    </div>
  </div>
  <div class="row" *ngIf="spinner">
    <div class="col-12 pt-4 pb-4 text-center">
        <rtrt-spinner [visible]="spinner"></rtrt-spinner>
    </div>
  </div>
  <div class="form-fields" *ngIf="!spinner">
    <div class="row">
      <div class="form-group col">
        <label class="form-control-label required" for="username">User Name</label>
        <input type="text" class="form-control" id="username" [(ngModel)]="username" name="username" required>
      </div>
    </div>
    <div class="row">
      <div class="form-group col">
        <label class="form-control-label required" for="password">Password</label>
        <input type="password" class="form-control" id="password" [(ngModel)]="password" name="password" required>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button type="submit" class="btn btn-primary w-100" [disabled]="spinner">Login</button>
    </div>    
  </div>
</form>