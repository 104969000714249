import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'objectFilter'
})
export class ObjectFilterPipe implements PipeTransform {
    transform(obj: any[], prop: string | string[], searchText: string): any[] {
        const arr = [];
        const ret = [];
        if (!obj || obj.length === 0 || !prop) return [];

        if (!Array.isArray(prop)) arr.push(prop);
        else prop.map(p => arr.push(p));

        if (!searchText || arr.filter(p => !obj[0].hasOwnProperty(p)).length > 0) return obj;

        searchText = searchText.toLowerCase();

        arr.forEach(p => {
            obj.filter((item) => item[p].toLowerCase().includes(searchText))
                .forEach(res => {
                    if (ret.indexOf(res) < 0)
                        ret.push(res);
                });
        });

        return ret;
    }
}
