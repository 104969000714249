import { OnInit, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '../models';
var AddressCardComponent = /** @class */ (function () {
    function AddressCardComponent(modalService) {
        this.modalService = modalService;
        this.delete = new EventEmitter();
    }
    AddressCardComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(AddressCardComponent.prototype, "name", {
        get: function () {
            return (this.address && this.address.Name) ? this.address.Name.toString() : 'Address';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressCardComponent.prototype, "type", {
        get: function () {
            return (this.address && this.address.IsConsignee) ? 'consignee' : 'shipper';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressCardComponent.prototype, "defaults", {
        get: function () {
            return (this.address.IsDefaultBillTo) ? 'Billing' : '';
        },
        enumerable: true,
        configurable: true
    });
    AddressCardComponent.prototype.open = function (content) {
        var _this = this;
        this.modalService.open(content).result.then(function (result) {
            _this.delete.emit(_this.address.ID);
        }, function (reason) { });
    };
    return AddressCardComponent;
}());
export { AddressCardComponent };
