/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bug.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "./bug.component";
var styles_BugComponent = [i0.styles];
var RenderType_BugComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BugComponent, data: {} });
export { RenderType_BugComponent as RenderType_BugComponent };
function View_BugComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "bug-content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
function View_BugComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "button", [["class", "bug-wrapper"], ["tabindex", "-1"], ["type", "button"]], [[4, "float", null]], null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i2.NgbPopover, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i2.NgbPopoverConfig, i1.NgZone, i3.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { ngbPopover: [0, "ngbPopover"], placement: [1, "placement"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "bug-icon"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v.parent, 0); var currVal_2 = _co.placement; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.float; _ck(_v, 0, 0, currVal_0); }); }
function View_BugComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "bug-wrapper"], ["tabindex", "-1"], ["type", "button"]], [[4, "float", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open(i1.ɵnov(_v.parent, 0)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "bug-icon"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.float; _ck(_v, 0, 0, currVal_0); }); }
export function View_BugComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["content", 2]], null, 0, null, View_BugComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BugComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BugComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.modal; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.modal; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_BugComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-bug", [], null, null, null, View_BugComponent_0, RenderType_BugComponent)), i1.ɵdid(1, 49152, null, 0, i4.BugComponent, [i2.NgbModal], null, null)], null, null); }
var BugComponentNgFactory = i1.ɵccf("rtrt-bug", i4.BugComponent, View_BugComponent_Host_0, { float: "float", placement: "placement", modal: "modal" }, {}, ["*"]);
export { BugComponentNgFactory as BugComponentNgFactory };
