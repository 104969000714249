import { Component, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {
  Shipment,
  OrderDateTime,
  OrderDateTimeType,
  Commodity,
  OrderClient,
  Carrier,
  Accessorial,
  Location,
  Reference
} from '../../models';

import { OrderService } from '../../services/order.service';

@Component({
  selector: 'rtrt-shipment-tracking',
  templateUrl: './shipment-tracking.component.html',
  styleUrls: ['./shipment-tracking.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShipmentTrackingComponent {
  @Output() onDocRequest: EventEmitter<any> = new EventEmitter<any>();
  @Input() shipment: Shipment = new Shipment();
  @Input() tracking: any = {};
  subscription: any;

  constructor(
    private orderService: OrderService,
    private router: Router
  ) { }

  get shipDate(): OrderDateTime {
    return this.shipment.DateTimes.find(dt => dt.TypeID === OrderDateTimeType.ShipDate);
  }

  get orderDate(): OrderDateTime {
    return this.shipment.DateTimes.find(dt => dt.TypeID === OrderDateTimeType.OrderDate);
  }

  get remainingTracking(): any[] {
    return (this.tracking && this.tracking.Statuses && this.tracking.Statuses.length > 1)
      ? this.tracking.Statuses.filter((i, idx) => idx > 0) : [];
  }

  getBOL() {
    this.onDocRequest.emit({ jls: this.shipment.JlsNo, carrierLine: '1', type: 'BillOfLadingRpt', idcShipper: (this.shipment.FinalMileShipment && this.shipment.Carriers[0].InsideDelivery), idcConsignee: (this.shipment.FinalMileShipment && !this.shipment.Carriers[0].InsideDelivery) });
  }

  getLabel() {
    this.onDocRequest.emit({ jls: this.shipment.JlsNo, type: 'labelSampleRpt', carrierLine: '1', idcShipper: (this.shipment.FinalMileShipment && this.shipment.Carriers[0].InsideDelivery), idcConsignee: (this.shipment.FinalMileShipment && !this.shipment.Carriers[0].InsideDelivery) });
  }

  getBoth() {
    this.onDocRequest.emit({ jls: this.shipment.JlsNo, type: 'BolandLabel', carrierLine: '1', idcShipper: (this.shipment.FinalMileShipment && this.shipment.Carriers[0].InsideDelivery), idcConsignee: (this.shipment.FinalMileShipment && !this.shipment.Carriers[0].InsideDelivery) });
  }

  goBack() {
    this.router.navigate(['information']);
  }

}
