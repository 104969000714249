import { Component, Input } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rtrt-bug',
  templateUrl: './bug.component.html',
  styleUrls: ['./bug.component.scss']
})
export class BugComponent {
  @Input() float = 'right';
  @Input() placement = 'top';
  @Input() modal = false;

  constructor(private modalService: NgbModal) {}

  open(content) {
    this.modalService.open(content).result.then(() => {}, () => {});
  }

  handleModalConfirm(r: any) {

  }
}
