import { EventEmitter, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from '../../services/order.service';
var dt = new Date();
var OrderHistoryComponent = /** @class */ (function () {
    function OrderHistoryComponent(orderService) {
        this.orderService = orderService;
        this.onSelectOrder = new EventEmitter();
        this.onDocRequest = new EventEmitter();
        this.orders = [];
        this.loggedIn = false;
        this.carriers = [];
        this.subscriptions = [];
        this.showDelivered = false;
        this.json = false;
        this.filterCarriers = [];
        this.dropdownSettings = {};
    }
    OrderHistoryComponent.prototype.ngOnInit = function () {
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'carrierId',
            textField: 'carrierName',
            selectAllText: 'All Carriers',
            unSelectAllText: 'None',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            searchPlaceholderText: "Carrier Name",
            showSelectedItemsAtTop: true
        };
    };
    OrderHistoryComponent.prototype.viewDetails = function (o) {
        this.onSelectOrder.emit(o);
    };
    OrderHistoryComponent.prototype.getBOL = function (o) {
        console.log(o);
        this.onDocRequest.emit({ jls: o.jlsNo, type: 'BillOfLadingRpt', carrierLine: o.carrierLine, idcShipper: (o.isFinalMile == "True" && o.insideDelivery == "True"), idcConsignee: (o.isFinalMile == "True" && o.insideDelivery != "True") });
    };
    OrderHistoryComponent.prototype.getLabel = function (o) {
        this.onDocRequest.emit({ jls: o.jlsNo, type: 'labelSampleRpt', carrierLine: o.carrierLine, idcShipper: (o.isFinalMile == "True" && o.insideDelivery == "True"), idcConsignee: (o.isFinalMile == "True" && o.insideDelivery != "True") });
    };
    OrderHistoryComponent.prototype.getBoth = function (o) {
        this.onDocRequest.emit({ jls: o.jlsNo, type: 'BolandLabel', carrierLine: o.carrierLine, idcShipper: (o.isFinalMile == "True" && o.insideDelivery == "True"), idcConsignee: (o.isFinalMile == "True" && o.insideDelivery != "True") });
    };
    OrderHistoryComponent.prototype.clearFilters = function () {
        this.search = null;
        this.searchDate = null;
        this.filterCarriers = [];
        this.showDelivered = false;
    };
    OrderHistoryComponent.prototype.toggleDelivered = function () {
        this.showDelivered = !this.showDelivered;
    };
    OrderHistoryComponent.prototype.toggleJSON = function () {
        this.json = !this.json;
    };
    Object.defineProperty(OrderHistoryComponent.prototype, "carrierFilteredOrders", {
        get: function () {
            var _this = this;
            if (this.loggedIn && this.filterCarriers.length == 0) {
                return this.orders;
            }
            else {
                return this.orders.filter(function (o) { return _this.filterCarriers.some(function (f) { return f.carrierId == o.carrierId; }); });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderHistoryComponent.prototype, "filteredOrders", {
        get: function () {
            var _this = this;
            return this.carrierFilteredOrders.filter(function (o) { return o.jlsNo == _this.search || o.proNo == _this.search || o.mrNo == _this.search; });
        },
        enumerable: true,
        configurable: true
    });
    return OrderHistoryComponent;
}());
export { OrderHistoryComponent };
