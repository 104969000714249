import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';

import { AddressBookLayoutComponent } from './address-book-layout.component';
import { AccountAddressComponent } from './account-address.component';
import { ConsigneeAddressComponent } from './consignee-address.component';
import { AuthGuard } from '../core/auth.guard';

const routes: Routes = [
    {
        path: 'addresses',
        component: AddressBookLayoutComponent,
        canActivate: [AuthGuard],
        data: {
            state: 'primary',
            headerImage: 'assets/images/banners/Account-lo.jpg'
        }
    },
    // {
    //     path: 'consignee',
    //     component: ConsigneeAddressComponent,
    //     canActivate: [AuthGuard],
    //     data: {
    //         state: 'consignee',
    //         headerImage: 'assets/images/banners/Account-lo.jpg'
    //     }
    // },
    // {
    //     path: '',
    //     redirectTo: 'primary',
    //     pathMatch: 'full'
    // }
    // {
    //   path: 'start/:id',
    //   component: QuoteComponent,
    //   data: {
    //     state: 'quote',
    //     headerImage: 'assets/images/banners/RiteQuote-lo.jpg'
    //   }
    // },
];

@NgModule({
    imports: [
      RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
  })
  export class AddressBookRoutingModule { }
