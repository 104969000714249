import { Component, OnInit, Input } from "@angular/core";
import { BlogPost } from "../../../models";
import { Router } from "@angular/router";

@Component({
  selector: "rtrt-blog-post",
  templateUrl: "./blog-post.component.html",
  styleUrls: ["./blog-post.component.scss"]
})
export class BlogPostComponent implements OnInit {
  @Input() post: BlogPost;

  constructor(private router: Router) {}

  ngOnInit() {}

  getPostDetails() {
    this.router.navigate(
      [
        "/blog/" +
          this.post.BlogTitle.replace(/\W+(?!$)/g, "-")
            .replace(/\W$/, "")
            .replace(/-+$/, "")
            .toLowerCase()
      ],
      {
        state: { post: this.post }
      }
    );
  }
  formatTag(tag: string) {
    return "/blog/" + tag.replace(/\W+(?!$)/g, "-")
      .replace(/\W$/, "")
      .replace(/-+$/, "")
      .toLowerCase();
  }
  formatTagList(tag: string) {
    return tag.replace(/\W+(?!$)/g, "-")
      .replace(/\W$/, "")
      .replace(/-+$/, "")
      .toLowerCase();
  }
}
