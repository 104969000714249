import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, Subject, Observable, merge } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';

import { QuoteService } from '../../services/quote.service';
import {
  Shipment,
  Reference
} from '../../models';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'rtrt-reference-selector',
  templateUrl: './reference-selector.component.html',
  styleUrls: ['./reference-selector.component.scss']
})
export class ReferenceSelectorComponent implements OnInit, OnDestroy {
  quote: Shipment;
  _references: any[] = [];
  selected = null;
  value = '';
  quoteReady = false;
  subscriptions: Subscription[] = [];

  @ViewChild('ref', { static: false })
  ref: any;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => this.references.filter(f => new RegExp(term, 'mi').test(f.Value.toString())))
    );
  }
  formatter = (x: any) => x.Value;
  
  constructor(
    private quoteService: QuoteService,
    private router: Router,
    private authService: AuthenticationService
  ) {
    this.subscriptions.push(
      this.quoteService.quote$
        .subscribe(q => {
          this.quote = q;
          this.quoteReady = this.quoteService.readyForPayment;
        })
    );
    this.subscriptions.push(
      this.quoteService.references$
        .subscribe(r => this._references = r)
    );
  }

  ngOnInit() {
  }

  get selectedRef() {
    if (!this.selected || !this.references || this.references.length === 0 || this.selected === null)
      return 'Please Select';
    const ref = this.references.find(r => r.ID === this.selected.ID);
    return (!ref) ? 'Please Select' : ref.Value;
  }

  get references() {
    return this._references.filter(r => !this.quote.References.map(qr => qr.TypeID).includes(r.ID));
  }

  get isAddReferenceDisabled() {
    return (this.selected === null || this.value === '');
  }

  selectRef(r: any) {
    this.selected = r;
  }
  validateRefType(input: any) {
    this.ref.control.markAsTouched();
    if (input !== '') {
      if (this.references.map(f => f.Value).indexOf(this.selected.Value) === -1) {
        this.ref.control.setErrors({ 'invalid': true });
      }
      else
        this.ref.control.setErrors(null);
    }
  }
  addReference() {
    if (!this.authService.isLoggedIn())
      return;
    const r = new Reference();
    r.TypeID = this.selected.ID;
    r.ReferenceType = this.selected.Value;
    r.ReferenceValue = this.value;
    this.quote.References.push(r);
    this.selected = null;
    this.value = '';
  }

  removeReference(refid = '') {
    if (!this.authService.isLoggedIn())
      return;
    const idx = this.quote.References.findIndex(r => r.TypeID === refid);
    if (idx && idx !== null && idx > -1) {
      this.quote.References.splice(idx, 1);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  handleReferenceCompletion() {
    if (!this.authService.isLoggedIn())
      return;
    if (this.selectedRef !== 'Please Select' && this.value !== '' && this.value !== null) {
      this.addReference();
    }
    this.quoteService.updateQuote(this.quote, true, null, null, false);
    let step = 'carrier';
    if (this.quote.Carriers
        && this.quote.Carriers.length > 0
        && this.quote.Carriers[0].CarrierName
        && this.quote.Carriers[0].CarrierName !== '')
      step = 'item-details';
    if (this.quote.Commodities
        && this.quote.Commodities.length > 0
        && this.quote.Commodities[0].CommodityDescription
        && this.quote.Commodities[0].CommodityDescription !== '')
      step = 'origin';
    if (this.quote.Shipper && this.quote.Shipper.Name && this.quote.Shipper.Name !== '')
      step = 'destination';
    if (this.quote.Consignee && this.quote.Consignee.Name && this.quote.Consignee.Name !== '')
      step = 'confirmation';
    this.router.navigate(['ltl-shipping-quote', step]);
  }

}
