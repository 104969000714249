import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../services/account.service';
import { ObjectFilterPipe } from '../pipes/object-filter.pipe';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../services/meta-service.service';
import { ADDRESS_META } from '../pages/meta';
var AddressBookLayoutComponent = /** @class */ (function () {
    function AddressBookLayoutComponent(route, accountService, objectFilter, meta, metaService, title) {
        var _this = this;
        this.route = route;
        this.accountService = accountService;
        this.objectFilter = objectFilter;
        this.meta = meta;
        this.metaService = metaService;
        this.title = title;
        this.headerBg = '';
        this.subscriptions = [];
        this.activeRoute = '';
        this.loading = true;
        this.view = 'shipper';
        this.search = '';
        this.destinations = [];
        this.shippers = [];
        this.addressFilterProps = ['AddressLineOne', 'City', 'Name', 'PostalCode', 'StateOrProvince', 'PhoneOne'];
        this.subscriptions.push(this.route.data.subscribe(function (data) {
            _this.headerBg = data.headerImage;
        }));
    }
    AddressBookLayoutComponent.prototype.onDelete = function (id) {
        this.accountService.deleteAddress(id);
    };
    AddressBookLayoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.metaService.createCanonicalURL();
        this.title.setTitle(ADDRESS_META.title);
        this.metaService.removeItemprop();
        ADDRESS_META.meta.forEach(function (m) { return _this.meta.updateTag(m); });
        this.accountService.refreshAddresses();
        this.subscriptions.push(this.accountService.address$
            .subscribe(function (a) {
            _this.destinations = a.filter(function (ad) { return ad.IsConsignee && ad.IsDefaultBillTo; }).concat(a.filter(function (ad) { return ad.IsConsignee && !ad.IsDefaultBillTo; }).sort(function (n1, n2) {
                if (n1.Name.toUpperCase() > n2.Name.toUpperCase()) {
                    return 1;
                }
                if (n1.Name.toUpperCase() < n2.Name.toUpperCase()) {
                    return -1;
                }
                return 0;
            }));
            _this.shippers = a.filter(function (ad) { return ad.IsShipper && ad.IsDefaultBillTo; }).concat(a.filter(function (ad) { return ad.IsShipper && !ad.IsDefaultBillTo; }).sort(function (n1, n2) {
                if (n1.Name.toUpperCase() > n2.Name.toUpperCase()) {
                    return 1;
                }
                if (n1.Name.toUpperCase() < n2.Name.toUpperCase()) {
                    return -1;
                }
                return 0;
            }));
            _this.loading = false;
        }));
    };
    Object.defineProperty(AddressBookLayoutComponent.prototype, "type", {
        get: function () {
            return (this.view === 'consignee' ? 'consignee' : 'shipper');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressBookLayoutComponent.prototype, "activeRouteLower", {
        get: function () {
            return this.activeRoute.toLowerCase();
        },
        enumerable: true,
        configurable: true
    });
    AddressBookLayoutComponent.prototype.switchView = function (s) {
        this.view = s;
    };
    AddressBookLayoutComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    AddressBookLayoutComponent.prototype.compare = function (a, b) {
        if (a > b)
            return 1;
        if (b > a)
            return -1;
        return 0;
    };
    return AddressBookLayoutComponent;
}());
export { AddressBookLayoutComponent };
