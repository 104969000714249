import { OnInit, OnDestroy, AfterViewInit, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { QuoteService } from "../../services/quote.service";
import { AuthenticationService } from "../../services/authentication.service";
import { ConfigService } from "../../services/config.service";
import { FlashService } from "../../shared/flash/flash.service";
import { AccountService } from "../../services/account.service";
import { Shipment, Location, OrderDateTimeType, LocationOpenTime, OpenHoursType } from "../../models";
import { RtValidators } from "../../validators/rt-validators";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ZipService } from '../../services/zip.service';
var DestinationFormComponent = /** @class */ (function () {
    function DestinationFormComponent(fb, quoteService, authService, config, router, flash, accountService, zone, zipService) {
        var _this = this;
        this.fb = fb;
        this.quoteService = quoteService;
        this.authService = authService;
        this.config = config;
        this.router = router;
        this.flash = flash;
        this.accountService = accountService;
        this.zone = zone;
        this.zipService = zipService;
        this.subscriptions = [];
        this._today = null;
        this.states = [];
        this._addresses = [];
        this.notesArr = [];
        this.onAddressChange = new Subject();
        this.cities = [];
        this.subscriptions.push(this.authService.loggedIn.subscribe(function (res) { return (_this.isAuthenticated = res); }));
        this.subscriptions.push(this.quoteService.quote$.subscribe(function (q) {
            _this.quote = q;
            if (_this.quote &&
                _this.quote.Consignee &&
                _this.quote.Consignee.OpenHours &&
                _this.quote.Consignee.OpenHours.length === 0) {
                var oh = new LocationOpenTime();
                oh.Type = OpenHoursType.Receiving;
                _this.quote.Consignee.OpenHours.push(oh);
                _this.originalAddress = Object.apply({}, _this.quote.Consignee);
            }
        }));
        var dt = new Date();
        this._today = {
            year: dt.getFullYear(),
            month: dt.getMonth() + 1,
            day: dt.getDate()
        };
        this.states = config.getConfig("states").filter(function (s) { return s.country == _this.quote.Consignee.CountryString; });
    }
    DestinationFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.accountService.refreshAddresses();
        this.subscriptions.push(this.accountService.address$.subscribe(function (a) {
            _this._addresses = a;
            _this.instantiateForm();
            _this.onFormChanges();
        }));
        this.zipService
            .checkZip(this.quote.Consignee.PostalCode)
            .pipe()
            .subscribe(function (res) {
            res = JSON.parse(res);
            _this.cities = res.cities;
            console.log(_this.cities);
        });
    };
    DestinationFormComponent.prototype.ngAfterViewInit = function () {
        if (this.quote &&
            this.quote.Consignee &&
            this.quote.Consignee.AddressLineOne &&
            !this.quote.Consignee.Validated) {
            this.onAddressChange.next();
        }
    };
    DestinationFormComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    DestinationFormComponent.prototype.handleAddressValidation = function (street) {
        if (street) {
            this.address1.setValue(street);
            this.quote.Consignee.Validated = true;
        }
        else {
            this.quote.Consignee.Validated = false;
        }
    };
    Object.defineProperty(DestinationFormComponent.prototype, "puDate", {
        get: function () {
            var puDate = this.quote.DateTimes.find(function (dt) { return dt.TypeID === OrderDateTimeType.ShipDate; });
            if (puDate)
                return new Date(puDate.ShortDateString);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DestinationFormComponent.prototype, "puNGBDate", {
        get: function () {
            var dt = new Date();
            if (this.puDate)
                return {
                    year: this.puDate.getFullYear(),
                    month: this.puDate.getMonth() + 1,
                    day: this.puDate.getDate() + 1
                };
            else
                return {
                    year: dt.getFullYear(),
                    month: dt.getMonth() + 1,
                    day: dt.getDate() + 1
                };
        },
        enumerable: true,
        configurable: true
    });
    DestinationFormComponent.prototype.isFilledLocation = function (l) {
        return !!(l && l.PostalCode && l.PostalCode !== "");
    };
    Object.defineProperty(DestinationFormComponent.prototype, "showAddressClear", {
        get: function () {
            return (this.isFilledLocation(this.originalAddress) &&
                this.originalAddress !== this.quote.Consignee);
        },
        enumerable: true,
        configurable: true
    });
    DestinationFormComponent.prototype.selectAddress = function (a) {
        var _this = this;
        this.zone.run(function () {
            _this.quote.Consignee = Object.assign({}, a);
            _this.instantiateForm();
            _this.onFormChanges();
            _this.onAddressChange.next();
        });
    };
    Object.defineProperty(DestinationFormComponent.prototype, "addresses", {
        get: function () {
            var _this = this;
            if (!Array.isArray(this._addresses))
                return null;
            return this._addresses
                .filter(function (a) {
                return ((_this.isFilledLocation(_this.quote.Consignee) &&
                    a.PostalCode === _this.quote.Consignee.PostalCode) ||
                    (_this.isFilledLocation(_this.originalAddress) &&
                        a.PostalCode === _this.originalAddress.PostalCode)) &&
                    a.IsDefaultBillTo &&
                    a.IsConsignee;
            })
                .concat(this._addresses
                .filter(function (a) {
                return ((_this.isFilledLocation(_this.quote.Consignee) &&
                    a.PostalCode === _this.quote.Consignee.PostalCode) ||
                    (_this.isFilledLocation(_this.originalAddress) &&
                        a.PostalCode === _this.originalAddress.PostalCode)) &&
                    !a.IsDefaultBillTo &&
                    a.IsConsignee;
            })
                .sort(function (n1, n2) {
                if (n1.Name.toUpperCase() > n2.Name.toUpperCase()) {
                    return 1;
                }
                if (n1.Name.toUpperCase() < n2.Name.toUpperCase()) {
                    return -1;
                }
                return 0;
            }));
        },
        enumerable: true,
        configurable: true
    });
    DestinationFormComponent.prototype.instantiateForm = function () {
        var _this = this;
        if (this.quote.JlsNotes.search(" ---- Delivery Notes:") > -1) {
            this.notesArr = this.quote.JlsNotes.split(" ---- Delivery Notes:");
            this.notesArr[0] = this.notesArr[0].replace("Pickup Notes: ", "");
        }
        else {
            this.notesArr[0] = this.quote.JlsNotes || "";
            this.notesArr[1] = "";
        }
        this.form = this.fb.group({
            name: [this.quote.Consignee.Name, [Validators.required]],
            address1: [this.quote.Consignee.AddressLineOne, [Validators.required]],
            address2: [this.quote.Consignee.AddressLineTwo],
            contact: [this.quote.Consignee.ContactName, [Validators.required]],
            phone: [
                this.quote.Consignee.PhoneOne,
                [Validators.required, RtValidators.phone]
            ],
            fax: [this.quote.Consignee.Fax || ""],
            email: [
                this.quote.Consignee.ContactEmail,
                [Validators.required, Validators.email]
            ],
            deliveryInstructions: [this.notesArr[1]],
            deliveryReferenceNo: [""]
        });
        this.form.get("fax").valueChanges.subscribe(function (fax) {
            if (fax !== "" && fax !== null) {
                _this.form.get("fax").setValidators([RtValidators.phone]);
            }
            else {
                _this.form.get("fax").clearValidators();
            }
            _this.form.get("fax").updateValueAndValidity({ emitEvent: false });
        });
    };
    DestinationFormComponent.prototype.onFormChanges = function () {
        var _this = this;
        this.subscriptions.push(this.name.valueChanges.subscribe(function (val) {
            _this.quote.Consignee.Name = val;
        }));
        this.subscriptions.push(this.address1.valueChanges.subscribe(function (val) {
            if (_this.quote.Consignee.AddressLineOne !== val) {
                _this.quote.Consignee.Validated = false;
                _this.quote.Consignee.AddressLineOne = val;
                _this.onAddressChange.next();
            }
        }));
        this.subscriptions.push(this.address2.valueChanges.subscribe(function (val) {
            _this.quote.Consignee.AddressLineTwo = val;
        }));
        this.subscriptions.push(this.phone.valueChanges.subscribe(function (val) {
            _this.quote.Consignee.PhoneOne = val;
        }));
        this.subscriptions.push(this.fax.valueChanges.subscribe(function (val) {
            _this.quote.Consignee.Fax = val;
        }));
        this.subscriptions.push(this.contact.valueChanges.subscribe(function (val) {
            _this.quote.Consignee.ContactName = val;
        }));
        this.subscriptions.push(this.email.valueChanges.subscribe(function (val) {
            _this.quote.Consignee.ContactEmail = val;
        }));
    };
    DestinationFormComponent.prototype.resetForm = function () {
        this.instantiateForm();
    };
    Object.defineProperty(DestinationFormComponent.prototype, "name", {
        get: function () {
            return this.form.get("name");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DestinationFormComponent.prototype, "address1", {
        get: function () {
            return this.form.get("address1");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DestinationFormComponent.prototype, "address2", {
        get: function () {
            return this.form.get("address2");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DestinationFormComponent.prototype, "contact", {
        get: function () {
            return this.form.get("contact");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DestinationFormComponent.prototype, "phone", {
        get: function () {
            return this.form.get("phone");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DestinationFormComponent.prototype, "fax", {
        get: function () {
            return this.form.get("fax");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DestinationFormComponent.prototype, "email", {
        get: function () {
            return this.form.get("email");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DestinationFormComponent.prototype, "deliveryInstructions", {
        get: function () {
            return this.form.get("deliveryInstructions");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DestinationFormComponent.prototype, "saveInstructions", {
        get: function () {
            return this.form.get("saveInstructions");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DestinationFormComponent.prototype, "deliveryReferenceNo", {
        get: function () {
            return this.form.get("deliveryReferenceNo");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DestinationFormComponent.prototype, "deliveryNotes", {
        get: function () {
            return this.notesArr[1];
        },
        set: function (val) {
            this.notesArr[1] = val;
        },
        enumerable: true,
        configurable: true
    });
    DestinationFormComponent.prototype._fillQuote = function () {
        var destForm = this.form;
        this.quote.Consignee.Name = destForm.get("name").value;
        this.quote.Consignee.AddressLineOne = destForm.get("address1").value;
        this.quote.Consignee.AddressLineTwo =
            destForm.get("address2").value === ""
                ? null
                : destForm.get("address2").value;
        this.quote.Consignee.ContactName = destForm.get("contact").value;
        this.quote.Consignee.PhoneOne = destForm.get("phone").value;
        this.quote.Consignee.Fax = destForm.get("fax").value;
        this.quote.Consignee.ContactEmail = destForm.get("email").value;
        this.quote.JlsNotes = this.notesArr[0];
        if (destForm.get("deliveryInstructions").value !== "")
            this.quote.JlsNotes +=
                " ---- Delivery Notes: " + destForm.get("deliveryInstructions").value;
    };
    DestinationFormComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!this.authService.isLoggedIn())
            return;
        if (!this.form.valid || !this.quote.Consignee.Validated) {
            Object.keys(this.form.controls)
                .map(function (x) { return _this.form.controls[x]; })
                .forEach(function (control) {
                control.markAsTouched();
            });
            this.flash.flashMessage("Please fill out all required fields.", "", "danger");
            return false;
        }
        this.flash.dismissMessage();
        this._fillQuote();
        this.quoteService.updateQuote(this.quote, true, false, true);
        this.router.navigate(["quote", "references"]);
    };
    DestinationFormComponent.prototype.selectCity = function (city) {
        this.quote.Consignee.City = city;
    };
    return DestinationFormComponent;
}());
export { DestinationFormComponent };
