<section class="page page-services">
  <header>
    <div class="container">
      <h1 class="text-light">LTL Freight Shipping</h1>
    </div>
  </header>
  <section class="container-fluid">
    <div class="row">
      <div class="container">
        <div class="row pt-5">
          <div class="col-sm-10 col-12">
                    <h2>LTL Freight Quotes</h2>
                    <p>RiteRouting provides less-than-truckload (LTL) freight quotes powered by cutting-edge technology and backed by
                      experienced LTL shipping professionals.</p>
                    <p>Our dedicated LTL shipping team helps your organization ship freight more efficiently and cost-effectively by
                      utilizing our large LTL carrier network. RiteRouting provides you with multiple LTL freight quotes to choose from,
                      ensuring that you get the best cost for your shipment, and the right service level for your requirements - every time.
                      No more time-consuming LTL rate shopping and comparing multiple LTL carriers and LTL freight quotes.</p>
                    
                    <h2 class="mt-5">What is LTL Shipping?</h2>
                    <p>Less-than-truckload (LTL) shipping allows multiple freight shippers to share space on a truck. LTL is the best option
                      for shippers that do not want to, or need to, use an entire truck and/or have smaller shipments that fit on a standard
                      pallet.</p>
                    <p>LTL carriers have published freight rates for every transportation route (or lane) they service and for every freight
                      class they haul in their transportation network. Freight classes are determined by the stowability, liability, ease of
                      handling, and density of a product. Every product that can be moved via LTL is assigned a freight class. Add in
                      dimensional weight, distance, and negotiated volume breaks and LTL shipping can be very complicated.</p>
                    <p>Navigating the complexities of LTL shipping is one of the many benefits of working with RiteRouting. We’ll complete
                      your shipment with exacting attention to detail and provide the carrier with correct information so you avoid shipping
                      delays and costly errors.</p>
                    <p>Logistics never stops and neither does RiteRouting.</p>
                    
                    <h2 class="mt-5">When to Use LTL Freight:</h2>
                    <ul>
                      <li>When your shipment is too big for parcel, but too small to fill a truck</li>
                      <li>When you are shipping 1-10 pallets</li>
                      <li>When your shipment is less than 10,000 pounds</li>
                    </ul>
                    
                    <h2 class="mt-5">Let’s get started!</h2>
                    <p><a (click)="routeTo('/ltl-shipping-quote')">Start your LTL quote</a> today for Shipping Done Rite!</p>
          </div>
          <div class="col-xl-2 col-md-3 col-4 d-sm-block">
            <img style="width: 200%; height: auto;" src="assets/images/updated_images/tia.jpg" />
          </div>
        </div>
      </div>
    </div>
  </section>
</section>