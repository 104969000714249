import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AccountService } from '../../services/account.service';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';

@Component({
  selector: 'rtrt-forgot-password-form',
  templateUrl: './forgot-password-form.component.html'
})
export class ForgotPasswordFormComponent implements OnInit {
  email: string;
  message: string;
  spinner = false;
  resetSuccess = false;

  @Input() title: string;

  constructor(private as: AccountService) { }

  ngOnInit() {
    this.email = this.as.getUserEmail();
  }

  onSubmit() {
    this.message = null;
    this.spinner = true;
    this.as.forgotPassword(this.email)
      .then(result => {
        this.spinner = false;
        this.message = null;
        this.resetSuccess = true;
      })
      .catch(err => {
        this.message = 'There was a problem resetting your password.';
      });
  }

}
