import {
  throwError,
  BehaviorSubject,
  Subject,
  Subscription,
  Observable
} from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ConfigService } from "./config.service";
import { QuoteService } from "./quote.service";
import { Carrier, Shipment, Estimate } from "../models";

@Injectable()
export class EstimateService implements OnInit {
  private _lastEstimateFetch: Number;
  private _subscriptions: Subscription[] = [];
  public eQuoteID$: Subject<string> = new Subject<string>();
  public estimates$: BehaviorSubject<Estimate[]> = new BehaviorSubject([]);
  api: string;

  constructor(private httpClient: HttpClient, private config: ConfigService) {
    this.api = this.config.getConfig("api");
    // if (this.config.getEnv() !== 'production') {
    //   const data = localStorage.getItem('estimate');
    //   if (data) {
    //     this._quote = JSON.parse(data);
    //   } else {
    //     this._quote = new Shipment();
    //   }
    //   this.quote$.next(this._quote);
    // }

    // if (!this._quote) {
    //   this._quote = new Shipment();
    // }
    // if (this._quote.items.length === 0) {
    //   this._quote.items.push(new FreightItem());
    //   this.quote$.next(this._quote);
    // }
  }

  ngOnInit() {
    // this._subscriptions.push(
    //   this.quoteService.quote$
    //     .subscribe(q => {
    //       this._quote = q;
    //       if (this._quote && this._quote.Carriers.length === 0)
    //         this.loadEstimates();
    //     })
    // );
  }

  loadEstimates(q: Shipment) {
    return (
      this.httpClient
        .post(`${this.api}api/quotes/fetch`, q)
        .pipe(catchError(err => throwError(err)))
        // .map((r: any) => { console.log('Estimate fetch results ... ', r); return r.data; })
        .toPromise()
        .then((results: any) => {
          console.log(results);
          if (
            typeof results === "undefined" ||
            results === null ||
            typeof results.data === "undefined" ||
            results.data === null
          )
            this.estimates$.next([]);
          else
            this.estimates$.next(
              results.data.map(e => Object.assign(new Estimate(), e))
            );
          if (
            !(
              typeof results === "undefined" ||
              results === null ||
              typeof results.eQuoteId === "undefined" ||
              results.eQuoteId === null
            )
          )
            this.eQuoteID$.next(results.eQuoteId);
        })
    );
    // const q = new Shipment();
    // Object.assign(q, this._quote);
    // const accessorials = this._quote.accessorials.map(a => a.display);
    // q.accessorials = accessorials;
    // return new Promise<void> (resolve => {
    //   this.httpClient.post(
    //     this.api + 'api/quotes/fetch',
    //     q
    //   )
    //     .catch(err => {
    //       resolve();
    //       return Observable.of(err);
    //     })
    //     .subscribe(estimates => {
    //       this._estimates = estimates.data;
    //       this.estimates$.next(this._estimates);
    //       resolve();
    //     });
    // });
  }

  // get quote() {
  //   return this._quote;
  // }

  // set quote(q: Shipment) {
  //   this._quote = q;
  //   this.quote$.next(this._quote);
  // }

  // setQuote(q: Shipment) {
  //   this._quote = q;
  //   this.quote$.next(this._quote);
  //   if (this.config.getEnv() !== 'production') {
  //     localStorage.setItem('estimate', JSON.stringify(this._quote));
  //   }
  // }

  // setToken(t: any) {
  //   console.log('Setting token ... ', t);
  //   this._token = t;
  // }

  // loadEstimates() {
  //   const q = new Shipment();
  //   Object.assign(q, this._quote);
  //   const accessorials = this._quote.accessorials.map(a => a.display);
  //   q.accessorials = accessorials;
  //   return new Promise<void> (resolve => {
  //     this.httpClient.post(
  //       this.api + 'api/quotes/fetch',
  //       q
  //     )
  //       .catch(err => {
  //         resolve();
  //         return Observable.of(err);
  //       })
  //       .subscribe(estimates => {
  //         this._estimates = estimates.data;
  //         this.estimates$.next(this._estimates);
  //         resolve();
  //       });
  //   });
  // }

  // updateQuote(q: Shipment) {
  //   Object.apply({q, ...this._quote});
  //   this.quote$.next(this._quote);
  //   if (this.config.getEnv() !== 'production') {
  //     localStorage.setItem('estimate', JSON.stringify(this._quote));
  //   }
  // }

  // makePayment() {
  //   return this.httpClient.post(this.api + 'api/quotes/charge', { token: this._token.id, charge: this._quote.estimate.cost });
  // }

  // createOrder() {
  //   this._quote.ordered = new Date();
  //   console.log('Submitting order ... ', this._quote);

  //   return this.httpClient.post(
  //     this.api + 'api/orders/create',
  //     this._quote
  //   )
  //     .catch(err => {
  //       console.log(err);
  //       return Observable.of(err);
  //     })
  //     .toPromise();
  // }
}
