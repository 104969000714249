import { throwError, BehaviorSubject, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import {
  Shipment
  // Carrier,
  // Commodity,
  // Accessorial,
  // OrderDateTime,
  // OrderDateTimeType
} from "../models";
import { ConfigService } from "./config.service";
import { PromoCode } from '../models/promo-code.model';
// import { User } from '../account/user.model';
// import { resolve } from "dns";

@Injectable()
export class QuoteService {
  private _temp: Shipment = null;
  public quote$: BehaviorSubject<Shipment>;
  api: string;
  private _handlingunits: any[] = [];
  public handlingUnits$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    this._handlingunits
  );
  private _references: any[] = [];
  public references$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    this._references
  );

  constructor(
    private httpClient: HttpClient, 
    private config: ConfigService
  ) {
    this.api = this.config.getConfig("api");
    const quoteStr = localStorage.getItem("quote");
    const quote = quoteStr === null ? new Shipment() : JSON.parse(quoteStr);
    //if (quote.Shipper.Name === "") quote.Shipper.Name = "Shipper";
    this.quote$ = new BehaviorSubject<Shipment>(quote);
    this._loadStoredValues();
  }

  setQuote(s: Shipment): void {
    if (this.config.getEnv() !== "production")
      localStorage.setItem("quote", JSON.stringify(s));
    this.quote$.next(s);
  }

  getQuote(): Shipment {
    return this.quote$.getValue();
  }

  clearQuote(): void {
    this.setQuote(new Shipment());
  }

  createQuote(s: Shipment = null) {
    // console.log("createQuote");
    // console.log(Error().stack);
    const shipment = s === null ? this.quote$.getValue() : s;
    if (shipment.QueueStatus !== 0) shipment.QueueStatus = 0;

    console.log("shipment to save", shipment);
    return this.httpClient
      .post(`${this.api}api/orders/save`, {order: shipment, saveShipper: false, saveConsignee: false})
      .pipe(
        map(res => {
          if (typeof res === "string") {
            this.updateQuote(JSON.parse(res));
          }
        })
      )
      .toPromise();
  }

  loadQuote(jls: string) {
    return this.httpClient
      .get(`${this.api}api/orders/load/${jls}`)
      .pipe(
        catchError(err => {
          console.log(err);
          return err;
        }),
        map((res: any) => {
          let q: Shipment;
          if (typeof res === "string") {
            this.clearQuote();
            q = JSON.parse(res);
          } else {
            q = res;
          }
          this.updateQuote(q);
        })
      )
      .toPromise();
  }

  savePayment() {
    return this.httpClient
      .post(
        `${this.api}api/payment/data/save`,
        {...this.quote$.getValue().PaymentData, ...{'isFinalMile': false }}
      )
      .pipe(catchError(err => throwError(err)))
      .toPromise();
  }

  orderQuote(promoCode: PromoCode = null) {
    // const q = this.quote$.getValue();
    // q.eQuoteNumber = q.JlsNo;
    // q.JlsNo = '';
    return this.httpClient
      .post(`${this.api}api/orders/create`, { order: this.quote$.getValue(), promoCode: promoCode } )
      .pipe(map((res: any) => JSON.parse(res).JlsNo))
      .toPromise();
  }

  updateQuote(s: Shipment, save = false, saveShipper: boolean = false, saveConsignee: boolean = false, generateIntent: boolean = false) {
    // console.log("updateQuote");
    // console.trace();
    this.setQuote({ ...this.quote$.getValue(), ...s });
    
    if (save || generateIntent) {
      return new Promise<string>((resolve, reject) => 
        this.saveQuote(s, saveShipper, saveConsignee, generateIntent)
          .then((res: string) => {
            var intent = JSON.parse(res).PaymentIntentSecret;
            this.quote$.getValue().PaymentIntentSecret = intent;
            resolve(intent);
          })
        );
    } else {
      return new Promise<string>((resolve, reject) => {
        resolve(null);
      });
    }
  }

  saveCommodity(Com){
    return this.httpClient
      .post(`${this.api}api/orders/saveCom`, {Com: Com})
      .pipe(map((res: any) => JSON.parse(res)))
      .toPromise();
  }

  saveQuote(s: Shipment = null, saveShipper: boolean = false, saveConsignee: boolean = false, generateIntent: boolean = false) {
    const shipment = s === null ? this.quote$.getValue() : s;
    return this.httpClient
      .post(`${this.api}api/orders/save`, { order: shipment, saveShipper: saveShipper, saveConsignee: saveConsignee, generateIntent: generateIntent })
      .toPromise();
  }

  checkPromoCode(code: string, clientId: string = null): Promise<any> {
    return this.httpClient
      .post(this.api + "api/orders/promo", {
        clientId: clientId,
        code: code
      })
      .toPromise();
  }

  checkAccountPromoCode(clientId: string): Promise<any> {
    return this.httpClient
      .post(this.api + "api/orders/promo", {
        clientId: clientId
      })
      .toPromise();
  }

  private _fetchHandlingUnits() {
    return this.httpClient
      .get(`${this.api}api/quotes/handlingtypes`)
      .pipe(map((res: any) => JSON.parse(res)))
      .toPromise();
  }

  private _fetchReferences() {
    return this.httpClient
      .get(`${this.api}api/quotes/references`)
      .pipe(map((res: any) => JSON.parse(res)))
      .toPromise();
  }

  private async _loadStoredValues() {
    // Handling Units 
    await this._fetchHandlingUnits().then(res => {
      this._handlingunits = res;
      localStorage.setItem(
        "handlingunits",
        JSON.stringify({ expiry: new Date(), data: this._handlingunits })
      );
    });

    this.handlingUnits$.next(this._handlingunits);

    // References
    await this._fetchReferences().then(res => {
      this._references = res;
      localStorage.setItem(
        "references",
        JSON.stringify({ expiry: new Date(), data: this._references })
      );
    });

    this.references$.next(this._references);
  }

  private _dateDiff(d1, d2) {
    return Math.ceil(
      Math.abs(d2.getTime() - d1.getTime()) / (1000 * 3600 * 24)
    );
  }

  private _loadFromStorage(key: string) {
    const raw: string = localStorage.getItem(key);
    if (!raw || raw === null || raw === "") return false;
    const json: any = JSON.parse(raw);
    if (
      !json.expiry ||
      !json.data ||
      this._dateDiff(new Date(), new Date(json.expiry)) > 1
    )
      return false;

    return json.data;
  }

  get readyForPayment(): boolean {
    let isReady = true;
    if (this.quote$ && this.quote$.getValue() !== null) {
      const q = this.quote$.getValue();
      if (q.Commodities.length === 0) isReady = false;
      if (q.Carriers.length === 0) isReady = false;
      if (
        !q.Consignee.Name ||
        q.Consignee.Name === null ||
        q.Consignee.Name === ""
      )
        isReady = false;
      if (!q.Shipper.Name || q.Shipper.Name === null || q.Shipper.Name === "")
        isReady = false;
    } else {
      isReady = false;
    }
    return isReady;
  }

  printQuote(jlsNo: string, carrierId: string, eSelectId: string) {
    return this.httpClient.get(
      `${this.api}api/quotes/print/${jlsNo}/${carrierId}/${eSelectId}`,
      { responseType: 'blob' }
    )
      .pipe(
        catchError((err) => {
          console.log('Error getting document ... ', err);
          return throwError(err);
        }),
        map(res => {
          const file = new Blob([res], { type: 'application/pdf' });
          return file;
        })
      )
      .toPromise<Blob>();
  }

  loadQuotes(limit = 3) {
    return this.httpClient
      .get(`${this.api}api/quotes/history/${limit}`)
      .pipe(map((res: any) => JSON.parse(res)))
      .toPromise();
  }

  loadAllQuotes() {
    return this.httpClient
      .get(`${this.api}api/quotes/history`)
      .pipe(map((res: any) => JSON.parse(res)))
      .toPromise();
  }

}
