export enum OrderDateTimeType {
    None = 0,
    DepartDateFromConsignee = 2,
    ETA = 3,
    OrderDate = 4,
    RequestedDeliveryDate = 5,
    ShipDate = 6,
    ReadyDate = 7,
    ReconsignedDate = 8,
    ArrivedDateFromConsignee = 9,
    ActualDeliveryDate = 10,
    AppointmentDate = 11,
    DeliveryArrival = 12,
    DepartedShipper = 13,
    AppointmentSetDate = 14,
    CustomClearance = 15,
    SailDate = 16,
    ArrivePortOfDemark = 17,
    ArrivePortOfEmbark = 18,
    EdiTendered = 19,
    PickupAppt = 20,
    DeliveryAppointment = 21,
    CloseDate = 22,
    PickupArrival = 23,
    ShipmentDelayed = 24,
    DeliveryAppointmentSecured = 25,
    EnrouteToDeliveryLocation = 26,
    ReqShipDate = 27,
    EtaToPickup = 28,
    DepartedConsignee = 29,
    FlexShipDate = 30,
    DelReadyTime = 31,
    DelCloseTime = 32
}
