import { FinalMileCarrier } from './final-mile-carrier.model';

export class FinalMileEstimate {
    FinalMileCarrier: FinalMileCarrier;
    PartnerCarrier: FinalMileCarrier;
    ServiceType: string;
    ServiceTypeID: number;
    CarrierLogo: Int8Array;
    CarrierLogoFilename: string;
    TerminalID: string;
    CarrierName: string;
    TransitTime: string;
    FinalMileOnly: boolean;
    get Logo(): string {
        // return "data:image/jpg;base64," + this.CarrierLogo.toString();
        return this.CarrierLogoFilename;
    }
    get TotalCost(): number {
        let lTotalCost = this.FinalMileCarrier.TotalAR;

        if(!this.FinalMileOnly)
            lTotalCost += this.PartnerCarrier.TotalAR;
        
            return lTotalCost;
    }

    constructor() { }
}