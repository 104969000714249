<ng-template #content><div class="bug-content"><ng-content></ng-content></div></ng-template>
<button *ngIf="!modal"
  type="button" 
  class="bug-wrapper"
  [style.float]="float"
  [ngbPopover]="content"
  [placement]="placement"
   tabindex="-1">
  <div class="bug-icon"></div>
</button>
<button *ngIf="modal"
  type="button" 
  class="bug-wrapper"
  [style.float]="float"
  (click)="open(content)"
   tabindex="-1">
  <div class="bug-icon"></div>
</button>