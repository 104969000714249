import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, FormControl, PatternValidator } from '@angular/forms';

const DEC_REGEX: RegExp = /^-?[0-9]+([,.][0-9]+)?$/i;

export function validateDecimal(c: AbstractControl) {
  return DEC_REGEX.test(c.value) ? null : {
    decimal: {
      valid: false
    }
  };
}

@Directive({
    selector: '[validateDecimal][formControlName],[validateDecimal][formControl],[validateDecimal][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: DecimalValidator, multi: true }
    ]
})
export class DecimalValidator implements Validator {
  constructor() {}

  validate(c: AbstractControl) {
    return validateDecimal(c);
  }
}
