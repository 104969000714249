import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { ConfigService } from '../services/config.service';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./config.service";
var LaunchDarklyService = /** @class */ (function () {
    function LaunchDarklyService(config) {
        this.config = config;
        this.initialize();
    }
    LaunchDarklyService.prototype.ngOnDestroy = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.close()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LaunchDarklyService.prototype.initialize = function () {
        var context = {
            kind: "user",
            key: "default"
        };
        this.client = LaunchDarkly.initialize(this.config.getConfig("launchDarklyClientId"), context);
    };
    LaunchDarklyService.prototype.getFlag = function (flagKey, defaultValue) {
        var _this = this;
        if (defaultValue === void 0) { defaultValue = false; }
        var fetchFlag = new Subject();
        this.client.on("change:" + flagKey, function () {
            fetchFlag.next();
        });
        this.client.waitUntilReady().then(function () {
            fetchFlag.next();
        });
        return fetchFlag.pipe(map(function () {
            return _this.client.variation(flagKey, defaultValue);
        }));
    };
    LaunchDarklyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LaunchDarklyService_Factory() { return new LaunchDarklyService(i0.ɵɵinject(i1.ConfigService)); }, token: LaunchDarklyService, providedIn: "root" });
    return LaunchDarklyService;
}());
export { LaunchDarklyService };
