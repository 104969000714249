<section class="container-fluid">
  <div class="row">
    <div class="col-12 pb-3 pt-4">
        <button type="submit" class="btn btn-primary float-right btn-mini" (click)="onSubmit()">Continue</button>
      <h3>Book Your Shipment<span>Destination Details</span></h3>
    </div>
  </div>
  <div class="row" *ngIf="addresses && addresses.length > 0">
    <div class="col-sm-4 col-md-10 pb-3 pt-3">
      <div ngbDropdown class="d-inline-block w-100">
        <button class="btn btn-outline-primary w-100" id="addressSelector" ngbDropdownToggle>Use Address from Address Book</button>
        <div ngbDropdownMenu aria-labelledby="addressSelector" id="addressDropdown">
          <button class="dropdown-item" *ngFor="let address of addresses" (click)="selectAddress(address)">
            <strong>{{ address.Name }}</strong>, {{ address.AddressLineOne }}, {{ address.City }}, {{ address.StateOrProvince }}, {{ address.PostalCode }}<strong *ngIf="address.IsDefaultBillTo"> (Bill To)</strong>
          </button>
        </div>
      </div>
    </div>
    <div class="col-sm-2 col-md-2 pt-3 pb-3">
      <button class="btn btn-link mt-3" *ngIf="showAddressClear" (click)="selectAddress(originalAddress)">Clear</button>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate *ngIf="form">
    <div class="row pt-3">
      <div class="col-12 form-group required">
        <label for="destinationName">Company Name</label>
        <rtrt-autocomplete controlName="name" [formGroup]="form" locationType="destination" [quotedLocation]="quote.Consignee"></rtrt-autocomplete>
        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="form-helpers">
          <small *ngIf="name.errors.required" class="text-danger">Company is required.</small>
        </div>
      </div>
      <div class="col-12 required">
        <label for="destinationAddress1">Address</label>
        <rtrt-autocomplete controlName="address1" [formGroup]="form" locationType="destination" placeholderText="Address 1" [quotedLocation]="quote.Consignee"></rtrt-autocomplete>
        <div *ngIf="address1.invalid && (address1.dirty || address1.touched)" class="form-helpers">
          <small *ngIf="address1.errors.required" class="text-danger">Address is required.</small>
        </div>
        <div *ngIf="!this.quote.Consignee.Validated" class="form-helpers">
          <small *ngIf="!this.quote.Consignee.Validated" class="text-danger">Invalid address.</small>
        </div>

        <rtrt-address-validator
          [show]="!quote.Consignee.Validated"
          [postal]="quote.Consignee.PostalCode"
          [street]="quote.Consignee.AddressLineOne"
          [addressChange]="onAddressChange"
          (select)="handleAddressValidation($event)"></rtrt-address-validator>
      </div>
      <div class="col-12 form-group">
        <input type="text" class="form-control" placeholder="Address 2" formControlName="address2" id="destinationAddress2" />
      </div>
      <div class="col-6" *ngIf="cities.length <= 1">
        <label>City</label>
        <p>{{quote.Consignee.City}}</p>
      </div>
      <div class="col-6" *ngIf="cities.length > 1">
        <label>City</label>
        <div ngbDropdown>
            <button type="button" class="btn btn-link pl-0" id="citySelector" style="color: black; font-size: 1.8em;"
            ngbDropdownToggle>{{quote.Consignee.City}}</button>
          <div ngbDropdownMenu aria-labelledby="citySelector">
            <button type="button" class="dropdown-item" *ngFor="let city of cities" (click)="selectCity(city)">
              {{ city }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-2">
        <label>State</label>
        <p>{{quote.Consignee.StateOrProvince}}</p>
      </div>
      <div class="col-2">
        <label>Zip</label>
        <p>{{quote.Consignee.PostalCode}}</p>
      </div>
      <div class="col-12 form-group required">
        <label for="destinationContact">Contact Name</label>
        <input type="text" class="form-control" formControlName="contact" id="destinationContact" />
        <div *ngIf="contact.invalid && (contact.dirty || contact.touched)" class="form-helpers">
          <small *ngIf="contact.errors.required" class="text-danger">Contact is required.</small>
        </div>
      </div>
      <div class="col-6 form-group required">
        <label for="destinationPhone">Phone</label>
        <input type="text" placeholder="1112223333" class="form-control" formControlName="phone" id="destinationPhone" />
        <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="form-helpers">
          <small *ngIf="phone.errors.required" class="text-danger">Phone number is required.</small>
          <small *ngIf="phone.errors.phone" class="text-danger">Please provide a valid phone number.</small>
        </div>
      </div>
      <div class="col-6 form-group">
        <label for="destinationFax">Fax</label>
        <input type="text" placeholder="1112223333" class="form-control" formControlName="fax" id="destinationFax" />
        <div *ngIf="fax.invalid && (fax.dirty || fax.touched)" class="form-helpers">
          <small *ngIf="fax.errors.phone" class="text-danger">Please provide a valid fax number.</small>
        </div>
      </div>
      <div class="col-12 form-group required">
        <label for="destinationEmail">Email</label>
        <input type="text" class="form-control" formControlName="email" id="destinationEmail" />
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="form-helpers">
          <small *ngIf="email.errors.required" class="text-danger">Email is required.</small>
          <small *ngIf="email.errors.email" class="text-danger">Please provide a valid email address.</small>
        </div>
      </div>
      <div class="col-12 form-group">
        <label for="deliveryInstructions">Special Instructions for RiteRouting (Delivery)</label>
        <input type="text" class="form-control non-primary-label" formControlName="deliveryInstructions" id="deliveryInstructions"  [(ngModel)]="deliveryNotes"/>
      </div>
    </div>
    <div class="row">
      <div class="col-6 pt-4">
        <button type="submit" class="btn btn-primary w-100" (click)="onSubmit()">Add References</button>
      </div>
    </div>
  </form>
</section>
