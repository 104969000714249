// FROM https://gist.github.com/fernandohu/122e88c3bcd210bbe41c608c36306db9
import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class ConfigService {
  private config: any = null;
  private env: any = null;
  private logging = true;

  constructor(private http: HttpClient) {}

  public getConfig(key: any) {
    if (this.config !== null) {
      return this.config[key];
    } else {
      return null;
    }
  }

  public setLogging(b: boolean) {
    this.logging = b;
  }

  public log(arg, ...args) {
    if (this.logging) {
      if (args.length > 1) {
        console.log(arg, args);
      } else {
        console.log(arg, args[0]);
      }
    }
  }

  public force(...args) {
    console.log(args);
  }

  public getEnv() {
    return this.env;
  }

  public load() {
    return new Promise((resolve, reject) => {
      this.http
        .get("config/rtrt.env.json")
        .pipe(
          catchError((error: any) => {
            console.log("Environment file could not be read.");
            reject(false);
            return throwError(error.json().error || "Server error");
          })
        )
        .subscribe((envResponse: any) => {
          this.env = envResponse.env;
          let request: any = null;
          switch (envResponse.env) {
            case "production":
            case "beta":
            case "test":
            case "dev":
            case "local":
              {
                request = this.http.get(
                  "config/rtrt.config." + envResponse.env + ".json"
                );
              }
              break;

            case "default":
              {
                console.error("Environment file not set or invalid.");
              }
              break;
          }
          if (request) {
            request
              .pipe(
                catchError((error: any) => {
                  console.log(
                    "Error reading " + envResponse.env + " configuration file."
                  );
                  reject(false);
                  return throwError(error.json().error || "Server error");
                })
              )
              .subscribe(responseData => {
                this.config = responseData;
                resolve(true);
              });
          } else {
            console.error("Env config file is not valid.");
            reject(false);
          }
        });
    });
  }
}
