<div class="row">
<div class="col-12">
  <h2>Contact RiteRouting</h2>
  <p>We are here to help you ship your freight rite. It’s in our name and it’s what we do. Feel free to call or email us
    during business hours to learn more or have your questions answered. You can also use the contact form below.</p>
  <h3>Address:</h3>
  <p>1347 N. Main Street, Orrville, OH 44667</p>
  <h3>Phone:</h3>
  <p>833-725-RITE (7483)</p>
  <h3>Email:</h3>
  <p>info@riterouting.com</p>
  <h3>Hours:</h3>
</div>
<div class="col-2">
  <p>Monday</p>
  <p>Tuesday</p>
  <p>Wednesday</p>
  <p>Thursday</p>
  <p>Friday</p>
</div>
<div class="col-10">
  <p>7a – 5p (ET)</p>
  <p>7a – 5p (ET)</p>
  <p>7a – 5p (ET)</p>
  <p>7a – 5p (ET)</p>
  <p>7a – 5p (ET)</p>
</div>
<div class="col-12 b-top-secondary mt-3 pt-3 pl-0 pr-0">
  <form *ngIf="form" [formGroup]="form" class="container-fluid" novalidate (submit)="handleSubmit($event)">
    <div class="row">
      <div class="col-12 col-sm-6 form-group required">
        <label for="name">Full Name</label>
        <input type="text" class="form-control alert" formControlName="name" id="name" required />
        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="form-helpers">
          <small *ngIf="name.errors.required" class="text-danger">Name is required.</small>
        </div>
      </div>
      <div class="col-12 col-sm-6 form-group required">
        <label for="company">Company</label>
        <input type="text" class="form-control" formControlName="company" id="company" required />
        <div *ngIf="company.invalid && (company.dirty || company.touched)" class="form-helpers">
          <small *ngIf="company.errors.required" class="text-danger">Company is required.</small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 form-group required">
        <label for="email">Email</label>
        <input type="email" class="form-control" formControlName="email" id="email" required />
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="form-helpers">
          <small *ngIf="email.errors.required" class="text-danger">Email is required.</small>
          <small *ngIf="!email.errors.required && email.errors.email" class="text-danger">Please provide a valid email
            address.</small>
        </div>
      </div>
      <div class="col-12 col-sm-6 form-group required">
        <label for="phone">Phone</label>
        <input type="text" class="form-control" formControlName="phone" id="phone" required />
        <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="form-helpers">
          <small *ngIf="phone.errors.required" class="text-danger">Phone is required.</small>
          <small *ngIf="!phone.errors.required && phone.errors.validatePhone" class="text-danger">Please provide a valid
            phone number.</small>
        </div>
      </div>
    </div>
    <div class="row b-top-secondary pt-3">
      <div class="col-12 form-group">
        <label for="message">Message</label>
        <textarea class="form-control" formControlName="message" id="message" rows="5"></textarea>
      </div>
    </div>
    <div class="row b-top-secondary pt-3">
      <div class="col-sm-12 col-md-4 form-group">
        <button class="btn btn-primary w-100" type="submit" [disabled]="loading">Contact Us</button>
      </div>
      <div class="col-sm-6 col-md-2 pt-3">
        <rtrt-spinner [visible]="loading"></rtrt-spinner>
      </div>
    </div>
  </form>
</div>
<div class="footer-content col-12">

  <div class="icon-wrapper">
    <span class="icon">
      <a href="https://www.facebook.com/RiteRouting/" target="_blank" rel="noopener">
        <img width="36px" height="36px" alt="Facebook Logo" class="img-fluid rounded-circle"
          src="../../../assets/images/icon-facebook.svg" /></a>
    </span>
    <span class="icon">
      <a href="https://www.linkedin.com/company/rite-routing" target="_blank" rel="noopener">
        <img width="36px" height="36px" alt="LinkedIn Logo" class="img-fluid rounded-circle"
          src="../../../assets/images/icon-linkedin.svg" /></a>
    </span>
    <span class="icon">
      <a href="https://twitter.com/RiteRouting" target="_blank" rel="noopener">
        <img width="36px" height="36px" alt="Twitter Logo" class="img-fluid rounded-circle"
          src="../../../assets/images/icon-twitter.svg" /></a>
    </span>
  </div>
</div>
</div>









