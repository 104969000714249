import { CarrierReview } from './carrier-review.model';

export class Estimate {
    CarrierName: string;
    TotalCost: number;
    TransitDays: number;
    CreatedDate: Date;
    Mode: string;
    QuoteNumber: string;
    LiabilityCPP: number;
    VolumeLiabilityCPP: number;
    MaxLiability: number;
    ShipmentLiability: number;
    QuoteType: string;
    CarrierLogo: Int8Array;
    CarrierLogoFilename: string;
    CarrierReviews: CarrierReview[];

    get Liability(): string {
         if(this.QuoteType !== null && this.QuoteType !== undefined) {
             if (this.QuoteType === 'eQuote') {
                 return formatCurrency((Math.round(this.LiabilityCPP * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2));
             }
             else if (this.QuoteType === 'P44Volume') {
                 return formatCurrency((Math.round(this.VolumeLiabilityCPP * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2));
             }
             else {
                 return '0.00';
             }
         }
         else {
             return '0.00';
         }
    }
    get Max(): string {
        return formatCurrency((Math.round(this.MaxLiability * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2));
    }
    get TotalLiability(): string {
        return formatCurrency((Math.round(this.ShipmentLiability * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2));
    }
    get Cost(): string {
        return formatCurrency((Math.round(this.TotalCost * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2));
    }
    get Logo(): string {
        // return "data:image/jpg;base64," + this.CarrierLogo.toString();
        return this.CarrierLogoFilename;
    }
    get ReviewCount(): number {
        return this.CarrierReviews.length;
    }
    get AverageRating(): number {
        return this.ReviewCount > 0 ? this.CarrierReviews.map(r => parseFloat(r.rating.toString())).reduce(sum) / this.CarrierReviews.length : 0;
    }

    constructor() {
        this.LiabilityCPP = 0;
        this.MaxLiability = 0;
        this.VolumeLiabilityCPP = 0;
        this.ShipmentLiability = 0;
        this.CarrierReviews = [];
    }
}

function formatCurrency(x: string) {
    return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function sum (accumulator, val) {
    if (!isNaN(val))
        return accumulator + val;
    else
        return accumulator;
}
