import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';

import { HOME_META } from '../meta';
import { BannerComponent } from './banner/banner.component';

@Component({
  selector: 'rtrt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,
    private title: Title,
    private meta: Meta,
    private metaService: MetaService
  ) { }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.title.setTitle(HOME_META.title);
    this.metaService.removeItemprop();
    HOME_META.meta.forEach(m => this.meta.updateTag(m));
  }

  routeTo(path: string) {
    this.router.navigate([path]);
  }

  ngAfterViewInit() {
    // Lazy load the hi-res image 2 seconds after page loaded
    setTimeout(() => {
      document.querySelector('.hero1').classList.add("lazy");
    }, 2000);
  }
}
