import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';


import {
  Accessorial,
  Shipment,
  Commodity,
  Reference,
  OrderDateTimeType
} from '../../models';
import { QuoteService } from '../../services/quote.service';

import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'rtrt-quote-details',
    templateUrl: './quote-details.component.html'
  })
  export class QuoteDetailsComponent implements OnDestroy {
    quote: Shipment;
    private _subscriptions: Subscription[] = [];

    constructor(
      private quoteService: QuoteService,
      private router: Router,
      private modalService: NgbModal
    ) {
      this._subscriptions.push(this.quoteService.quote$
        .subscribe(q => { this.quote = q; console.log(this.quote); }));

      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.router.onSameUrlNavigation = 'reload';
    }

    private sum = (accumulator, val) => {
      if (!isNaN(val))
        return accumulator + val;
      else
        return accumulator;
    }

    get grossWeight() {
      return this.quote.Commodities.map(w => parseFloat(w.GrossWeight.toString())).reduce(this.sum);
    }

    get totalItems() {
      return this.quote.Commodities.length;
    }

    get totalUnits() {
      return this.quote.Commodities.map(w => w.HandlingUnits).reduce(this.sum);
    }

    get pickupDate() {
      const pdate = this.quote.DateTimes.find(dt => dt.TypeID === OrderDateTimeType.PickupAppt);
      if (pdate)
        return pdate.ShortDateString;
    }

    edit(dest: string) {
      if (dest == '')
        this.router.navigate(['ltl-shipping-quote']);
      else
        this.router.navigate(['ltl-shipping-quote', dest]);
    }

    confirm(confirmContent) {
      this.modalService.open(confirmContent).result.then((result) => {
        this.quoteService.clearQuote();
        this.router.navigate(['ltl-shipping-quote']);
      });
    }

    ngOnDestroy() {
      this._subscriptions.forEach(s => s.unsubscribe());
    }
  }
