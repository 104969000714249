import { Routes } from '@angular/router';
import { AuthGuard } from '../core/auth.guard';
import { AccountProfileComponent } from './account-profile/account-profile.component';
import { QuotesComponent } from './quotes/quotes.component';
var ɵ0 = {
    state: 'account',
    headerImage: 'assets/images/banners/Account-lo.jpg'
}, ɵ1 = {
    state: 'account',
    headerImage: 'assets/images/banners/Account-lo.jpg'
};
var routes = [
    {
        path: 'account',
        redirectTo: 'account/profile',
        pathMatch: 'full'
    },
    {
        path: 'account/quotes',
        component: QuotesComponent,
        canActivate: [AuthGuard],
        data: ɵ0
    },
    {
        path: 'account/profile',
        component: AccountProfileComponent,
        canActivate: [AuthGuard],
        data: ɵ1
    }
];
var AccountModule = /** @class */ (function () {
    function AccountModule() {
    }
    return AccountModule;
}());
export { AccountModule };
export { ɵ0, ɵ1 };
