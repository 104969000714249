import { transition } from "@angular/animations";
import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";
import { MetaService } from '../services/meta-service.service';
import { Meta, Title } from "@angular/platform-browser";

// import { Quote } from './quote.model';
// import { Payment } from './payment.model';
// import { Shipment } from './shipment.model';
import { Shipment } from "../models";
// import { EstimateService } from '../services/estimate.service';
// import { StatusService } from '../services/status.service';
// import { QuoteService } from '../services/quote.service';
import { AuthenticationService } from "../services/authentication.service";
// import { AccessorialService } from '../services/accessorials.service';

// import { ShipmentSummaryComponent } from '../shared/shipment-summary/shipment-summary.component';

const stepLinks = [
  {
    id: "request",
    display: "Quote Your Shipment"
  },
  {
    id: "connect",
    display: "Book Your Shipment"
  },
  {
    id: "purchase",
    display: "Look Up Your Shipment"
  }
];

@Component({
  selector: "rtrt-quote",
  templateUrl: "./quote.component.html",
  styleUrls: ["./quote.component.scss"]
})
export class QuoteComponent implements OnInit {
  isAuthenticated = false;
  // status: any;
  routeSub: Subscription;
  headerBg: string;
  // activeLink: any;
  processStep = 1;
  // stepLinks = stepLinks;
  // completed: any;
  currentForm = "start";
  a = new Shipment();
  jls: string = '';
  stepText = "";
  showStepText = false;
  metaStepTitle = "";
  // // quote: Quote;
  // cc: Payment;
  // tracking: string;
  // isAuthenticated = false;
  // subscriptions: Subscription[] = [];
  // shipment: Shipment;
  // private _quote: Shipment;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private metaService: MetaService
  ) {
    this.isAuthenticated = this.authService.isAuthenticated.valueOf();
    route.data.subscribe(data => {
      this.headerBg = data.headerImage;
    });
  }

  ngOnInit() {
    this.routeSub = this.route.paramMap.subscribe((params: ParamMap) => {
      this.jls = '';

      let param = params.get("id");
      console.log("id:", param);

      try {
        const jls = parseInt(param, 10);
        if (!isNaN(jls)) {
          this.jls = param;
          param = "start";
        }
      } finally {
      }
      console.log("jls:", this.jls);

      this.currentForm = param ? param : "start";
      console.log("currentForm:", this.currentForm);

      this.setProcessStep();
      this.setMeta();
    });
  }

  private setProcessStep() {
    switch (this.currentForm) {
      case "":
      case "start":
      case "initiate":
        this.processStep = 1;
        this.metaStepTitle = "Get an LTL Shipping Quote | ";
        break;
      case "carrier":
        this.processStep = 1;
        this.metaStepTitle = "Quote Your Shipment | ";
        break;
      case "item-details":
      case "origin":
      case "destination":
      case "references":
        this.processStep = 2;
        this.metaStepTitle = "Book Your Shipment | ";
        break;
      case "payment":
      case "confirmation":
        this.processStep = 3;
        this.metaStepTitle = "Purchase Your Shipment | ";
        break;
      default:
        this.processStep = 1;
        this.metaStepTitle = "Get an LTL Shipping Quote | ";
        break;
    }
  }

  setMeta() {
    let title = this.metaStepTitle;
    switch (this.currentForm) {
      case "carrier":
        title += "Carrier Details";
        break;
      case "item-details":
        title += "Item Details";
        break;
      case "origin":
        title += "Origin Details";
        break;
      case "destination":
        title += "Destination Details";
        break;
      case "references":
        title += "References";
        break;
      case "payment":
        title += "Payment Details";
        break;
      case "confirmation":
        title += "Confirm Shipment Information";
        break;
      default:
        title += "RiteRouting - Shipping Done Rite";
        break;
    }
    this.metaService.createCanonicalURL();
    this.title.setTitle(title);
    this.metaService.removeItemprop();
    let desc = `RiteRouting provides LTL freight shipping that saves you time, stress, effort and money. Get your free LTL quote now.`;
    this.meta.updateTag({
      name: "description",
      content: desc
    });
    this.meta.updateTag({
      property: "og:title",
      content: title
    });
    this.meta.updateTag({
      property: "og:description",
      content: desc
    });
    this.meta.updateTag({
      itemprop: "name",
      content: title
    });
    this.meta.updateTag({
      itemprop: "description",
      content: desc
    });
  }

  toggleStepText(t: string) {
    this.stepText = t;
    this.showStepText = t === "" ? false : true;
  }

  handleEditRequest(e: string) {
    this.router.navigate(["quote", e]);
  }
}
