<form [formGroup]="userForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="container">
    <div class="row">
      <div class="col-12 required">
        <label>Email Address</label>
        <input type="email" class="form-control" [ngClass]="{'invalid': email.invalid && (email.dirty || email.touched)}" formControlName="email" id="email" required>
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="form-helpers">
          <small *ngIf="email.errors.required" class="text-danger">Email is required.</small>
          <small *ngIf="email.errors.email" class="text-danger">Please enter a valid email address.</small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-7 form-group required">
        <label>Username</label>
        <input type="text" class="form-control" [ngClass]="{'invalid': username.invalid && (username.dirty || username.touched)}" formControlName="username" id="username" required>
        <div *ngIf="username.invalid && (username.dirty || username.touched)" class="form-helpers">
          <small *ngIf="username.errors.required" class="text-danger">Username is required.</small>
        </div>
      </div>
      <div class="col-lg-5 form-group required">
        <label>Phone</label>
        <input type="text" class="form-control" [ngClass]="{'invalid': phone.invalid && (phone.dirty || phone.touched)}" formControlName="phone" id="phone" required>
        <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="form-helpers">
          <small *ngIf="phone.errors.required" class="text-danger">Phone is required.</small>
          <small *ngIf="phone.errors.phone" class="text-danger">Please enter a valid phone number.</small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3">
        <label>Title</label>
          <input type="text" class="form-control" formControlName="title" id="title">
      </div>
      <div class="col-lg-4 required">
        <label>First</label>
          <input type="text" (change)="onNameChange" class="form-control" [ngClass]="{'invalid': firstname.invalid && (firstname.dirty || firstname.touched)}" formControlName="firstname" id="firstname" required>          
          <div *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)" class="form-helpers">
            <small *ngIf="firstname.errors.required" class="text-danger">Firstname is required.</small>
          </div>
      </div>
      <div class="col-lg-5 required">
        <label>Last Name</label>
          <input type="text" (change)="onNameChange" class="form-control" [ngClass]="{'invalid': lastname.invalid && (lastname.dirty || lastname.touched)}" formControlName="lastname" id="lastname" required>          
          <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)" class="form-helpers">
            <small *ngIf="lastname.errors.required" class="text-danger">Lastname is required.</small>
          </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 required">
        <label type="text">New Password</label>
          <input type="password" class="form-control" [ngClass]="{'invalid': password.invalid && (password.dirty || password.touched)}" formControlName="password" id="password" required>
          <div *ngIf="password.invalid && (password.dirty || password.touched)" class="form-helpers">
            <small *ngIf="password.errors.required" class="text-danger">Password is required.</small>
          </div>
      </div>
      <div class="col-lg-6 required">
        <label>Confirm New Password</label>
          <input type="password" class="form-control" [ngClass]="{'invalid': passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched)}" formControlName="passwordConfirm" id="passwordConfirm" required validateEqual="password">
          <div *ngIf="passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched)" class="form-helpers">
            <small *ngIf="passwordConfirm.errors.required" class="text-danger">Password confirmation is required.</small>
            <small *ngIf="passwordConfirm.errors.MatchPassword " class="text-danger">Passwords must match.</small>
          </div>        
      </div>
    </div>
  </div>
</form>