import { Component, Input, OnInit } from '@angular/core';
import { Location } from '../models';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rtrt-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss']
})
export class AddressModalComponent implements OnInit {
  @Input() address: Location;
  @Input() type = 'billing';
  fn: string;
  closeResult: string;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    if (!this.address) {
      this.fn = 'Add';
      this.address = new Location();
      this.address.IsDefaultBillTo = false;
      this.address.IsConsignee = (this.type === 'consignee') ? true : false;
      this.address.IsShipper = (this.type === 'shipper') ? true : false;
    } else {
      this.fn = 'Edit';
      if (!this.address.IsConsignee && !this.address.IsShipper) {
        this.address.IsConsignee = (this.type === 'consignee') ? true : false;
        this.address.IsShipper = (this.type === 'shipper') ? true : false;
      }
    }
  }

  open(content) {
    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
