import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';
import { QUOTEHISTORY_META } from "../meta";

@Component({
  selector: 'rtrt-quote-history',
  templateUrl: './quote-history.component.html',
  styleUrls: ['./quote-history.component.scss']
})
export class QuoteHistoryComponent implements OnInit {

  constructor(
    private title: Title,
    private meta: Meta,
    private metaService: MetaService) {

  }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.title.setTitle(QUOTEHISTORY_META.title);
    this.metaService.removeItemprop();
    QUOTEHISTORY_META.meta.forEach(m => this.meta.updateTag(m));
  }

}
