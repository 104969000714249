import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule, CanActivate } from '@angular/router';

import { routes } from './shipment-routes';
import { SharedModule } from '../shared/shared.module';
import { InitiateComponent } from './initiate/initiate.component';
import { EstimateComponent } from './estimate/estimate.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule
    , RouterModule.forChild(routes)
    , FormsModule
    , SharedModule
    , NgbModule
  ],
  declarations: [InitiateComponent, EstimateComponent],
  exports: []
})
export class ShipmentModule { }
