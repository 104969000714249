export class BlogPost {
    ID: number;
    AuthorName: string;
    BlogSummary: string;
    BlogContent: string;
    BlogDate: string;
    BlogImage: Int8Array;
    BlogTitle: string;
    BlogTags: string[];
    MetaDescription: string;

    get Image(): string {
        return this.BlogImage.toString();
    }

    constructor() {
        this.ID = 0;
        this.AuthorName = "";
        this.BlogSummary = "";
        this.BlogContent = "";
        this.BlogTitle = "";
        this.AuthorName = "";
        this.BlogDate = "";
        this.BlogTags = [];
        this.MetaDescription = "";
    }
}
