var BlogTag = /** @class */ (function () {
    function BlogTag() {
        this.PostCount = 0;
        this.TagName = "";
    }
    Object.defineProperty(BlogTag.prototype, "FomattedTagName", {
        get: function () {
            return this.TagName.replace(/\W+(?!$)/g, "-")
                .replace(/\W$/, "")
                .toLowerCase();
        },
        enumerable: true,
        configurable: true
    });
    return BlogTag;
}());
export { BlogTag };
