import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';

import { AccountService } from '../../services/account.service';
import { QuoteService } from '../../services/quote.service';
import { ACCOUNT_META } from '../../pages/meta';
import { ForgotPasswordModalComponent } from '../forgot-password-modal/forgot-password-modal.component';
import { User } from '../user.model';
import { Location, Shipment, OrderDateTime, OrderDateTimeType } from '../../models';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rtrt-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss']
})
export class AccountProfileComponent implements OnInit, OnDestroy {

  headerBg: string;
  subscriptions: Subscription[] = [];
  user: User;
  _addresses: Location[];
  history: any[];

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private quoteService: QuoteService,
    private meta: Meta,
    private metaService: MetaService,
    private title: Title,
    private modalService: NgbModal
  ) {
    route.data.subscribe((data) => {
      this.headerBg = data.headerImage;
    });

    this.subscriptions.push(
      this.accountService.user$
        .subscribe(u => this.user = u)
    );

    this.subscriptions.push(
      this.accountService.address$
        .subscribe(a => this._addresses = a)
    );

    this.quoteService.loadQuotes()
      .then((res: any[]) => this.history = res);
  }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.title.setTitle(ACCOUNT_META.title);
    this.metaService.removeItemprop();
    ACCOUNT_META.meta.forEach(m => this.meta.updateTag(m));
  }

  getOriginAddress(s: any) {
    return (s.Shipper && s.Shipper.City && s.Shipper.StateOrProvince)
      ? s.Shipper.City + ', ' + s.Shipper.StateOrProvince + ' ' + s.Shipper.PostalCode
      : s.Shipper.PostalCode;
  }

  getDestinationAddress(s: any) {
    return (s.Consignee && s.Consignee.City && s.Consignee.StateOrProvince)
      ? s.Consignee.City + ', ' + s.Consignee.StateOrProvince + ' ' + s.Consignee.PostalCode
      : s.Consignee.PostalCode;
  }

  getOrderDate(s: any) {
    return (s.DateTimes && s.DateTimes.find(d => d.TypeID === OrderDateTimeType.OrderDate))
      ? OrderDateTime.toShortDateString(s.DateTimes.find(d => d.TypeID === OrderDateTimeType.OrderDate).DateUnix)
      : '';
  }

  get location() {
    return new Location();
  }

  get addresses() {
    if (!Array.isArray(this._addresses))
      return null;
    return this._addresses.filter(a => a.IsDefaultBillTo);
  }

  open(target) {
    if (typeof target === 'string' && target === 'password') {
      const modalRef = this.modalService.open(ForgotPasswordModalComponent);
      // modalRef
      //   .result.then((result) => {
      //     this.handleModalConfirm(result);
      //   }, (reason) => {
      //     console.log(reason);
      //   });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
