var FinalMileEstimate = /** @class */ (function () {
    function FinalMileEstimate() {
    }
    Object.defineProperty(FinalMileEstimate.prototype, "Logo", {
        get: function () {
            // return "data:image/jpg;base64," + this.CarrierLogo.toString();
            return this.CarrierLogoFilename;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FinalMileEstimate.prototype, "TotalCost", {
        get: function () {
            var lTotalCost = this.FinalMileCarrier.TotalAR;
            if (!this.FinalMileOnly)
                lTotalCost += this.PartnerCarrier.TotalAR;
            return lTotalCost;
        },
        enumerable: true,
        configurable: true
    });
    return FinalMileEstimate;
}());
export { FinalMileEstimate };
