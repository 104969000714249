export class Accessorial {
    private _Cost: number;
    Text: string;
    Type: string;
    get Cost(): number {
        return Math.round(this._Cost * Math.pow(10, 2)) / Math.pow(10, 2);
    }

    set Cost(c: number) {
        this._Cost = c;
    }

    constructor(cost?: number, text?: string, type?: string) {
        this._Cost = cost,
        this.Text = text,
        this.Type = type
    }
}
