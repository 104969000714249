import { Component, OnInit } from '@angular/core';
import { BlogPost } from '../../models/blog-post.model';
import { Subscription } from 'rxjs';
import { BlogService } from '../../services/blog.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BlogTag } from '../../models/blog-tag.model';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';
import { BLOG_META } from '../meta';

@Component({
  selector: 'rtrt-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogPosts: BlogPost[] = [];
  blogTags: BlogTag[] = [];
  subscriptions: Subscription[] = [];
  headerBg: string;
  showAllTags: boolean = false;
  routeSub: Subscription;
  urlpage: number = 0;

  constructor(
    private blogService: BlogService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private metaService: MetaService
  ) {
    route.data.subscribe((data) => {
      this.headerBg = data.headerImage;
    });
    this.routeSub = this.route.paramMap.subscribe((params: ParamMap) => {
      let param1 = params.get("tagname");
      let param2 = params.get("pagenumber");
      this.urlpage = parseInt(param2);
      console.log(param1);
      console.log(param2);
      try {
        if (typeof param1 !== "undefined" &&
            param1 !== null &&
            typeof param2 !== "undefined" &&
            param2 !== null) {
          this.blogService.FilterAndPageChange(param1, param2);
        }
      } finally {
      }
      try {
        if (typeof param1 !== "undefined" &&
            param1 !== null) {
          this.blogTagFilter = param1;
        } else {
          this.blogTagFilter = "";
        }
      } finally {
      }
      try {
        if (typeof param2 !== "undefined" &&
            param2 !== null) {
          if (param2 == "01") {
            this.blogService.startOver();
          } else {
            this.currentPage = parseInt(param2);
          }
        }
      } finally {
      }
    });

    this.subscriptions.push(this.blogService.posts$.subscribe(posts => {
      this.blogPosts = posts;
      if (this.blogService.totalPages < this.urlpage) {
        this.metaService.overrideCanonicalURL(document.location.href.replace('/page/' + this.urlpage.toString(), '/page/' + this.blogService.totalPages.toString()));
      } else {
        this.metaService.createCanonicalURL();
      }
      var theTitle = BLOG_META.title + (this.blogTagFilter.length > 0 ? " | Category " + this.capitalizeFirstLetter(this.blogTagFilter) : "") + " | Page " + this.currentPage.toString() + " of " + this.totalPages.toString();
      if (theTitle.length > 64) {
        theTitle = BLOG_META.title + (this.blogTagFilter.length > 0 ? " | " + this.capitalizeFirstLetter(this.blogTagFilter) : "") + " | Page " + this.currentPage.toString() + " of " + this.totalPages.toString();
      }
      if (theTitle.length > 64) {
        theTitle = BLOG_META.title + (this.blogTagFilter.length > 0 ? " | " + this.capitalizeFirstLetter(this.blogTagFilter) : "") + " | " + this.currentPage.toString() + " of " + this.totalPages.toString();
      }
      if (theTitle.length > 64) {
        theTitle = BLOG_META.title.replace("- Shipping Done Rite", "") + (this.blogTagFilter.length > 0 ? " | " + this.capitalizeFirstLetter(this.blogTagFilter) : "") + " | " + this.currentPage.toString() + " of " + this.totalPages.toString();
      }
      this.title.setTitle(theTitle);
      // this.title.setTitle(BLOG_META.title + (this.blogTagFilter.length > 0 ? " | Category " + this.capitalizeFirstLetter(this.blogTagFilter) : "") + " | Page " + this.currentPage.toString() + " of " + this.totalPages.toString());
    }));

    this.subscriptions.push(this.blogService.tags$.subscribe(tags => {
      this.blogTags = tags;
    }));
   }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.title.setTitle(BLOG_META.title); // + (this.blogTagFilter.length > 0 ? " | Category " + this.capitalizeFirstLetter(this.blogTagFilter) : "") + " | Page " + this.currentPage.toString() + " of " + this.totalPages.toString());
    this.metaService.removeItemprop();
    BLOG_META.meta.forEach(m => this.meta.updateTag(m));
  }

  get currentPage() {
    return this.blogService.currentPage;
  }

  set currentPage(newPage: number) {
    this.blogService.currentPage = newPage;
  }

  get totalPages() {
    return this.blogService.totalPages;
  }

  get blogTagFilter() {
    return this.blogService.blogTagFilter;
  }

  set blogTagFilter(newPageFiler: string) {
    this.blogService.blogTagFilter = newPageFiler;
  }

  get filteredBlogs() {
      return this.blogPosts;
  }

  capitalizeFirstLetter(aString) {
    return aString.charAt(0).toUpperCase() + aString.slice(1);
  }
}