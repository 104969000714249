<article class="page">
    <section class="container-fluid">
        <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
            <div class="container">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-8">
                        <div class="row pt-5">
                            <div class="col-12 text-center">
                                <h3>Your Review for {{carrierName}}</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <span class="rating" (mouseenter)="clearRating()" (mouseleave)="setRating(0)">
                                    <a *ngFor="let star of highlightedStars; let i = index" [ngClass]="{'selectedRating': star}" (click)="setRating(5-i)" title="{{5-i}}">★</a>
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 form-group">
                                <label for="comments">Comments</label>
                                <textarea style="height: 100px; resize: none;" type="text" rows="2" class="form-control non-primary-label" formControlName="comments" [(ngModel)]="reviewComments" id="comments"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 form-group text-center">
                                <button *ngIf="!submitted && isAuthenticated" type="submit" class="btn btn-primary w-50" [disabled]="submitDisabled">Submit My Review</button>
                                <button *ngIf="!submitted && !isAuthenticated" class="btn btn-primary" (click)="open('login')">Login to Submit Review</button>
                                <h4 *ngIf="submitted">Your review has been submitted. Thank you!</h4>
                            </div>
                        </div>
                        <div *ngIf="error" class="row">
                            <div class="col-12 form-helpers text-center">
                                <small class="text-danger">There was an issue submitting your review, please try again.</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-2"></div>
                </div>
            </div>
        </form>
    </section>
</article>
