import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';

import { FinalMileQuoteComponent } from './final-mile-quote.component';
import { AuthGuard } from '../core/auth.guard';
import { FinalMileAboutComponent } from '../pages/final-mile-about/final-mile-about.component';

const routes: Routes = [
  {
    path: 'initial',
    redirectTo: 'start'
  },
  {
    path: 'final-mile-delivery',
    redirectTo: '/'
    // component: FinalMileAboutComponent,
    // data: {
    //   state: 'final-mile',
    //   headerImage: 'assets/images/updated_images/Services.jpg'
    // }
  },
  {
    path: 'final-mile-delivery/:id',
    redirectTo: '/'
    // component: FinalMileQuoteComponent,
    // canActivate: [AuthGuard],
    // data: {
    //   state: 'final-mile',
    //   headerImage: 'assets/images/banners/feature-header-request.jpg'
    // }
  }
  // {
  //   path: 'start/:id',
  //   component: QuoteComponent,
  //   data: {
  //     state: 'quote',
  //     headerImage: 'assets/images/banners/RiteQuote-lo.jpg'
  //   }
  // },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class FinalMileRoutingModule { }

