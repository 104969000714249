import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject ,  Observable ,  Subscription } from 'rxjs';

import { ConfigService } from './config.service';
import { Email } from '../models/email.model';

@Injectable()
export class EmailService {
    api: string;

    constructor(
        private config: ConfigService,
        private httpClient: HttpClient
      ) {
        this.api = this.config.getConfig('api');
        this.api += 'api/email/send';
    }

    public sendEmail(e: Email): Promise<any> {
      return this.httpClient.post(this.api, e)
        .toPromise()
        .then(res => true)
        .catch(err => err);
      // return new Promise((resolve, reject) => {
      //   this.httpClient.post(this.api, e)
      //     .catch(err => {
      //       console.log('Email error ... ', err);
      //       reject('');
      //       return Observable.throw(err);
      //     })
      //     .subscribe(res => {
      //       console.log('I sent an email ... ', res);
      //       resolve('');
      //     })
      //     .unsubscribe();
      // });
    }
}
