import * as tslib_1 from "tslib";
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
var NgbDateENParserFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(NgbDateENParserFormatter, _super);
    function NgbDateENParserFormatter(locale) {
        if (locale === void 0) { locale = 'en'; }
        var _this = _super.call(this) || this;
        _this.locale = locale;
        return _this;
    }
    NgbDateENParserFormatter.prototype.format = function (date) {
        if (date === null)
            return '';
        var d = new Date();
        d.setDate(date.day);
        d.setFullYear(date.year);
        d.setMonth(date.month - 1);
        return d.toLocaleDateString(this.locale);
    };
    NgbDateENParserFormatter.prototype.parse = function (value) {
        if (!value)
            return null;
        var d = new Date(value);
        if (d) {
            return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
        }
        else {
            return null;
        }
    };
    return NgbDateENParserFormatter;
}(NgbDateParserFormatter));
export { NgbDateENParserFormatter };
