/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./address-validator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./address-validator.component";
import * as i4 from "../../services/smartystreets.service";
var styles_AddressValidatorComponent = [i0.styles];
var RenderType_AddressValidatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddressValidatorComponent, data: {} });
export { RenderType_AddressValidatorComponent as RenderType_AddressValidatorComponent };
function View_AddressValidatorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "address-validator__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelect(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_AddressValidatorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please select a valid address: "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressValidatorComponent_4)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.suggestions; _ck(_v, 3, 0, currVal_0); }, null); }
function View_AddressValidatorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No address suggestions"]))], null, null); }
function View_AddressValidatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "address-validator__container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressValidatorComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noSuggestions", 2]], null, 0, null, View_AddressValidatorComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.suggestions.length > 0); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AddressValidatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "address-validator"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressValidatorComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.suggestions; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AddressValidatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressValidatorComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AddressValidatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-address-validator", [], null, null, null, View_AddressValidatorComponent_0, RenderType_AddressValidatorComponent)), i1.ɵdid(1, 245760, null, 0, i3.AddressValidatorComponent, [i4.SmartyStreetsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddressValidatorComponentNgFactory = i1.ɵccf("rtrt-address-validator", i3.AddressValidatorComponent, View_AddressValidatorComponent_Host_0, { show: "show", street: "street", postal: "postal", addressChange: "addressChange", debounce: "debounce" }, { select: "select" }, []);
export { AddressValidatorComponentNgFactory as AddressValidatorComponentNgFactory };
