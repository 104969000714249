import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';

import { QuoteComponent } from './quote.component';
import { AuthGuard } from '../core/auth.guard';

const routes: Routes = [
  {
    path: 'initial',
    redirectTo: 'start'
  },
  {
    path: 'ltl-shipping-quote/start',
    redirectTo: 'ltl-shipping-quote'
  },
  {
    path: 'ltl-shipping-quote',
    component: QuoteComponent,
    data: {
      state: 'quote',
      headerImage: 'assets/images/banners/feature-header-request.jpg'
    }
  },
  {
    path: 'ltl-shipping-quote/:id',
    component: QuoteComponent,
    canActivate: [AuthGuard],
    data: {
      state: 'quote',
      headerImage: 'assets/images/banners/feature-header-request.jpg'
    }
  }
    // {
    //   path: 'start/:id',
    //   component: QuoteComponent,
    //   data: {
    //     state: 'quote',
    //     headerImage: 'assets/images/banners/RiteQuote-lo.jpg'
    //   }
    // },
];

@NgModule({
    imports: [
      RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
  })
  export class QuoteRoutingModule { }
