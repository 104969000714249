<article class="page page-account pb-4">
  <header>
    <div class="container">
      <h1 class="text-light">My Account</h1>
    </div>
  </header>
  <section class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="container">
          <!-- <div class="row b-bottom-secondary pt-5 mb-5">
            <div class="col-12 d-flex align-items-center justify-content-between">
              <h2>My Account</h2>
            </div>
          </div> -->
          <div class="row pt-5">
            <div class="col-12 col-lg-6">
              <div class="row" *ngIf="user">
                <div class="col-12">
                  <h3 class="mb-0 d-inline-block mr-3">Profile Details</h3>
                  <!-- <p class="d-inline-block"><button type="button" class="btn btn-link btn-mini">Update</button></p> -->
                </div>
                <div class="col-4 text-right"><p>Name</p></div>
                <div class="col-8"><p><strong>{{user.name}}</strong></p></div>
                <div class="col-4 text-right"><p>Username</p></div>
                <div class="col-8"><p><strong>{{user.username}}</strong></p></div>
                <div class="col-4 text-right"><p>Email Address</p></div>
                <div class="col-8"><p><strong>{{user.email}}</strong></p></div>
                <div class="col-4 text-right"><p>Phone Number</p></div>
                <div class="col-8"><p><strong>{{user.phone}}</strong></p></div>
                <div class="col-4 text-right"><p>Password</p></div>
                <div class="col-8"><p><button type="button" class="btn btn-link btn-mini" (click)="open('password')">Change</button></p></div>

              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="row">
                <div class="col-8 pb-3">
                  <h3 class="mb-0 d-inline-block mr-3">Address Information</h3>
                  <p class="d-inline-block"><button type="button" class="btn btn-link btn-mini" [routerLink]="'/addresses'">Manage</button></p>
                </div>
              </div>
              <div class="row" *ngIf="addresses">
                <div class="col-12 mb-5">
                    <rtrt-address-card [noDelete]="true" *ngFor="let address of addresses" [address]="address"></rtrt-address-card>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-8 pb-3">
              <h3 class="mb-0 d-inline-block mr-3">Recent Quotes</h3>
              <p class="d-inline-block">
                <rtrt-spinner [visible]="!history"></rtrt-spinner>
                <button type="button" class="btn btn-link btn-mini" [routerLink]="'/account/quotes'" *ngIf="history && history.length > 0">View More</button>
              </p>
            </div>
          </div>
          <div class="row" *ngIf="history?.length === 0">
            <div class="col-12">
              <p>You have no recent quotes.</p>
            </div>
          </div>
          <rtrt-quote-history [history]="history"></rtrt-quote-history>
        </div>
      </div>
    </div>
  </section>
</article>