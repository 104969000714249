<article class="page page-contact">
  <header>
    <div class="container">
      <h1 class="text-light">Contact Us</h1>
    </div>
  </header>
  <section class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="container">
          <div class="row pt-5 pb-5">
              <rtrt-contact-form></rtrt-contact-form>

          </div>
        </div>
      </div>
    </div>
  </section>
</article>
<!-- <section class="container-fluid">
  <div class="row">
    <div class="col-6">
      <div class="col-12">
        <label for="originAddress1">First Name</label>
      </div>
      <div class="col-12">
        <input type="text" class="form-control" formControlName="address1" id="originAddress1" />
      </div>
    </div>
    <div class="col-6">
      <div class="col-12">
        <label for="originAddress1">Last Name</label>
      </div>
      <div class="col-12">
        <input type="text" class="form-control" formControlName="address1" id="originAddress1" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="col-12 form-group">
        <label for="originEmail">Email</label>
        <input type="text" class="form-control" formControlName="email" id="originEmail" />
      </div>
    </div>
    <div class="col-6">
      <div class="col-6 form-group">
        <label for="originPhone">Phone</label>
        <input type="text" placeholder="1112223333" class="form-control" formControlName="phone" id="originPhone" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label for="contactAddress1">Address</label>
    </div>
    <div class="col-6">
      <input type="text" class="form-control" placeholder="Address 1" formControlName="address1" id="contactAddress1" />
    </div>
    <div class="col-6 form-group">
      <input type="text" class="form-control" placeholder="Address 2" formControlName="address2" id="contactAddress2" />
    </div>
  </div>
  <div class="row">
    <div class="col-12 form-group">
      <label for="contactCity">City</label>
      <input type="text" class="form-control" formControlName="city" id="contactCity" />
    </div>
    <div class="col-12 form-group">
      <label for="contactCity">State</label>
      <input type="text" class="form-control" formControlName="state" id="contactState" />
    </div>
    <div class="col-12 form-group">
      <label for="contactCity">Zip</label>
      <input type="text" class="form-control" formControlName="zip" id="contactZip" />
    </div>
  </div>
</section> -->