import { Component } from '@angular/core';

import { ForgotPasswordFormComponent } from '../forgot-password-form/forgot-password-form.component';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rtrt-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html'
})
export class ForgotPasswordModalComponent {
  constructor(public activeModal: NgbActiveModal) { }
}
