<article class="page page-review">
    <!--     <header [style.background-image]="'url(' + headerBg + ')'">
        <div class="container">
            <h1 class="text-light">Blog</h1>
        </div>
    </header> -->
    <section class="container-fluid">
        <div class="row">
            <div class="container">
                <div class="row pt-5">
                    <div class="col-12 m-auto">
                        <h2>Customer Reviews for {{carrierName}}</h2>
                        <div class="carrier-review-wrapper">
                            <div class="row" id="reviewSummaryContainer">
                                <div class="col-12 mb-0">
                                    <div>
                                        <span>
                                            <rtrt-carrier-review-stars [rating]="AverageRating"></rtrt-carrier-review-stars>
                                        </span>
                                        <small>{{AverageRating}} out of 5</small>
                                    </div>
                                    <small class="subText">{{ReviewCount}} customer reviews</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div *ngFor="let number of [5,4,3,2,1]" class="col-12 pl-0">
                                        <div class="d-inline-block" style="width: 12%;"><p><small>{{number}} Star</small></p></div>
                                        <div class="rating-progress-bar d-inline-block" style="width: 78%;">
                                            <div class="rating-progress" style="height:24px;" [ngStyle]="{'width': getRatingPercentage(number)}"></div>
                                        </div>
                                        <div class="d-inline-block text-right" style="width: 10%;"><p><small>{{getRatingPercentage(number)}}</small></p></div>
                                    </div>
                                </div>  
                            </div>
                            <div class="row" *ngFor="let review of carrierReviews">
                                <rtrt-carrier-review-detail [review]="review"></rtrt-carrier-review-detail>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</article>
