
import {throwError,  Observable ,  Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter, NgZone} from '@angular/core';
import { Form, FormGroup, FormControl } from '@angular/forms';


import { ConfigService } from '../services/config.service';
import { AccountService } from '../services/account.service';

import { Location } from '../models';
import { RtValidators } from '../validators/rt-validators';
import { ZipService } from '../services/zip.service';

@Component({
  selector: 'rtrt-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, OnDestroy {
  @ViewChild('f', { static: false }) form: FormGroup;
  @Input() model: Location;
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  isNew = false;
  states: any[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private config: ConfigService,
    private accountService: AccountService,
    private zipService: ZipService,
    private zone: NgZone

  ) {
  }

  submit() {
    if (!this.form.valid) {
      // this.touchAllFormFields();
      // this.form.markAsDirty();
      console.log('Submitted .... ', this.form);
      return false;
    }

    if (!this.model.ID) {
      this.accountService.addAddress(this.model);
    } else {
      this.accountService.updateAddress(this.model);
    }
    this.model = new Location();
    this.onSave.emit();
  }

  handleZipLookup(control: FormControl) {
    const zip = control.value;
    if (!zip || zip === '') {
      this.model.City = '';
      this.model.StateOrProvince = null;
      return;
    }
    this.zipService.checkZip(zip)
      .pipe(
        catchError((err: any) => {
          this.flagPostalError(control);
          return throwError(err);
        })
      )
      .subscribe((v: any) => {
        console.log(v);
        v = JSON.parse(v);
        if (v.err && v.err !== '') {
          this.flagPostalError(control);
          return;
        }
        this.states = this.config.getConfig('states').filter(s => s.country == v.country);
        if (v.cities.length > 0)
          this.model.City = v.cities[0];
        this.model.StateOrProvince = v.stateprov;
        this.setCountry(v.country);
      });
  }

  private flagPostalError(control: FormControl) {
    this.model.City = '';
    this.model.StateOrProvince = null;
    this.model.PostalCode = '';
  }

  handleCancel() {
    this.onCancel.emit();
  }

  ngOnInit() {
    if (!this.model) {
      this.model = new Location();
      this.isNew = true;
    }
    this.states = this.config.getConfig('states').filter(s => s.country == this.model.CountryString);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get addressType() {
    return (this.model.IsConsignee ? 'Destination' : 'Shipper');
  }

  setAddressType(s: string) {
    this.model.IsConsignee = (s === 'consignee') ? true : false;
    this.model.IsShipper = (s === 'shipper') ? true : false;
  }

  get country() {
    switch (this.model.CountryString) {
      case 'CAN':
      case 'Canada':
      case 'CA':
        return 'Canada';
      case 'USA':
      case 'US':
        return 'United States';
      default:
        return '';
    }
  }

  setCountry(c: string) {
    switch (c) {
      case 'CAN':
      case 'Canada':
      case 'CA':
        this.model.CountryString = "CAN";
        return;
      case 'USA':
      case 'US':
        this.model.CountryString = "USA";
        return;
      default:
        this.model.CountryString = "";
        return;
    }
  }

  fillAddress(a: Location, fillName: boolean) {
    this.zone.run(() => {
      this.states = this.config.getConfig('states').filter(s => s.country == a.CountryString);

      this.model.Name = (fillName ? a.Name : this.model.Name);
      this.model.AddressLineOne = a.AddressLineOne;
      this.model.City = a.City;
      this.model.StateOrProvince = a.StateOrProvince;
      this.model.PostalCode = a.PostalCode;
      this.model.CountryString = a.CountryString;
    });
  }
}
