import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { QuoteRoutingModule } from './quote-routing.module';

import { QuoteComponent } from './quote.component';
import { QuoteFormComponent } from './quote-form/quote-form.component';
import { QuoteItemFormComponent } from './quote-item-form/quote-item-form.component';
import { CarrierSelectionComponent } from './carrier-selection/carrier-selection.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { TlQuoteFormComponent } from './tl-quote-form/tl-quote-form.component';
import { QuoteDetailsComponent } from './quote-details/quote-details.component';
import { OriginFormComponent } from './origin-form/origin-form.component';
import { DestinationFormComponent } from './destination-form/destination-form.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ShipmentHistoryComponent } from './shipment-history/shipment-history.component';
import { FreightItemComponent } from './freight-item/freight-item.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { FreightItemDetailComponent } from './freight-item-detail/freight-item-detail.component';

import { PaymentService } from '../services/payment.service';
import { QuoteService } from '../services/quote.service';
import { AccessorialService } from '../services/accessorials.service';
import { EstimateService } from '../services/estimate.service';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReferenceSelectorComponent } from './reference-selector/reference-selector.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
    , QuoteRoutingModule
    , NgbModule
    , SharedModule
  ],
  declarations: [
    QuoteComponent
    , QuoteFormComponent
    , QuoteItemFormComponent
    , CarrierSelectionComponent
    , PaymentFormComponent
    , TlQuoteFormComponent
    , OriginFormComponent
    , DestinationFormComponent
    , ConfirmationComponent
    , ShipmentHistoryComponent
    , FreightItemComponent
    , QuoteDetailsComponent
    , ItemDetailsComponent
    , FreightItemDetailComponent
    , ReferenceSelectorComponent
  ],
  exports: [
    QuoteComponent
    , QuoteFormComponent
    , QuoteItemFormComponent
    , CarrierSelectionComponent
    , PaymentFormComponent
    , TlQuoteFormComponent
    , OriginFormComponent
    , DestinationFormComponent
    , ConfirmationComponent
    , ShipmentHistoryComponent
    , FreightItemComponent
    , QuoteDetailsComponent
    , ItemDetailsComponent
    , FreightItemDetailComponent
    , ReferenceSelectorComponent
  ],
  providers: [
    PaymentService
    , AccessorialService
  ]
})
export class QuoteModule { }
