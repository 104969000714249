import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Observable, Subject, merge } from 'rxjs';
import { Commodity } from '../../models';
import { QuoteService } from '../../services/quote.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
var FreightItemDetailComponent = /** @class */ (function () {
    function FreightItemDetailComponent(quoteService) {
        var _this = this;
        this.quoteService = quoteService;
        this.handlingUnits = [];
        this.commodities = [];
        this._clientCommodities = [];
        this.validationResult = new EventEmitter();
        this.subscriptions = [];
        this.controls = {
            errors: {
                desc: true,
                hu: true,
                pieces: true
            },
            touched: {
                desc: false,
                hu: false,
                pieces: false
            }
        };
        this.clickHU$ = new Subject();
        this.focusHU$ = new Subject();
        this.clickComm$ = new Subject();
        this.focusComm$ = new Subject();
        this.searchHandlingUnit = function (textHU$) {
            var debouncedText$ = textHU$.pipe(debounceTime(200), distinctUntilChanged());
            var clicksWithClosedPopup$ = _this.clickHU$.pipe(filter(function () { return !_this.instance.isPopupOpen(); }));
            var inputFocus$ = _this.focusHU$;
            return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(map(function (term) { return _this.handlingUnits.filter(function (f) { return new RegExp(term, 'mi').test(f.Value.toString()); }); }));
        };
        this.searchCommodity = function (textComm$) {
            var debouncedText$ = textComm$.pipe(debounceTime(200), distinctUntilChanged());
            var clicksWithClosedPopup$ = _this.clickComm$.pipe(filter(function () { return !_this.instance2.isPopupOpen(); }));
            var inputFocus$ = _this.focusComm$;
            return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(map(function (term) { return _this.clientCommodities.filter(function (f) { return new RegExp(term, 'mi').test(f.MatchString.toString()); }); }));
        };
        this.formatter = function (x) { return x.Value; };
        this.formatter1 = function (x) { return x.FullDescription; };
    }
    FreightItemDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.commodity.HandlingTypeID && this.commodity.HandlingTypeID !== null)
            this.selectHU({ ID: this.commodity.HandlingTypeID, Value: this.commodity.HandlingType });
        this.subscriptions.push(this.isSubmitting.subscribe(function (submitting) {
            if (submitting)
                _this.validate();
        }));
    };
    Object.defineProperty(FreightItemDetailComponent.prototype, "clientCommodities", {
        get: function () {
            var _this = this;
            return this._clientCommodities.filter(function (c) {
                return c.Class == _this.commodity.FreightClass.toString();
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FreightItemDetailComponent.prototype, "handlingUnit", {
        get: function () {
            var _this = this;
            var hu = (!this.commodity
                || !this.commodity.HandlingTypeID
                || this.commodity.HandlingTypeID === null
                || !this.handlingUnits
                || this.handlingUnits.length === 0)
                ? null
                : this.handlingUnits.find(function (h) { return h.ID === _this.commodity.HandlingTypeID; });
            return (!hu || hu === null)
                ? 'Please Select'
                : hu.Value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FreightItemDetailComponent.prototype, "displayID", {
        get: function () {
            return this.id + 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FreightItemDetailComponent.prototype, "touched", {
        get: function () {
            return this.controls.touched;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FreightItemDetailComponent.prototype, "errors", {
        get: function () {
            return this.controls.errors;
        },
        enumerable: true,
        configurable: true
    });
    FreightItemDetailComponent.prototype.touch = function (idx, val) {
        if (val === void 0) { val = null; }
        this.controls.touched[idx] = true;
        if (idx !== 'hu') {
            if (!this.commodity[val] || this.commodity[val] === '' || this.commodity[val] === null || this.commodity[val] <= 0)
                this.controls.errors[idx] = true;
            else
                this.controls.errors[idx] = false;
        }
        else {
            if ((!this.commHU || this.commHU.Value == null || this.commHU.Value == '') || (this.commHU.Value != '' && this.handlingUnits.map(function (hu) { return hu.Value; }).indexOf(this.commHU.Value) === -1)) {
                this.controls.errors[idx] = true;
            }
            else
                this.controls.errors[idx] = false;
        }
    };
    FreightItemDetailComponent.prototype.touchAll = function () {
        this.touch('hu', "HandlingType");
        this.touch('desc', 'CommodityDescription');
        this.touch('nmfc', 'NMFC');
        this.touch('pieces', 'Pieces');
    };
    FreightItemDetailComponent.prototype.selectHU = function (hu) {
        this.commHU = hu;
        this.commodity.HandlingTypeID = hu.ID;
        this.commodity.HandlingType = hu.Value;
        this.controls.errors.hu = false;
        //this.touch('hu');
    };
    FreightItemDetailComponent.prototype.selectComm = function ($event, input) {
        $event.preventDefault();
        var comm = $event.item;
        this.commodity.CommodityDescription = comm.Description;
        this.commodity.NMFC = comm.NMFC;
        input.value = '';
    };
    FreightItemDetailComponent.prototype.validate = function () {
        this.touchAll();
        var isValid = !(this.errors.hu || this.errors.desc || this.errors.pieces);
        this.validationResult.emit({ item: this.id, isValid: isValid });
    };
    FreightItemDetailComponent.prototype.selectCopy = function (commoditiy, i) {
        this.commodity.CommodityDescription = commoditiy.CommodityDescription;
        this.commodity.NMFC = commoditiy.NMFC;
        this.commodity.Pieces = commoditiy.Pieces;
        this.commodity.HandlingType = commoditiy.HandlingType;
        this.commodity.HandlingTypeID = commoditiy.HandlingTypeID;
        this.selectHU({ ID: this.commodity.HandlingTypeID, Value: this.commodity.HandlingType });
        this.copyID = i + 1;
    };
    FreightItemDetailComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    FreightItemDetailComponent.prototype.onSubmit = function (e) {
        console.log(e);
    };
    return FreightItemDetailComponent;
}());
export { FreightItemDetailComponent };
