import { AfterViewInit, OnDestroy, EventEmitter, ChangeDetectorRef, ElementRef, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { Subscription, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ConfigService } from "./../../services/config.service";
import { Location, Payment } from "../../models";
import { PaymentService } from "../../services/payment.service";
import { FlashService } from "../../shared/flash/flash.service";
import { AccountService } from "../../services/account.service";
import { FormGroup } from "@angular/forms";
import { ZipService } from "../../services/zip.service";
import { AuthenticationService } from "../../services/authentication.service";
var cards = [
    { number: "4242424242424242", description: "Visa" },
    {
        number: "4000000000000077",
        description: "Charge succeeds and funds will be added directly to your available balance (bypassing your pending balance)."
    },
    {
        number: "4000000000000093",
        description: "Charge succeeds and domestic pricing is used (other test cards use international pricing). This card is only significant in countries with split pricing."
    },
    {
        number: "4000000000000010",
        description: "The address_line1_check and address_zip_check verifications fail. If your account is blocking payments that fail ZIP code validation, the charge is declined."
    },
    {
        number: "4000000000000028",
        description: "Charge succeeds but the address_line1_check verification fails."
    },
    {
        number: "4000000000000036",
        description: "The address_zip_check verification fails. If your account is blocking payments that fail ZIP code validation, the charge is declined."
    },
    {
        number: "4000000000000044",
        description: "Charge succeeds but the address_zip_check and address_line1_check verifications are both unavailable."
    },
    {
        number: "4000000000000101",
        description: "If a CVC number is provided, the cvc_check fails. If your account is blocking payments that fail CVC code validation, the charge is declined."
    },
    {
        number: "4000000000000341",
        description: "Attaching this card to a Customer object succeeds, but attempts to charge the customer fail."
    },
    {
        number: "4000000000009235",
        description: "Charge succeeds with a risk_level of elevated and placed into review."
    },
    {
        number: "4000000000000002",
        description: "Charge is declined with a card_declined code."
    },
    {
        number: "4100000000000019",
        description: "Results in a charge with a risk level of highest. The charge is blocked as it's considered fraudulent."
    },
    {
        number: "4000000000000127",
        description: "Charge is declined with an incorrect_cvc code."
    },
    {
        number: "4000000000000069",
        description: "Charge is declined with an expired_card code."
    },
    {
        number: "4000000000000119",
        description: "Charge is declined with a processing_error code."
    },
    {
        number: "4242424242424241",
        description: "Charge is declined with an incorrect_number code as the card number fails the Luhn check."
    }
];
var StripeComponent = /** @class */ (function () {
    function StripeComponent(router, zipService, cd, config, flash, paymentService, accountService, authService) {
        var _this = this;
        this.router = router;
        this.zipService = zipService;
        this.cd = cd;
        this.config = config;
        this.flash = flash;
        this.paymentService = paymentService;
        this.accountService = accountService;
        this.authService = authService;
        this.onPaymentSuccess = new EventEmitter();
        this.onPaymentError = new EventEmitter();
        this.contact = new Location();
        this.isFinalMile = false;
        this.paymentIntentSecret = null;
        this.buttonText = null;
        this.freightClasses = [];
        this.touched = false;
        this.subscriptions = [];
        this.cardHandler = this.onChange.bind(this);
        this.loading = false;
        this.useOriginAddress = true;
        this.cards = cards;
        this.style = {
            base: {
                color: "#000",
                fontWeight: 400,
                fontFamily: "'Titillium Web', sans-serif",
                fontSize: "19px",
                fontSmoothing: "antialiased",
                "::placeholder": {
                    color: "#ccc"
                }
            },
            invalid: {
                color: "#b40028"
            }
        };
        this.isProd = true;
        this.showTestCards = false;
        this.testToggleText = "Show";
        this.states = [];
        this._addresses = [];
        this.billingAddress = new Location();
        this.hasDefaultBilling = false;
        this.zips = {
            billing: {
                loading: false,
                isValid: true
            }
        };
        this.isProd = this.config.getEnv() === "production";
        this.states = config.getConfig("states").filter(function (s) { return s.country == "USA"; });
        this.subscriptions.push(this.accountService.address$.subscribe(function (a) {
            _this._addresses = a;
            _this.setDefaultBillingAddress();
        }));
    }
    StripeComponent.prototype.ngOnInit = function () {
        this.billingAddress = Object.assign({}, this.contact);
        this.billingType = this.isFinalMile ? "default" : "origin";
    };
    StripeComponent.prototype.ngAfterViewInit = function () {
        this.card = elements.create("card", {
            style: this.style,
            hidePostalCode: true
        });
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener("change", this.cardHandler);
    };
    StripeComponent.prototype.onChange = function (_a) {
        var error = _a.error;
        if (error) {
            this.error = error.message;
        }
        else {
            this.error = null;
        }
        this.cd.detectChanges();
    };
    StripeComponent.prototype.setDefaultBillingAddress = function () {
        if (this._addresses.findIndex(function (a) { return a.IsDefaultBillTo; }) > -1) {
            this.defaultAddress = Object.assign({}, this._addresses.find(function (a) { return a.IsDefaultBillTo; }));
            this.hasDefaultBilling = true;
        }
        else {
            this.defaultAddress = new Location();
        }
    };
    StripeComponent.prototype.updateBillingAddress = function (type) {
        switch (type) {
            case "origin":
                this.billingAddress = Object.assign({}, this.contact);
                break;
            case "default":
                this.billingAddress = Object.assign({}, this.defaultAddress);
                break;
            case "enter":
                this.billingAddress = new Location();
                break;
            default:
                this.billingAddress = Object.assign({}, this.originAddress);
                break;
        }
    };
    StripeComponent.prototype.toggleTestInfo = function () {
        this.showTestCards = !this.showTestCards;
        this.testToggleText = this.showTestCards ? "Hide" : "Show";
    };
    StripeComponent.prototype._getPaymentFromResponse = function (res) {
        var p = new Payment();
        p.Amount = res.paymentIntent.amount * 0.01;
        //p.Brand = res.paymentIntent.brand;
        p.Currency = res.paymentIntent.currency;
        p.Description = res.paymentIntent.description;
        //p.Last4Digits = res.paymentIntent.last4;
        p.Status = res.paymentIntent.status;
        p.TransactionDate = new Date().toLocaleString();
        p.ReceiptEmail = this.billingAddress.ContactEmail;
        return p;
    };
    StripeComponent.prototype.validateZip = function () {
        var _this = this;
        if (this.billingAddress.PostalCode === "") {
            this.zips.billing.isValid = false;
            return false;
        }
        this.zips.billing.loading = true;
        this.zipService
            .checkZip(this.billingAddress.PostalCode)
            .pipe(catchError(function (err) { return of(err); }))
            .subscribe(function (res) {
            res = JSON.parse(res);
            _this.zips.billing.loading = false;
            if (res.err && res.err !== "") {
                _this.zips.billing.isValid = false;
                return;
            }
            _this.states = _this.config.getConfig('states').filter(function (s) { return s.country == res.country; });
            _this.billingAddress.City = res.cities[0];
            _this.billingAddress.StateOrProvince = res.stateprov;
            _this.zips.billing.isValid = true;
            return;
        }, function (err) {
            _this.zips.billing.loading = false;
            _this.zips.billing.isValid = false;
        });
    };
    StripeComponent.prototype.onSubmit = function () {
        var _this = this;
        this.verifyTokenNotExpired(true);
        if (this.form.invalid) {
            Object.keys(this.form.controls)
                .map(function (x) { return _this.form.controls[x]; })
                .forEach(function (control) {
                control.markAsTouched();
            });
            this.touched = true;
            this.flash.flashMessage("Please fill out all required fields.", "", "danger");
            this.verifyTokenNotExpired(false);
            return false;
        }
        if ((this.quote.FinalMileShipment === false &&
            this.quote.Carriers.length === 0) ||
            (this.quote.FinalMileShipment === true &&
                this.quote.Vendors.length === 0)) {
            this.flash.flashMessage("Unexpected problem, there are no carriers on this quote.", "ERROR", "error");
            this.verifyTokenNotExpired(false);
            return false;
        }
        if (!this.charge || this.charge === 0) {
            this.onPaymentError.emit({
                error: true,
                message: "No charge found",
                errCode: 1
            });
            this.verifyTokenNotExpired(false);
            return false;
        }
        this.paymentService.setCharge(this.charge);
        this.loading = true;
        // console.log("*** PAYMENT STEP 1 ***");
        this.paymentService.makeCardPayment(this.quote, this.card, this.billingAddress)
            .then(function (res) {
            var jres;
            if (res) {
                try {
                    jres = JSON.parse(res);
                }
                catch (_a) { }
            }
            // this.loading = false;
            _this.flash.dismissMessage();
            if (res && (!jres || !jres.error)) {
                // console.log("*** PAYMENT STEP 6 SUCCESS ***");
                _this.flash.flashMessage("Payment succeeded, redirecting to summary...");
                console.log("Payment Succeeded...");
                console.log(res);
                _this.onPaymentSuccess.emit({
                    contact: _this.defaultAddress,
                    payment: _this._getPaymentFromResponse(res)
                });
            }
            else {
                // console.log("*** PAYMENT STEP 6 ERR ***");
                _this.loading = false;
                if (res && jres && jres.error && jres.error.message) {
                    console.log(jres.error.message);
                    _this.flash.flashMessage(jres.error.message.toString(), "There Was a Problem (2)", "danger");
                }
                else {
                    var errMsg = "There was no response from paymentService.makeCardPayment";
                    console.log(errMsg);
                    _this.flash.flashMessage(errMsg);
                }
                _this.verifyTokenNotExpired(false);
            }
        })
            .catch(function (err) {
            // console.log("*** PAYMENT STEP 1 ERR ***");
            _this.verifyTokenNotExpired(false);
            _this.loading = false;
            if (!!err && !!err.message) {
                console.log(err.message);
                _this.flash.flashMessage(err.message.toString(), "There Was a Problem", "danger");
            }
            else {
                console.log("Caught error in paymentService.makeCardPayment but there is no error message");
                _this.flash.flashMessage("Caught error in paymentService.makeCardPayment but there is no error message");
            }
        });
    };
    StripeComponent.prototype.ngOnDestroy = function () {
        this.card.removeEventListener("change", this.cardHandler);
        this.card.destroy();
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    return StripeComponent;
}());
export { StripeComponent };
// TESTING DATA
// https://stripe.com/docs/testing
// 4242424242424242	Visa
// 4000000000000077	Charge succeeds and funds will be added directly to your available balance (bypassing your pending balance).
// 4000000000000093	Charge succeeds and domestic pricing is used (other test cards use international pricing).
// This card is only significant in countries with split pricing.
// 4000000000000010	The address_line1_check and address_zip_check verifications fail.
// If your account is blocking payments that fail ZIP code validation, the charge is declined.
// 4000000000000028	Charge succeeds but the address_line1_check verification fails.
// 4000000000000036	The address_zip_check verification fails.
// If your account is blocking payments that fail ZIP code validation, the charge is declined.
// 4000000000000044	Charge succeeds but the address_zip_check and address_line1_check verifications are both unavailable.
// 4000000000000101	If a CVC number is provided, the cvc_check fails.
// If your account is blocking payments that fail CVC code validation, the charge is declined.
// 4000000000000341	Attaching this card to a Customer object succeeds, but attempts to charge the customer fail.
// 4000000000009235	Charge succeeds with a risk_level of elevated and placed into review.
// 4000000000000002	Charge is declined with a card_declined code.
// 4100000000000019	Results in a charge with a risk level of highest. The charge is blocked as it's considered fraudulent.
// 4000000000000127	Charge is declined with an incorrect_cvc code.
// 4000000000000069	Charge is declined with an expired_card code.
// 4000000000000119	Charge is declined with a processing_error code.
// 4242424242424241	Charge is declined with an incorrect_number code as the card number fails the Luhn check.
