<form [formGroup]="accountForm" (ngSubmit)="onSubmit()" novalidate>  
  <div class="form-section row">
      <div class="col">          
          <h3 class="b-bottom-secondary">Account Information</h3> 
          <p class="text-primary float-right help-block">Red labels denote required fields</p>          
      </div>          
  </div>
  <rtrt-user-form></rtrt-user-form>
  <div class="form-section">
    <div class="row">
        <div class="col">            
            <h3 class="b-bottom-secondary">Business Information</h3>
            <p class="text-primary float-right help-block">Red labels denote required fields</p> 
        </div>
    </div>
    <div class="row">
      <div class="col-lg-12 required">
        <label>Company</label>
        <input type="text" class="form-control" [ngClass]="{'invalid': businessName.invalid && (businessName.dirty || businessName.touched)}" formControlName="businessName" id="businessName" google-place (onSelect)="fillAddress($event, true)" required>
        <div *ngIf="businessName.invalid && (businessName.dirty || businessName.touched)" class="form-helpers">
          <small *ngIf="businessName.errors.required" class="text-danger">Company is required.</small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 required">
        <label>Address</label>
        <input type="text" class="form-control" [ngClass]="{'invalid': address.invalid && (address.dirty || address.touched)}" formControlName="address" id="address" google-place (onSelect)="fillAddress($event, false)" required>
        <div *ngIf="address.invalid && (address.dirty || address.touched)" class="form-helpers">
          <small *ngIf="address.errors.required" class="text-danger">Address is required.</small>
        </div>
      </div>
      <div class="col-lg-12">
        <label>Address (cont.)</label>
        <input type="text" class="form-control" formControlName="address2" id="address2">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 required">
        <label>City</label>
        <input type="text" class="form-control" [ngClass]="{'invalid': city.invalid && (city.dirty || city.touched)}" formControlName="city" id="city" required>
        <div *ngIf="city.invalid && (city.dirty || city.touched)" class="form-helpers">
          <small *ngIf="city.errors.required" class="text-danger">City is required.</small>
        </div>
      </div>
      <div class="col-lg-6 required">
        <label for="state">State</label>
        <select class="form-control" [ngClass]="{'invalid': state.invalid && (state.dirty || state.touched)}" formControlName="state" id="state" required>
          <option *ngFor="let state of states" [value]="state.abbreviation">{{state.abbreviation}}</option>
        </select>
        <div *ngIf="state.invalid && (state.dirty || state.touched)" class="form-helpers">
          <small *ngIf="state.errors.required" class="text-danger">State is required.</small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 required">
        <label>Zip/Postal Code</label>
        <input type="text" class="form-control" [ngClass]="{'invalid': postal.invalid && (postal.dirty || postal.touched)}" formControlName="postal" id="postal" required (blur)="handleZipLookup()">
        <div *ngIf="postal.invalid && (postal.dirty || postal.touched)" class="form-helpers">
          <small *ngIf="postal.errors.required" class="text-danger">Origin zip/postal code is required.</small>
          <small *ngIf="postal.errors.zip" class="text-danger">Please provide a valid zip/postal code.</small>
        </div>
      </div>
      <div class="col-lg-6 required">
        <label for="accountPhone">Phone Number</label>
        <input type="text" class="form-control" [ngClass]="{'invalid': phone.invalid && (phone.dirty || phone.touched)}" formControlName="phone" id="accountPhone" required>
        <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="form-helpers">
          <small *ngIf="phone.errors.required" class="text-danger">Phone is required.</small>
          <small *ngIf="phone.errors.phone" class="text-danger">Please provide a valid phone number.</small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <label>Promo Code</label>
        <input type="text" class="form-control" [ngClass]="{'invalid': !promoCodeValid}" (blur)="handlePromoCode()"
          formControlName="promoCode" id="accountPromoCode">
        <div *ngIf="!promoCodeValid || (promoCodeValid && accountPromoCode.PromoCodeText != '')" class="form-helpers">
          <small *ngIf="!promoCodeValid" class="text-danger">The promo code is either invalid or expired.</small>
          <small *ngIf="promoCodeValid && accountPromoCode.PromoCodeText != ''" class="text-success">You will receive {{formattedDiscount}} off your first order!</small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
          <label>What modes of transportation do you use?</label>
      </div>
    </div>
    <div formArrayName="modes">
      <div *ngFor="let mode of modes.controls; let i=index"  [formGroupName]="i" class="row pt-2 pb-2 align-items-center striped mode-row">
        <div class="col-12 col-lg-4 mb-0">
          <label class="form-check-label">
            <input type="checkbox" formControlName="selected" />
            <span class="control"></span>
            {{modeOpts[i].name}}
          </label>
        </div>
        <div class="col-12 col-lg-4 mb-0 pt-2 pb-2" *ngIf="mode.controls.selected.value">
          <div ngbDropdown role="group" aria-label="Shipment volume quantity." class="w-100">
            <button type="button" class="btn dropdown w-100" ngbDropdownToggle>{{getModeQuantity(i) || 'Quantity'}}</button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button type="button" class="dropdown-item" *ngFor="let quantity of (modeOpts[i].name == 'Parcel' ? parcelQuantityOpts : volumeQuantityOpts)" (click)="handleQuantitySelection(quantity, i)">{{quantity}}</button>
            </div>
          </div>
          <input type="hidden" formControlName="volumeQuantity" />
        </div>
        <div class="col-12 col-lg-4 mb-0 pt-2 pb-2" *ngIf="mode.controls.selected.value">
        <div ngbDropdown role="group" aria-label="Shipment period." class="w-100">
          <button type="button" class="btn dropdown w-100" ngbDropdownToggle>{{getModePeriod(i) || 'Period'}}</button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button type="button" class="dropdown-item" *ngFor="let period of volumePeriodOpts" (click)="handlePeriodSelection(period, i)">{{period}}</button>
          </div>
        </div>
        <input type="hidden" formControlName="volumePeriod" />
      </div>
      </div>
    </div> 
    <div class="row pt-5">
      <div class="col-12 errmsg" *ngIf="errors.length > 0">
        <p *ngFor="let error of errors" class="text-alert">{{error}}</p>
      </div>
      <div class="col-12 text-center">
        <input class="mr-2" type="checkbox" [checked]="agreedToTerms" (change)="agreedToTerms = !agreedToTerms">
        <p class="d-inline-block"><small>By Creating a RiteRouting account you are agreeing to the <a target="_blank" routerLink="/terms-conditions">Terms and
          Conditions</a></small></p>
      </div>
      <div class="col-12">
        <button type="submit" [disabled]="submitDisabled || !agreedToTerms" [title]="(!agreedToTerms ? 'You must accept the terms and conditions before creating an account.' : (submitDisabled ? 'Your account is being created' : ''))" class="btn btn-primary w-100">Create My Account</button>
      </div>
    </div>
  </div>
</form>