import { Component, OnInit, Input, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { Observable, Subscription, of } from "rxjs";
import { catchError, zip } from "rxjs/operators";

import { RtValidators } from "./../../validators/rt-validators";
import { ZipService } from "../../services/zip.service";
import { ConfigService } from "../../services/config.service";
import { QuoteService } from "../../services/quote.service";
import { AccessorialService } from "../../services/accessorials.service";
import { Accessorial, Shipment, Commodity, Location } from "../../models";
import { SpinnerComponent } from "../../shared/spinner/spinner.component";
import { FreightItemComponent } from "../freight-item/freight-item.component";
import { FlashService } from "../../shared/flash/flash.service";
import { AccountService } from "../../services/account.service";
import { User } from "../../account/user.model";

import { LaunchDarklyService } from "../../services/launch-darkly-service.service";


@Component({
  selector: "rtrt-quote-form",
  templateUrl: "./quote-form.component.html",
  styleUrls: ["./quote-form.component.scss"]
})
export class QuoteFormComponent implements OnInit, OnDestroy {
  @Input() jls: string;
  @ViewChild("f", { static: false }) form: FormGroup;
  freightClasses: string[] = [];
  touched = false;
  quote: Shipment = new Shipment();
  private _accessorials: Accessorial[];
  loading = false;
  subscriptions: Subscription[] = [];
  shipperZip: string;
  consigneeZip: string;
  originalAddress: Location;
  private _addresses: Location[] = [];
  billingAddress: Location = new Location();
  hasDefaultBilling: boolean = false;
  email = "";
  firstname = "";
  lastname = "";
  _loggedIn = false;
  creating = false;
  zips = {
    shipper: {
      loading: false,
      isValid: false
    },
    consignee: {
      loading: false,
      isValid: false
    }
  };
  user: User;
  formErrorMsg = "";
  addressFilterProps = ["PostalCode"];
  includeAccessorials: boolean;
  isTradeshow: boolean;
  options = [
    {
      value: true, name: 'Yes'
    },
    {
      value: false, name: 'No'
    }
  ];
  errors = {
    acc:
    {
      selected: true,
      added: true
    },
    tradeshow:
    {
      selected: true
    }
  };
  serviceOutage: boolean = false;
     
  constructor(
    // private estimateService: EstimateService,
    private zipService: ZipService,
    private quoteService: QuoteService,
    private config: ConfigService,
    private router: Router,
    private accessorialService: AccessorialService,
    private flash: FlashService,
    private accountService: AccountService,
    private ld: LaunchDarklyService
  ) {
    this.freightClasses = config
      .getConfig("freightClassOptions")
      .map(f => f as number);
  }

  ngOnInit() {

    this.ld.getFlag("ServiceOutage").subscribe((flag) => {
      this.serviceOutage = flag;
    });

    if (this.quote.Shipper.PostalCode && !this.zips.shipper.isValid) {
      this.validateOriginZip();
    }
    if (this.quote.Consignee.PostalCode && !this.zips.consignee.isValid) {
      this.validateDestinationZip();
    }
    if (this.jls && this.jls !== null) {
      // console.log("no jls");
      this.loading = true;
      this.quoteService.loadQuote(this.jls).then(res => {
        this.loading = false;
      });
    }
    this.accountService.refreshAddresses();
    this.subscriptions.push(
      this.accountService.address$.subscribe(a => {
        this._addresses = a;
        this.setDefaultBillingAddress();
        if (
          this.shipperZip &&
          (this.shipperZip === null || this.shipperZip === "")
        ) {
          this.quote.Shipper = new Location();
        }
      })
    );
    this.subscriptions.push(
      this.accountService.user$.subscribe(u => {
        this.user = u;
      })
    );
    this.subscriptions.push(
      this.accountService.loginExpired.subscribe(x => {
        // console.log("*** quote-form accountService.loginExpired subscription _loggedIn=" + x);
        this._loggedIn = typeof x == 'undefined' ? false : !x;
      })
    );
    this.subscriptions.push(
      this.quoteService.quote$.subscribe(q => {
        this.quote = q;

        if (this.quote.Shipper.PostalCode) this.validateOriginZip(false);

        if (this.quote.Consignee.PostalCode) this.validateDestinationZip(false);

        if (this.quote.Commodities.length === 0)
          this.quote.Commodities.push(new Commodity());

        if (this.email === "") {
          this.subscriptions.push(
            this.accountService.user$.subscribe(u => {
              this.email = (u && u !== null) ? u.email : "";
              // this._loggedIn = u && u !== null;
              this._loggedIn = !this.accountService.getisLoginExpired();
              // console.log("*** quote-form accountService.user$ subscription _loggedIn=" + this._loggedIn);
              if (this._loggedIn)
                this.handleNewEmail();
            })
          );
        } else {
          this.handleNewEmail();
        }
      })
    );
    this.subscriptions.push(
      this.accessorialService.accessorials$.subscribe(a => {
        this._accessorials = a;
      })
    );
  }

  private isFilledLocation(l: Location) {
    return !!(l && l.PostalCode && l.PostalCode !== "");
  }
  setDefaultBillingAddress() {
    if (Array.isArray(this._addresses) && this._addresses.findIndex(a => a.IsDefaultBillTo) > -1) {
      this.quote.BillToLocation = Object.assign(
        {},
        this._addresses.find(a => a.IsDefaultBillTo)
      );
      this.hasDefaultBilling = true;
      console.log(this.quote.BillToLocation);
    }
  }
  selectOriginAddress(a: Location) {
    this.quote.Shipper = Object.assign({}, a);
    this.validateOriginZip(false);
  }

  selectConsigneeAddress(a: Location) {
    this.quote.Consignee = Object.assign({}, a);
    this.validateDestinationZip(false);
  }

  get originAddresses() {
    if (!Array.isArray(this._addresses))
      return null;
    return this._addresses
      .filter(a => a.IsShipper && a.IsDefaultBillTo)
      .concat(
        this._addresses
          .filter(a => a.IsShipper && !a.IsDefaultBillTo)
          .sort((n1, n2) => {
            if (n1.Name.toUpperCase() > n2.Name.toUpperCase()) {
              return 1;
            }

            if (n1.Name.toUpperCase() < n2.Name.toUpperCase()) {
              return -1;
            }

            return 0;
          })
      );
  }

  get consigneeAddresses() {
    if (!Array.isArray(this._addresses))
      return null;
    return this._addresses
      .filter(a => a.IsConsignee && a.IsDefaultBillTo)
      .concat(
        this._addresses
          .filter(a => a.IsConsignee && !a.IsDefaultBillTo)
          .sort((n1, n2) => {
            if (n1.Name.toUpperCase() > n2.Name.toUpperCase()) {
              return 1;
            }

            if (n1.Name.toUpperCase() < n2.Name.toUpperCase()) {
              return -1;
            }

            return 0;
          })
      );
  }

  private handleNewEmail() {
    if (
      !this.quote.Shipper.ContactEmail ||
      this.quote.Shipper.ContactEmail === ""
    )
      this.quote.Shipper.ContactEmail = this.email;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get accessorials() {
    return this._accessorials.filter(a => {
      return this.quote.AccessorialAR.map(ac => ac.Type).indexOf(a.Type) === -1;
    });
  }

  selectAccessorial(a: Accessorial) {
    // console.log(a);
    this.quote.AccessorialAR.push(a);
  }

  removeAccessorial(s: string) {
    const idx = this.quote.AccessorialAR.findIndex(a => a.Type === s);
    this.quote.AccessorialAR.splice(idx, 1);
  }

  addFreightItem() {
    this.quote.Commodities.push(new Commodity());
  }

  removeItem(e: Commodity) {
    this.quote.Commodities.splice(this.quote.Commodities.indexOf(e), 1);
    if (this.quote.Commodities.length === 0) {
      this.quote.Commodities.push(new Commodity());
    }
  }

  catchOriginalOriginZip() {
    this.shipperZip = this.quote.Shipper.PostalCode;
  }

  validateOriginZip(resetAddress = true) {
    if (this.quote.Shipper.PostalCode === "") {
      this.zips.shipper.isValid = false;
      return false;
    }
    // Check for valid canadian that may fail in google api and clean it if needed
    const regCan = new RegExp(
      "^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][-s]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$",
      "i"
    );
    const regStrictCan = new RegExp(
      "^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][s][0-9][ABCEGHJ-NPRSTV-Z][0-9]$",
      "i"
    );
    const pc = this.quote.Shipper.PostalCode.toUpperCase();
    if (pc !== this.quote.Shipper.PostalCode) {
      this.quote.Shipper.PostalCode = pc;
    }
    if (regCan.test(pc) === true && regStrictCan.test(pc) === false) {
      this.quote.Shipper.PostalCode =
        pc.substr(0, 3) + " " + pc.substr(pc.length - 3, 3);
      this.quote.Shipper.CountryString = "CAN";
    }
    this.zips.shipper.loading = true;
    this.zipService
      .checkZip(this.quote.Shipper.PostalCode)
      .pipe(catchError(err => of(err)))
      .subscribe(
        (res: any) => {
          res = JSON.parse(res);
          this.zips.shipper.loading = false;
          if (res.err && res.err !== "") {
            this.zips.shipper.isValid = false;
            return;
          }
          if (resetAddress) {
            this.quote.Shipper = Object.assign(new Location(), {
              PostalCode: this.quote.Shipper.PostalCode,
              CountryString: this.quote.Shipper.CountryString
            });
          }

          let cityIndex = res.cities.indexOf(this.quote.Shipper.City);

          if (cityIndex > -1)
            this.quote.Shipper.City = res.cities[cityIndex];
          else
            this.quote.Shipper.City = res.cities[0];

          this.quote.Shipper.StateOrProvince = res.stateprov;
          this.quote.Shipper.CountryString = res.country;
          console.log("shipper", this.quote.Shipper);
          this.zips.shipper.isValid = true;
          return;
        },
        (err: any) => {
          this.zips.shipper.loading = false;
          this.zips.shipper.isValid = false;
        }
      );
  }

  catchOriginalDestinationZip() {
    this.consigneeZip = this.quote.Consignee.PostalCode;
  }

  validateDestinationZip(resetAddress = true) {
    if (this.quote.Consignee.PostalCode === "") {
      this.zips.consignee.isValid = false;
      return false;
    }
    // Check for valid canadian that may fail in google api and clean it if needed
    const regCan = new RegExp(
      "^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][-s]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$",
      "i"
    );
    const regStrictCan = new RegExp(
      "^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][s][0-9][ABCEGHJ-NPRSTV-Z][0-9]$",
      "i"
    );
    const pc = this.quote.Consignee.PostalCode.toUpperCase();
    if (pc !== this.quote.Consignee.PostalCode) {
      this.quote.Consignee.PostalCode = pc;
    }
    if (regCan.test(pc) === true && regStrictCan.test(pc) === false) {
      this.quote.Consignee.PostalCode =
        pc.substr(0, 3) + " " + pc.substr(pc.length - 3, 3);
      this.quote.Consignee.CountryString = "CAN";
    }

    this.zips.consignee.loading = true;
    this.zipService
      .checkZip(this.quote.Consignee.PostalCode)
      .pipe(catchError(err => of(err)))
      .subscribe(
        (res: any) => {
          res = JSON.parse(res);
          this.zips.consignee.loading = false;
          if (res.err && res.err !== "") {
            this.zips.consignee.isValid = false;
            return;
          }
          console.log(resetAddress);
          if (resetAddress) {
            this.quote.Consignee = Object.assign(new Location(), {
              PostalCode: this.quote.Consignee.PostalCode,
              CountryString: this.quote.Consignee.CountryString
            });
          }

          let cityIndex = res.cities.indexOf(this.quote.Consignee.City);

          if (cityIndex > -1)
            this.quote.Consignee.City = res.cities[cityIndex];
          else
            this.quote.Consignee.City = res.cities[0];

          this.quote.Consignee.StateOrProvince = res.stateprov;
          this.quote.Consignee.CountryString = res.country;
          console.log("Consignee", this.quote.Consignee);

          this.zips.consignee.isValid = true;
          return;
        },
        (err: any) => {
          this.zips.consignee.loading = false;
          this.zips.consignee.isValid = false;
        }
      );
  }

  private validateCommodies(): boolean {
    let isValid = true;
    if (this.quote.Commodities.map(w => w.ValueOfGoods).reduce(this.sum) > 250000)
      isValid = false;
    else {
    this.quote.Commodities.forEach(c => {
      if (!this.validateCommodity(c)) isValid = false;
    });
    }

    return isValid;
  }

  private validateCommodity(c: Commodity) {
    let isValid = true;
    if (!this.validateCommodityNumber(c, "GrossWeight")) isValid = false;
    if (!this.validateCommodityNumber(c, "Length")) isValid = false;
    if (!this.validateCommodityNumber(c, "Width")) isValid = false;
    if (!this.validateCommodityNumber(c, "Height")) isValid = false;
    if (!this.validateCommodityNumber(c, "HandlingUnits")) isValid = false;
    if (!this.validateCommodityNumber(c, "HandlingUnits")) isValid = false;
    if (this.quote.Insurance && !this.validateCommodityProperty(c, "ValueOfGoods")) isValid = false;

    if (this.freightClasses.indexOf(c.FreightClass.toString()) === -1)
      isValid = false;
    return isValid;
  }

  private validateCommodityNumber(c: Commodity, prop: string) {
    return !(!c[prop] || c[prop] <= 0);
  }

  private validateCommodityProperty(c: Commodity, prop: string) {
    if (typeof (c[prop]) === "number")
      return !(!c[prop] || c[prop] <= 0);
    else if (typeof (c[prop]) === "string")
      return !(!c[prop] || c[prop] == '');
    else
      return false;
  }

  private validateAccessorials(): boolean {
    let isValid = true;

    if (this.includeAccessorials === undefined || this.includeAccessorials == null) {
      this.errors.acc.selected = false;
      isValid = false;
    }
    else if(this.includeAccessorials && this.quote.AccessorialAR.length < 1) {
      this.errors.acc.selected = true;
      this.errors.acc.added = false;
      isValid = false;
    }
    else {
      this.errors.acc.selected = true;
      this.errors.acc.added = true;
    }

    console.log(this.errors.acc);

    return isValid;
  }

  private validateTradeshowSelection(): boolean {
    let isValid = true;

    if (this.isTradeshow === undefined || this.isTradeshow == null) {
      this.errors.tradeshow.selected = false;
      isValid = false;
    }
    else {
      this.errors.tradeshow.selected = true;
    }

    return isValid;
  }
  
  private sum = (accumulator, val) => {
    if (!isNaN(val))
      return accumulator + val;
    else
      return accumulator;
  }

  private validateForm(): boolean {
    let isValid = true;

    isValid = !this.form.invalid && isValid;
    isValid = this.zips.consignee.isValid && isValid;
    isValid = this.zips.shipper.isValid && isValid;
    isValid = this.validateCommodies() && isValid;
    isValid = this.validateAccessorials() && isValid;
    isValid = this.validateTradeshowSelection() && isValid;

    return isValid;
  }

  onSubmit() {
    this.quote.Shipper.ContactName = (this.firstname + ' ' + this.lastname).trim();
    this.formErrorMsg = "";
    if (!this.validateForm()) {
      Object.keys(this.form.controls)
        .map(x => this.form.controls[x])
        .forEach(control => {
          control.markAsTouched();
        });
      this.touched = true;

      if(this.quote.Commodities.map(w => w.ValueOfGoods).reduce(this.sum) > 250000)
        this.formErrorMsg = "The maximum value of goods cannot exceed $250,000. Please contact RiteRouting for additional support.";
      else
      this.formErrorMsg = "Please fill out all required fields.";
        
      return false;
    }
    this.flash.dismissMessage();

    // remove insrance carrier if insurance was declined
    if(!this.quote.Insurance && this.quote.Carriers.length > 1) {
      this.quote.Carriers.pop();
    }

    this._loggedIn = !this.accountService.getisLoginExpired();
    // console.log("*** quote-form submit _loggedIn=" + this._loggedIn);
    if (
      this._loggedIn &&
      (!this.quote.JlsNo ||
        this.quote.JlsNo === "" ||
        this.quote.JlsNo === null)
    ) {
      // console.log("*** quote-form.component is creating a quote.");
      this.creating = true;
      this.quoteService.createQuote(this.quote).then(() => {
        this.creating = false;
        console.log("createQuote OK");
        this.router.navigate(["/quote", "carrier"]);
      }).catch(() => {
        /* Ignore failure since you don't need to be logged in on this page */
        console.log("createQuote Err");
        this.router.navigate(["/quote", "carrier"]);
      });
    } else if (this._loggedIn) {
      // console.log("*** quote-form.component is updating a quote.");
      this.quoteService.updateQuote(this.quote, true).then(() => {
        /* Woo hoo */
        console.log("updateQuote OK");
        this.router.navigate(["/quote", "carrier"]);
      }).catch(() => { 
        /* Ignore failure since you don't need to be logged in on this page */ 
        console.log("updateQuote Err");
        this.router.navigate(["/quote", "carrier"]);
      });
    } else {
      // Log a HubSpot tracking event with the user info provided
      var _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', window.location + '/notLoggedInAccount']);
      _hsq.push(['identify', {
          email: this.quote.Shipper.ContactEmail, 
          firstname: this.firstname,
          lastname: this.lastname,
          company: this.quote.Shipper.Name,
          phone: this.quote.Shipper.PhoneOne
        }]);
      _hsq.push(['trackPageView']);

      // console.log("*** quote-form.component is not saving a quote because we're not logged in.");
      this.router.navigate(["/quote", "carrier"]);
    }
  }

  onAccSelectionChange(e: Event, option) {
    this.errors.acc.selected = true;

    if (this.includeAccessorials == option) {
      e.preventDefault();
      return false;
    }

    this.includeAccessorials = option;

    if(!this.includeAccessorials) {
      this.quote.AccessorialAR = [];
      this.quote.AccessorialAP = [];
      if (this.quote.Carriers != null && this.quote.Carriers.length > 0) {
        this.quote.Carriers[0].AccessorialAP = [];
        this.quote.Carriers[0].AccessorialAR= [];
      }
      this.errors.acc.added = true;
    }
  }

  onTradeshowSelectionChange(e: Event, option) {
    this.errors.tradeshow.selected = true;

    if (this.isTradeshow == option) {
      e.preventDefault();
      return false;
    } 

    this.isTradeshow = option;
  }

  onInsuranceSelectionChange(e: Event, option) {
    if (this.quote.Insurance == option) {
      e.preventDefault();
      console.log(this.quote.Insurance);

      return false;
    }

    this.quote.Insurance = option;

    console.log(this.quote.Insurance);
  }
}
