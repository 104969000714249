import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes, Router } from '@angular/router';

import { AuthGuard } from '../core/auth.guard';
import { SharedModule } from './../shared/shared.module';
// import { AddressBookModule } from '../address-book';

import { AccountFormComponent } from './account-form/account-form.component';
import { AccountCreateModalComponent } from './account-create-modal/account-create-modal.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { UserFormComponent } from './user-form/user-form.component';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { ForgotPasswordModalComponent } from './forgot-password-modal/forgot-password-modal.component';
import { PasswordResetFormComponent } from './password-reset-form/password-reset-form.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccountProfileComponent } from './account-profile/account-profile.component';
import { QuotesComponent } from './quotes/quotes.component';
import { ObjectFilterPipe } from '../pipes/object-filter.pipe';
import { ObjectDateFilterPipe } from '../pipes/object-date-filter.pipe';

import { NgbDatepickerModule, NgbPaginationModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

// import { ObjectFilterPipe } from '../pipes/object-filter.pipe';
// import { ObjectDateFilterPipe } from '../pipes/object-date-filter.pipe';
// import { NgbDateENParserFormatter } from '../core/ngb-date-en-parser-formatter.model';
// import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AddressBookModule } from '../address-book';
import { QuoteHistoryComponent } from './account-profile/quote-history.component';
const routes: Routes = [
  {
    path: 'account',
    redirectTo: 'account/profile',
    pathMatch: 'full'
  },
  {
    path: 'account/quotes',
    component: QuotesComponent,
    canActivate: [AuthGuard],
    data: {
      state: 'account',
      headerImage: 'assets/images/banners/Account-lo.jpg'
    }
  },
  {
    path: 'account/profile',
    component: AccountProfileComponent,
    canActivate: [AuthGuard],
    data: {
      state: 'account',
      headerImage: 'assets/images/banners/Account-lo.jpg'
    }
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    // RouterModule,
    NgbDatepickerModule,
    NgbPaginationModule,
    NgbDropdownModule,
    // NgbModule,
    RouterModule.forChild(routes),
    AddressBookModule
  ],
  declarations: [
    AccountFormComponent,
    AccountCreateModalComponent,
    LoginModalComponent,
    LoginFormComponent,
    PasswordResetFormComponent,
    ForgotPasswordFormComponent,
    ForgotPasswordModalComponent,
    UserFormComponent,
    AccountProfileComponent,
    QuotesComponent,
    QuoteHistoryComponent
  ],
  exports: [
    AccountCreateModalComponent,
    PasswordResetFormComponent,
    ForgotPasswordModalComponent,
    LoginModalComponent,
    RouterModule
  ],
  providers: [
    ObjectFilterPipe,
    ObjectDateFilterPipe
  ]
})
export class AccountModule { }
