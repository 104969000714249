import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Shipment, OrderDateTime, OrderDateTimeType } from '../../models';
import { OrderService } from '../../services/order.service';
var ShipmentTrackingComponent = /** @class */ (function () {
    function ShipmentTrackingComponent(orderService, router) {
        this.orderService = orderService;
        this.router = router;
        this.onDocRequest = new EventEmitter();
        this.shipment = new Shipment();
        this.tracking = {};
    }
    Object.defineProperty(ShipmentTrackingComponent.prototype, "shipDate", {
        get: function () {
            return this.shipment.DateTimes.find(function (dt) { return dt.TypeID === OrderDateTimeType.ShipDate; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShipmentTrackingComponent.prototype, "orderDate", {
        get: function () {
            return this.shipment.DateTimes.find(function (dt) { return dt.TypeID === OrderDateTimeType.OrderDate; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShipmentTrackingComponent.prototype, "remainingTracking", {
        get: function () {
            return (this.tracking && this.tracking.Statuses && this.tracking.Statuses.length > 1)
                ? this.tracking.Statuses.filter(function (i, idx) { return idx > 0; }) : [];
        },
        enumerable: true,
        configurable: true
    });
    ShipmentTrackingComponent.prototype.getBOL = function () {
        this.onDocRequest.emit({ jls: this.shipment.JlsNo, carrierLine: '1', type: 'BillOfLadingRpt', idcShipper: (this.shipment.FinalMileShipment && this.shipment.Carriers[0].InsideDelivery), idcConsignee: (this.shipment.FinalMileShipment && !this.shipment.Carriers[0].InsideDelivery) });
    };
    ShipmentTrackingComponent.prototype.getLabel = function () {
        this.onDocRequest.emit({ jls: this.shipment.JlsNo, type: 'labelSampleRpt', carrierLine: '1', idcShipper: (this.shipment.FinalMileShipment && this.shipment.Carriers[0].InsideDelivery), idcConsignee: (this.shipment.FinalMileShipment && !this.shipment.Carriers[0].InsideDelivery) });
    };
    ShipmentTrackingComponent.prototype.getBoth = function () {
        this.onDocRequest.emit({ jls: this.shipment.JlsNo, type: 'BolandLabel', carrierLine: '1', idcShipper: (this.shipment.FinalMileShipment && this.shipment.Carriers[0].InsideDelivery), idcConsignee: (this.shipment.FinalMileShipment && !this.shipment.Carriers[0].InsideDelivery) });
    };
    ShipmentTrackingComponent.prototype.goBack = function () {
        this.router.navigate(['information']);
    };
    return ShipmentTrackingComponent;
}());
export { ShipmentTrackingComponent };
