import { Routes } from '@angular/router';
import { AddressBookLayoutComponent } from './address-book-layout.component';
import { AuthGuard } from '../core/auth.guard';
var ɵ0 = {
    state: 'primary',
    headerImage: 'assets/images/banners/Account-lo.jpg'
};
var routes = [
    {
        path: 'addresses',
        component: AddressBookLayoutComponent,
        canActivate: [AuthGuard],
        data: ɵ0
    },
];
var AddressBookRoutingModule = /** @class */ (function () {
    function AddressBookRoutingModule() {
    }
    return AddressBookRoutingModule;
}());
export { AddressBookRoutingModule };
export { ɵ0 };
