var Payment = /** @class */ (function () {
    function Payment() {
    }
    Object.defineProperty(Payment.prototype, "DiscountAmount", {
        get: function () {
            if (this.PromoCode == null || (this.PromoCode.ArFlatCharge == 0 && this.PromoCode.ArDiscount == 0))
                return 0;
            else
                return this.PromoCode.ArFlatCharge > 0 ? this.PromoCode.ArFlatCharge : this.round((this.Amount / (1 - this.PromoCode.ArDiscount) - this.Amount), 2);
        },
        enumerable: true,
        configurable: true
    });
    Payment.prototype.round = function (value, decimalPlaces) {
        return Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    };
    return Payment;
}());
export { Payment };
