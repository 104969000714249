<section class="container-fluid pt-4">
  <div class="row">
    <div class="col-12 pb-4">
      <h3>Quote Your Shipment<span>Carrier Details</span></h3>
    </div>
  </div>
  <div class="rt-header">
    <header>
      <div class="col-sm-12 col-md-6">
        <h4 class="pl-md-2">Provider</h4>
      </div>
      <div class="col-sm-12 col-md-2">
        <h4>Transit</h4>
      </div>
      <div class="col-sm-12 col-md-4">
        <h4>Quote</h4>
      </div>
    </header>
  </div>
  <div id="carrier-results">
    <div class="row" *ngIf="loading">
      <div class="col-12">
        <div class="d-block mt-4 mb-4">
          <rtrt-spinner [width]="'100%'" [visible]="loading" message="Pulling together estimates..."></rtrt-spinner>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!loading && (!estimates || estimates.length === 0)">
      <div class="col-12 mt-4">
        <p>Sorry, no estimates were found.</p>
      </div>
    </div>
    <div *ngIf="!loading && estimates && estimates.length > 0">
      <div *ngFor="let estimate of estimates" class="row estimate-row pb-4 pt-4 align-items-center">
        <div *ngIf="estimate.CarrierLogoFilename && estimate.CarrierLogoFilename.length > 0" class="col-sm-12 col-md-3 text-center">
          <div class="col-12 pl-md-0 pr-md-0">
            <p>
              <img [src]="estimate.Logo" style="max-width: 100%; max-height: 75px;" />
            </p>
          </div>
          <div class="col-12 carrier-rating">
            <span id="ratingStarContainer">
              <rtrt-carrier-review-stars [rating]="estimate.AverageRating"></rtrt-carrier-review-stars>
            </span>
            <a (click)="openReviews(estimate.CarrierName)" class="reviewCount">({{estimate.ReviewCount}})</a>
          </div>
        </div>
        <div *ngIf="estimate.CarrierLogoFilename && estimate.CarrierLogoFilename.length > 0" class="col-sm-12 col-md-3">
          <p>
            <strong>{{estimate.CarrierName}}</strong>
          </p>
        </div>
        <div *ngIf="!(estimate.CarrierLogoFilename && estimate.CarrierLogoFilename.length > 0)" class="col-sm-12 col-md-6">
          <div class="col-12">
            <p>
              <strong>{{estimate.CarrierName}}</strong>
            </p>
          </div>
          <div class="col-12 pl-md-0">
          <div class="col-12 col-md-6 carrier-rating">
            <span id="ratingStarContainer">
              <rtrt-carrier-review-stars [rating]="estimate.AverageRating"></rtrt-carrier-review-stars>
            </span>
            <a (click)="openReviews(estimate.CarrierName)" class="reviewCount">({{estimate.ReviewCount}})</a>
          </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-2">
          <p>{{estimate.TransitDays}} {{estimate.TransitDays > 1 ? "Days" : "Day"}}</p>
        </div>
        <div class="col-sm-12 col-md-2">
          <p>
            <strong>${{round(estimate.TotalCost, 2)}}</strong>
          </p>
        </div>
        <div class="col-sm-12 col-md-2">
          <button class="btn btn-primary book-it-button ml-2" (click)="select(estimate)">Book</button>
        </div>
        <!-- <div class="col-sm-12 col-md-2" *ngIf="(isAuthenticated)">
          <button class="btn btn-primary book-it-button ml-2" (click)="select(estimate)">Book</button>
        </div>
        <div class="col-sm-12 col-md-2" *ngIf="!(isAuthenticated)">
          <button class="btn btn-primary book-it-button ml-2" (click)="open('login')">Book</button>
        </div> -->
      </div>
    </div>
  </div>
</section>