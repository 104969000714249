import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import {
  Location,
  LocationOpenTime
} from '../models';

@Component({
  selector: 'rtrt-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss']
})
export class AddressCardComponent implements OnInit {
  @Input() address: Location;
  @Input() noDelete: boolean;
  @Output() delete: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    
  }

  get name() {
    return (this.address && this.address.Name) ? this.address.Name.toString() : 'Address';
  }

  get type() {
    return (this.address && this.address.IsConsignee) ? 'consignee' : 'shipper';
  }

  get defaults() {
    return (this.address.IsDefaultBillTo) ? 'Billing' : '';
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.delete.emit(this.address.ID);
    }, (reason) => {});
  }

}
