/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./quoting-services.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./quoting-services.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../services/meta-service.service";
var styles_QuotingServicesComponent = [i0.styles];
var RenderType_QuotingServicesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuotingServicesComponent, data: {} });
export { RenderType_QuotingServicesComponent as RenderType_QuotingServicesComponent };
function View_QuotingServicesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "text-left"], ["id", "desktopLTLDescription"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "descriptionHeader p-2 m-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Shipment weighs between 150 - 19,999 lbs "])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["1-6 non-stackable (or up to 12 stackable) pallets"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "descriptionHeader p-2 m-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Does not require an entire 48 ft or 53 ft dry van trailer "])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Shared space helps minimize cost"]))], null, null); }
export function View_QuotingServicesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "section", [["class", "page page-quoting-services"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 24, "section", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 23, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 22, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "row pt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select an option to get started!"])), (_l()(), i1.ɵeld(8, 0, null, null, 17, "div", [["class", "row justify-content-center pt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 16, "div", [["class", "col-sm-12 col-lg-6 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "button", [["class", "mb-3 serviceButton"], ["id", "btnLtlShipping"], ["style", "box-shadow: 0 0 10px 0px #666; background-position: center; background-size: cover;"]], null, [[null, "click"], [null, "mouseover"], [null, "mouseout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.routeTo("/ltl-shipping-quote") !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = ((_co.showLTL = true) !== false);
        ad = (pd_1 && ad);
    } if (("mouseout" === en)) {
        var pd_2 = ((_co.showLTL = false) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuotingServicesComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 12, "div", [["class", "text-left d-none"], ["id", "mobileLTLDescription"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "descriptionHeader p-2 m-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Shipment weighs between 150 - 19,999 lbs "])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["1-6 non-stackable (or up to 12 stackable) pallets"])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "div", [["class", "descriptionHeader p-2 m-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Does not require an entire 48 ft or 53 ft dry van trailer "])), (_l()(), i1.ɵeld(22, 0, null, null, 3, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Shared space helps minimize cost"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLTL; _ck(_v, 12, 0, currVal_0); }, null); }
export function View_QuotingServicesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-quoting-services", [], null, null, null, View_QuotingServicesComponent_0, RenderType_QuotingServicesComponent)), i1.ɵdid(1, 114688, null, 0, i3.QuotingServicesComponent, [i4.Router, i5.Title, i5.Meta, i6.MetaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuotingServicesComponentNgFactory = i1.ɵccf("rtrt-quoting-services", i3.QuotingServicesComponent, View_QuotingServicesComponent_Host_0, {}, {}, []);
export { QuotingServicesComponentNgFactory as QuotingServicesComponentNgFactory };
