export const GLOSSARY_TERMS = [
  {
    label: 'Accessorial Charges (also called "Assessorial Charges")',
    definition: `
        <p>Charges made for performing services beyond normal pickup and delivery, such as residential delivery, liftgate, inside delivery or storage charges.</p>
      `
  },
  {
    label: "Backhaul",
    definition: `
        <p>In full truckload shipping, a load picked up after the original load is delivered, that is transported back to the driver’s base of operation.</p>
      `
  },
  {
    label: "Bill of Lading (BOL or B/L)",
    definition: `
        <p>A Bill of Lading is a binding contract that serves three main purposes:</p>
        <ol>
          <li>A receipt for the goods delivered to the transportation provider for shipment</li>
          <li>A definition or description of the goods</li>
          <li>Evidence of title to the relative goods, if "negotiable"</li>
        </ol>
      `
  },
  {
    label: "Bill of Lading Exceptions",
    definition: `
        <p>The terms and conditions of most Bills of Lading release transportation providers from liability for loss or damage arising from:</p>
        <ol>
          <li>An Act of God</li>
          <li>A public enemy</li>
          <li>The authority of law</li>
          <li>The act or default of the shipper</li>
        </ol>
      `
  },
  {
    label: `Bonded Carrier`,
    definition: `
        <p>A transportation provider by <a target="_blank" href="https://www.cbp.gov/">U.S. Customs</a> to carry Customs-controlled merchandise between Customs points. <a target="_blank" href="https://yrc.com/">YRC Freight</a> for example is a bonded carrier.</p>
      `
  },
  {
    label: `Bracing`,
    definition: `
        <p>A way to secure freight in the truck during truckload freight shipments.</p>
      `
  },
  {
    label: `Broker`,
    definition: `
        <p>A broker is an independent contractor paid to arrange motor carrier transportation. A broker may work on the carrier or shipper's behalf. RiteRouting is a freight broker.</p>
      `
  },
  {
    label: `Capacity`,
    definition: `
        <p>In freight shipping, capacity is defined as the ratio of goods to be shipped to the number of trucks to move them.</p>
      `
  },
  {
    label: `Carmack`,
    definition: `
        <p>An industry term regarding loss or damage of goods. <a target="_blank" href="https://corporate.findlaw.com/business-operations/the-carmack-amendment-a-uniform-system-of-liability-for.html">The Carmack Amendment</a> is governed by 49 U.S.C 14706, which states that a motor carrier must</p>
        <ul>
          <li>Issue the Bill of Lading and</li>
          <li>Pay the actual loss or injury to the property.</li>
        </ul>
      `
  },
  {
    label: `Carrier`,
    definition: `
        <p>A company or individual truck owner that transports LTL or truckload freight.</p>
      `
  },
  {
    label: `Cartage Agent`,
    definition: `
        <p>A carrier who performs pickup or delivery in areas that LTL carriers do not serve.</p>
        <ul>
          <li>Cartage agents use their own paperwork while transporting the shipment.</li>
          <li>LTL carriers do not give shipment information while it is in the cartage agent's possession.</li>
          <li>When an LTL carrier gives a shipment to a cartage agent for delivery, the shipment is considered to be "delivered".</li>
        </ul>
      `
  },
  {
    label: `Cash on Delivery`,
    definition: `
        <p>Also known as “freight collect,” freight shipped collect must be paid for in cash when it is delivered.</p>
      `
  },
  {
    label: `Claim`,
    definition: `
        <p>Cargo: A "Cargo Claim" is a demand made on a transportation company for payment for goods allegedly lost or damaged while the shipment was in the transportation provider's possession. Pursuant to the <a target="_blank" href="http://www.nmfta.org/pages/nmfc">National Motor Freight Classification</a> (NMFC) Uniform Bill of Lading, all cargo claims must be filed within 9 months.</p>
        <p>Overcharge/Undercharge: Overcharge or undercharge claims are demands on a transportation company for a refund of an overcharge from the erroneous application of rates, weights and/or assessment of freight charges.</p>
      `
  },
  {
    label: `Collect Terms`,
    definition: `
        <p>Shipping payment terms that state that the consignee is liable for the cargo charges.</p>
      `
  },
  {
    label: `Commercial Invoice`,
    definition: `
        <p>Document from the manufacturer of goods shipped that determines an item’s value. Commercial invoices are often needed when filing a freight claim.</p>
      `
  },
  {
    label: `Commodity`,
    definition: `
        <p>Any article of commerce or goods shipped.</p>
      `
  },
  {
    label: `Common Carrier`,
    definition: `
        <p>Company that provides transportation services to the public in return for compensation.</p>
      `
  },
  {
    label: `Concealed Damage`,
    definition: `
        <p>Damage to a shipment that isn’t obvious upon delivery, such as broken items in an intact carton.</p>
      `
  },
  {
    label: `Concealed Loss`,
    definition: `
        <p>Shortage or missing freight not evident at delivery.</p>
      `
  },
  {
    label: `Consignee`,
    definition: `
        <p>The person or place where a shipment will be transferred for the last time (destination); the individual or organization to whom the goods are addressed.</p>
      `
  },
  {
    label: `Consignor`,
    definition: `
        <p>The individual or business that begins the shipment; also known as the shipper.</p>
      `
  },
  {
    label: `Corrected Bill of Lading (CBL)`,
    definition: `
        <p>A document that a shipper issues to correct the original Bill of Lading.</p>
      `
  },
  {
    label: `Cubic Capacity`,
    definition: `
        <p>The capacity of a truck or other shipping container measured in cubic volume.</p>
      `
  },
  {
    label: `Customs Broker`,
    definition: `
        <p>Broker that handles all necessary paperwork and logistics to get freight across the border. Customs services are not included in all freight brokerage services.</p>
      `
  },
  {
    label: `Deadhead`,
    definition: `
        <p>In truckload shipping, when a driver returns to a point of origin carrying no freight.</p>
      `
  },
  {
    label: `Declared Value`,
    definition: `
        <p>Value of freight as declared on the BOL at the time of pickup. Often used for claims.</p>
      `
  },
  {
    label: `Delivery Appointment`,
    definition: `
        <p>An appointment that must be made with the consignee to deliver freight at a certain time in in a specific time window.</p>
      `
  },
  {
    label: `Delivery Receipt`,
    definition: `
        <p>Document a consignee or its agent dates and signs at delivery, stating the condition of the goods at delivery. The driver takes the signed delivery receipt to the terminal for retention. The customer retains the remaining copy.</p>
      `
  },
  {
    label: `Demurrage`,
    definition: `
        <p>The confinement of a cargo vehicle past the assessed time period.</p>
      `
  },
  {
    label: `Density`,
    definition: `
        <p>Measurement of an item’s pounds per cubic foot. Density is important for determining freight quotes, and density-based freight class.</p>
      `
  },
  {
    label: `Destination Terminal`,
    definition: `
        <p>Site in the LTL carrier’s network where the shipment delivering to consignee goes out for final delivery.</p>
      `
  },
  {
    label: `Detention`,
    definition: `
        <p>Cost assessed by a carrier when a truck is held up at delivery or pickup longer than the time allotted for the delivery.</p>
      `
  },
  {
    label: `Dispatch`,
    definition: `
        <p>The act of sending a driver on his/her assigned route with instructions and required shipping papers. Carriers maintain contact with drivers throughout the day by phone, pager, radio, satellite communication or cellular phone.</p>
      `
  },
  {
    label: `Dock`,
    definition: `
        <p>A platform, generally the same height as the trailer floor, where trucks are loaded and unloaded</p>
      `
  },
  {
    label: `Drayage`,
    definition: `
        <p>The transport of goods over a short distance.</p>
      `
  },
  {
    label: `Drop Trailer`,
    definition: `
        <p>A trailer that is left at a location for pickup at a later date after it is loaded.</p>
      `
  },
  {
    label: `Dry Van`,
    definition: `
        <p>A standard truck trailer (either 48 ft. or 52 ft. long) that is neither heated nor cooled.</p>
      `
  },
  {
    label: `Electronic Data Interchange (EDI)`,
    definition: `
        <p>The electronic transmission of routine business documents, such as purchase orders, invoices and Bills of Lading, between computers in a standard format. The data formats, or transaction sets, are usually sent between mainframe computers.</p>
      `
  },
  {
    label: `ETA`,
    definition: `
        <p>Estimated Time of Arrival.</p>
      `
  },
  {
    label: `ETD`,
    definition: `
        <p>Estimated Time of Departure.</p>
      `
  },
  {
    label: `Exceptions`,
    definition: `
        <p>An exception is any delivery in which the receiver or driver notes a problem on the delivery receipt before signing it. Typically, exceptions concern shortage and/or damage.</p>
      `
  },
  {
    label: `Exclusive Use`,
    definition: `
        <p>A shipper pays a premium rate for the sole use of a trailer. The trailer will be sealed at loading, and the seal number is recorded on the manifest. The seal number is verified before the trailer is unloaded at destination. When a shipper requests an exclusive-use trailer, no other freight may be added to the unit even if space permits.</p>
      `
  },
  {
    label: `Expedited Shipment`,
    definition: `
        <p>LTL freight that is delivered faster than a standard LTL shipment for an additional fee.</p>
      `
  },
  {
    label: `Flatbed`,
    definition: `
        <p>A flat, open truck trailer.</p>
      `
  },
  {
    label: `Freight`,
    definition: `
        <p>Any product being transported.</p>
      `
  },
  {
    label: `Freight of All Kinds (FAK)`,
    definition: `
        <p>A rate agreement between shipper or broker and the carrier that groups freight of different classes into one payment group.</p>
      `
  },
  {
    label: `Freight Auditing`,
    definition: `
        <p>Process performed by some freight brokers to confirm any additional charges before passing the final freight bill on to the customer.</p>
      `
  },
  {
    label: `Freight Bill`,
    definition: `
        <p>Shipping document a carrier prepares to confirm shipment delivery and indicate payment terms (prepaid or collect). The document describes the shipment, its weight, the amount of charges and taxes and whether the bill is collect or prepaid. If the bill is prepaid, the shipper pays the shipping charges. If the bill is collect, the consignee pays the shipping charges.</p>
      `
  },
  {
    label: `Freight Broker`,
    definition: `
        <p>Any person who sells transportation without actually providing it. The term usually refers to an agent for truckload shipments, matching small shippers with carriers.</p>
      `
  },
  {
    label: `Freight Class`,
    definition: `
        <p>Freight class is an identification number assigned to all LTL freight that determines price.</p>
      `
  },
  {
    label: `Freight Collect`,
    definition: `
        <p>See Cash on Delivery.</p>
      `
  },
  {
    label: `Freight Forwarder`,
    definition: `
        <p>A freight forwarder combines less-than-truckload (LTL) or less-than-carload (LCL) shipments into carload or truckload lots. Freight forwarders are designated as common carriers. They also issue Bills of Lading and accept responsibility for goods. The term may also refer to the company that fills railroad trains with trailers.</p>
      `
  },
  {
    label: `Freight Insurance`,
    definition: `
        <p>When the shipper or broker will agree to pay a premium for coverage in case of loss or damage to freight. Most carriers offer freight liability, but it might not cover the entire value of the freight.</p>
      `
  },
  {
    label: `Gross Weight`,
    definition: `
        <p>The total weight of an item or shipment, including packaging and pallet.</p>
      `
  },
  {
    label: `Guaranteed Shipment`,
    definition: `
        <p>LTL freight shipment that is guaranteed for delivery by a certain time. An additional fee is paid for this service, and if the delivery time is not met the shipping charges are typically waived.</p>
      `
  },
  {
    label: `Handling`,
    definition: `
        <p>A factor that helps determine an item’s freight class. Items that are fragile or oversized are hard to handle, resulting in a higher freight class.</p>
      `
  },
  {
    label: `Hazardous Material`,
    definition: `
        <p>Hazardous materials are defined by the <a target="_blank" href="https://www.transportation.gov/">U.S. Department of Transportation</a> in accordance with the <a target="_blank" href="https://www.fmcsa.dot.gov/regulations/hazardous-materials/how-comply-federal-hazardous-materials-regulations">Federal Hazardous Material Law</a>. A substance or material may be designated as hazardous if the transportation of the material in a particular amount and form poses an unreasonable risk to health and safety or property. Hazardous material may include: an explosive, radioactive material, etiologic agent, flammable or combustible liquid or solid, poison, oxidizing or corrosive material, and compressed gas.</p>
      `
  },
  {
    label: `In Bond`,
    definition: `
        <p>Shipments move under bond from point of entry to an interior U.S. destination for clearance or to another border location for clearance.</p>
      `
  },
  {
    label: `Intermodal`,
    definition: `
        <p>Using multiple forms of transportation to move freight, such as van to train or shipping container.</p>
      `
  },
  {
    label: `Less-Than-Truckload (LTL)`,
    definition: `
        <p>Goods weighing less than 10,000 pounds from several shippers loaded onto one trailer. LTL carriers use a nationwide network to efficiently move goods from origin to destination.</p>
      `
  },
  {
    label: `Liftgate`,
    definition: `
        <p>A lift on the back of a truck that raises and lowers to get freight on and off the truck. Often used when a loading dock is not available.</p>
      `
  },
  {
    label: `Linehaul`,
    definition: `
        <p>Movement of goods between cities or between freight terminals, particularly between origin terminal and destination terminal (excluding pickup and delivery service).</p>
      `
  },
  {
    label: `Load Board`,
    definition: `
        <p>Online message board where freight loads are posted for carriers and brokers to schedule pickups and deliveries.</p>
      `
  },
  {
    label: `Lumping or Lumper`,
    definition: `
        <p>When the driver or a person hired helps load or unload a truck at pickup or delivery.</p>
      `
  },
  {
    label: `Manifest`,
    definition: `
        <p>A report that lists the contents of a shipment.</p>
      `
  },
  {
    label: `Minimum Charge`,
    definition: `
        <p>The lowest charge for which a shipment will be handled after discount and/or adjustment.</p>
      `
  },
  {
    label: `National Motor Freight Classification (NMFC)`,
    definition: `
        <p><a target="_blank" href="http://www.nmfta.org/pages/nmfc">Industry standard tariff</a> published by motor carriers containing rules, descriptions and rating on all products moving in commerce; used to classify goods for the purpose of rating the freight bill.</p>
      `
  },
  {
    label: `Net Weight`,
    definition: `
        <p>The weight of a shipment not including packaging.</p>
      `
  },
  {
    label: `Notify Consignee`,
    definition: `
        <p>When the carrier calls the receiver to alert them that their shipment is out for delivery.</p>
      `
  },
  {
    label: `Origin Terminal`,
    definition: `
        <p>Site where the shipment picked up from shipper first enters the LTL carrier’s network.</p>
      `
  },
  {
    label: `Overage`,
    definition: `
        <p>Number of units received is in excess of the quantity shown on shipping documents. Overages should not be delivered to a customer. They're returned to the terminal unless the terminal receives more information while the driver is making pickups and deliveries.</p>
      `
  },
  {
    label: `Overcharge Claims`,
    definition: `
        <p>The payor of the shipping charges files an overcharge claim to dispute a discrepancy in charges that can stem from overpayment, weight or description corrections, etc.</p>
      `
  },
  {
    label: `Pallet`,
    definition: `
        <p>A low, portable platform that holds freight for transportation.</p>
      `
  },
  {
    label: `Pallet Deck`,
    definition: `
        <p>A metal support that enables two pallets of LTL freight to be stacked on top of each other in a trailer.</p>
      `
  },
  {
    label: `Payment Terms`,
    definition: `
        <p>Generally, the shipper is responsible for payment for prepaid shipments, and the consignee is responsible for payment for collect shipments unless a third party is indicated as payor on the shipping papers.</p>
      `
  },
  {
    label: `Pickup and Delivery (P&D)`,
    definition: `
        <p>Local movement of goods between the shipper (or pickup point) and the origin terminal or between the destination terminal and the consignee (or delivery point).</p>
      `
  },
  {
    label: `Point of Origin`,
    definition: `
        <p>The origin of an LTL shipment.</p>
      `
  },
  {
    label: `Prepaid Terms`,
    definition: `
        <p>Freight payment terms that indicate that the shipper or a third party is liable for the cargo charges.</p>
      `
  },
  {
    label: `PRO Number`,
    definition: `
        <p>An acronym for progressive rotating order; it is a 10-digit number assigned to each shipment and serves as an identification number and usually the invoice number.</p>
      `
  },
  {
    label: `Proof of Delivery (POD)`,
    definition: `
        <p>Also known as a Delivery Receipt (DR). Document signed at the time of delivery noting damage or loss, or if the freight is delivered as expected. PODs are valuable in the filing of freight claims.</p>
      `
  },
  {
    label: `Reclass`,
    definition: `
        <p>Invoice discrepancy where the carrier invoices the shipment at a higher or lower class than indicated on the BOL.</p>
      `
  },
  {
    label: `Reefer`,
    definition: `
        <p>Truck trailer that is temperature controlled, most often refrigerated to transport perishable items.</p>
      `
  },
  {
    label: `Reweigh`,
    definition: `
        <p>Invoice discrepancy where the carrier invoices the shipment at a higher or lower weight than noted on the BOL.</p>
      `
  },
  {
    label: `Seal`,
    definition: `
        <p>Used to ensure that a trailer isn’t opened during transport.</p>
      `
  },
  {
    label: `Shipper`,
    definition: `
        <p>The origin party of an LTL freight shipment. Shippers are responsible for getting the BOL to the carrier at the time of pickup.</p>
      `
  },

  {
    label: `Shipping Documents`,
    definition: `
        <p>Papers accompanying a shipment as it moves through the freight system, including Bills of Lading, packing slips, Customs paperwork, manifests and shipment bills.</p>
      `
  },
  {
    label: `Shortage`,
    definition: `
        <p>The number of units received is less than the quantity shown on shipping documents. The outstanding units may be delivered later.</p>
      `
  },
  {
    label: `Short Shipment`,
    definition: `
        <p>When freight is missing pieces as noted on the BOL.</p>
      `
  },
  {
    label: `Step Deck`,
    definition: `
        <p>Open trailer used for larger pieces of equipment.</p>
      `
  },
  {
    label: `Tare Weight`,
    definition: `
        <p>The weight of an empty vehicle or container.</p>
      `
  },
  {
    label: `Tariff`,
    definition: `
        <p>A Tariff is a document setting forth applicable rules, rates and charges to move goods. A tariff sets forth a contract for the shipper, the consignee and the carrier. Since January 1, 1996, motor carriers are not required to publish tariffs. However, in accordance with federal law, tariffs must be provided to a shipper on request.</p>
      `
  },
  {
    label: `Terminal`,
    definition: `
        <p>Building and grounds where shipments are prepared for local delivery or transportation to other terminals.</p>
      `
  },
  {
    label: `Terms`,
    definition: `
        <p>Payment terms that determine who is responsible for paying the shipment charges.</p>
      `
  },
  {
    label: `Third-Party`,
    definition: `
        <p>A party other than the shipper or consignee that is ultimately responsible for paying the shipment charges.</p>
      `
  },
  {
    label: `Third Party Logistics Provider (3PL)`,
    definition: `
        <p>An LTL freight broker that acts as a go-between for shippers and carriers. 3PLs often have discounted freight rates and offer value-added services.</p>
      `
  },
  {
    label: `Transit Time`,
    definition: `
        <p>The time between when a shipment is picked up and delivered. Standard LTL shipments always use estimated transit times.</p>
      `
  },
  {
    label: `Transportation Management System (TMS)`,
    definition: `
        <p>A web-based tool that assists customers in scheduling pickups, creating BOLs, and tracking shipments.</p>
      `
  },
  {
    label: `Triples`,
    definition: `
        <p>Three trailers pulled by one tractor.</p>
      `
  },
  {
    label: `Truckload (TL)`,
    definition: `
        <p>Freight shipping that specializes in moving freight that takes up a full truckload of space.</p>
      `
  },
  {
    label: `UN Number`,
    definition: `
        <p>An internationally accepted 4-digit number used to identify hazardous material.</p>
      `
  },
  {
    label: `Vendor`,
    definition: `
        <p>The manufacturer or distributor of a product. Many LTL shipments are picked up directly from vendor warehouses.</p>
      `
  },
  {
    label: `Volume`,
    definition: `
        <p>LTL shipments that are larger than the standard LTL size, but smaller than a full truckload.</p>
      `
  },
  {
    label: `Warehousing`,
    definition: `
        <p>Warehousing is the storing of products.</p>
      `
  },
  {
    label: `Waybill`,
    definition: `
        <p>A "Waybill" is a non-negotiable document prepared by or on behalf of the carrier at origin. The document shows origin point, destination, route, consignor, consignee, shipment description and amount charged for the transportation service.</p>
      `
  },
  {
    label: `Weight and Inspection Certificate (W&I)`,
    definition: `
        <p>Document created by the carrier when a shipment is reweighed or reclassed. W&I certificates contain the date, location, and agent responsible for the discrepancy and are used in freight claims.</p>
      `
  }
];
