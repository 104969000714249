import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularMaterialModule } from './angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { CoreModule } from "./core/core.module";
import { PagesModule } from "./pages/pages.module";
import { AccountModule } from "./account/account.module";
import { SharedModule } from "./shared/shared.module";
import { ShipmentModule } from "./shipment/shipment.module";
import { DevModule } from "./dev/dev.module";

import { AuthGuard } from "./core/auth.guard";
import { AuthenticationService } from "./services/authentication.service";
import { AccountService } from "./services/account.service";
import { EstimateService } from "./services/estimate.service";
import { ZipService } from "./services/zip.service";
import { QuoteService } from "./services/quote.service";
import { ConfigService } from "./services/config.service";
import { AnalyticsService } from "./services/analytics.service";
import { StatusService } from "./services/status.service";
import { FormService } from "./services/form.service";
import { UserService } from "./services/user.service";
// import { AccessorialsService } from './services/accessorials.service';
import { TokenInterceptor } from "./services/token.interceptor";
import { EmailService } from "./services/email.service";
import { OrderService } from "./services/order.service";
import { ShipmentService } from "./services/shipment.service";
import { SmartyStreetsService } from "./services/smartystreets.service";
import { FinalMileService } from "./services/final-mile.service";
import { FinalMileEstimateService } from "./services/final-mile-estimate.service";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// FAKE STUFF
//import { fakeBackendProvider } from "./_mock/backend";
//import { MockBackend, MockConnection } from "@angular/http/testing";
//import { BaseRequestOptions } from "@angular/http";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { WINDOW_PROVIDERS } from "./window.provider";
import { MAT_LABEL_GLOBAL_OPTIONS } from '@angular/material';
import { BlogService } from './services/blog.service';
import { ReviewService } from './services/review.service';
//import { GooglePlacesDirective } from './shared/googlemaps/google-places.directive';


export function initConfig(config: ConfigService) {
  return () => config.load();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    AccountModule,
    AppRoutingModule,
    PagesModule,
    HttpClientModule,
    ShipmentModule,
    DevModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    })
  ],
  exports: [AppComponent],
  entryComponents: [AppComponent],
  providers: [
    WINDOW_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthenticationService,
    HttpClientModule,
    ConfigService,
    AnalyticsService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [ConfigService, HttpClientModule],
      multi: true
    },
    AccountService,
    AuthGuard,
    QuoteService,
    StatusService,
    FormService,
    UserService,
    EstimateService,
    ZipService,
    // AccessorialsService,
    EmailService,
    OrderService,
    ShipmentService,
    SmartyStreetsService,
    BlogService,
    ReviewService,
    FinalMileService,
    FinalMileEstimateService,
    { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: 'never' } }
    
    // fakeBackendProvider,
    // MockBackend,
    // BaseRequestOptions
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
