<form #resetForm="ngForm" class="container" (ngSubmit)="reset($event)">
    <div class="form-fields">
        <div class="row">
        <div class="form-group col-12 col-md-6 col-lg-4">
            <label class="form-control-label required" for="password">New Password</label>
            <input type="password" class="form-control" id="password" [(ngModel)]="password" name="password" required>
        </div>
        </div>
        <div class="row">
        <div class="form-group col-12 col-md-6 col-lg-4">
            <label class="form-control-label required" for="password2">Re-enter Password</label>
            <input type="password" class="form-control" id="password2" [(ngModel)]="password2" name="password2" required>
        </div>
        </div>
    </div>
    <div class="row pb-5">
        <div class="col-12 col-md-6 col-lg-4">
            <button type="submit" class="btn btn-primary w-100" [disabled]="spinner">Reset Password</button>
        </div>
        <div class="col-1 text-center" *ngIf="spinner">
            <rtrt-spinner [visible]="spinner"></rtrt-spinner>
        </div>
    </div>
</form>