import { OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { Subscription, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ZipService } from "../../services/zip.service";
import { ConfigService } from "../../services/config.service";
import { QuoteService } from "../../services/quote.service";
import { AccessorialService } from "../../services/accessorials.service";
import { Shipment, Commodity, Location } from "../../models";
import { FlashService } from "../../shared/flash/flash.service";
import { AccountService } from "../../services/account.service";
import { LaunchDarklyService } from "../../services/launch-darkly-service.service";
var QuoteFormComponent = /** @class */ (function () {
    function QuoteFormComponent(
    // private estimateService: EstimateService,
    zipService, quoteService, config, router, accessorialService, flash, accountService, ld) {
        this.zipService = zipService;
        this.quoteService = quoteService;
        this.config = config;
        this.router = router;
        this.accessorialService = accessorialService;
        this.flash = flash;
        this.accountService = accountService;
        this.ld = ld;
        this.freightClasses = [];
        this.touched = false;
        this.quote = new Shipment();
        this.loading = false;
        this.subscriptions = [];
        this._addresses = [];
        this.billingAddress = new Location();
        this.hasDefaultBilling = false;
        this.email = "";
        this.firstname = "";
        this.lastname = "";
        this._loggedIn = false;
        this.creating = false;
        this.zips = {
            shipper: {
                loading: false,
                isValid: false
            },
            consignee: {
                loading: false,
                isValid: false
            }
        };
        this.formErrorMsg = "";
        this.addressFilterProps = ["PostalCode"];
        this.options = [
            {
                value: true, name: 'Yes'
            },
            {
                value: false, name: 'No'
            }
        ];
        this.errors = {
            acc: {
                selected: true,
                added: true
            },
            tradeshow: {
                selected: true
            }
        };
        this.serviceOutage = false;
        this.sum = function (accumulator, val) {
            if (!isNaN(val))
                return accumulator + val;
            else
                return accumulator;
        };
        this.freightClasses = config
            .getConfig("freightClassOptions")
            .map(function (f) { return f; });
    }
    QuoteFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ld.getFlag("ServiceOutage").subscribe(function (flag) {
            _this.serviceOutage = flag;
        });
        if (this.quote.Shipper.PostalCode && !this.zips.shipper.isValid) {
            this.validateOriginZip();
        }
        if (this.quote.Consignee.PostalCode && !this.zips.consignee.isValid) {
            this.validateDestinationZip();
        }
        if (this.jls && this.jls !== null) {
            // console.log("no jls");
            this.loading = true;
            this.quoteService.loadQuote(this.jls).then(function (res) {
                _this.loading = false;
            });
        }
        this.accountService.refreshAddresses();
        this.subscriptions.push(this.accountService.address$.subscribe(function (a) {
            _this._addresses = a;
            _this.setDefaultBillingAddress();
            if (_this.shipperZip &&
                (_this.shipperZip === null || _this.shipperZip === "")) {
                _this.quote.Shipper = new Location();
            }
        }));
        this.subscriptions.push(this.accountService.user$.subscribe(function (u) {
            _this.user = u;
        }));
        this.subscriptions.push(this.accountService.loginExpired.subscribe(function (x) {
            // console.log("*** quote-form accountService.loginExpired subscription _loggedIn=" + x);
            _this._loggedIn = typeof x == 'undefined' ? false : !x;
        }));
        this.subscriptions.push(this.quoteService.quote$.subscribe(function (q) {
            _this.quote = q;
            if (_this.quote.Shipper.PostalCode)
                _this.validateOriginZip(false);
            if (_this.quote.Consignee.PostalCode)
                _this.validateDestinationZip(false);
            if (_this.quote.Commodities.length === 0)
                _this.quote.Commodities.push(new Commodity());
            if (_this.email === "") {
                _this.subscriptions.push(_this.accountService.user$.subscribe(function (u) {
                    _this.email = (u && u !== null) ? u.email : "";
                    // this._loggedIn = u && u !== null;
                    _this._loggedIn = !_this.accountService.getisLoginExpired();
                    // console.log("*** quote-form accountService.user$ subscription _loggedIn=" + this._loggedIn);
                    if (_this._loggedIn)
                        _this.handleNewEmail();
                }));
            }
            else {
                _this.handleNewEmail();
            }
        }));
        this.subscriptions.push(this.accessorialService.accessorials$.subscribe(function (a) {
            _this._accessorials = a;
        }));
    };
    QuoteFormComponent.prototype.isFilledLocation = function (l) {
        return !!(l && l.PostalCode && l.PostalCode !== "");
    };
    QuoteFormComponent.prototype.setDefaultBillingAddress = function () {
        if (Array.isArray(this._addresses) && this._addresses.findIndex(function (a) { return a.IsDefaultBillTo; }) > -1) {
            this.quote.BillToLocation = Object.assign({}, this._addresses.find(function (a) { return a.IsDefaultBillTo; }));
            this.hasDefaultBilling = true;
            console.log(this.quote.BillToLocation);
        }
    };
    QuoteFormComponent.prototype.selectOriginAddress = function (a) {
        this.quote.Shipper = Object.assign({}, a);
        this.validateOriginZip(false);
    };
    QuoteFormComponent.prototype.selectConsigneeAddress = function (a) {
        this.quote.Consignee = Object.assign({}, a);
        this.validateDestinationZip(false);
    };
    Object.defineProperty(QuoteFormComponent.prototype, "originAddresses", {
        get: function () {
            if (!Array.isArray(this._addresses))
                return null;
            return this._addresses
                .filter(function (a) { return a.IsShipper && a.IsDefaultBillTo; })
                .concat(this._addresses
                .filter(function (a) { return a.IsShipper && !a.IsDefaultBillTo; })
                .sort(function (n1, n2) {
                if (n1.Name.toUpperCase() > n2.Name.toUpperCase()) {
                    return 1;
                }
                if (n1.Name.toUpperCase() < n2.Name.toUpperCase()) {
                    return -1;
                }
                return 0;
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuoteFormComponent.prototype, "consigneeAddresses", {
        get: function () {
            if (!Array.isArray(this._addresses))
                return null;
            return this._addresses
                .filter(function (a) { return a.IsConsignee && a.IsDefaultBillTo; })
                .concat(this._addresses
                .filter(function (a) { return a.IsConsignee && !a.IsDefaultBillTo; })
                .sort(function (n1, n2) {
                if (n1.Name.toUpperCase() > n2.Name.toUpperCase()) {
                    return 1;
                }
                if (n1.Name.toUpperCase() < n2.Name.toUpperCase()) {
                    return -1;
                }
                return 0;
            }));
        },
        enumerable: true,
        configurable: true
    });
    QuoteFormComponent.prototype.handleNewEmail = function () {
        if (!this.quote.Shipper.ContactEmail ||
            this.quote.Shipper.ContactEmail === "")
            this.quote.Shipper.ContactEmail = this.email;
    };
    QuoteFormComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    Object.defineProperty(QuoteFormComponent.prototype, "accessorials", {
        get: function () {
            var _this = this;
            return this._accessorials.filter(function (a) {
                return _this.quote.AccessorialAR.map(function (ac) { return ac.Type; }).indexOf(a.Type) === -1;
            });
        },
        enumerable: true,
        configurable: true
    });
    QuoteFormComponent.prototype.selectAccessorial = function (a) {
        // console.log(a);
        this.quote.AccessorialAR.push(a);
    };
    QuoteFormComponent.prototype.removeAccessorial = function (s) {
        var idx = this.quote.AccessorialAR.findIndex(function (a) { return a.Type === s; });
        this.quote.AccessorialAR.splice(idx, 1);
    };
    QuoteFormComponent.prototype.addFreightItem = function () {
        this.quote.Commodities.push(new Commodity());
    };
    QuoteFormComponent.prototype.removeItem = function (e) {
        this.quote.Commodities.splice(this.quote.Commodities.indexOf(e), 1);
        if (this.quote.Commodities.length === 0) {
            this.quote.Commodities.push(new Commodity());
        }
    };
    QuoteFormComponent.prototype.catchOriginalOriginZip = function () {
        this.shipperZip = this.quote.Shipper.PostalCode;
    };
    QuoteFormComponent.prototype.validateOriginZip = function (resetAddress) {
        var _this = this;
        if (resetAddress === void 0) { resetAddress = true; }
        if (this.quote.Shipper.PostalCode === "") {
            this.zips.shipper.isValid = false;
            return false;
        }
        // Check for valid canadian that may fail in google api and clean it if needed
        var regCan = new RegExp("^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][-s]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$", "i");
        var regStrictCan = new RegExp("^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][s][0-9][ABCEGHJ-NPRSTV-Z][0-9]$", "i");
        var pc = this.quote.Shipper.PostalCode.toUpperCase();
        if (pc !== this.quote.Shipper.PostalCode) {
            this.quote.Shipper.PostalCode = pc;
        }
        if (regCan.test(pc) === true && regStrictCan.test(pc) === false) {
            this.quote.Shipper.PostalCode =
                pc.substr(0, 3) + " " + pc.substr(pc.length - 3, 3);
            this.quote.Shipper.CountryString = "CAN";
        }
        this.zips.shipper.loading = true;
        this.zipService
            .checkZip(this.quote.Shipper.PostalCode)
            .pipe(catchError(function (err) { return of(err); }))
            .subscribe(function (res) {
            res = JSON.parse(res);
            _this.zips.shipper.loading = false;
            if (res.err && res.err !== "") {
                _this.zips.shipper.isValid = false;
                return;
            }
            if (resetAddress) {
                _this.quote.Shipper = Object.assign(new Location(), {
                    PostalCode: _this.quote.Shipper.PostalCode,
                    CountryString: _this.quote.Shipper.CountryString
                });
            }
            var cityIndex = res.cities.indexOf(_this.quote.Shipper.City);
            if (cityIndex > -1)
                _this.quote.Shipper.City = res.cities[cityIndex];
            else
                _this.quote.Shipper.City = res.cities[0];
            _this.quote.Shipper.StateOrProvince = res.stateprov;
            _this.quote.Shipper.CountryString = res.country;
            console.log("shipper", _this.quote.Shipper);
            _this.zips.shipper.isValid = true;
            return;
        }, function (err) {
            _this.zips.shipper.loading = false;
            _this.zips.shipper.isValid = false;
        });
    };
    QuoteFormComponent.prototype.catchOriginalDestinationZip = function () {
        this.consigneeZip = this.quote.Consignee.PostalCode;
    };
    QuoteFormComponent.prototype.validateDestinationZip = function (resetAddress) {
        var _this = this;
        if (resetAddress === void 0) { resetAddress = true; }
        if (this.quote.Consignee.PostalCode === "") {
            this.zips.consignee.isValid = false;
            return false;
        }
        // Check for valid canadian that may fail in google api and clean it if needed
        var regCan = new RegExp("^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][-s]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$", "i");
        var regStrictCan = new RegExp("^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][s][0-9][ABCEGHJ-NPRSTV-Z][0-9]$", "i");
        var pc = this.quote.Consignee.PostalCode.toUpperCase();
        if (pc !== this.quote.Consignee.PostalCode) {
            this.quote.Consignee.PostalCode = pc;
        }
        if (regCan.test(pc) === true && regStrictCan.test(pc) === false) {
            this.quote.Consignee.PostalCode =
                pc.substr(0, 3) + " " + pc.substr(pc.length - 3, 3);
            this.quote.Consignee.CountryString = "CAN";
        }
        this.zips.consignee.loading = true;
        this.zipService
            .checkZip(this.quote.Consignee.PostalCode)
            .pipe(catchError(function (err) { return of(err); }))
            .subscribe(function (res) {
            res = JSON.parse(res);
            _this.zips.consignee.loading = false;
            if (res.err && res.err !== "") {
                _this.zips.consignee.isValid = false;
                return;
            }
            console.log(resetAddress);
            if (resetAddress) {
                _this.quote.Consignee = Object.assign(new Location(), {
                    PostalCode: _this.quote.Consignee.PostalCode,
                    CountryString: _this.quote.Consignee.CountryString
                });
            }
            var cityIndex = res.cities.indexOf(_this.quote.Consignee.City);
            if (cityIndex > -1)
                _this.quote.Consignee.City = res.cities[cityIndex];
            else
                _this.quote.Consignee.City = res.cities[0];
            _this.quote.Consignee.StateOrProvince = res.stateprov;
            _this.quote.Consignee.CountryString = res.country;
            console.log("Consignee", _this.quote.Consignee);
            _this.zips.consignee.isValid = true;
            return;
        }, function (err) {
            _this.zips.consignee.loading = false;
            _this.zips.consignee.isValid = false;
        });
    };
    QuoteFormComponent.prototype.validateCommodies = function () {
        var _this = this;
        var isValid = true;
        if (this.quote.Commodities.map(function (w) { return w.ValueOfGoods; }).reduce(this.sum) > 250000)
            isValid = false;
        else {
            this.quote.Commodities.forEach(function (c) {
                if (!_this.validateCommodity(c))
                    isValid = false;
            });
        }
        return isValid;
    };
    QuoteFormComponent.prototype.validateCommodity = function (c) {
        var isValid = true;
        if (!this.validateCommodityNumber(c, "GrossWeight"))
            isValid = false;
        if (!this.validateCommodityNumber(c, "Length"))
            isValid = false;
        if (!this.validateCommodityNumber(c, "Width"))
            isValid = false;
        if (!this.validateCommodityNumber(c, "Height"))
            isValid = false;
        if (!this.validateCommodityNumber(c, "HandlingUnits"))
            isValid = false;
        if (!this.validateCommodityNumber(c, "HandlingUnits"))
            isValid = false;
        if (this.quote.Insurance && !this.validateCommodityProperty(c, "ValueOfGoods"))
            isValid = false;
        if (this.freightClasses.indexOf(c.FreightClass.toString()) === -1)
            isValid = false;
        return isValid;
    };
    QuoteFormComponent.prototype.validateCommodityNumber = function (c, prop) {
        return !(!c[prop] || c[prop] <= 0);
    };
    QuoteFormComponent.prototype.validateCommodityProperty = function (c, prop) {
        if (typeof (c[prop]) === "number")
            return !(!c[prop] || c[prop] <= 0);
        else if (typeof (c[prop]) === "string")
            return !(!c[prop] || c[prop] == '');
        else
            return false;
    };
    QuoteFormComponent.prototype.validateAccessorials = function () {
        var isValid = true;
        if (this.includeAccessorials === undefined || this.includeAccessorials == null) {
            this.errors.acc.selected = false;
            isValid = false;
        }
        else if (this.includeAccessorials && this.quote.AccessorialAR.length < 1) {
            this.errors.acc.selected = true;
            this.errors.acc.added = false;
            isValid = false;
        }
        else {
            this.errors.acc.selected = true;
            this.errors.acc.added = true;
        }
        console.log(this.errors.acc);
        return isValid;
    };
    QuoteFormComponent.prototype.validateTradeshowSelection = function () {
        var isValid = true;
        if (this.isTradeshow === undefined || this.isTradeshow == null) {
            this.errors.tradeshow.selected = false;
            isValid = false;
        }
        else {
            this.errors.tradeshow.selected = true;
        }
        return isValid;
    };
    QuoteFormComponent.prototype.validateForm = function () {
        var isValid = true;
        isValid = !this.form.invalid && isValid;
        isValid = this.zips.consignee.isValid && isValid;
        isValid = this.zips.shipper.isValid && isValid;
        isValid = this.validateCommodies() && isValid;
        isValid = this.validateAccessorials() && isValid;
        isValid = this.validateTradeshowSelection() && isValid;
        return isValid;
    };
    QuoteFormComponent.prototype.onSubmit = function () {
        var _this = this;
        this.quote.Shipper.ContactName = (this.firstname + ' ' + this.lastname).trim();
        this.formErrorMsg = "";
        if (!this.validateForm()) {
            Object.keys(this.form.controls)
                .map(function (x) { return _this.form.controls[x]; })
                .forEach(function (control) {
                control.markAsTouched();
            });
            this.touched = true;
            if (this.quote.Commodities.map(function (w) { return w.ValueOfGoods; }).reduce(this.sum) > 250000)
                this.formErrorMsg = "The maximum value of goods cannot exceed $250,000. Please contact RiteRouting for additional support.";
            else
                this.formErrorMsg = "Please fill out all required fields.";
            return false;
        }
        this.flash.dismissMessage();
        // remove insrance carrier if insurance was declined
        if (!this.quote.Insurance && this.quote.Carriers.length > 1) {
            this.quote.Carriers.pop();
        }
        this._loggedIn = !this.accountService.getisLoginExpired();
        // console.log("*** quote-form submit _loggedIn=" + this._loggedIn);
        if (this._loggedIn &&
            (!this.quote.JlsNo ||
                this.quote.JlsNo === "" ||
                this.quote.JlsNo === null)) {
            // console.log("*** quote-form.component is creating a quote.");
            this.creating = true;
            this.quoteService.createQuote(this.quote).then(function () {
                _this.creating = false;
                console.log("createQuote OK");
                _this.router.navigate(["/quote", "carrier"]);
            }).catch(function () {
                /* Ignore failure since you don't need to be logged in on this page */
                console.log("createQuote Err");
                _this.router.navigate(["/quote", "carrier"]);
            });
        }
        else if (this._loggedIn) {
            // console.log("*** quote-form.component is updating a quote.");
            this.quoteService.updateQuote(this.quote, true).then(function () {
                /* Woo hoo */
                console.log("updateQuote OK");
                _this.router.navigate(["/quote", "carrier"]);
            }).catch(function () {
                /* Ignore failure since you don't need to be logged in on this page */
                console.log("updateQuote Err");
                _this.router.navigate(["/quote", "carrier"]);
            });
        }
        else {
            // Log a HubSpot tracking event with the user info provided
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', window.location + '/notLoggedInAccount']);
            _hsq.push(['identify', {
                    email: this.quote.Shipper.ContactEmail,
                    firstname: this.firstname,
                    lastname: this.lastname,
                    company: this.quote.Shipper.Name,
                    phone: this.quote.Shipper.PhoneOne
                }]);
            _hsq.push(['trackPageView']);
            // console.log("*** quote-form.component is not saving a quote because we're not logged in.");
            this.router.navigate(["/quote", "carrier"]);
        }
    };
    QuoteFormComponent.prototype.onAccSelectionChange = function (e, option) {
        this.errors.acc.selected = true;
        if (this.includeAccessorials == option) {
            e.preventDefault();
            return false;
        }
        this.includeAccessorials = option;
        if (!this.includeAccessorials) {
            this.quote.AccessorialAR = [];
            this.quote.AccessorialAP = [];
            if (this.quote.Carriers != null && this.quote.Carriers.length > 0) {
                this.quote.Carriers[0].AccessorialAP = [];
                this.quote.Carriers[0].AccessorialAR = [];
            }
            this.errors.acc.added = true;
        }
    };
    QuoteFormComponent.prototype.onTradeshowSelectionChange = function (e, option) {
        this.errors.tradeshow.selected = true;
        if (this.isTradeshow == option) {
            e.preventDefault();
            return false;
        }
        this.isTradeshow = option;
    };
    QuoteFormComponent.prototype.onInsuranceSelectionChange = function (e, option) {
        if (this.quote.Insurance == option) {
            e.preventDefault();
            console.log(this.quote.Insurance);
            return false;
        }
        this.quote.Insurance = option;
        console.log(this.quote.Insurance);
    };
    return QuoteFormComponent;
}());
export { QuoteFormComponent };
