import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class FormService {

  constructor(private http: HttpClient) { }

  getAccessorials() {
  }

  getItemAccessorials() {
  }

}
