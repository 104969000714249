import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { Location } from '../models';
import { AccountService } from '../services/account.service';
import { ObjectFilterPipe } from '../pipes/object-filter.pipe';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../services/meta-service.service';
import { ADDRESS_META } from '../pages/meta';

@Component({
  selector: 'rtrt-address-book-layout',
  templateUrl: './address-book-layout.component.html',
  styleUrls: ['./address-book-layout.component.scss']
})
export class AddressBookLayoutComponent implements OnInit, OnDestroy {
  @Input() headerBg = '';
  subscriptions: Subscription[] = [];
  activeRoute = '';
  loading = true;
  view = 'shipper';
  search = '';
  destinations: Location[] = [];
  shippers: Location[] = [];
  addressFilterProps = ['AddressLineOne', 'City', 'Name', 'PostalCode', 'StateOrProvince', 'PhoneOne'];

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private objectFilter: ObjectFilterPipe,
    private meta: Meta,
    private metaService: MetaService,
    private title: Title
  ) {
    this.subscriptions.push(
      this.route.data.subscribe((data) => {
        this.headerBg = data.headerImage;
      })
    );
  }

  onDelete(id: number) {
    this.accountService.deleteAddress(id);
  }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.title.setTitle(ADDRESS_META.title);
    this.metaService.removeItemprop();
    ADDRESS_META.meta.forEach(m => this.meta.updateTag(m));

    this.accountService.refreshAddresses();
    this.subscriptions.push(
      this.accountService.address$
        .subscribe(a => {
          this.destinations = a.filter(ad => ad.IsConsignee && ad.IsDefaultBillTo).concat(a.filter(ad => ad.IsConsignee && !ad.IsDefaultBillTo).sort((n1, n2) => {
            if (n1.Name.toUpperCase() > n2.Name.toUpperCase()) {
              return 1;
            }

            if (n1.Name.toUpperCase() < n2.Name.toUpperCase()) {
              return -1;
            }

            return 0;
          }));
          this.shippers = a.filter(ad => ad.IsShipper && ad.IsDefaultBillTo).concat(a.filter(ad => ad.IsShipper && !ad.IsDefaultBillTo).sort((n1, n2) => {
            if (n1.Name.toUpperCase() > n2.Name.toUpperCase() ) {
              return 1;
            }

            if (n1.Name.toUpperCase() < n2.Name.toUpperCase() ) {
              return -1;
            }

            return 0;
          }));
          this.loading = false;
        })
    );
  }

  get type() {
    return (this.view === 'consignee' ? 'consignee' : 'shipper');
  }

  get activeRouteLower() {
    return this.activeRoute.toLowerCase();
  }

  switchView(s: string) {
    this.view = s;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  
  compare(a, b) {
  if (a > b) return 1;
  if (b > a) return -1;

  return 0;
}

}
