import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, merge } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { QuoteService } from '../../services/quote.service';
import { Reference } from '../../models';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
var ReferenceSelectorComponent = /** @class */ (function () {
    function ReferenceSelectorComponent(quoteService, router, authService) {
        var _this = this;
        this.quoteService = quoteService;
        this.router = router;
        this.authService = authService;
        this._references = [];
        this.selected = null;
        this.value = '';
        this.quoteReady = false;
        this.subscriptions = [];
        this.focus$ = new Subject();
        this.click$ = new Subject();
        this.search = function (text$) {
            var debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
            var clicksWithClosedPopup$ = _this.click$.pipe(filter(function () { return !_this.instance.isPopupOpen(); }));
            var inputFocus$ = _this.focus$;
            return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(map(function (term) { return _this.references.filter(function (f) { return new RegExp(term, 'mi').test(f.Value.toString()); }); }));
        };
        this.formatter = function (x) { return x.Value; };
        this.subscriptions.push(this.quoteService.quote$
            .subscribe(function (q) {
            _this.quote = q;
            _this.quoteReady = _this.quoteService.readyForPayment;
        }));
        this.subscriptions.push(this.quoteService.references$
            .subscribe(function (r) { return _this._references = r; }));
    }
    ReferenceSelectorComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(ReferenceSelectorComponent.prototype, "selectedRef", {
        get: function () {
            var _this = this;
            if (!this.selected || !this.references || this.references.length === 0 || this.selected === null)
                return 'Please Select';
            var ref = this.references.find(function (r) { return r.ID === _this.selected.ID; });
            return (!ref) ? 'Please Select' : ref.Value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReferenceSelectorComponent.prototype, "references", {
        get: function () {
            var _this = this;
            return this._references.filter(function (r) { return !_this.quote.References.map(function (qr) { return qr.TypeID; }).includes(r.ID); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReferenceSelectorComponent.prototype, "isAddReferenceDisabled", {
        get: function () {
            return (this.selected === null || this.value === '');
        },
        enumerable: true,
        configurable: true
    });
    ReferenceSelectorComponent.prototype.selectRef = function (r) {
        this.selected = r;
    };
    ReferenceSelectorComponent.prototype.validateRefType = function (input) {
        this.ref.control.markAsTouched();
        if (input !== '') {
            if (this.references.map(function (f) { return f.Value; }).indexOf(this.selected.Value) === -1) {
                this.ref.control.setErrors({ 'invalid': true });
            }
            else
                this.ref.control.setErrors(null);
        }
    };
    ReferenceSelectorComponent.prototype.addReference = function () {
        if (!this.authService.isLoggedIn())
            return;
        var r = new Reference();
        r.TypeID = this.selected.ID;
        r.ReferenceType = this.selected.Value;
        r.ReferenceValue = this.value;
        this.quote.References.push(r);
        this.selected = null;
        this.value = '';
    };
    ReferenceSelectorComponent.prototype.removeReference = function (refid) {
        if (refid === void 0) { refid = ''; }
        if (!this.authService.isLoggedIn())
            return;
        var idx = this.quote.References.findIndex(function (r) { return r.TypeID === refid; });
        if (idx && idx !== null && idx > -1) {
            this.quote.References.splice(idx, 1);
        }
    };
    ReferenceSelectorComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    ReferenceSelectorComponent.prototype.handleReferenceCompletion = function () {
        if (!this.authService.isLoggedIn())
            return;
        if (this.selectedRef !== 'Please Select' && this.value !== '' && this.value !== null) {
            this.addReference();
        }
        this.quoteService.updateQuote(this.quote, true, null, null, false);
        var step = 'carrier';
        if (this.quote.Carriers
            && this.quote.Carriers.length > 0
            && this.quote.Carriers[0].CarrierName
            && this.quote.Carriers[0].CarrierName !== '')
            step = 'item-details';
        if (this.quote.Commodities
            && this.quote.Commodities.length > 0
            && this.quote.Commodities[0].CommodityDescription
            && this.quote.Commodities[0].CommodityDescription !== '')
            step = 'origin';
        if (this.quote.Shipper && this.quote.Shipper.Name && this.quote.Shipper.Name !== '')
            step = 'destination';
        if (this.quote.Consignee && this.quote.Consignee.Name && this.quote.Consignee.Name !== '')
            step = 'confirmation';
        this.router.navigate(['ltl-shipping-quote', step]);
    };
    return ReferenceSelectorComponent;
}());
export { ReferenceSelectorComponent };
