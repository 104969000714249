import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';
import { ObjectFilterPipe } from '../../pipes/object-filter.pipe';
import { ObjectDateFilterPipe } from '../../pipes/object-date-filter.pipe';
import { QuoteService } from '../../services/quote.service';
import { QUOTEHISTORY_META } from '../../pages/meta';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
var QuotesComponent = /** @class */ (function () {
    function QuotesComponent(route, quoteService, objectFilter, objectDateFilter, title, meta, metaService) {
        var _this = this;
        this.route = route;
        this.quoteService = quoteService;
        this.objectFilter = objectFilter;
        this.objectDateFilter = objectDateFilter;
        this.title = title;
        this.meta = meta;
        this.metaService = metaService;
        this.subscriptions = [];
        this.searchDate = null;
        this.search = null;
        // Pagination  //
        this.page = 1;
        route.data.subscribe(function (data) {
            _this.headerBg = data.headerImage;
        });
        this.quoteService.loadAllQuotes()
            .then(function (res) { return _this._history = res; });
    }
    QuotesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.metaService.createCanonicalURL();
        this.title.setTitle(QUOTEHISTORY_META.title);
        this.metaService.removeItemprop();
        QUOTEHISTORY_META.meta.forEach(function (m) { return _this.meta.updateTag(m); });
    };
    QuotesComponent.prototype.clearFilters = function () {
        this.search = null;
        this.searchDate = null;
    };
    Object.defineProperty(QuotesComponent.prototype, "location", {
        get: function () {
            return new Location();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuotesComponent.prototype, "history", {
        // Pagination / Filtering //
        get: function () {
            var _this = this;
            return this.dateFilteredHistory.filter(function (q, idx) { return idx >= ((_this.page - 1) * 10) && idx < _this.page * 10; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuotesComponent.prototype, "searchFilteredHistory", {
        get: function () {
            return this.objectFilter.transform(this._history, ['jlsNo', 'shipperZipCode', 'consigneeZipCode', 'mrNo'], this.search);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuotesComponent.prototype, "dateFilteredHistory", {
        get: function () {
            return this.objectDateFilter.transform(this.searchFilteredHistory, ['orderDate'], this.searchDate);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuotesComponent.prototype, "collectionSize", {
        get: function () {
            return this.dateFilteredHistory.length;
        },
        enumerable: true,
        configurable: true
    });
    // End Pagination / Filtering //
    QuotesComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    return QuotesComponent;
}());
export { QuotesComponent };
