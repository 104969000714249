import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';

import { AuthGuard } from './core/auth.guard';
import { AboutComponent } from './pages/about/about.component';
import { AccountComponent } from './pages/account/account.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { OurProcessComponent } from './pages/our-process/our-process.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
// import { QuoteComponent } from './pages/quote/quote.component';
import { ServicesComponent } from './pages/services/services.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { HelpComponent } from './pages/help/help.component';
import { TrackingComponent } from './pages/tracking/tracking.component';
import { TruckloadComponent } from './pages/truckload/truckload.component';
import { LessThanTruckloadComponent } from './pages/less-than-truckload/less-than-truckload.component';
import { PasswordResetComponent } from './pages/account/password-reset.component';
import { QuoteHistoryComponent } from './pages/quote-history/quote-history.component';
import { ProfileComponent } from './pages/account/profile.component';
// import { AddressBookComponent } from './address-book';
import { AccountProfileComponent } from './account/account-profile/account-profile.component';
import { QuotesComponent } from './account/quotes/quotes.component';
import { StarRatingComponent } from './pages/star-rating/star-rating.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogPostDetailComponent } from './pages/blog/blog-post-detail/blog-post-detail.component';
import { CarrierReview } from './models';
import { CarrierReviewsComponent } from './pages/carrier-reviews/carrier-reviews.component';
// import { FinalMileTrackingComponent } from './pages/tracking/final-mile-tracking/final-mile-tracking.component';
// import { FinalMileAboutComponent } from './pages/final-mile-about/final-mile-about.component';
import { QuotingServicesComponent } from './pages/quoting-services/quoting-services.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'final-mile',
    redirectTo: '/'
  },
  {
    path: 'final-mile/information',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'final-mile-delivery/carrier',
    redirectTo: '/', 
    pathMatch: 'full'
  },
  {
    path: 'final-mile-delivery/freight-tracking',
    redirectTo: '/', 
    pathMatch: 'full'
  },
  {
    path: 'final-mile/information/:tracking',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'final-mile-delivery/freight-tracking/:tracking',
    redirectTo: '/', 
    pathMatch: 'full'
  },
  // See src\app\final-mile\final-mile-routing.module.ts
  // {
  //   path: 'final-mile-delivery',
  //   redirectTo: '/', 
  //   pathMatch: 'full'
  // },
  {
    path: 'about-us',
    component: AboutComponent,
    data: {
      state: 'about'
      // headerImage: 'assets/images/updated_images/about.jpg'
    }
  },
  {
    path: 'blog',
    redirectTo: 'blog/page/01',
    pathMatch: 'full'
  },
  {
    path: 'blog/page/:pagenumber',
    component: BlogComponent,
    data: {
      // headerImage: 'assets/images/updated_images/banner-image.jpeg'
    }
  },
  {
    path: 'blog/:id',
    component: BlogPostDetailComponent,
    data: {
      // headerImage: 'assets/images/updated_images/banner-image.jpeg'
    }
  },
  {
    path: 'blog/tag/:tagname',
    redirectTo: 'blog/tag/:tagname/page/1',
    pathMatch: 'full'
  },
  {
    path: 'blog/tag/:tagname/page/:pagenumber',
    component: BlogComponent,
    data: {
      // headerImage: 'assets/images/updated_images/banner-image.jpeg'
    }
  },
  {
    path: 'reviews/:carrierName',
    component: CarrierReviewsComponent,
  },
  {
    path: 'rating',
    component: StarRatingComponent,
  },
  // {
  //   path: 'account',
  //   loadChildren: './account/account.module#AccountModule'
  // },
  {
    path: 'account',
    redirectTo: 'account/profile',
    pathMatch: 'full'
  },
  {
    path: 'account/profile',
    component: AccountProfileComponent,
    canActivate: [AuthGuard],
    data: {
      state: 'account'
      // ,headerImage: 'assets/images/banners/feature-header-account.jpg'
    }
  },
  {
    path: 'account/quotes',
    component: QuotesComponent,
    canActivate: [AuthGuard],
    data: {
      state: 'account'
      // ,headerImage: 'assets/images/banners/feature-header-account.jpg'
    }
  },
  {
    path: 'addresses',
    loadChildren: './address-book/address-book.module#AddressBookModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
    data: {
      state: 'account'
      // ,headerImage: 'assets/images/updated_images/Contact-us.jpg'
    }
  },
  {
    path: 'contact-us',
    component: ContactComponent,
    data: {
      state: 'contact'
      // ,headerImage: 'assets/images/updated_images/Contact-us.jpg'
    }
  },
  {
    path: 'quote-history',
    component: QuoteHistoryComponent,
    canActivate: [AuthGuard],
    data: {
      state: 'quote-history'
      // ,headerImage: 'assets/images/banners/feature-header-account.jpg'
    }
  },
  {
    path: 'our-process',
    redirectTo: 'how-to-ship-freight',
    pathMatch: 'full'
  },
  {
    path: 'our-process/:step',
    redirectTo: 'how-to-ship-freight/:step',
    pathMatch: 'full'
  },
  {
    path: 'how-to-ship-freight/:step',
    redirectTo: 'how-to-ship-freight',
    pathMatch: 'full'
  },
  {
    path: 'how-to-ship-freight',
    component: OurProcessComponent,
    data: {
      state: 'our-process'
      // ,headerImage: 'assets/images/updated_images/OUR-PROCESS.jpg'
    }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      state: 'privacy-policy'
      // ,headerImage: 'assets/images/banners/feature-header-policies.jpg'
    }
  },
  {
    path: 'ltl-freight-shipping',
    component: ServicesComponent,
    data: {
      state: 'services'
      // ,headerImage: 'assets/images/updated_images/Services.jpg'
    }
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
    data: {
      state: 'terms-conditions'
      // ,headerImage: 'assets/images/banners/feature-header-policies.jpg'
    }
  },
  {
    path: 'help',
    redirectTo: '/resources/faq',
    pathMatch: 'full'
  },
  {
    path: 'help/:step',
    redirectTo: '/resources/:step',
    pathMatch: 'full'
  },
  {
    path: 'help/:step/:jlsNo',
    redirectTo: '/resources/:step/:jlsNo',
    pathMatch: 'full'
  },
  {
    path: 'resources',
    redirectTo: '/resources/faq',
    pathMatch: 'full'
  },
  {
    path: 'resources/:step',
    component: HelpComponent,
    data: {
      state: 'help',
      headerImage: 'assets/images/updated_images/FAQ-GLOSSARY-CONTACT-US.jpg'
    }
  },
  {
    path: 'resources/:step/:jlsNo',
    component: HelpComponent,
    data: {
      state: 'help',
      headerImage: 'assets/images/updated_images/FAQ-GLOSSARY-CONTACT-US.jpg'
    }
  },
  {
    path: 'information',
    redirectTo: '/freight-tracking',
    pathMatch: 'full'
  },
  {
    path: 'freight-tracking',
    component: TrackingComponent,
    data: {
      state: 'tracking',
      headerImage: 'assets/images/banners/feature-header-account.jpg'
    }
  },
  {
    path: 'information/:tracking',
    redirectTo: '/freight-tracking/:tracking',
    pathMatch: 'full'
  },
  {
    path: 'freight-tracking/:tracking',
    component: TrackingComponent,
    data: {
      state: 'tracking',
      headerImage: 'assets/images/banners/feature-header-account.jpg'
    }
  },
  {
    path: 'truckload',
    component: TruckloadComponent,
    data: {
      state: 'truckload',
      headerImage: 'assets/images/banners/feature-header-services.jpg'
    }
  },
  {
    path: 'less-than-truckload',
    component: LessThanTruckloadComponent,
    data: {
      state: 'less-than-truckload',
      headerImage: 'assets/images/banners/feature-header-services.jpg'
    }
  },
  {
    path: 'quote',
    redirectTo: '/ltl-shipping-quote',
    pathMatch: 'full'
  },
  {
    path: 'quote/:id',
    redirectTo: '/ltl-shipping-quote/:id',
    pathMatch: 'full'
  },
  {
    path: 'ltl-shipping-quote',
    loadChildren: './quote/quote.module#QuoteModule'
  },
  {
    path: 'categories',
    component: QuotingServicesComponent,
    data: {
      state: 'quoting-services',
      headerImage: 'assets/images/banners/feature-header-services.jpg'
    }
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];
  // {
  //   path: '**',
  //   redirectTo: '/404'
  // }
// const routes: Routes = [
//   {
//     path: '',
//     loadChildren: './shipment/shipment.module#ShipmentModule'
//   }
// ];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: false }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
