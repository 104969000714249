import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";

import { ConfigService } from "./config.service";
import { Account } from "../account/account.model";
import { catchError } from "rxjs/operators";

@Injectable()
export class AuthenticationService {
  private api;
  private clientId;
  private clientSecret;
  private access_time: Date;
  private tokenExpirationDurationSeconds = 1800;
  private tokenExpirationStripeBufferSeconds = 300;
  private showExtraConsoleDebugMessages = false;
  public stripeProcessingStarted = false;
  isAuthenticated = false;
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  loginExpired: EventEmitter<boolean> = new EventEmitter();
  
  constructor(
    private config: ConfigService, 
    private http: HttpClient
  ) {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.api = this.config.getConfig("api");
    this.clientId = this.config.getConfig("client_id");
    this.clientSecret = this.config.getConfig("client_secret");
    this.tokenExpirationDurationSeconds = this.config.getConfig("token_expiration_duration_seconds");
    if (this.tokenExpirationDurationSeconds > 355)
      this.tokenExpirationStripeBufferSeconds = 300;
    else
      this.tokenExpirationStripeBufferSeconds = 120;
    this.showExtraConsoleDebugMessages = this.config.getConfig("show_extra_console_debug_messages");

    const tkn = JSON.parse(localStorage.getItem("rtrt_token"));
    if (tkn && tkn.access_time) {
      this.access_time = tkn.access_time;
    }
  }

  getFullToken() {
    const tknString = localStorage.getItem("rtrt_token");
    if (!tknString) {
      return null;
    }
    const tkn = JSON.parse(tknString);
    tkn.access_time = new Date(tkn.access_time);
    return tkn;
  }

  getToken() {
    return this.getFullToken().access_token;
  }

  getLoginHeaders(): string {
    return btoa(this.clientId + ":" + this.clientSecret);
  }

  login(token: any) {
    token.access_time = new Date();
    localStorage.setItem("rtrt_token", JSON.stringify(token));
    this.isLoggedIn();
  }

  logout(): void {
    this.isAuthenticated = false;
    localStorage.removeItem("rtrt_token");
    this.loginExpired.emit();
    this.isLoggedIn();
  }

  isLoggedIn() {
	// Pile of spagetti
    // No token => 
    //    isAuthenticated = false
    // Token expired and Stripe processing started => 
    //    isAuthenticated = false &&
    //    stripeProcessingStarted = false &&
    //    throw error
    // Stripe processing started =>
    //    isAuthenticated = true
    // Time till expired < Stripe buffer =>
    //    Renew token
    //      If renewed 
    //    If not renewed emit LoginExpired
    var oldIsAuthenticated = this.isAuthenticated;
    const tkn = this.getFullToken();
    if (tkn && tkn.access_token) {
      var secondsTillTimeout = this.getAccessTimeDiff(tkn);
      if (secondsTillTimeout < 0 && 
          this.stripeProcessingStarted == true) {
        if (this.showExtraConsoleDebugMessages)
          console.log('Token timed out while stripe was processing, this should never happen');
        if (oldIsAuthenticated !== false) {
          this.isAuthenticated = false;
          this.loginExpired.emit();
          // console.log("*** authentication.service isLoggedIn false 1");
          this.loggedIn.next(false);
        }
        this.stripeProcessingStarted = false;
        throw new Error("Token expired after Stripe processing started but before RiteRouting DB was updated.");
      } else if (this.stripeProcessingStarted == true) {
        if (this.showExtraConsoleDebugMessages)
          console.log('Stripe is processing, don\'t check token for timeout');
        if (oldIsAuthenticated !== true) {
          this.isAuthenticated = true;
          // console.log("*** authentication.service isLoggedIn true 2");
          this.loggedIn.next(true);
        }
      } else if (secondsTillTimeout < this.tokenExpirationStripeBufferSeconds) {
        if (this.showExtraConsoleDebugMessages)
          console.log('Token will time out in ' + secondsTillTimeout.toString() + ' seconds. Renewing login now to beat the rush.');
        this.validateTokenExpiry(tkn)
        .then(res => {
          if (oldIsAuthenticated !== res) {
            this.isAuthenticated = res;
            // console.log("*** authentication.service isLoggedIn " + res + " 3");
            this.loggedIn.next(res);
          }
          if (this.isAuthenticated == false) {
            this.loginExpired.emit();
          }
          // return this.isAuthenticated;
        })
        .catch(err => {
          if (oldIsAuthenticated !== false) {
            this.isAuthenticated = false;
            // console.log("*** authentication.service isLoggedIn false 4");
            this.loggedIn.next(false);
          }
          this.loginExpired.emit();
        });
        if (oldIsAuthenticated !== false) {
          this.isAuthenticated = false;
          this.loginExpired.emit();
          // console.log("*** authentication.service isLoggedIn false 5");
          this.loggedIn.next(false);
        }
      } else {
        if (this.showExtraConsoleDebugMessages)
          console.log('Token is fine, it won\'t time out for another ' + secondsTillTimeout.toString() + ' seconds.');
        if (oldIsAuthenticated !== true) {
          this.isAuthenticated = true;
          // console.log("*** authentication.service isLoggedIn true 6");
          this.loggedIn.next(true);
        }
      }
    } else {
      if (oldIsAuthenticated !== false) {
        this.isAuthenticated = false;
        this.loginExpired.emit();
        // console.log("*** authentication.service isLoggedIn false 7");
        this.loggedIn.next(false);
      }
    }
    return this.isAuthenticated;
  }

  validateTokenExpiry(tkn: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .get(this.api + "api/auth/authorize")
        .toPromise()
        .then(res => {
          resolve(true);
        })
        .catch(err => {
          resolve(false);
          //return; // throwError(err);
        });
    });
  }

  getAccessTimeDiff(tkn: any): number {
    if (tkn == null || tkn.access_time == null) {
      return -1;
    }
    const now = new Date();
    const diff = (now.getTime() - tkn.access_time.getTime()) / 1000;
    return tkn.expires_in - diff;
  }
}
