<form #checkout="ngForm" (ngSubmit)="onSubmit()" class="container-fluid">
  <div class="row">
    <div class="col-12 form-group required">
      <button type="button" class="btn btn-link float-right" (click)="toggleTestInfo()" *ngIf="!isProd">{{testToggleText}} Test Data</button>
      <label>Credit Card</label>
      <div id="card-info" #cardInfo></div>
      <div id="card-errors" role="alert" *ngIf="error" class="form-helpers">
        <small class="text-danger">{{error}}</small>
      </div>
    </div>
  </div>

  <div class="row pb-3">
    <div *ngIf="!isFinalMile" class="col-12 col-md-4">
      <label for="useOrigin" class="form-check-label non-primary-label">
        <input type="radio" name="billingType" id="useOrigin" [(ngModel)]="billingType" [value]="'origin'" (click)="updateBillingAddress('origin')"/>
        <span class="control"></span>
        Use Origin Address
      </label>
    </div>
    <div class="col-12 col-md-4">
      <label for="useEntered" class="form-check-label non-primary-label">
        <input type="radio" name="billingType" id="useEntered" [(ngModel)]="billingType" [value]="'enter'" (click)="updateBillingAddress('enter')"/>
        <span class="control"></span>
        Enter New Address
      </label>
    </div>
    <div class="col-12 col-md-4" *ngIf="hasDefaultBilling">
      <label for="useDefault" class="form-check-label non-primary-label">
        <input type="radio" name="billingType" id="useDefault" [(ngModel)]="billingType" [value]="'default'" (click)="updateBillingAddress('default')"/>
        <span class="control"></span>
        Default Bill To Address
      </label>
    </div>
  </div>

  <div class="row">
    <div class="col-6 form-group required">
      <label for="name">Name on Card</label>
      <input type="text" class="form-control" name="name" #name="ngModel" [(ngModel)]="billingAddress.ContactName" required>
      <div *ngIf="name.invalid && (name.dirty || name.touched || touched)" class="form-helpers">
        <small *ngIf="name.errors.required" class="text-danger">Name on card is required.</small>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="billingType === 'enter'">
    <div class="col-12 form-group required">
      <label for="address">Address</label>
      <input type="text" class="form-control" placeholder="Address 1" name="address" #address="ngModel" [(ngModel)]="billingAddress.AddressLineOne" required />
      <div *ngIf="address.invalid && (address.dirty || address.touched || touched)" class="form-helpers">
        <small *ngIf="address.errors.required" class="text-danger">Address is required.</small>
      </div>
    </div>
    <div class="col-12 form-group">
      <input type="text" class="form-control" placeholder="Address 2" name="address2" [(ngModel)]="billingAddress.AddressLineTwo" />
    </div>
    <div class="col-sm-6 col-lg-5 form-group required">
      <label for="city">City</label>
      <input type="text" class="form-control" name="city" #city="ngModel" [(ngModel)]="billingAddress.City" required />
      <div *ngIf="city.invalid && (city.dirty || city.touched || touched)" class="form-helpers">
        <small *ngIf="city.errors.required" class="text-danger">City is required.</small>
      </div>
    </div>
    <div class="col-sm-3 col-lg-3 form-group required">
      <label for="state">State</label>
      <select class="form-control" name="state" #state="ngModel" [(ngModel)]="billingAddress.StateOrProvince" required>
        <option *ngFor="let state of states" [value]="state.abbreviation">{{state.abbreviation}}</option>
      </select>
      <div *ngIf="state.invalid && (state.dirty || state.touched || touched)" class="form-helpers">
        <small *ngIf="state.errors.required" class="text-danger">State is required.</small>
      </div>
    </div> 
    <div class="col-sm-3 col-lg-4 form-group required">
        <label for="zip">Zip</label>
        <rtrt-spinner [visible]="zips.billing?.loading"></rtrt-spinner>
        <input type="text" class="form-control" name="zip" #zip="ngModel" [(ngModel)]="billingAddress.PostalCode" (blur)="validateZip()" required />
        <div *ngIf="zip.invalid && (zip.dirty || zip.touched || touched)" class="form-helpers">
          <small *ngIf="zip.errors.required" class="text-danger">Zip code is required.</small>
        </div>
        <div *ngIf="!zips.billing?.isValid && !zip.errors?.required" class="form-helpers">
          <small *ngIf="!zips.billing?.isValid" class="text-danger">Supplied zip code is not valid.</small>
        </div>
    </div>
    <div class="col-12 form-group required">
      <label for="email">Email Address</label>
      <input type="text" class="form-control" name="email" #email="ngModel" [(ngModel)]="billingAddress.ContactEmail" required />
      <div *ngIf="email.invalid && (email.dirty || email.touched || touched)" class="form-helpers">
        <small *ngIf="email.errors.required" class="text-danger">Email addreess is required.</small>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6 pt-4">
      <button type="submit" class="btn btn-primary w-100" [disabled]="loading">{{ buttonText }}</button>
    </div>
    <div class="col-6 pt-4">
      <rtrt-spinner [visible]="loading" message="Making sure you're good for it..."></rtrt-spinner>
    </div>
  </div>
</form>
<div id="ccNumbers" *ngIf="!isProd && showTestCards">
  <div class="container-fluid">
    <h3>Testing Cards</h3>
    <p>
      <small><a href="https://stripe.com/docs/testing" target="_blank">Provided by Stripe</a></small>
    </p>
    <div class="row" *ngFor="let card of cards">
      <div class="col-12">
        {{card.number}}
      </div>
      <div class="col-12">
        {{card.description}}
      </div>
    </div>
  </div>
</div>