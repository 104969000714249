<article class="page page-tc">
  <section class="container-fluid">
    <div class="row">
      <div class="container">
        <div class="row pt-5">
          <div class="col-12" [innerHtml]="content.copy | safeHtml"></div>
        </div>
      </div>
    </div>
  </section>
</article>