<article class="page page-blog">
  <header>
    <div class="container">
      <h1 class="text-light">Blog</h1>
    </div>
  </header>
  <section class="container-fluid">
    <div class="row">
      <div class="container">
        <div class="row pt-5">
          <div class="col-sm-12 col-md-10 pr-lg-3">
            <div class="blog-post-wrapper">
              <div class="section post-header">
                <h2>
                  <span>{{ post.BlogTitle }}</span>
                </h2>
                <div>
                  <span *ngIf="post.AuthorName">Posted by {{ post.AuthorName }} on
                    {{ post.BlogDate }}</span>
                  <span *ngIf="!post.AuthorName">Posted on {{ post.BlogDate }}</span>
                </div>
              </div>
              <br clear="all" />
              <br clear="all" />
              <div class="section post-body">
                <span>
                  <p class="blog-content">
                    <span>
                      <!-- <img *ngIf="post.BlogImage" [src]="post.Image" class="blog-image" /> -->
                      <img *ngIf="post.BlogImage" [src]="post.BlogImage" class="blog-image" width="444px" height="278px" alt="{{post.BlogTitle}}" />
                      <span [innerHTML]="post.BlogContent | safeHtml"></span>
                    </span>
                  </p>
                  <p>
                    Tags: <span *ngFor="let tag of post.BlogTags; let i = index">
                      <a [routerLink]="'/blog/tag/' + formatTag(tag) + '/page/1'"> {{tag}}</a>
                      <span *ngIf="i + 1 < post.BlogTags.length">, </span>
                    </span>
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-2 b-left-lg-secondary pl-lg-3">
            <rtrt-blog-tag-summary></rtrt-blog-tag-summary>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
