<section class="modal-body container">
  <header class="row">
    <div class="col">
      <button type="button" class="close float-right" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
      <h4 class="modal-title">Create A RiteRouting Account</h4>
    </div>
  </header>
  <rtrt-account-form (onAccountCreation)="handleAccountCreation($event)"></rtrt-account-form>
  <div class="row">
      <div class="col text-center modal-footer-text">
          <p>Already have a RiteRouting account?<br /><button type="button" class="btn btn-link" (click)="activeModal.close('login')">Login to Your Account</button></p>
      </div>
  </div>    
</section>
