import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'rtrt-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GlossaryComponent implements OnInit {
  @Input()
  terms: string;

  constructor() { }

  ngOnInit() {
  }

}
