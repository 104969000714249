<button type="button" class="btn btn-link btn-mini" (click)="open(content)">{{fn}}</button>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="fn === 'Add'">Add a new address</h4>
    <h4 class="modal-title" *ngIf="fn === 'Edit'">Edit {{address.Name}} address</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <rtrt-address-form [model]="address" (onSave)="c($event)" (onCancel)="d($event)"></rtrt-address-form>
  </div>
</ng-template>