<article class="page page-blog">
    <header>
        <div class="container">
            <h1 class="text-light">{{this.blogTagFilter.length > 0 ? this.blogTagFilter + ' BLOG' : 'Blog'}}</h1>
        </div>
    </header>
    <section class="container-fluid">
        <div class="row">
            <div class="container">
                <div class="row pt-5">
                    <div class="col-sm-12 col-md-10 pr-lg-3">
                        <div *ngFor="let post of filteredBlogs" class="post-item">
                            <rtrt-blog-post [post]="post"></rtrt-blog-post>
                        </div>
                        <div class="paginator">
                            Pages: <span *ngFor="let o of [].constructor(totalPages); let i = index;">
                                &nbsp;
                                <a *ngIf="(i + 1) !== currentPage" 
                                    [routerLink]="'/blog' + (blogTagFilter.length > 0 ? '/tag/' + blogTagFilter : '') + '/page/' + (i + 1)">{{ i + 1 }}</a>
                                <span *ngIf="(i + 1) === currentPage">{{ i + 1 }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2 b-left-lg-secondary pl-lg-3">
                        <rtrt-blog-tag-summary></rtrt-blog-tag-summary>
                    </div>
                </div>
            </div>
        </div>
    </section>
</article>