export class Reference {
  JlsNo: string;
  TypeID: string;
  ReferenceType: string;
  ReferenceValue: string;
  Pieces: string;
  Weight: string;
  ValueName: string;
  Misc: string;

  constructor() {}
}
