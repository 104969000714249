import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, FormControl, PatternValidator } from '@angular/forms';

const CC_REGEX: RegExp = /\b(?:3[47]\d{2}([\ \-]?)\d{6}\1\d|(?:(?:4\d|5[1-5]|65)\d{2}|6011)([\ \-]?)\d{4}\2\d{4}\2)\d{4}\b/i;

export function validateCreditCard(c: AbstractControl) {
  return CC_REGEX.test(c.value) ? null : {
    cc: {
      valid: false
    }
  };
}

@Directive({
    selector: '[validateCreditCard][formControlName],[validateCreditCard][formControl],[validateCreditCard][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => PatternValidator), multi: true }
    ]
})
export class CreditCardValidator implements Validator {
  constructor() {}

  validate(c: AbstractControl) {
    return validateCreditCard(c);
  }
}
