import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Input,
  EventEmitter,
  Output,
  HostListener,
  ViewChild,
  NgZone,
  AfterViewInit
} from "@angular/core";
import { Router } from "@angular/router";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { Subscription, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { QuoteService } from "../../services/quote.service";
import { AuthenticationService } from "../../services/authentication.service";
import { ConfigService } from "../../services/config.service";
import { FlashService } from "../../shared/flash/flash.service";
import { AccountService } from "../../services/account.service";

import {
  Shipment,
  Location,
  OrderDateTime,
  OrderDateTimeType,
  LocationOpenTime,
  DaysOfTheWeek,
  OpenHoursType,
  Reference
} from "../../models";
import { RtValidators } from "../../validators/rt-validators";
import { isUndefined } from "util";

import { NgbDateENParserFormatter } from "./../../helpers/ngb-date-parser-formatter";
import { NgbDatePipe } from "./../../helpers/ngb-date.pipe";
import {
  NgbTimeStruct,
  NgbDateStruct,
  NgbDatepickerConfig,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";
import { ZipService } from '../../services/zip.service';

@Component({
  selector: "rtrt-origin-form",
  templateUrl: "./origin-form.component.html",
  styleUrls: ["./origin-form.component.scss"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateENParserFormatter }
  ]
})
export class OriginFormComponent implements OnInit, AfterViewInit, OnDestroy {
  // validStreets: { label: string }[] = [
  //   { label: "1234 Test" },
  //   { label: "4321 Test" }
  // ];
  quote: Shipment;
  isAuthenticated: boolean;
  subscriptions: Subscription[] = [];
  private _pickupDate: NgbDateStruct;
  today: NgbDateStruct = null;
  form: FormGroup;
  states: any[] = [];
  searchResults: any[] = [];
  private _addresses: Location[] = [];
  originalShipper: Location;
  showAdditionalEmails = false;
  notesArr = [];
  bolNotesArr = [];
  datepickerOpen = false;
  public addrKeys: string[];
  public addr: object;
  addressSuggestions: string[];
  onAddressChange = new Subject<void>();
  cities: string[] = [];

  // Goal is to not get the toggle out of whack when the
  // user clicks outside the datepicker to close it
  @HostListener("document:click", ["$event"])
  public onDocumentClick(ev: any): void {
    if (this.datepickerOpen && ev.target.name !== "calendarIcon") {
      this.datepickerOpen = !this.datepickerOpen;
    }
  }

  constructor(
    private fb: FormBuilder,
    private quoteService: QuoteService,
    private authService: AuthenticationService,
    private config: ConfigService,
    private router: Router,
    private flash: FlashService,
    private accountService: AccountService,
    private zone: NgZone,
    private zipService: ZipService
  ) {
    this.subscriptions.push(
      this.authService.loggedIn.subscribe(res => (this.isAuthenticated = res))
    );
    this.subscriptions.push(
      this.quoteService.quote$.subscribe(q => {
        this.quote = q;
        if (
          this.quote &&
          this.quote.Shipper &&
          this.quote.Shipper.OpenHours &&
          this.quote.Shipper.OpenHours.length === 0
        ) {
          const oh = new LocationOpenTime();
          oh.Type = OpenHoursType.Shipping;
          this.quote.Shipper.OpenHours.push(oh);
          this.originalShipper = this.quote.Shipper;
        }
      })
    );
    const dt = new Date();
    this.today = {
      year: dt.getFullYear(),
      month: dt.getMonth() + 1,
      day: dt.getDate()
    };
    this.states = config.getConfig("states").filter(s => s.country == this.quote.Shipper.CountryString);
  }

  ngAfterViewInit() {
    if (
      this.quote &&
      this.quote.Shipper &&
      this.quote.Shipper.AddressLineOne &&
      !this.quote.Shipper.Validated
    ) {
      this.onAddressChange.next();
    }
  }

  ngOnInit() {
    this.accountService.refreshAddresses();
    this.subscriptions.push(
      this.accountService.address$.subscribe(a => {
        this._addresses = a;
        if (!(this.quote && this.isFilledLocation(this.quote.Shipper))) {
          this.quote.Shipper = new Location();
        }
        this.instantiateForm();
        this.onFormChanges();
      })
    );

    this.zipService
      .checkZip(this.quote.Shipper.PostalCode)
      .pipe()
      .subscribe(
        (res: any) => {
          res = JSON.parse(res);
          this.cities = res.cities;
          console.log(this.cities);
        }
      );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  handleAddressValidation(street: string) {
    if (street) {
      this.address1.setValue(street);
      this.quote.Shipper.Validated = true;
    } else {
      this.quote.Shipper.Validated = false;
    }
  }

  get startTime(): { hour: number; minute: number; second: number } {
    if (!(this.quote && this.quote.Shipper && this.quote.DateTimes))
      return null;
    const val = this.quote.DateTimes.find(
      o => o.TypeID === OrderDateTimeType.ReadyDate
    );
    if (!val) return null;
    const dt = new Date(val.DateValue);
    return {
      hour: dt.getHours(),
      minute: dt.getMinutes(),
      second: dt.getSeconds()
    };
  }

  get stopTime(): { hour: number; minute: number; second: number } {
    if (!(this.quote && this.quote.Shipper && this.quote.DateTimes))
      return null;
    const val = this.quote.DateTimes.find(
      o => o.TypeID === OrderDateTimeType.CloseDate
    );
    if (!val) return null;
    const dt = new Date(val.DateValue);
    return {
      hour: dt.getHours(),
      minute: dt.getMinutes(),
      second: dt.getSeconds()
    };
  }

  get puDate() {
    let dt: Date = null;
    if (
      this.quote &&
      this.quote.DateTimes &&
      this.quote.DateTimes.find(d => d.TypeID === OrderDateTimeType.ShipDate)
    ) {
      dt = new Date(
        this.quote.DateTimes.find(
          d => d.TypeID === OrderDateTimeType.ShipDate
        ).ShortDateString
      );
      return {
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        day: dt.getDate()
      };
    }

    dt = new Date();
    return {
      year: dt.getFullYear(),
      month: dt.getMonth() + 1,
      day: dt.getDate()
    };
  }

  instantiateForm() {
    if (this.quote.JlsNotes.search(" ---- Delivery Notes:") > -1) {
      this.notesArr = this.quote.JlsNotes.split(" ---- Delivery Notes:");
      this.notesArr[0] = this.notesArr[0].replace("Pickup Notes: ", "");
    } else if (this.quote.JlsNotes.search("Pickup Notes: ") > -1) {
      this.notesArr[0] = this.quote.JlsNotes.replace("Pickup Notes: ", "");
      this.notesArr[1] = "";
    } else {
      this.notesArr[0] = "";
      this.notesArr[1] = "";
    }

    this.bolNotesArr[0] = this.quote.CarrierNotes;

    this.form = this.fb.group({
      name: [this.quote.Shipper.Name, [Validators.required]],
      address1: [this.quote.Shipper.AddressLineOne, [Validators.required]],
      address2: [this.quote.Shipper.AddressLineTwo],
      contact: [this.quote.Shipper.ContactName, [Validators.required]],
      phone: [
        this.quote.Shipper.PhoneOne,
        [Validators.required, RtValidators.phone]
      ],
      fax: [this.quote.Shipper.Fax || ""],
      emails: this.fb.array([]),
      email: [
        this.quote.Shipper.ContactEmail,
        [Validators.required, Validators.email]
      ],
      pickupDate: [this.puDate, [Validators.required]],
      loadingStartHour: [this.startTime, [Validators.required]],
      loadingStopHour: [this.stopTime, [Validators.required]],
      bolToShipperEmail: [this.quote.EmailBolTo.length > 0],
      additionalEmail: [this.showAdditionalEmails],
      pickupInstructions: [this.notesArr[0]],
      pickupReferenceNo: [""],
      printedOnBOL: [this.bolNotesArr[0]]
    });
    this.form.get("fax").valueChanges.subscribe((fax: string) => {
      if (fax !== "" && fax !== null) {
        this.form.get("fax").setValidators([RtValidators.phone]);
      } else {
        this.form.get("fax").clearValidators();
      }
      this.form.get("fax").updateValueAndValidity({ emitEvent: false });
    });
  }

  handleAdditionalToggle() {
    const newVal = this.form.get("additionalEmail").value;
    if (newVal && this.emails.length === 0) this.addEmail();
    else
      while (this.emails.length !== 0) {
        this.emails.removeAt(0);
      }
    this.showAdditionalEmails = newVal;
  }

  createEmail(): FormGroup {
    return this.fb.group({
      email: ["", [Validators.email]]
    });
  }

  addEmail() {
    this.emails.push(this.createEmail());
  }

  removeEmail(idx: number) {
    if (this.emails.length > 1) this.emails.removeAt(idx);
  }

  private isFilledLocation(l: Location) {
    return !!(l && l.PostalCode && l.PostalCode !== "");
  }

  get showShipperClear() {
    return (
      this.isFilledLocation(this.originalShipper) &&
      this.originalShipper !== this.quote.Shipper
    );
  }

  selectAddress(a: Location) {
    this.zone.run(() => {
      this.quote.Shipper = Object.assign({}, a);
      this.instantiateForm();
      this.onFormChanges();
      this.onAddressChange.next();
    });
  }

  get addresses() {
    if (!Array.isArray(this._addresses))
      return null;
    return this._addresses
      .filter(
        a =>
          ((this.isFilledLocation(this.quote.Shipper) &&
            a.PostalCode === this.quote.Shipper.PostalCode) ||
            (this.isFilledLocation(this.originalShipper) &&
              a.PostalCode === this.originalShipper.PostalCode)) &&
          a.IsDefaultBillTo &&
          a.IsShipper
      )
      .concat(
        this._addresses
          .filter(
            a =>
              ((this.isFilledLocation(this.quote.Shipper) &&
                a.PostalCode === this.quote.Shipper.PostalCode) ||
                (this.isFilledLocation(this.originalShipper) &&
                  a.PostalCode === this.originalShipper.PostalCode)) &&
              !a.IsDefaultBillTo &&
              a.IsShipper
          )
          .sort((n1, n2) => {
            if (n1.Name.toUpperCase() > n2.Name.toUpperCase()) {
              return 1;
            }

            if (n1.Name.toUpperCase() < n2.Name.toUpperCase()) {
              return -1;
            }

            return 0;
          })
      );
  }

  onFormChanges() {
    this.form.get("loadingStartHour").valueChanges.subscribe(val => {
      if (
        val !== null &&
        (!this.loadingStopHour.value ||
          this.loadingStopHour.value === null ||
          this.loadingStopHour.value === "")
      ) {
        this.loadingStopHour.setValue({ ...val, ...{ hour: val.hour + 4 } });
      }
    });

    this.subscriptions.push(
      this.name.valueChanges.subscribe(val => {
        this.quote.Shipper.Name = val;
      })
    );

    this.subscriptions.push(
      this.address1.valueChanges.subscribe(val => {
        if (this.quote.Shipper.AddressLineOne !== val) {
          this.quote.Shipper.Validated = false;
          this.quote.Shipper.AddressLineOne = val;
          this.onAddressChange.next();
        }
      })
    );

    this.subscriptions.push(
      this.address2.valueChanges.subscribe(val => {
        this.quote.Shipper.AddressLineTwo = val;
      })
    );

    this.subscriptions.push(
      this.phone.valueChanges.subscribe(val => {
        this.quote.Shipper.PhoneOne = val;
      })
    );

    this.subscriptions.push(
      this.fax.valueChanges.subscribe(val => {
        this.quote.Shipper.Fax = val;
      })
    );

    this.subscriptions.push(
      this.contact.valueChanges.subscribe(val => {
        this.quote.Shipper.ContactName = val;
      })
    );

    this.subscriptions.push(
      this.email.valueChanges.subscribe(val => {
        this.quote.Shipper.ContactEmail = val;
      })
    );

    this.subscriptions.push(
      this.pickupDate.valueChanges.pipe(debounceTime(500)).subscribe(val => {
        if (typeof val !== "string" && val !== null) this._pushPickupDate();
      })
    );
  }

  resetForm() {
    this.instantiateForm();
  }

  handleAddresChange(e: any) {
    console.log("Address changed ... ", e);
  }

  get name() {
    return this.form.get("name");
  }
  get address1() {
    return this.form.get("address1");
  }
  get address2() {
    return this.form.get("address2");
  }
  get contact() {
    return this.form.get("contact");
  }
  get phone() {
    return this.form.get("phone");
  }
  get fax() {
    return this.form.get("fax");
  }
  get emails() {
    return this.form.get("emails") as FormArray;
  }
  get email() {
    return this.form.get("email");
  }
  get pickupDate() {
    return this.form.get("pickupDate");
  }
  get loadingStartHour() {
    return this.form.get("loadingStartHour");
  }
  get loadingStopHour() {
    return this.form.get("loadingStopHour");
  }
  get bolToShipperEmail() {
    return this.form.get("bolToShipperEmail");
  }
  get pickupInstructions() {
    return this.form.get("pickupInstructions");
  }
  get saveInstructions() {
    return this.form.get("saveInstructions");
  }
  get pickupReferenceNo() {
    return this.form.get("pickupReferenceNo");
  }
  get pickedUp() {
    return this.form.get("pickedUp");
  }
  get pickupNotes() {
    return this.notesArr[0];
  }
  set pickupNotes(val: string) {
    this.notesArr[0] = val;
  }
  get bolNotes() {
    return this.bolNotesArr[0];
  }
  set bolNotes(val: string) {
    this.bolNotesArr[0] = val;
  }

  toggleDatepicker(el: any) {
    el.toggle();
    this.datepickerOpen = !this.datepickerOpen;
  }

  onDateSelect(ev: NgbDateStruct) {
    this.datepickerOpen = !this.datepickerOpen;
  }

  private _fillQuote() {
    const originForm = this.form;
    this.quote.Shipper.Name = originForm.get("name").value;
    this.quote.Shipper.AddressLineOne = originForm.get("address1").value;
    this.quote.Shipper.AddressLineTwo =
      originForm.get("address2").value === ""
        ? null
        : originForm.get("address2").value;
    this.quote.Shipper.ContactName = originForm.get("contact").value;
    this.quote.Shipper.PhoneOne = originForm.get("phone").value;
    this.quote.Shipper.Fax = originForm.get("fax").value;
    this.quote.Shipper.ContactEmail = originForm.get("email").value;
    // this.quote.EmailBolTo = originForm.get('bolToShipperEmail').value;
    this.quote.JlsNotes = "";
    this.quote.CarrierNotes = "";
    if (originForm.get("pickupInstructions").value !== "")
      this.quote.JlsNotes +=
        "Pickup Notes: " + originForm.get("pickupInstructions").value;

    if (this.notesArr[1] !== "")
      this.quote.JlsNotes += " ---- Delivery Notes:" + this.notesArr[1];

    if (originForm.get("printedOnBOL").value !== "")
      this.quote.CarrierNotes += originForm.get("printedOnBOL").value;

    if (originForm.get("bolToShipperEmail").value)
      this.quote.EmailBolTo = this.quote.Shipper.ContactEmail;
    else this.quote.EmailBolTo = "";

    // Add additional emails if checked
    if (this.showAdditionalEmails && this.emails.length > 0) {
      this.emails.controls.forEach(control => {
        if (!control.invalid) {
          this.quote.EmailBolTo += this.quote.EmailBolTo === "" ? "" : ";";
          this.quote.EmailBolTo += control.get("email").value;
        }
      });
    }

    const loading = new LocationOpenTime();
    loading.FromHour = originForm.get("loadingStartHour").value.hour;
    loading.ToHour = originForm.get("loadingStopHour").value.hour;
    loading.FromMinute = originForm.get("loadingStartHour").value.minute;
    loading.ToMinute = originForm.get("loadingStopHour").value.minute;
    loading.Type = OpenHoursType.Shipping;

    if (
      !this.quote.Shipper.OpenHours ||
      this.quote.Shipper.OpenHours.length > 0
    )
      this.quote.Shipper.OpenHours = [];

    this.quote.Shipper.OpenHours.push(loading);
    this._pushPickupDate();
  }

  private _pushPickupDate() {
    const pDateVal = this.form.get("pickupDate").value;
    const dString = pDateVal.month + "/" + pDateVal.day + "/" + pDateVal.year;

    // Requested Delivery Date
    const pdate = new OrderDateTime(dString);
    pdate.TypeID = OrderDateTimeType.ShipDate;
    pdate.Description = "Ship Date";
    pdate.JlsNo = this.quote.JlsNo;

    this.quote.DateTimes = this.quote.DateTimes.filter(
      dt =>
        dt.TypeID !== OrderDateTimeType.ShipDate &&
        dt.TypeID !== OrderDateTimeType.ReadyDate &&
        dt.TypeID !== OrderDateTimeType.CloseDate
    ).slice();

    this.quote.DateTimes.push(pdate);

    const startHour = this.form.get("loadingStartHour").value.hour;
    const stopHour = this.form.get("loadingStopHour").value.hour;
    const startMin = this.form.get("loadingStartHour").value.minute;
    const stopMin = this.form.get("loadingStopHour").value.minute;

    // Del Ready Time
    const openDate = new OrderDateTime(
      dString + " " + startHour + ":" + startMin
    );
    openDate.TypeID = OrderDateTimeType.ReadyDate;
    openDate.JlsNo = this.quote.JlsNo;
    openDate.Description = "Ready Date";
    this.quote.DateTimes.push(openDate);

    // Del Close Time
    const closeDate = new OrderDateTime(
      dString + " " + stopHour + ":" + stopMin
    );
    closeDate.TypeID = OrderDateTimeType.CloseDate;
    closeDate.JlsNo = this.quote.JlsNo;
    closeDate.Description = "Close Date";
    this.quote.DateTimes.push(closeDate);
  }

  onSubmit() {
    if (!this.authService.isLoggedIn())
      return;
    if (!this.form.valid || !this.quote.Shipper.Validated) {
      Object.keys(this.form.controls)
        .map(x => this.form.controls[x])
        .forEach(control => {
          control.markAsTouched();
        });
      this.flash.flashMessage(
        "Please fill out all required fields.",
        "",
        "danger"
      );
      return false;
    }
    this.flash.dismissMessage();
    this._fillQuote();
    this.quoteService.updateQuote(this.quote, true, true, false);
    this.router.navigate(["quote", "destination"]);
  }
  selectCity(city: string) {
    this.quote.Shipper.City = city;
  }
}
