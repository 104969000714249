import { ConfigService } from "./services/config.service";
//import { GooglePlacesDirective } from './shared/googlemaps/google-places.directive';
export function initConfig(config) {
    return function () { return config.load(); };
}
var ɵ0 = { float: 'never' };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
