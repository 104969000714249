import * as tslib_1 from "tslib";
import { BehaviorSubject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { FinalMileShipment } from "../models";
import { ConfigService } from "./config.service";
var FinalMileService = /** @class */ (function () {
    function FinalMileService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
        this._temp = null;
        this._handlingunits = [];
        this.handlingUnits$ = new BehaviorSubject(this._handlingunits);
        this._references = [];
        this.references$ = new BehaviorSubject(this._references);
        this.api = this.config.getConfig("api");
        var quoteStr = localStorage.getItem("final-mile-quote");
        var quote = quoteStr === null ? new FinalMileShipment() : JSON.parse(quoteStr);
        //const quote = new FinalMileShipment();
        this.quote$ = new BehaviorSubject(quote);
        this._loadStoredValues();
    }
    FinalMileService.prototype.setQuote = function (s) {
        if (this.config.getEnv() !== "production")
            localStorage.setItem("final-mile-quote", JSON.stringify(s));
        this.quote$.next(s);
    };
    FinalMileService.prototype.getQuote = function () {
        return this.quote$.getValue();
    };
    FinalMileService.prototype.clearQuote = function () {
        this.setQuote(new FinalMileShipment());
    };
    FinalMileService.prototype.createQuote = function (s, saveRates) {
        var _this = this;
        if (s === void 0) { s = null; }
        if (saveRates === void 0) { saveRates = false; }
        var shipment = s === null ? this.quote$.getValue() : s;
        shipment.Vendors = shipment.Vendors.map(function (o) { return (tslib_1.__assign({}, o, { QueueStatus: 0 })); });
        console.log("shipment to save", shipment);
        return this.httpClient
            .post(this.api + "api/final-mile/save", { orders: shipment, saveShipper: false, saveConsignee: false, saveRates: saveRates })
            .pipe(map(function (res) {
            if (typeof res === "string") {
                _this.updateQuote(JSON.parse(res), false, false, false, saveRates);
            }
        }))
            .toPromise();
    };
    FinalMileService.prototype.loadQuote = function (mrNo) {
        var _this = this;
        return this.httpClient
            .get(this.api + "api/final-mile/load/" + mrNo)
            .pipe(catchError(function (err) {
            console.log(err);
            return err;
        }), map(function (res) {
            var q;
            if (typeof res === "string") {
                _this.clearQuote();
                q = JSON.parse(res);
            }
            else {
                q = res;
            }
            _this.updateQuote(q);
        }))
            .toPromise();
    };
    FinalMileService.prototype.savePayment = function () {
        return this.httpClient
            .post(this.api + "api/payment/data/save", tslib_1.__assign({}, this.quote$.getValue().PaymentData, { 'isFinalMile': true }))
            .pipe(catchError(function (err) { return throwError(err); }))
            .toPromise();
    };
    FinalMileService.prototype.orderQuote = function () {
        // const q = this.quote$.getValue();
        // q.eQuoteNumber = q.JlsNo;
        // q.JlsNo = '';
        return this.httpClient
            .post(this.api + "api/final-mile/create", { orders: this.quote$.getValue() })
            .pipe(map(function (res) { return JSON.parse(res).Vendors[0].JlsNo; }))
            .toPromise();
    };
    FinalMileService.prototype.updateQuote = function (s, save, saveShipper, saveConsignee, saveRates, generateIntent) {
        var _this = this;
        if (save === void 0) { save = false; }
        if (saveShipper === void 0) { saveShipper = false; }
        if (saveConsignee === void 0) { saveConsignee = false; }
        if (saveRates === void 0) { saveRates = false; }
        if (generateIntent === void 0) { generateIntent = false; }
        this.setQuote(tslib_1.__assign({}, this.quote$.getValue(), s));
        if (save || generateIntent) {
            return new Promise(function (resolve) {
                return _this.saveQuote(s, saveShipper, saveConsignee, saveRates, generateIntent)
                    .then(function (res) {
                    // need to reset the quote if rates were applied
                    if (typeof res === "string" && saveRates) {
                        var shipment = JSON.parse(res);
                        _this.setQuote(tslib_1.__assign({}, _this.quote$.getValue(), shipment));
                    }
                    var intent = JSON.parse(res).Vendors[0].PaymentIntentSecret;
                    _this.quote$.getValue().PaymentIntentSecret = intent;
                    // console.log("updateQuote after saveQuote end:", this.quote$.getValue());
                    // console.log("updateQuote after saveQuote return PaymentIntentSecret:", JSON.parse(res).Vendors[0].PaymentIntentSecret);
                    resolve(intent);
                });
            });
        }
    };
    FinalMileService.prototype.saveQuote = function (s, saveShipper, saveConsignee, saveRates, generateIntent) {
        if (s === void 0) { s = null; }
        if (saveShipper === void 0) { saveShipper = false; }
        if (saveConsignee === void 0) { saveConsignee = false; }
        if (saveRates === void 0) { saveRates = false; }
        if (generateIntent === void 0) { generateIntent = false; }
        var shipment = s === null ? this.quote$.getValue() : s;
        return this.httpClient
            .post(this.api + "api/final-mile/save", { orders: shipment, saveShipper: saveShipper, saveConsignee: saveConsignee, saveRates: saveRates, generateIntent: generateIntent })
            .toPromise();
    };
    FinalMileService.prototype.checkInsurance = function (valueOfGoods) {
        return this.httpClient
            .post(this.api + "api/validate/insurance", { valueOfGoods: valueOfGoods })
            .pipe(catchError(function (err) { return throwError(err); }))
            .toPromise();
    };
    FinalMileService.prototype._fetchHandlingUnits = function () {
        return this.httpClient
            .get(this.api + "api/final-mile/handlingtypes")
            .pipe(map(function (res) { return JSON.parse(res); }))
            .toPromise();
    };
    FinalMileService.prototype._fetchReferences = function () {
        return this.httpClient
            .get(this.api + "api/quotes/references")
            .pipe(map(function (res) { return JSON.parse(res); }))
            .toPromise();
    };
    FinalMileService.prototype._loadStoredValues = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // Handling Units
                    return [4 /*yield*/, this._fetchHandlingUnits().then(function (res) {
                            _this._handlingunits = res;
                            localStorage.setItem("handlingunits", JSON.stringify({ expiry: new Date(), data: _this._handlingunits }));
                        })];
                    case 1:
                        // Handling Units
                        _a.sent();
                        this.handlingUnits$.next(this._handlingunits);
                        // References
                        return [4 /*yield*/, this._fetchReferences().then(function (res) {
                                _this._references = res;
                                localStorage.setItem("references", JSON.stringify({ expiry: new Date(), data: _this._references }));
                            })];
                    case 2:
                        // References
                        _a.sent();
                        this.references$.next(this._references);
                        return [2 /*return*/];
                }
            });
        });
    };
    FinalMileService.prototype._dateDiff = function (d1, d2) {
        return Math.ceil(Math.abs(d2.getTime() - d1.getTime()) / (1000 * 3600 * 24));
    };
    FinalMileService.prototype._loadFromStorage = function (key) {
        var raw = localStorage.getItem(key);
        if (!raw || raw === null || raw === "")
            return false;
        var json = JSON.parse(raw);
        if (!json.expiry ||
            !json.data ||
            this._dateDiff(new Date(), new Date(json.expiry)) > 1)
            return false;
        return json.data;
    };
    Object.defineProperty(FinalMileService.prototype, "readyForPayment", {
        get: function () {
            var isReady = true;
            if (this.quote$ && this.quote$.getValue() !== null) {
                var q = this.quote$.getValue();
                if (q.Vendors.length === 0)
                    isReady = false;
                if (q.Vendors.some(function (v) { return v.Commodities.length === 0; }))
                    isReady = false;
                if (q.Vendors.some(function (v) { return v.Carriers.length === 0; }))
                    isReady = false;
                if (!q.Consignee.Name ||
                    q.Consignee.Name === null ||
                    q.Consignee.Name === "")
                    isReady = false;
                if (q.Vendors.some(function (v) { return (!v.Shipper.Name || v.Shipper.Name === null || v.Shipper.Name === ""); }))
                    isReady = false;
            }
            else {
                isReady = false;
            }
            return isReady;
        },
        enumerable: true,
        configurable: true
    });
    FinalMileService.prototype.loadOrder = function (jlsno) {
        return this.httpClient.get(this.api + "api/final-mile/load/" + jlsno)
            .pipe(catchError(function (err) {
            return throwError(err);
        }), map(function (res) {
            return Object.assign(new FinalMileShipment(), JSON.parse(res));
        }))
            .toPromise();
    };
    FinalMileService.prototype.loadTracking = function (jlsno) {
        return this.httpClient.get(this.api + "api/information/final-mile/load/" + jlsno)
            .pipe(catchError(function (err) {
            return throwError(err);
        }), map(function (res) { return JSON.parse(res); }))
            .toPromise();
    };
    FinalMileService.prototype.loadHistory = function () {
        return this.httpClient.get(this.api + "api/orders/history")
            .pipe(catchError(function (err) {
            return throwError(err);
        }), map(function (res) { return JSON.parse(res); }))
            .toPromise();
    };
    FinalMileService.prototype.loadDocument = function (jls, type, carrierLine, useIDCShipper, useIDCConsignee) {
        if (type === void 0) { type = 'BillOfLadingRpt'; }
        if (carrierLine === void 0) { carrierLine = '0'; }
        if (useIDCShipper === void 0) { useIDCShipper = false; }
        if (useIDCConsignee === void 0) { useIDCConsignee = false; }
        return this.httpClient.get(this.api + "api/orders/document/" + type + "/" + jls + "/" + carrierLine + "/" + useIDCShipper + "/" + useIDCConsignee, { responseType: 'blob' })
            .pipe(catchError(function (err) {
            console.log('Error getting document ... ', err);
            return throwError(err);
        }), map(function (res) {
            var file = new Blob([res], { type: 'application/pdf' });
            return file;
        }))
            .toPromise();
    };
    return FinalMileService;
}());
export { FinalMileService };
