/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./confirmation.component";
import * as i5 from "../../services/quote.service";
import * as i6 from "../../services/payment.service";
import * as i7 from "../../services/authentication.service";
import * as i8 from "../../services/account.service";
var styles_ConfirmationComponent = [i0.styles];
var RenderType_ConfirmationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationComponent, data: {} });
export { RenderType_ConfirmationComponent as RenderType_ConfirmationComponent };
export function View_ConfirmationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-12 pb-3 pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Purchase Your Shipment"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm Shipment Information"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please confirm the shipment details."])), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "div", [["class", "col-6 pt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["By clicking the ", " button you are agreeing to the "])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [["routerLink", "/terms-conditions"], ["target", "_blank"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Terms and Conditions"])), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [["class", "btn btn-primary w-100"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmCompleted() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(21, null, ["", ""]))], function (_ck, _v) { var currVal_3 = "_blank"; var currVal_4 = "/terms-conditions"; _ck(_v, 16, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonText; _ck(_v, 14, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 16).target; var currVal_2 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_1, currVal_2); var currVal_5 = _co.loading; _ck(_v, 20, 0, currVal_5); var currVal_6 = _co.buttonText; _ck(_v, 21, 0, currVal_6); }); }
export function View_ConfirmationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-confirmation", [], null, null, null, View_ConfirmationComponent_0, RenderType_ConfirmationComponent)), i1.ɵdid(1, 180224, null, 0, i4.ConfirmationComponent, [i5.QuoteService, i6.PaymentService, i7.AuthenticationService, i8.AccountService, i2.Router], null, null)], null, null); }
var ConfirmationComponentNgFactory = i1.ɵccf("rtrt-confirmation", i4.ConfirmationComponent, View_ConfirmationComponent_Host_0, {}, { onEdit: "onEdit" }, []);
export { ConfirmationComponentNgFactory as ConfirmationComponentNgFactory };
