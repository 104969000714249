import { Location } from './location.model';
export var ContractType;
(function (ContractType) {
    ContractType[ContractType["NewContract"] = 0] = "NewContract";
    ContractType[ContractType["OldContract"] = 1] = "OldContract";
    ContractType[ContractType["Canada"] = 2] = "Canada";
})(ContractType || (ContractType = {}));
var Failure = /** @class */ (function () {
    function Failure() {
    }
    return Failure;
}());
export { Failure };
var Invoice = /** @class */ (function () {
    function Invoice() {
        this.InvoiceDate = new Date();
    }
    return Invoice;
}());
export { Invoice };
var MarkUp = /** @class */ (function () {
    function MarkUp() {
    }
    return MarkUp;
}());
export { MarkUp };
var Carrier = /** @class */ (function () {
    function Carrier() {
        this.CarrierAddress = new Location();
        this.AccessorialAP = [];
        this.AccessorialAR = [];
        this.CarrierInvoice = new Invoice();
        this.Failures = [];
        this.MarkUp = new MarkUp();
        this.CarrierReviews = [];
        this.InsideDelivery = false;
        this.StripePayment = false;
    }
    Object.defineProperty(Carrier.prototype, "Logo", {
        get: function () {
            // return "data:image/jpg;base64," + this.CarrierLogo.toString();
            return this.CarrierLogoFilename.toString();
        },
        enumerable: true,
        configurable: true
    });
    return Carrier;
}());
export { Carrier };
