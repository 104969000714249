import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { AddressBookRoutingModule } from './address-book-routing.module';

import { ConfigService } from '../services/config.service';
import { AccountService } from '../services/account.service';

import { AccountAddressComponent } from './account-address.component';
import { ConsigneeAddressComponent } from './consignee-address.component';
import { AddressBookLayoutComponent } from './address-book-layout.component';
import { AddressCardComponent } from './address-card.component';
import { AddressFormComponent } from './address-form.component';
import { AddressModalComponent } from './address-modal.component';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    AddressBookRoutingModule,
    FormsModule,
    SharedModule,
    NgbDropdownModule
  ],
  declarations: [
    AccountAddressComponent,
    ConsigneeAddressComponent,
    AddressBookLayoutComponent,
    AddressCardComponent,
    AddressFormComponent,
    AddressModalComponent
  ],
  exports: [
    AddressCardComponent
  ],
  providers: [
    AccountService
  ]
})
export class AddressBookModule { }
