import { FlashComponent } from "./flash/flash.component";
import { SlideComponent } from "./slide/slide.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { ContactFormComponent } from "./contact-form/contact-form.component";
import { StripeComponent } from "./stripe/stripe.component";
import { ComboboxComponent } from "./combobox/combobox.component";
import { ShipmentSummaryComponent } from "./shipment-summary/shipment-summary.component";
import { BugComponent } from "./bug/bug.component";
import { ListOptionComponent } from "./list-option/list-option.component";
import { AutocompleteComponent } from "./autocomplete/autocomplete.component";
import { AddressValidatorComponent } from "./address-validator/address-validator.component";
// import {
//   AddressListComponent,
//   AddressCardComponent,
//   AddressFormComponent,
//   AddressModalComponent
// } from './address-manager';
import { NgbTimePipe } from "../helpers/ngb-time.pipe";
import { NgbDatePipe } from "../helpers/ngb-date.pipe";
import { SafeHtmlPipe } from "../helpers/safe-html.pipe";
import { SafeStylePipe } from "../helpers/safe-style.pipe";
import { NoSpacesPipe } from "../helpers/no-spaces.pipe";
import { PhoneValidator } from "../validators/phone.directive";
import { ZipValidator } from "../validators/zip.directive";
import { DecimalValidator } from "../validators/decimal.directive";
import { PositiveValidator } from "../validators/positive.directive";
import { IntegerValidator } from "../validators/integer.directive";
import { PasswordValidator } from "../validators/password.directive";
import { CreditCardValidator } from "../validators/credit-card.directive";
import { ObjectFilterPipe } from "../pipes/object-filter.pipe";
import { ObjectDateFilterPipe } from "../pipes/object-date-filter.pipe";
import { NgbDateENParserFormatter } from "../core/ngb-date-en-parser-formatter.model";
import { CarrierReviewStarsComponent } from './carrier-review-stars/carrier-review-stars.component';
var components = [
    FlashComponent,
    SlideComponent,
    SpinnerComponent,
    ContactFormComponent,
    StripeComponent,
    ComboboxComponent,
    BugComponent,
    ShipmentSummaryComponent,
    NgbTimePipe,
    NgbDatePipe,
    SafeHtmlPipe,
    SafeStylePipe,
    NoSpacesPipe,
    PhoneValidator,
    ZipValidator,
    DecimalValidator,
    PositiveValidator,
    IntegerValidator,
    PasswordValidator,
    CreditCardValidator,
    ObjectFilterPipe,
    ObjectDateFilterPipe,
    AddressValidatorComponent,
    ListOptionComponent,
    AutocompleteComponent,
    CarrierReviewStarsComponent
];
var ɵ0 = function () { return new NgbDateENParserFormatter(); };
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    return SharedModule;
}());
export { SharedModule };
export { ɵ0 };
