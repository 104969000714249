var ClientCommodity = /** @class */ (function () {
    function ClientCommodity() {
        this.ID = "";
        this.Description = "";
        this.NMFC = "";
        this.Class = "";
    }
    Object.defineProperty(ClientCommodity.prototype, "FullDescription", {
        get: function () {
            return this.Description + " | NMFC: " + this.NMFC + " | Class: " + this.Class;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientCommodity.prototype, "MatchString", {
        get: function () {
            return this.Description + " " + this.NMFC + " " + this.Class;
        },
        enumerable: true,
        configurable: true
    });
    return ClientCommodity;
}());
export { ClientCommodity };
