/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./address-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./address-form.component.ngfactory";
import * as i4 from "./address-form.component";
import * as i5 from "../services/config.service";
import * as i6 from "../services/account.service";
import * as i7 from "../services/zip.service";
import * as i8 from "./address-modal.component";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
var styles_AddressModalComponent = [i0.styles];
var RenderType_AddressModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddressModalComponent, data: {} });
export { RenderType_AddressModalComponent as RenderType_AddressModalComponent };
function View_AddressModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add a new address"]))], null, null); }
function View_AddressModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Edit ", " address"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.address.Name; _ck(_v, 1, 0, currVal_0); }); }
function View_AddressModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressModalComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressModalComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "modal-body p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "rtrt-address-form", [], null, [[null, "onSave"], [null, "onCancel"]], function (_v, en, $event) { var ad = true; if (("onSave" === en)) {
        var pd_0 = (_v.context.close($event) !== false);
        ad = (pd_0 && ad);
    } if (("onCancel" === en)) {
        var pd_1 = (_v.context.dismiss($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_AddressFormComponent_0, i3.RenderType_AddressFormComponent)), i1.ɵdid(10, 245760, null, 0, i4.AddressFormComponent, [i5.ConfigService, i6.AccountService, i7.ZipService, i1.NgZone], { model: [0, "model"] }, { onSave: "onSave", onCancel: "onCancel" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.fn === "Add"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.fn === "Edit"); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.address; _ck(_v, 10, 0, currVal_2); }, null); }
export function View_AddressModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-link btn-mini"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open(i1.ɵnov(_v, 2)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵand(0, [["content", 2]], null, 0, null, View_AddressModalComponent_1))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fn; _ck(_v, 1, 0, currVal_0); }); }
export function View_AddressModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-address-modal", [], null, null, null, View_AddressModalComponent_0, RenderType_AddressModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.AddressModalComponent, [i9.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddressModalComponentNgFactory = i1.ɵccf("rtrt-address-modal", i8.AddressModalComponent, View_AddressModalComponent_Host_0, { address: "address", type: "type" }, {}, []);
export { AddressModalComponentNgFactory as AddressModalComponentNgFactory };
