import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'objectDateFilter'
})
export class ObjectDateFilterPipe implements PipeTransform {
    transform(obj: any[], prop: string | string[], searchDate: any): any[] {
        const arr = [];
        const ret = [];
        if (!obj || obj.length === 0 || !prop) return [];

        if (!Array.isArray(prop)) arr.push(prop);
        else prop.map(p => arr.push(p));

        if (!searchDate || arr.filter(p => !obj[0].hasOwnProperty(p)).length > 0) return obj;

        const dateVal: Date = new Date();
        dateVal.setDate(searchDate.day);
        dateVal.setFullYear(searchDate.year);
        dateVal.setMonth(searchDate.month - 1);

        arr.forEach(p => {
            obj.filter((item) => new Date(item[p]).toDateString() === dateVal.toDateString())
                .forEach(res => {
                    if (ret.indexOf(res) < 0)
                        ret.push(res);
                });
        });

        return ret;
    }
}
