<article class="page page-account">
    <header [style.background-position]="'left'">
        <div class="container">
        <h1 class="text-light">My Account</h1>
        </div>
    </header>
    <section class="container-fluid">
        <div class="row">
        <div class="container">
            <div class="row pt-5">
            <div class="col-12">
                <rtrt-password-reset-form [id]="id" [token]="token" (onReset)="handleReset($event)"></rtrt-password-reset-form>
            </div>
            </div>
        </div>
        </div>
    </section>
</article>