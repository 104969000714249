<form #f="ngForm" class="container-fluid pt-0 pb-0 pl-2 pr-2" (ngSubmit)="submit()">
    <div class="row">
      <div class="col-12 col-lg-4 form-group required">
        <label>Address Type</label>
        <div ngbDropdown role="group" class="w-100">
          <button type="button" class="btn btn-outline dropdown w-100" ngbDropdownToggle>{{ addressType }}</button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button type="button" class="dropdown-item" (click)="setAddressType('shipper')">Shipper</button>
            <button type="button" class="dropdown-item" (click)="setAddressType('consignee')">Destination</button>
          </div>
        </div>
      </div>
      <div class="col-6 col-lg-4 form-group addressDefault">
        <label class="form-check-label pt-4 mt-4">
          <input type="checkbox" class="form-control" [(ngModel)]="model.IsDefaultBillTo" name="IsDefaultBillTo" #IsDefaultBillTo="ngModel">
          <span class="control"></span>
          Default Bill To
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12 form-group required">
        <label for="Name">Address Name</label>
        <input class="form-control" type="text" [(ngModel)]="model.Name" name="Name" #Name="ngModel" google-place (onSelect)="fillAddress($event, true)" required />
        <div *ngIf="Name.invalid && (Name.dirty || Name.touched || (f.submitted && !f.valid))" class="form-helpers">
          <small *ngIf="Name.errors?.required" class="text-danger">Name is required.</small>
        </div>
      </div>
      <div class="col-12 form-group required">
        <label for="AddressLineOne">Address</label>
        <input class="form-control" type="text" [(ngModel)]="model.AddressLineOne" name="AddressLineOne" #AddressLineOne="ngModel" google-place (onSelect)="fillAddress($event, false)" required />
        <div *ngIf="AddressLineOne.invalid && (AddressLineOne.dirty || AddressLineOne.touched || (f.submitted && !f.valid))" class="form-helpers">
          <small *ngIf="AddressLineOne.errors.required" class="text-danger">Address is required.</small>
        </div>
      </div>
      <div class="col-12 form-group">
          <label for="AddressLineTwo">Address (cont.)</label>
        <input class="form-control" type="text" [(ngModel)]="model.AddressLineTwo" #AddressLineTwo="ngModel" name="AddressLineTwo" />
      </div>
      <div class="col-12 form-group required">
        <label for="City">City</label>
        <input class="form-control" type="text" [(ngModel)]="model.City" name="City" #City="ngModel" required disabled />
        <div *ngIf="City.invalid && (City.dirty || City.touched || (f.submitted && !f.valid))" class="form-helpers">
          <small *ngIf="City.errors.required" class="text-danger">City is required.</small>
        </div>
      </div>
      <div class="col-6 col-lg-3 form-group required">
        <label for="StateOrProvince">State/Province</label>
        <select class="form-control" name="StateOrProvince" [(ngModel)]="model.StateOrProvince" #StateOrProvince="ngModel" required disabled>
          <option *ngFor="let state of states" [value]="state.abbreviation">{{state.abbreviation}}</option>
        </select>
        <div *ngIf="StateOrProvince.invalid && (StateOrProvince.dirty || StateOrProvince.touched || (f.submitted && !f.valid))" class="form-helpers">
          <small *ngIf="StateOrProvince.errors.required" class="text-danger">State is required.</small>
        </div>
      </div>
      <div class="col-6 col-lg-4 form-group required">
        <label for="PostalCode">Zip/Postal Code</label>
        <input class="form-control" type="text" [(ngModel)]="model.PostalCode" name="PostalCode" #PostalCode="ngModel" required (blur)="handleZipLookup(PostalCode)" />
        <div *ngIf="PostalCode.invalid && (PostalCode.dirty || PostalCode.touched || (f.submitted && !f.valid))" class="form-helpers">
          <small *ngIf="PostalCode.errors.required" class="text-danger">Zip is required.</small>
          <small *ngIf="PostalCode.errors.zip" class="text-danger">Zip is invalid.</small>
        </div>
      </div>
      <div class="col-6 col-lg-5 form-group required">
        <label for="CountryString">Country</label>
        <div ngbDropdown role="group" class="w-100">
          <button type="button" class="btn btn-outline dropdown w-100" ngbDropdownToggle>{{ country }}</button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button type="button" class="dropdown-item" (click)="setCountry('USA')">United States</button>
            <button type="button" class="dropdown-item" (click)="setCountry('CAN')">Canada</button>
          </div>
        </div>
        <input type="hidden" #CountryString="ngModel" name="CountryString" [(ngModel)]="model.CountryString" />
      </div>
      <div class="col-12 col-lg-4 form-group required">
          <label for="PhoneOne">Phone</label>
        <input class="form-control" type="text" [(ngModel)]="model.PhoneOne" name="PhoneOne" #PhoneOne="ngModel" required />
        <div *ngIf="PhoneOne.invalid && (PhoneOne.dirty || PhoneOne.touched || (f.submitted && !f.valid))" class="form-helpers">
          <small *ngIf="PhoneOne.errors.required" class="text-danger">Phone number is required.</small>
        </div>
      </div>
      <div class="col-12 col-lg-4 form-group">
          <label for="PhoneTwo">Mobile</label>
        <input class="form-control" type="text" [(ngModel)]="model.PhoneTwo" name="PhoneTwo" #PhoneTwo="ngModel" />
        <div *ngIf="PhoneTwo.invalid && (PhoneTwo.dirty || PhoneTwo.touched || (f.submitted && !f.valid))" class="form-helpers">
          <small *ngIf="PhoneTwo.errors.required" class="text-danger">Mobile number is required.</small>
        </div>
      </div>
      <div class="col-12 col-lg-4 form-group">
        <label for="Fax">Fax</label>
        <input class="form-control" type="text" [(ngModel)]="model.Fax" name="Fax" #Fax="ngModel" />
        <div *ngIf="Fax.invalid && (Fax.dirty || Fax.touched || (f.submitted && !f.valid))" class="form-helpers">
          <small *ngIf="Fax.errors.required" class="text-danger">Fax is required.</small>
        </div>
      </div>
      <div class="col-12 col-lg-6 form-group required">
          <label for="ContactName">Contact Name</label>
        <input class="form-control" type="text" [(ngModel)]="model.ContactName" name="ContactName" #ContactName="ngModel" required />
        <div *ngIf="ContactName.invalid && (ContactName.dirty || ContactName.touched || (f.submitted && !f.valid))" class="form-helpers">
          <small *ngIf="ContactName.errors.required" class="text-danger">Contact name is required.</small>
        </div>
      </div>
      <div class="col-12 col-lg-6 form-group required">
        <label for="ContactEmail">Contact Email Address</label>
        <input class="form-control" type="text" [(ngModel)]="model.ContactEmail" name="ContactEmail" #ContactEmail="ngModel" required />
        <div *ngIf="ContactEmail.invalid && (ContactEmail.dirty || ContactEmail.touched || (f.submitted && !f.valid))" class="form-helpers">
          <small *ngIf="ContactEmail.errors.required" class="text-danger">Contact email is required.</small>
        </div>
      </div>
    </div>
    <div class="row p-2">
      <div class="col-4">
          <button type="button" class="btn btn-secondary w-100" (click)="handleCancel()">Cancel</button>
      </div>
      <div class="col-4 push-4">
          <button type="submit" class="btn btn-primary w-100">Save</button>
      </div>
    </div>
  </form>