import { Component, OnInit } from '@angular/core';
import { BlogService } from '../../../services/blog.service';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { BlogPost } from '../../../models';
import { map } from 'rxjs/operators';
import { MetaService } from '../../../services/meta-service.service';
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'rtrt-blog-post-detail',
  templateUrl: './blog-post-detail.component.html',
  styleUrls: ['./blog-post-detail.component.scss']
})
export class BlogPostDetailComponent implements OnInit {
  subscriptions: Subscription[] = [];
  blogId: any;
  post: BlogPost;
  error = false;
  headerBg: string;

  constructor(
    private blogService: BlogService,
    private route: ActivatedRoute,
    private metaService: MetaService,
    private meta: Meta,
    private title: Title
  ) {
    route.data.subscribe((data) => {
      this.headerBg = data.headerImage;
    });

    this.subscriptions.push(this.route.params.subscribe(params => {
      console.log(params['id']);

      if (params['id']) {
        this.blogId = params['id'];
      }
    }));
   }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.post = Object.assign(new BlogPost(), window.history.state.post);
    //console.log(this.post);
    // Check for missing data and load it for just this page if needed.
    // This will happen with search engines and bookmarked pages.
    if(this.post.ID == 0) {
      // The blogId in this case is really the last part of the url
      this.blogService.loadBlogPost(this.blogId)
      .catch(err => {
        this.error = true;
      })
      .then((p: BlogPost) => {
        this.post = p;
        // Set the title and description for SEO
        this.title.setTitle(p.BlogTitle.substr(0,64));
        // document.querySelector("head title").textContent = p.BlogTitle;
        this.meta.updateTag({
          name: "description",
          content: p.MetaDescription
        });
        this.meta.updateTag({
          property: "og:title",
          content: p.BlogTitle
        });
        this.meta.updateTag({
          property: "og:description",
          content: p.MetaDescription
        });
        this.meta.updateTag({
          property: "og:image",
          content: p.Image
        });
        // var desc = document.querySelector("meta[name=description]");
        // if (!desc) {
        //   desc = document.createElement("meta");
        //   desc.setAttribute("name","description");
        //   document.querySelector("head").appendChild(desc);
        // }
        // desc.setAttribute("content", p.MetaDescription);
        this.metaService.removeItemprop();
        var schemaorgjson = {
          "@context": "https://schema.org/",
          "@type": "Article",
          "name": p.BlogTitle,
          "author": {
            "@type": "Person",
            "name": p.AuthorName
          },
          "datePublished": p.BlogDate,
          "description": p.MetaDescription,
          "abstract": p.BlogSummary,
          "image": p.BlogImage
        };
        var schemaorg = document.querySelector("head script[type='application/ld+json']");
        if (schemaorg != null) {
          schemaorg.innerHTML = JSON.stringify(schemaorgjson);
        } else {
          var schemaorgscript = document.createElement("script");
          schemaorgscript.setAttribute("type", "application/ld+json")
          schemaorgscript.innerHTML = JSON.stringify(schemaorgjson);
          document.querySelector("head").append(schemaorgscript);
        }
      });
    } else {
      // document.querySelector("head title").textContent = this.post.BlogTitle;
      this.title.setTitle(this.post.BlogTitle.substr(0,64));
      this.meta.updateTag({
        name: "description",
        content: this.post.MetaDescription
      });
      this.meta.updateTag({
        property: "og:title",
        content: this.post.BlogTitle
      });
      this.meta.updateTag({
        property: "og:description",
        content: this.post.MetaDescription
      });
      this.meta.updateTag({
        property: "og:image",
        content: this.post.Image
      });
      // document.querySelector("meta[name=description]").setAttribute("content", this.post.MetaDescription);
      // var schemaorgjson = {
      //   "@context": "https://schema.org/",
      //   "@type": "Article",
      //   "name": this.post.BlogTitle,
      //   "author": {
      //     "@type": "Person",
      //     "name": this.post.AuthorName
      //   },
      //   "datePublished": this.post.BlogDate,
      //   "description": this.post.MetaDescription,
      //   "abstract": this.post.BlogSummary,
      //   "image": this.post.BlogImage
      // };
      this.metaService.removeItemprop();
      var schemaorg = document.querySelector("head script[type='application/ld+json']");
      if (schemaorg != null) {
        schemaorg.innerHTML = JSON.stringify(schemaorg);
      } else {
        var schemaorgscript = document.createElement("script");
        schemaorgscript.setAttribute("type", "application/ld+json")
        schemaorgscript.innerHTML = JSON.stringify(schemaorg);
        document.querySelector("head").append(schemaorgscript);
      }
    }
    //console.log(this.post);
  }

  formatTag(tag: string) {
    return tag.replace(/\W+(?!$)/g, "-")
      .replace(/\W$/, "")
      .toLowerCase();
  }
}
