import { Location } from './location.model';
import { OrderClient } from './order-client.model';
import { Reference } from './reference.model';
import { Carrier } from './carrier.model';
import { Commodity } from './commodity.model';
import { OrderDateTime } from './order-date-time.model';
import { Accessorial } from './accessorial.model';
import { Payment } from './payment.model';
import { PromoCode } from './promo-code.model';

export class Shipment {
    JlsNo: string;
    MrNo: string;
    StopNo: string;
    PickUpNo: string;
    IsQuote: boolean;
    EdiTestingIndicator: number;
    jShipUser: string;
    IsVoid: boolean;
    IsEshipVoid: boolean;
    IsCustomerCollect: boolean;
    IsCustomerThirdParty: boolean;
    IsInboundCollect: boolean;
    IsSupplyOrder: boolean;
    eQuoteNumber: string;
    OrderClient: OrderClient;
    ClientID: number;
    Terms: number;
    TermsString: string;
    LocationStatus: string;
    ShipmentStatusID: string;
    ShipmentStatusText: string;
    QueueStatus: number;
    QueueStatusText: string;
    IsAdditionalOpptOrder: boolean;
    AdditionalOpportunityReasonCode: number;
    ExpediteReasonCode: number;
    AdditionalOpportunityReasonText: string;
    ExpediteReasonText: string;
    AuthorizedName: string;
    References: Reference[];
    Carriers: Carrier[];
    DateTimes: OrderDateTime[];
    Shipper: Location;
    Consignee: Location;
    ThirdParty: Location;
    BillToLocation: Location;
    ShipperNotes: string;
    CarrierNotes: string;
    JlsNotes: string;
    DispatchApiNotes: string;
    TrackingNotesInternal: string;
    ExpediteApproval: string;
    BillingNotes: string;
    ETANotes: string;
    eShipNotes: string;
    OaCode: string;
    OaNotes: string;
    RadCode: string;
    RadNotes: string;
    IsTemplate: boolean;
    TemplateName: string;
    eShipAlertEmail: string;
    EmailBolTo: string;
    PrepaidADD: boolean;
    BolSent: boolean;
    LabelSent: boolean;
    Edi204Codes: any[];
    Commodities: Commodity[];
    JtsEmployees: string[];
    ShipmentDelayReason: string;
    Direction: number;
    FirstCarrier: any;
    AllDateStrings: {
      shipDate: string;
      orderDate: string
    };
    TotalCommodityGrossWeight: number;
    TotalCommodityNetWeight: number;
    TotalCommodityHandlingUnits: number;
    TotalCommodityPieces: number;
    TotalLineHaulAP: number;
    TotalFuelAP: number;
    TotalAccessorialsAP: number;
    TotalAP: number;
    TotalAR: number;
    IsHazardous: boolean;
    IsProtectFromFreeze: boolean;
    ActualCost: number;
    CustomerCosts: null;
    TotalMiles: number;
    AccessorialAR: Accessorial[];
    AccessorialAP: Accessorial[];
    PaymentData: Payment;
    PaymentIntentSecret: string;
    ChargeAccount = false;
    PromoCodeApplied = false;
    PromoCode: PromoCode;
    FinalMileShipment = false;
    FinalMileServiceType: number;
    FinalMileOnly = false;
    Insurance = false;
  constructor(jls: string = '') {
      this.JlsNo = jls;
      this.OrderClient = new OrderClient();
      this.Shipper = new Location();
      this.Consignee = new Location();
      this.ThirdParty = new Location();
      this.BillToLocation = new Location();
      this.References = [];
      this.Carriers = [];
      this.Carriers.push(new Carrier());
      this.DateTimes = [];
      this.Edi204Codes = [];
      this.TotalCommodityGrossWeight = 0;
      this.Commodities = [];
      this.JtsEmployees = [];
      this.AccessorialAP = [];
      this.AccessorialAR = [];
      this.CarrierNotes = "";
      this.PromoCode = new PromoCode();
  }
}
