<article class="page page-quote">
  <!-- <header [style.background-image]="'url(' + headerBg + ')'">
    <div class="container">
      <h1 class="text-light">RiteQuote Request</h1>
    </div>
  </header> -->
  <section class="container-fluid">
    <div class="row pt-6">
      <div class="col">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h1>Quote Request</h1>
            </div>
          </div>
          <div class="row pt-5 pb-3 align-items-center">
            <div class="col-12 col-lg-9 ltl-shipment">
              <h2>Less Than Truckload (LTL) Shipment</h2>
            </div>
            <div class="col-12 col-lg-3 text-left text-lg-right">
              <div class="step-indicator">
                  <div class="step-text" [ngClass]="{'visible': showStepText}">
                    <p>{{stepText}}</p>
                  </div>
                  <span class="circle" [ngClass]="{'active': processStep === 1}" (mouseenter)="toggleStepText('Quote Your Shipment')" (mouseleave)="toggleStepText('')"></span>
                  <span class="circle" [ngClass]="{'active': processStep === 2}" (mouseenter)="toggleStepText('Book Your Shipment')" (mouseleave)="toggleStepText('')"></span>
                  <span class="circle" [ngClass]="{'active': processStep === 3}" (mouseenter)="toggleStepText('Purchase Your Shipment')" (mouseleave)="toggleStepText('')"></span>
              </div>
            </div>
          </div>
          <div class="row b-top-secondary">
            <div class="col-12 col-lg-3 pr-lg-3 mb-5 pt-lg-5" id="quoteDetailsDiv">
              <!-- <nav class="step-nav sequential">
                <a class="text-center text-lg-left" *ngFor="let link of stepLinks" [ngClass]="{'active last' : link === activeLink, 'visited' : stepLinks.indexOf(link) < stepLinks.indexOf(activeLink), 'first' : stepLinks.indexOf(link) === 0}">
                  <span class="circle"></span>
                  <span class="text hidden-md-down">{{link.display}}</span>
                </a>
              </nav> -->
              <!-- <rtrt-shipment-summary [isEditable]="true" [shipment]="shipment" [processLocation]="currentForm" (onEdit)="handleEditRequest($event)"></rtrt-shipment-summary> -->
              <rtrt-quote-details></rtrt-quote-details>
            </div>
            <!-- <div class="d-block d-lg-none col-lg-12 text-center pt-4">
              <h3>{{activeLink.display}}</h3>
            </div> -->
            <div class="col-12 col-lg-9 b-left-lg-secondary pt-5 mb-5 pl-lg-2 pr-lg-0" id="quoteFormDiv">
              <rtrt-quote-form *ngIf="currentForm === 'start'" [jls]="jls"></rtrt-quote-form>
              <rtrt-carrier-selection *ngIf="currentForm === 'carrier'"></rtrt-carrier-selection>
              <rtrt-item-details *ngIf="currentForm === 'item-details'"></rtrt-item-details>
              <rtrt-origin-form *ngIf="currentForm === 'origin'"></rtrt-origin-form>
              <rtrt-destination-form *ngIf="currentForm === 'destination'"></rtrt-destination-form>
              <rtrt-reference-selector *ngIf="currentForm === 'references'"></rtrt-reference-selector>
              <rtrt-payment-form *ngIf="currentForm === 'payment'"></rtrt-payment-form>
              <rtrt-confirmation *ngIf="currentForm === 'confirmation'"></rtrt-confirmation> 
              <!-- <router-outlet name="quotesection"></router-outlet> -->
              <!-- <rtrt-quote-item-form></rtrt-quote-item-form> -->
              <!-- <rtrt-quote-form [quote]="quote" *ngIf="currentForm === 'start'"></rtrt-quote-form>
              <rtrt-carrier-selection [quote]="quote" *ngIf="currentForm === 'carrier'"></rtrt-carrier-selection>
              <rtrt-item-details [quote]="quote" *ngIf="currentForm === 'item-details'"></rtrt-item-details>
              <rtrt-origin-form [quote]="quote" *ngIf="currentForm === 'origin'"></rtrt-origin-form>
              <rtrt-destination-form [quote]="quote" *ngIf="currentForm === 'destination'"></rtrt-destination-form>
              <rtrt-payment-form [quote]="quote" *ngIf="currentForm === 'payment'"></rtrt-payment-form>
              <rtrt-confirmation *ngIf="currentForm === 'confirmation'"></rtrt-confirmation> -->
              <!-- <rtrt-confirmation [quote]="quote" [completed]="completed" (onEdit)="handleEdit($event)" [cc]="cc"></rtrt-confirmation> -->
              <!-- <rtrt-shipment-tracking *ngIf="currentForm === 'tracking'" [order]="{}"></rtrt-shipment-tracking> -->
            </div>
          </div>
          <div class="row pt-1 pb-4">
            <div class="col-12 disclaimer text-left">
              Additional charges may apply, line haul and fuel only. Transit is in Business Days. Quotes are valid for 7 calendar days from the created date stated on the quotation. Quotes are only valid if quoted shipment information (dimensions, weight, special service requirements, etc) match the actual characteristics of the freight shipped.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
