<div class="container pt-4" *ngIf="loading">
  <div class="row">
    <div class="col-12">
      <rtrt-spinner [visible]="loading" class="center-block d-block"></rtrt-spinner>
    </div>
  </div>
</div>
<form *ngIf="!loading" #f="ngForm" (ngSubmit)="onSubmit()" novalidate class="container-fluid pt-4">
  <div *ngIf="!serviceOutage">
    <div class="row">
      <div class="col-12 pb-3">
        <button type="submit" class="btn btn-primary btn-mini float-right">Continue</button>
        <h3>Quote Your Shipment</h3>
      </div>
    </div>
    <div class="row" id="shipmentZipCodes">
      <div class="col-12 col-md-6 form-group required">
        <div placement="center-bottom" ngbDropdown class="d-inline-block float-right mt-2" *ngIf="!!user && user !== null && originAddresses && (originAddresses | objectFilter: addressFilterProps : quote.Shipper.PostalCode).length > 0">
          <button type="button" class="btn btn-link btn-mini" id="addressOriginSelector" ngbDropdownToggle>Use Address Book</button>
          <div ngbDropdownMenu aria-labelledby="addressOriginSelector" class="addressDropdown">
            <button type="button" class="dropdown-item" *ngFor="let address of originAddresses | objectFilter: addressFilterProps : quote.Shipper.PostalCode" (click)="selectOriginAddress(address)">
              <strong>{{ address.Name }}</strong>, {{ address.AddressLineOne }}, {{ address.City }}, {{ address.StateOrProvince }}, {{ address.PostalCode }}<strong *ngIf="address.IsDefaultBillTo"> (Bill To)</strong>
            </button>
          </div>
        </div>
        <label for="originZip">Origin Zip Code</label>
        <rtrt-spinner [visible]="zips.shipper?.loading"></rtrt-spinner>
        <input type="text" class="form-control" [(ngModel)]="quote.Shipper.PostalCode" #originZip="ngModel" name="originZip" (focus)="catchOriginalOriginZip()" (blur)="validateOriginZip()" required>
        <div *ngIf="!zips.shipper?.isValid" class="form-helpers">
          <small *ngIf="!zips.shipper?.isValid" class="text-danger">Supplied zip is not valid.</small>
        </div>
        <div *ngIf="zips.shipper?.isValid && (quote.Shipper && quote.Shipper.PostalCode)" class="form-helpers">
          <p>{{quote.Shipper?.City}}, {{quote.Shipper?.StateOrProvince}}</p>
        </div>
      </div>
      <div class="col-12 col-md-6 form-group required">
        <div ngbDropdown class="d-inline-block float-right mt-2" *ngIf="!!user && user !== null && consigneeAddresses && (consigneeAddresses | objectFilter: addressFilterProps : quote.Consignee.PostalCode).length > 0">
          <button type="button" class="btn btn-link btn-mini" id="addressConsigneeSelector" ngbDropdownToggle>Use Address Book</button>
          <div ngbDropdownMenu aria-labelledby="addressConsigneeSelector" class="addressDropdown">
            <button type="button" class="dropdown-item" *ngFor="let address of consigneeAddresses | objectFilter: addressFilterProps : quote.Consignee.PostalCode" (click)="selectConsigneeAddress(address)">
              <strong>{{ address.Name }}</strong>, {{ address.AddressLineOne }}, {{ address.City }}, {{ address.StateOrProvince }}, {{ address.PostalCode }}<strong *ngIf="address.IsDefaultBillTo"> (Bill To)</strong>
            </button>
          </div>
        </div>      
        <label for="destinationZip">Destination Zip Code</label>
        <rtrt-spinner [visible]="zips.consignee?.loading"></rtrt-spinner>
        <input type="text" class="form-control" [(ngModel)]="quote.Consignee.PostalCode" #destinationZip="ngModel" name="destinationZip" (focus)="catchOriginalDestinationZip()" (blur)="validateDestinationZip()" required>
        <div *ngIf="!zips.consignee?.isValid" class="form-helpers" required>
          <small *ngIf="!zips.consignee?.isValid" class="text-danger">Supplied zip is not valid.</small>
        </div>
        <div *ngIf="zips.consignee?.isValid && (quote.Consignee && quote.Consignee.PostalCode)" class="form-helpers">
          <p>{{quote.Consignee?.City}}, {{quote.Consignee?.StateOrProvince}}</p>
        </div>
      </div>
    </div>
    <div class="row b-top-secondary pt-5 pb-5">
      <div class="col-12" style="display: none;">
        <p>
          <strong class="mr-2">Insurance</strong>
          <rtrt-bug float="none">
            <p>
              Selecting "No" indicates normal carrier liability is sufficient. <br />
              Selecting "Yes" indicates you would like full coverage for your freight.
            </p>
          </rtrt-bug>
        </p>
        <p>Do you want to purchase excess liability coverage?</p>
        <div class="col-3 text-center required d-inline" *ngFor="let option of options;">
          <label>{{option.name}}</label>
          <label class="form-check-label p-2">
            <input type="checkbox" (click)="onInsuranceSelectionChange($event, option.value)" [value]="option.value"
              name="radiogroup" [checked]="option.value === this.quote.Insurance" />
            <span class="control"></span>
          </label>
        </div>
      </div>
      <div class="col-12 pt-3 pb-3" *ngFor="let commodity of quote.Commodities; let i = index">
        <rtrt-freight-item [id]="i" [addInsurance]="this.quote.Insurance" [item]="commodity" [commodities]="this.quote.Commodities" [freightClasses]="freightClasses" (onRemoveItem)="removeItem($event)" [touched]="touched"></rtrt-freight-item>
      </div>
      <div class="col-12 pb-3">
          <button type="button" class="btn btn-secondary w-50" (click)="addFreightItem()">+ Add New Item</button>
      </div>
    </div>
    <div class="row b-top-secondary pt-5 pb-5" id="accRow">
      <div class="col-12">
        <p>
          <strong class="mr-2">Shipment Service Options</strong>
          <rtrt-bug float="none" [modal]="true">
            <dl>
                <dt>Residential Delivery</dt>
                <dd>Charged when the carrier makes a pickup or delivery at a private residence.</dd>
                <dt>Liftgate</dt>
                <dd>Lift gates enable carriers to lift and lower shipments from the ground to the truck and vice versa, so this fee applies when a shipper or receiver does not have the means to load or unload the freight using a dock or forklift.</dd>
                  <dt>Over Dimensional</dt>
                <dd>Fee applied for any shipment that is over a certain predetermined length. It will vary depending on the carrier. Most common is shipments over 12’ in length will be charged.</dd>
                <dt>Limited Access</dt>
                <dd>Applies when the carrier picks up or delivers to locations such as schools, prisons, military bases, convention centers, construction sites, airports, ocean docks, wharfs, or piers.</dd>
                <dt>Hazardous Materials Charge</dt>
                <dd>Applies to any shipment that contains hazardous chemicals or substances.</dd>
                <dt>Reconsignment</dt>
                <dd>charge that will apply when any shipment needs to have a the delivery address changed after it has been shipped out.</dd>
            </dl>
          </rtrt-bug>
        </p>
        <p>Do you want to add an Accessorial(s) to this shipment?</p>
        <div class="col-3 text-center required d-inline" *ngFor="let option of options;">
          <label>{{option.name}}</label>
          <label class="form-check-label p-2">
            <input type="checkbox" (click)="onAccSelectionChange($event, option.value)" [value]="option.value" name="radiogroup" [checked]="option.value === includeAccessorials" />
            <span class="control"></span>
          </label>
        </div>
        <div *ngIf="includeAccessorials">
          <div class="col-12">
            <div class="form-control pt-3 pb-3">
              <span class="rt-chip" *ngIf="quote.AccessorialAR.length < 1"><em>No accessorials added</em></span>
              <span class="rt-chip" *ngFor="let accessorial of quote.AccessorialAR"><button type="button"
                  class="rt-chip-remove" (click)="removeAccessorial(accessorial.Type)"></button> {{accessorial.Text}}</span>
            </div>
          </div>
          <div class="col-3" ngbDropdown>
            <button type="button" class="btn btn-primary btn-mini dropdown" ngbDropdownToggle>+ Add Accessorial</button>
            <div ngbDropdownMenu>
              <button type="button" *ngFor="let accessorial of accessorials" class="dropdown-item"
                (click)="selectAccessorial(accessorial)">{{accessorial.Text}}</button>
            </div>
          </div>
        </div>
        <div *ngIf="!errors.acc.selected || !errors.acc.added" class="form-helpers">
          <small class="text-danger" *ngIf="!errors.acc.selected">"Yes" or "No" must be selected.</small>
          <small class="text-danger" *ngIf="!errors.acc.added && quote.AccessorialAR.length < 1">If you selected "Yes", an
            accessorial must be added.</small>
        </div>
      </div>
    </div>


    <div class="row b-top-secondary pt-5 pb-5" id="accRow">
      <div class="col-12">
        <p>
          <strong class="mr-2">Tradeshow and Guaranteed Shipments</strong>
        </p>
        <p>Is this shipment a tradehow/guaranteed shipment?</p>
        <div class="col-3 text-center required d-inline" *ngFor="let option of options;">
          <label>{{option.name}}</label>
          <label class="form-check-label p-2">
            <input type="checkbox" (click)="onTradeshowSelectionChange($event, option.value)" [value]="option.value" name="radiogroup" [checked]="option.value === isTradeshow" />
            <span class="control"></span>
          </label>
        </div>
        <div *ngIf="!errors.acc.selected" class="form-helpers">
          <small class="text-danger">"Yes" or "No" must be selected.</small>
        </div>
      </div>
    </div>


      
    <div class="row b-top-secondary pt-4">
      <div class="col-12 form-group pt-3 pb-3 required" *ngIf="!_loggedIn">
        <label for="companyName">Company Name</label>
        <input type="text" class="form-control" [(ngModel)]="quote.Shipper.Name" #companyNameControl="ngModel" name="companyName" required>
        <div *ngIf="companyNameControl.invalid && (companyNameControl.dirty || companyNameControl.touched)" class="form-helpers">
          <small *ngIf="companyNameControl.errors.required" class="text-danger">Company name is required.</small>
        </div>
      </div>
      <!-- div style="margin: 0; padding: 0;" class="col-12 required" *ngIf="!_loggedIn">
        <label style="width: 100%; padding: 0 15px;" for="firstname">Contact Name</label>
        <div style="display: inline-block; padding: 0px 16px 0px 16px !important;" class="col-6 form-group pt-3 pb-3 required">
          <input type="text" class="form-control" [(ngModel)]="firstname" #firstnameControl="ngModel" name="firstname" placeholder="First Name" required>
        </div>
        <div style="display: inline-block; padding: 0px 16px 0px 16px !important;" class="col-6 form-group pt-3 pb-3 required">
          <input type="text" class="form-control" [(ngModel)]="lastname" #lastnameControl="ngModel" name="lastname" placeholder="Last Name" required>
        </div>
        <div *ngIf="firstnameControl.invalid && (firstnameControl.dirty || firstnameControl.touched) && lastnameControl.invalid && (lastnameControl.dirty || lastnameControl.touched)" class="form-helpers">
          <small *ngIf="firstnameControl.errors.required || lastnameControl.errors.required" class="text-danger">Contact first and last name are required.</small>
        </div>
      </div -->
      <div class="col-6 form-group pt-3 pb-3 required" *ngIf="!_loggedIn">
        <label for="contactFirstname">Contact Firstname</label>
        <input type="text" class="form-control" [(ngModel)]="firstname" #contactFirstnameControl="ngModel" name="contactFirstname" required>
        <div *ngIf="contactFirstnameControl.invalid && (contactFirstnameControl.dirty || contactFirstnameControl.touched)" class="form-helpers">
          <small *ngIf="contactFirstnameControl.errors.required" class="text-danger">Contact Firstname is required.</small>
        </div>
      </div>
      <div class="col-6 form-group pt-3 pb-3 required" *ngIf="!_loggedIn">
        <label for="contactLastname">Lastname</label>
        <input type="text" class="form-control" [(ngModel)]="lastname" #contactLastnameControl="ngModel" name="contactLastname" required>
        <div *ngIf="contactLastnameControl.invalid && (contactLastnameControl.dirty || contactLastnameControl.touched)" class="form-helpers">
          <small *ngIf="contactLastnameControl.errors.required" class="text-danger">Contact Lastname is required.</small>
        </div>
      </div>
      <div class="col-6 form-group pt-3 pb-3 required">
        <label for="email">Email Address</label>
        <input type="email" class="form-control" [(ngModel)]="quote.Shipper.ContactEmail" #emailControl="ngModel" name="email" required>
        <div *ngIf="emailControl.invalid && (emailControl.dirty || emailControl.touched)" class="form-helpers">
          <small *ngIf="emailControl.errors.required" class="text-danger">Email is required.</small>
          <small *ngIf="emailControl.errors.email" class="text-danger">Please provide a valid email address.</small>
        </div>
      </div>
      <div class="col-6 form-group pt-3 pb-3" *ngIf="!_loggedIn">
        <label for="phone">Phone Number</label>
        <input type="tel" class="form-control" [(ngModel)]="quote.Shipper.PhoneOne" #phoneControl="ngModel" name="phone">
      </div>
    </div>
    <div class="row" *ngIf="formErrorMsg !== ''">
      <div class="col">
          <div  class="form-helpers">
            <small class="text-danger">
              {{formErrorMsg}}
            </small>
          </div>
      </div>
    </div>
    <div class="row b-top-secondary pt-4" *ngIf="isTradeshow">
      <div class="col">
          <p class="text-danger text-nowrap">
            For all Guaranteed Service and Tradeshow shipments, please contact us at customerservice@riterouting.com or 888-744-7002
          </p>
    </div>
    </div>
    <div class="row b-top-secondary pt-4" *ngIf="!isTradeshow">
      <div class="col-6 pt-3">
        <button type="submit" class="btn btn-primary w-100" [disabled]="creating">Get an Estimate</button>
      </div>
      <div class="col-2 pt-4">
          <rtrt-spinner [visible]="creating" display="inline-block" class="mb-0"></rtrt-spinner>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="serviceOutage">
    <div class="col">
        <p class="text-danger">
          Dear Valued Customers,
          We would like to inform you that a transportation services provider is currently experiencing an outage, which may impact the accuracy of our quoting options. We apologize for any inconvenience this may cause.
          To ensure you receive the most accurate and personalized quotes during this period, please reach out to our dedicated team at riterouting@gojarrett.com. Our professionals will promptly assist you in providing accurate and tailored information for your transportation needs.
          We appreciate your understanding and patience as we work diligently to resolve this service interruption. Thank you for choosing RiteRouting for your transportation solutions.
          Best Regards,
          RiteRouting Team
          </p>
    </div>
  </div>
</form>