/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms-conditions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../helpers/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./terms-conditions.component";
import * as i5 from "@angular/router";
import * as i6 from "../../services/meta-service.service";
var styles_TermsConditionsComponent = [i0.styles];
var RenderType_TermsConditionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsConditionsComponent, data: {} });
export { RenderType_TermsConditionsComponent as RenderType_TermsConditionsComponent };
export function View_TermsConditionsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "article", [["class", "page page-tc"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "section", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "row pt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "col-12"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(7, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), _co.content.copy)); _ck(_v, 6, 0, currVal_0); }); }
export function View_TermsConditionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-terms-conditions", [], null, null, null, View_TermsConditionsComponent_0, RenderType_TermsConditionsComponent)), i1.ɵdid(1, 114688, null, 0, i4.TermsConditionsComponent, [i5.ActivatedRoute, i3.Title, i3.Meta, i6.MetaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermsConditionsComponentNgFactory = i1.ɵccf("rtrt-terms-conditions", i4.TermsConditionsComponent, View_TermsConditionsComponent_Host_0, {}, {}, []);
export { TermsConditionsComponentNgFactory as TermsConditionsComponentNgFactory };
