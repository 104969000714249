import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Shipment, OrderDateTimeType } from '../../models';
import { QuoteService } from '../../services/quote.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var QuoteDetailsComponent = /** @class */ (function () {
    function QuoteDetailsComponent(quoteService, router, modalService) {
        var _this = this;
        this.quoteService = quoteService;
        this.router = router;
        this.modalService = modalService;
        this._subscriptions = [];
        this.sum = function (accumulator, val) {
            if (!isNaN(val))
                return accumulator + val;
            else
                return accumulator;
        };
        this._subscriptions.push(this.quoteService.quote$
            .subscribe(function (q) { _this.quote = q; console.log(_this.quote); }));
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.router.onSameUrlNavigation = 'reload';
    }
    Object.defineProperty(QuoteDetailsComponent.prototype, "grossWeight", {
        get: function () {
            return this.quote.Commodities.map(function (w) { return parseFloat(w.GrossWeight.toString()); }).reduce(this.sum);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuoteDetailsComponent.prototype, "totalItems", {
        get: function () {
            return this.quote.Commodities.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuoteDetailsComponent.prototype, "totalUnits", {
        get: function () {
            return this.quote.Commodities.map(function (w) { return w.HandlingUnits; }).reduce(this.sum);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuoteDetailsComponent.prototype, "pickupDate", {
        get: function () {
            var pdate = this.quote.DateTimes.find(function (dt) { return dt.TypeID === OrderDateTimeType.PickupAppt; });
            if (pdate)
                return pdate.ShortDateString;
        },
        enumerable: true,
        configurable: true
    });
    QuoteDetailsComponent.prototype.edit = function (dest) {
        if (dest == '')
            this.router.navigate(['ltl-shipping-quote']);
        else
            this.router.navigate(['ltl-shipping-quote', dest]);
    };
    QuoteDetailsComponent.prototype.confirm = function (confirmContent) {
        var _this = this;
        this.modalService.open(confirmContent).result.then(function (result) {
            _this.quoteService.clearQuote();
            _this.router.navigate(['ltl-shipping-quote']);
        });
    };
    QuoteDetailsComponent.prototype.ngOnDestroy = function () {
        this._subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    return QuoteDetailsComponent;
}());
export { QuoteDetailsComponent };
