import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'rtrt-carrier-review-stars',
  templateUrl: './carrier-review-stars.component.html',
  styleUrls: ['./carrier-review-stars.component.scss']
})
export class CarrierReviewStarsComponent implements OnInit {
  @Input() rating: number;

  constructor() { }

  ngOnInit() {
  }
  getGradient(rating: number, star: number): string {
    if (Math.floor(rating) >= star)
      return "#FDD017";

    if (Math.ceil(rating) < star)
      return "lightgray";

    var highlightPct = (rating + 1) - star;
    var direction, leftColor, rightColor;

    if (highlightPct >= .5) {
      direction = "360deg";
      leftColor = `#FDD017 ${(highlightPct * 100).toString()}%`;
      rightColor = `lightgray ${(100 - (highlightPct * 100)).toString()}%`;
    }
    else {
      direction = "180deg";
      leftColor = `lightgray ${(100 - (highlightPct * 100)).toString()}%`;
      rightColor = `#FDD017 ${(highlightPct * 100).toString()}%`;
    }
    var gradient = `-webkit-linear-gradient(${direction}, ${leftColor}, ${rightColor}`;

    return gradient;
  }
}
