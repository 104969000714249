import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';
import { Subscription } from 'rxjs';

import { OrderService } from '../../services/order.service';
import { ShipmentService } from '../../services/shipment.service';
import { PaymentService } from '../../services/payment.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Shipment } from '../../models';

import { TRACKING_META } from '../meta';
import { Carrier } from '../../quote/carrier.model';

@Component({
  selector: 'rtrt-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrackingComponent implements OnInit, OnDestroy {
  headerBg: string;
  jlsNo = '';
  order: Shipment;
  tracking: any;
  orderHistory: any[] = [];
  showHistory = true;
  loading = true;
  error = false;
  subscriptions: Subscription[] = [];
  trackingCarriers: any[] = [];
  isAuthenticated: boolean;

  constructor(
    public auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private shipmentService: ShipmentService,
    private paymentService: PaymentService,
    public authService: AuthenticationService,
    private title: Title,
    private meta: Meta,
    private metaService: MetaService
  ) {
    route.data.subscribe((data) => {
      this.headerBg = data.headerImage;
    });
    this.subscriptions.push(
      this.authService.loggedIn.subscribe(r => this.loginStatusChanged(r))
    );
  }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.loading = true;
      if (params['tracking']) {
        this.jlsNo = params['tracking'];
        this.showHistory = false;
        this.shipmentService.loadOrder(this.jlsNo)
          .catch(err => {
            this.loading = false;
            this.error = true;
          })
          .then((o: Shipment) => {
            this.order = o;
            this.loading = false;
          });
        this.shipmentService.loadTracking(this.jlsNo)
          .then(t => { this.tracking = t; });
      } else {
        this.showHistory = true;
        if (this.orderHistory.length === 0)
          this.shipmentService.loadHistory()
            .then(h => {this.orderHistory = h; this.loading = false; });
        this.shipmentService.loadTrackingCarriers()
          .then(c => { this.trackingCarriers = c; });
      }
    }));
    this.title.setTitle(TRACKING_META.title);
    this.metaService.removeItemprop();
    TRACKING_META.meta.forEach(m => this.meta.updateTag(m));
  }

  handleOrderSelection(o: any) {
    this.router.navigate(['information', o.jlsNo]);
  }

  handleDocumentRequest(e: any) {
    this.loading = true;
    this.shipmentService.loadDocument(e.jls, e.type, e.carrierLine, e.idcShipper, e.idcConsignee)
        .then((file: Blob) => {
          this.loading = false;
          this.serveFile(file);
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
  }

  loginStatusChanged(loggedIn: boolean) {
    this.isAuthenticated = loggedIn;

    if (loggedIn) {
      this.loading = true;
      this.shipmentService.loadHistory()
        .then(h => { this.orderHistory = h; this.loading = false; });
    }
  }

  private serveFile(file: Blob) {
    const fileUrl = window.URL.createObjectURL(file);
    window.open(fileUrl);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
