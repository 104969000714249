import { OnInit } from '@angular/core';
import { BlogService } from '../../services/blog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';
import { BLOG_META } from '../meta';
var BlogComponent = /** @class */ (function () {
    function BlogComponent(blogService, route, router, title, meta, metaService) {
        var _this = this;
        this.blogService = blogService;
        this.route = route;
        this.router = router;
        this.title = title;
        this.meta = meta;
        this.metaService = metaService;
        this.blogPosts = [];
        this.blogTags = [];
        this.subscriptions = [];
        this.showAllTags = false;
        this.urlpage = 0;
        route.data.subscribe(function (data) {
            _this.headerBg = data.headerImage;
        });
        this.routeSub = this.route.paramMap.subscribe(function (params) {
            var param1 = params.get("tagname");
            var param2 = params.get("pagenumber");
            _this.urlpage = parseInt(param2);
            console.log(param1);
            console.log(param2);
            try {
                if (typeof param1 !== "undefined" &&
                    param1 !== null &&
                    typeof param2 !== "undefined" &&
                    param2 !== null) {
                    _this.blogService.FilterAndPageChange(param1, param2);
                }
            }
            finally {
            }
            try {
                if (typeof param1 !== "undefined" &&
                    param1 !== null) {
                    _this.blogTagFilter = param1;
                }
                else {
                    _this.blogTagFilter = "";
                }
            }
            finally {
            }
            try {
                if (typeof param2 !== "undefined" &&
                    param2 !== null) {
                    if (param2 == "01") {
                        _this.blogService.startOver();
                    }
                    else {
                        _this.currentPage = parseInt(param2);
                    }
                }
            }
            finally {
            }
        });
        this.subscriptions.push(this.blogService.posts$.subscribe(function (posts) {
            _this.blogPosts = posts;
            if (_this.blogService.totalPages < _this.urlpage) {
                _this.metaService.overrideCanonicalURL(document.location.href.replace('/page/' + _this.urlpage.toString(), '/page/' + _this.blogService.totalPages.toString()));
            }
            else {
                _this.metaService.createCanonicalURL();
            }
            var theTitle = BLOG_META.title + (_this.blogTagFilter.length > 0 ? " | Category " + _this.capitalizeFirstLetter(_this.blogTagFilter) : "") + " | Page " + _this.currentPage.toString() + " of " + _this.totalPages.toString();
            if (theTitle.length > 64) {
                theTitle = BLOG_META.title + (_this.blogTagFilter.length > 0 ? " | " + _this.capitalizeFirstLetter(_this.blogTagFilter) : "") + " | Page " + _this.currentPage.toString() + " of " + _this.totalPages.toString();
            }
            if (theTitle.length > 64) {
                theTitle = BLOG_META.title + (_this.blogTagFilter.length > 0 ? " | " + _this.capitalizeFirstLetter(_this.blogTagFilter) : "") + " | " + _this.currentPage.toString() + " of " + _this.totalPages.toString();
            }
            if (theTitle.length > 64) {
                theTitle = BLOG_META.title.replace("- Shipping Done Rite", "") + (_this.blogTagFilter.length > 0 ? " | " + _this.capitalizeFirstLetter(_this.blogTagFilter) : "") + " | " + _this.currentPage.toString() + " of " + _this.totalPages.toString();
            }
            _this.title.setTitle(theTitle);
            // this.title.setTitle(BLOG_META.title + (this.blogTagFilter.length > 0 ? " | Category " + this.capitalizeFirstLetter(this.blogTagFilter) : "") + " | Page " + this.currentPage.toString() + " of " + this.totalPages.toString());
        }));
        this.subscriptions.push(this.blogService.tags$.subscribe(function (tags) {
            _this.blogTags = tags;
        }));
    }
    BlogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.metaService.createCanonicalURL();
        this.title.setTitle(BLOG_META.title); // + (this.blogTagFilter.length > 0 ? " | Category " + this.capitalizeFirstLetter(this.blogTagFilter) : "") + " | Page " + this.currentPage.toString() + " of " + this.totalPages.toString());
        this.metaService.removeItemprop();
        BLOG_META.meta.forEach(function (m) { return _this.meta.updateTag(m); });
    };
    Object.defineProperty(BlogComponent.prototype, "currentPage", {
        get: function () {
            return this.blogService.currentPage;
        },
        set: function (newPage) {
            this.blogService.currentPage = newPage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BlogComponent.prototype, "totalPages", {
        get: function () {
            return this.blogService.totalPages;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BlogComponent.prototype, "blogTagFilter", {
        get: function () {
            return this.blogService.blogTagFilter;
        },
        set: function (newPageFiler) {
            this.blogService.blogTagFilter = newPageFiler;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BlogComponent.prototype, "filteredBlogs", {
        get: function () {
            return this.blogPosts;
        },
        enumerable: true,
        configurable: true
    });
    BlogComponent.prototype.capitalizeFirstLetter = function (aString) {
        return aString.charAt(0).toUpperCase() + aString.slice(1);
    };
    return BlogComponent;
}());
export { BlogComponent };
