import {
  Directive,
  ElementRef,
  OnInit,
  Output,
  EventEmitter
} from "@angular/core";
import { Location } from "../../models";

declare var google: any;

@Directive({
  selector: "[google-place]"
})
export class GooglePlacesDirective implements OnInit {
  @Output() public onSelect: EventEmitter<any> = new EventEmitter();

  private element: HTMLInputElement;

  constructor(elRef: ElementRef) {
    this.element = elRef.nativeElement;
  }

  getFormattedAddress(place) {
    let selectedLocation = new Location();
    let location_obj = {};

    console.log("My Google Place Directive getFormattedAddress ... ", place);

    for (let i in place.address_components) {
      let item = place.address_components[i];

      if (item["types"].indexOf("locality") > -1) {
        location_obj["locality"] = item["long_name"];
      } else if (item["types"].indexOf("administrative_area_level_1") > -1) {
        location_obj["admin_area_l1"] = item["short_name"];
      } else if (item["types"].indexOf("street_number") > -1) {
        location_obj["street_number"] = item["short_name"];
      } else if (item["types"].indexOf("route") > -1) {
        location_obj["route"] = item["short_name"];
      } else if (item["types"].indexOf("country") > -1) {
        location_obj["country"] = item["long_name"];
      } else if (item["types"].indexOf("postal_code") > -1) {
        location_obj["postal_code"] = item["short_name"];
      }
    }
    location_obj["formatted_address"] = place.formatted_address;

    selectedLocation.Name = place.name;
    selectedLocation.AddressLineOne =
      location_obj["street_number"] +
      (location_obj["street_number"] != null ? " " : "") +
      location_obj["route"];
    selectedLocation.PostalCode = location_obj["postal_code"] || "";
    selectedLocation.StateOrProvince = location_obj["admin_area_l1"] || "";
    selectedLocation.City = location_obj["locality"] || "";
    selectedLocation.CountryString = (location_obj["country"] || "") == "United States" ? "USA" : ((location_obj["country"] || "") == "Canada" ? "CAN" : "" );

    // make sure all fields are defined
    selectedLocation.Name =
      !selectedLocation.Name || selectedLocation.Name.includes("undefined")
        ? ""
        : selectedLocation.Name;
    selectedLocation.PhoneOne =
      !selectedLocation.PhoneOne ||
      selectedLocation.PhoneOne.includes("undefined")
        ? ""
        : selectedLocation.PhoneOne;
    selectedLocation.AddressLineOne =
      !selectedLocation.AddressLineOne ||
      selectedLocation.AddressLineOne.includes("undefined")
        ? ""
        : selectedLocation.AddressLineOne;
    selectedLocation.PostalCode =
      !selectedLocation.PostalCode ||
      selectedLocation.PostalCode.includes("undefined")
        ? ""
        : selectedLocation.PostalCode;
    selectedLocation.StateOrProvince =
      !selectedLocation.StateOrProvince ||
      selectedLocation.StateOrProvince.includes("undefined")
        ? ""
        : selectedLocation.StateOrProvince;
    selectedLocation.City =
      !selectedLocation.City || selectedLocation.City.includes("undefined")
        ? ""
        : selectedLocation.City;
    selectedLocation.CountryString =
      !selectedLocation.CountryString || selectedLocation.CountryString.includes("undefined")
        ? ""
      : selectedLocation.CountryString;
    if (selectedLocation.Name == selectedLocation.AddressLineOne)
      selectedLocation.Name = "";

    return selectedLocation;
  }

  ngOnInit() {
    console.log("Google place directive init");
    // Create a new session token.
    const autocomplete = new google.maps.places.Autocomplete(this.element, {
      fields: [
        "address_components",
        "formatted_address",
        "name"
      ]
    });
    //Event listener to monitor place changes in the input
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      this.onSelect.emit(this.getFormattedAddress(autocomplete.getPlace()));
    });
  }
}
