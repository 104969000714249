var BlogPost = /** @class */ (function () {
    function BlogPost() {
        this.ID = 0;
        this.AuthorName = "";
        this.BlogSummary = "";
        this.BlogContent = "";
        this.BlogTitle = "";
        this.AuthorName = "";
        this.BlogDate = "";
        this.BlogTags = [];
        this.MetaDescription = "";
    }
    Object.defineProperty(BlogPost.prototype, "Image", {
        get: function () {
            return this.BlogImage.toString();
        },
        enumerable: true,
        configurable: true
    });
    return BlogPost;
}());
export { BlogPost };
