/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../account/password-reset-form/password-reset-form.component.ngfactory";
import * as i2 from "../../account/password-reset-form/password-reset-form.component";
import * as i3 from "../../services/account.service";
import * as i4 from "../../shared/flash/flash.service";
import * as i5 from "./password-reset.component";
import * as i6 from "@angular/router";
var styles_PasswordResetComponent = [];
var RenderType_PasswordResetComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PasswordResetComponent, data: {} });
export { RenderType_PasswordResetComponent as RenderType_PasswordResetComponent };
export function View_PasswordResetComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "article", [["class", "page page-account"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "header", [], [[4, "background-position", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h1", [["class", "text-light"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["My Account"])), (_l()(), i0.ɵeld(5, 0, null, null, 6, "section", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "row pt-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "rtrt-password-reset-form", [], null, [[null, "onReset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onReset" === en)) {
        var pd_0 = (_co.handleReset($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PasswordResetFormComponent_0, i1.RenderType_PasswordResetFormComponent)), i0.ɵdid(11, 49152, null, 0, i2.PasswordResetFormComponent, [i3.AccountService, i4.FlashService], { id: [0, "id"], token: [1, "token"] }, { onReset: "onReset" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.id; var currVal_2 = _co.token; _ck(_v, 11, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = "left"; _ck(_v, 1, 0, currVal_0); }); }
export function View_PasswordResetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "rtrt-password-reset", [], null, null, null, View_PasswordResetComponent_0, RenderType_PasswordResetComponent)), i0.ɵdid(1, 49152, null, 0, i5.PasswordResetComponent, [i6.Router, i6.ActivatedRoute], null, null)], null, null); }
var PasswordResetComponentNgFactory = i0.ɵccf("rtrt-password-reset", i5.PasswordResetComponent, View_PasswordResetComponent_Host_0, {}, {}, []);
export { PasswordResetComponentNgFactory as PasswordResetComponentNgFactory };
