import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from '../services/config.service';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { LDFlagValue } from 'launchdarkly-js-client-sdk';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class LaunchDarklyService implements OnDestroy {
  client: LaunchDarkly.LDClient;

  async ngOnDestroy() {
      await this.client.close();
  }
  constructor(
    private config: ConfigService
  ) {
    this.initialize();
  }
  initialize(): void {
      const context = {
          kind: "user",
          key: "default"
      } as LaunchDarkly.LDContext;
      this.client = LaunchDarkly.initialize(this.config.getConfig("launchDarklyClientId"), context);
  }

  getFlag(flagKey: string, defaultValue: LDFlagValue = false): Observable<LDFlagValue> {
      const fetchFlag = new Subject<void>();

      this.client.on(`change:${flagKey}`, () => {
          fetchFlag.next();
      });
      this.client.waitUntilReady().then(() => {
          fetchFlag.next();
      });
      return fetchFlag.pipe(
          map(() => {
              return this.client.variation(flagKey, defaultValue);
          })
      );
  }
}