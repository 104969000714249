import { formatDate } from '@angular/common';

export class CarrierReview {
    carrierId: string;
    comments: string;
    name: string;
    rating: number;
    date: Date;
    
    get formattedDate() {
        return formatDate(this.date, 'longDate', 'en-US');
    }
    constructor() {
        this.date = new Date();
    }
}
