/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./quote-history.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./quote-history.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../services/meta-service.service";
var styles_QuoteHistoryComponent = [i0.styles];
var RenderType_QuoteHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuoteHistoryComponent, data: {} });
export { RenderType_QuoteHistoryComponent as RenderType_QuoteHistoryComponent };
export function View_QuoteHistoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" quote-history works!\n"]))], null, null); }
export function View_QuoteHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-quote-history", [], null, null, null, View_QuoteHistoryComponent_0, RenderType_QuoteHistoryComponent)), i1.ɵdid(1, 114688, null, 0, i2.QuoteHistoryComponent, [i3.Title, i3.Meta, i4.MetaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuoteHistoryComponentNgFactory = i1.ɵccf("rtrt-quote-history", i2.QuoteHistoryComponent, View_QuoteHistoryComponent_Host_0, {}, {}, []);
export { QuoteHistoryComponentNgFactory as QuoteHistoryComponentNgFactory };
