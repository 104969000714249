import { Component, OnInit } from '@angular/core';

declare global {
  interface Window { prerenderReady: boolean; }
}

@Component({
  selector: 'rtrt-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor() { 
    window.prerenderReady = false;
  }

  ngOnInit() {
    var meta = document.createElement('meta');
    meta.name = "prerender-status-code";
    meta.content = "404";
    document.getElementsByTagName('head')[0].appendChild(meta);

    var meta2 = document.createElement('meta');
    meta2.name = "robots";
    meta2.content = "noindex";
    document.getElementsByTagName('head')[0].appendChild(meta2);

    console.log("prerenderReady=" + window.prerenderReady);
    window.prerenderReady = true;
  }

}
