import { Shipment } from './shipment.model';
import { Location } from './location.model';
import { Accessorial } from './accessorial.model';
import { Carrier } from './carrier.model';
import { Payment } from './payment.model';
import { FinalMileEstimate } from './final-mile-estimate.model';

export class FinalMileShipment {
    Vendors: Shipment[];
    BillToLocation: Location;
    Consignee: Location;
    ContactEmail: string;
    MrNo: string;
    AccessorialAR: Accessorial[];
    DeliveryNotes: string;
    ChargeAccount: boolean = false;
    PaymentData: Payment;
    PaymentIntentSecret: string;
    Rates: FinalMileEstimate;
    FinalMileOnly: boolean = false;
    constructor() {
        this.BillToLocation = new Location();
        this.Consignee = new Location();
        this.Vendors = [];
        this.AccessorialAR = [];
        this.ContactEmail = "";
        this.MrNo = "";
        this.DeliveryNotes = "";
    }
}
