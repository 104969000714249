<article class="page page-quote-history pb-4">
    <header>
      <div class="container">
        <h1 class="text-light">My Account</h1>
      </div>
    </header>
    <section class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="container">
            <div class="row b-bottom-secondary pt-5">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <h2>Quote History</h2>
                <a class="btn btn-primary btn-mini" routerLink="/account/profile">Manage Profile</a>
              </div>
            </div>
            <div class="row">
              <div class="col-12 py-3">
                <p class="d-inline-block">
                  <rtrt-spinner [visible]="!history"></rtrt-spinner>
                </p>
              </div>
            </div>
            <div class="row align-items-center justify-content-between pb-5">
              <div class="col-12 col-sm-8 flex-grow">
                  <input type="text" class="form-control" placeholder="Search quotes" [(ngModel)]="search" />
              </div>
              <div class="col-12 col-sm-3">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="MM/DD/YYYY" [(ngModel)]="searchDate" ngbDatepicker placement="bottom-right" #d="ngbDatepicker" required />
                  <button class="input-group-addon" (click)="d.toggle()" type="button">
                    <img class="icon" src="/assets/images/icon-color-calendar.svg" />
                  </button>
                </div>
              </div>
              <div class="col-1"><button type="button" class="btn btn-mini" (click)="clearFilters()">Clear</button></div>
            </div>

            <div class="row" *ngIf="history?.length === 0">
              <div class="col-12">
                <p>You have no recent quotes.</p>
              </div>
            </div>
            <div *ngIf="history?.length > 0">
              
              <div class="row">
                <div class="col-12 px-0">
                  <ngb-pagination *ngIf="!this.isSearching" [collectionSize]="collectionSize" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [(page)]="page" aria-label="Quote History Pagination"></ngb-pagination>
                </div>
              </div>
              <rtrt-quote-history [history]="history"></rtrt-quote-history>
              <!-- <div class="row">
                <p class="col-3"><strong>Quote Started</strong></p>
                <p class="col-3"><strong>Quote Number</strong></p>
                <p class="col-2"><strong>Origin</strong></p>
                <p class="col-2"><strong>Destination</strong></p>
              </div>
              <div class="row align-items-center b-top-secondary pt-3" *ngFor="let quote of history">
                <p class="col-3">
                  {{quote.orderDate}}
                </p>
                <p class="col-3">{{quote.jlsNo}}</p>
                <p class="col-2">
                  {{quote.shipperZipCode}}
                </p>
                <p class="col-2">
                  {{quote.consigneeZipCode}}
                </p>
                <p class="col-2 text-right">
                  <button type="button" [routerLink]="'/quote/' + quote.jlsNo" class="btn btn-mini">Continue quote</button>
                </p>
              </div> -->
              <div class="row">
                <div class="col-12 mt-2 px-0">
                  <ngb-pagination *ngIf="!this.isSearching" [collectionSize]="collectionSize" [maxSize]="5" [rotate]="true" [ellipses]="false"
                    [boundaryLinks]="true" [(page)]="page" aria-label="Quote History Pagination"></ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </article>