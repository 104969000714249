/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tracking.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./order-history.component.ngfactory";
import * as i5 from "./order-history.component";
import * as i6 from "../../services/order.service";
import * as i7 from "./shipment-tracking.component.ngfactory";
import * as i8 from "./shipment-tracking.component";
import * as i9 from "../../shared/spinner/spinner.component.ngfactory";
import * as i10 from "../../shared/spinner/spinner.component";
import * as i11 from "./tracking.component";
import * as i12 from "../../services/authentication.service";
import * as i13 from "../../services/shipment.service";
import * as i14 from "../../services/payment.service";
import * as i15 from "@angular/platform-browser";
import * as i16 from "../../services/meta-service.service";
var styles_TrackingComponent = [i0.styles];
var RenderType_TrackingComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TrackingComponent, data: {} });
export { RenderType_TrackingComponent as RenderType_TrackingComponent };
function View_TrackingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "container pt-5 pb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sorry, there was a problem loading your shipment information. Please "])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 3), (_l()(), i1.ɵted(-1, null, ["contact us"])), (_l()(), i1.ɵted(-1, null, [" if you need assistance. "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 5, 0, "/help", "contact", _co.jlsNo); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_TrackingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-order-history", [], null, [[null, "onSelectOrder"], [null, "onDocRequest"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelectOrder" === en)) {
        var pd_0 = (_co.handleOrderSelection($event) !== false);
        ad = (pd_0 && ad);
    } if (("onDocRequest" === en)) {
        var pd_1 = (_co.handleDocumentRequest($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_OrderHistoryComponent_0, i4.RenderType_OrderHistoryComponent)), i1.ɵdid(1, 114688, null, 0, i5.OrderHistoryComponent, [i6.OrderService], { orders: [0, "orders"], loggedIn: [1, "loggedIn"], carriers: [2, "carriers"] }, { onSelectOrder: "onSelectOrder", onDocRequest: "onDocRequest" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.orderHistory; var currVal_1 = _co.isAuthenticated; var currVal_2 = _co.trackingCarriers; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_TrackingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-shipment-tracking", [], null, [[null, "onDocRequest"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDocRequest" === en)) {
        var pd_0 = (_co.handleDocumentRequest($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ShipmentTrackingComponent_0, i7.RenderType_ShipmentTrackingComponent)), i1.ɵdid(1, 49152, null, 0, i8.ShipmentTrackingComponent, [i6.OrderService, i2.Router], { shipment: [0, "shipment"], tracking: [1, "tracking"] }, { onDocRequest: "onDocRequest" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.order; var currVal_1 = _co.tracking; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TrackingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container pt-5 pb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "rtrt-spinner", [], null, null, null, i9.View_SpinnerComponent_0, i9.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i10.SpinnerComponent, [], { visible: [0, "visible"], width: [1, "width"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrackingComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrackingComponent_4)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = "100%"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (!_co.loading && _co.showHistory); _ck(_v, 4, 0, currVal_2); var currVal_3 = (!_co.loading && !_co.showHistory); _ck(_v, 6, 0, currVal_3); }, null); }
export function View_TrackingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "article", [["class", "page page-help"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "section", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrackingComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrackingComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.error; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_TrackingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-tracking", [], null, null, null, View_TrackingComponent_0, RenderType_TrackingComponent)), i1.ɵdid(1, 245760, null, 0, i11.TrackingComponent, [i12.AuthenticationService, i2.ActivatedRoute, i2.Router, i13.ShipmentService, i14.PaymentService, i12.AuthenticationService, i15.Title, i15.Meta, i16.MetaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TrackingComponentNgFactory = i1.ɵccf("rtrt-tracking", i11.TrackingComponent, View_TrackingComponent_Host_0, {}, {}, []);
export { TrackingComponentNgFactory as TrackingComponentNgFactory };
