export const SERVICES = {
    copy: `
        <h2>LTL Freight Quotes</h2>
        <p>RiteRouting provides less-than-truckload (LTL) freight quotes powered by cutting-edge technology and backed by experienced LTL shipping professionals.</p>
        <p>Our dedicated LTL shipping team helps your organization ship freight more efficiently and cost-effectively by utilizing our large LTL carrier network. RiteRouting provides you with multiple LTL freight quotes to choose from, ensuring that you get the best cost for your shipment, and the right service level for your requirements - every time. No more time-consuming LTL rate shopping and comparing multiple LTL carriers and LTL freight quotes.</p>
        
        <h2 class="mt-5">What is LTL Shipping?</h2>
        <p>Less-than-truckload (LTL) shipping allows multiple freight shippers to share space on a truck. LTL is the best option for shippers that do not want to, or need to, use an entire truck and/or have smaller shipments that fit on a standard pallet.</p>
        <p>LTL carriers have published freight rates for every transportation route (or lane) they service and for every freight class they haul in their transportation network. Freight classes are determined by the stowability, liability, ease of handling, and density of a product. Every product that can be moved via LTL is assigned a freight class. Add in dimensional weight, distance, and negotiated volume breaks and LTL shipping can be very complicated.</p>
        <p>Navigating the complexities of LTL shipping is one of the many benefits of working with RiteRouting. We’ll complete your shipment with exacting attention to detail and provide the carrier with correct information so you avoid shipping delays and costly errors.</p>
        <p>Logistics never stops and neither does RiteRouting.</p>
        
        <h2 class="mt-5">When to Use LTL Freight:</h2>
        <ul>
            <li>When your shipment is too big for parcel, but too small to fill a truck</li>
            <li>When you are shipping 1-10 pallets</li>
            <li>When your shipment is less than 10,000 pounds</li>
        </ul>

        <h2 class="mt-5">Let’s get started!</h2>
        <p><a (click)="routeTo('/ltl-shipping-quote')">Start your LTL quote</a> today for Shipping Done Rite!</p>
    `
};

export const FINALMILE = {
    copy: `
        <h2>Final Mile Delivery</h2>
        <p>Online sales of furniture and home furnishings are booming, accounting for 12% of eCommerce sales in the U.S.* 
        Is your business able to handle final mile delivery of furniture to your customers?</p>
        <p>With RiteRouting on your logistics team, the answer is yes!</p>

        <p>RiteRouting provides unrivaled final mile delivery services for furniture manufacturers, brick and mortar furniture retailers, 
        and online retailers with multiple service levels and a vast network of last mile carriers throughout the U.S.</p>

        <p>Your customers’ experience with your brand doesn’t end with the sale; it also includes the delivery and set-up experience, 
        and you need a final mile logistics partner that can fulfill your brand promise. Falling short of your customers’ expectations is not an option.</p>

        <p>RiteRouting offers national furniture last mile delivery that helps you earn customer loyalty and avoid costly service failures and returns. 
        Service level options include white glove, threshold, or curbside delivery to meet your customers' needs and expectations.</p>
        
        <h2 class="mt-5">White Glove Delivery</h2>
        <p>White Glove final mile furniture delivery service includes delivery to your customer’s home, where it is unpacked in their room of choice, 
        assembled, and packing materials removed. Customer signature is required.</p>
        
        <h2 class="mt-5">Threshold Delivery</h2>
        <p>Threshold delivery is available for customers that simply need their furniture delivered inside their garage or front door. 
        No additional services are provided and customer signature is required.</p>

        <h2 class="mt-5">Curbside Delivery</h2>
        <p>Curbside delivery is dropped off outside of your customer’s residence and customer signature is required.</p>

        <p>If you’re looking for a last mile logistics partner with the experience to handle your furniture delivery, <a (click)="routeTo('/final-mile-delivery/start')">Start your Final Mile quote</a> today for Shipping Done Rite!</p>

        <p class="disclaimer">source: Statista, March 2020</p>
    `
};

export const LTL = {
    copy: `
        <h2>Less than Truckload</h2>
        <p>A less than truckload shipment (LTL) is usually between 150lbs to 20,000lbs.  The shipment will consist of less than 10 skid spots (if skids can stack, can ship up
            to 20 skids) and take up less than 20’ of trailer space. </p>
        <p>Less than truckload carriers collect freight from multiple shippers and consolidate the freight onto one trailer. The consolidation occurs after pick up at the <strong>origin terminal</strong>.   Every evening carriers depart with freight loaded on a trailer destined to cities throughout the United States.  This is called the <strong>linehaul</strong>.</p>
        <p>The trailers then arrive at either a <strong>break bulk</strong> facility for additional consolidation, sorting and re-distribution or they arrive at the
        <strong>destination terminal</strong>.  The destination terminal is the terminal which will make the final delivery to the consignee (<strong>consignee</strong> is the receiver of the shipment).</p>
        <ul>
            <li>
                The typical day for an LTL driver will be deliveries first, followed by pickups and finally return to the terminal.  Most deliveries are made in the morning and most pickups are made in the afternoon.
            </li>
            <li>
                Not all LTL carriers pick up in every state.  There are regional LTL carriers which pick up in a specific area in the United States.  If an LTL carrier picks up freight destined to a consignee in a state they do not service, they will hand this off to an <strong>interline carrier</strong>.   An interline/partner carrier will make the final delivery.  These types of shipments will have a higher cost associated with them as there are multiple carriers to pay for the freight transport.  Other carriers are called national carriers and they will service the entire United States typically.
                <ul>
                    <li>There is a print out in the training manual showing carriers we utilize at JLS and their respective service footprints.</li>
                </ul>
            </li>
        </ul>
        <h4>How LTL carriers charge:</h4>
        <ul>
            <li>
                An LTL carrier will charge based on the following:  distance shipment is traveling (the longer the linehaul, the higher price); weight of shipment; classification of freight/Freight All Kinds (FAK); and applicable accessorial charges.
                <ul>
                    <li>The <span class="emphasis underline">distance a shipment will travel</span> will contribute to the linehaul charge.  This rate is determined based upon the pickup zip code and delivery zip code.  At JLS our rates are based on a CZARlite 99.  This is the base rate software used to calculate the rate per hundred weight for a shipment.</li>
                    <li><span class="emphasis underline">Weight of the shipment</span> – this is declared on the bill of lading (this is the document showing the carrier the delivery specifics, weight and class of the shipment)</li>
                    <li><span class="emphasis underline">Classification of the freight</span> – this is assigned by the National Motor Freight Transportation Association (NMFTA) and the corresponding number assigned is called the NMFC # (National Motor Freight Classification).  This is the tool used to compare commodities and assign the class to them.  Class is assigned based on an evaluation of density, ease of handling, liability and ease of transport.   Classes are as follows:  50; 55; 60; 65; 70; 77.5; 85; 92.5; 100; 125; 150; 175; 200; 250; 300; 400; 500.  Freight shipping at class 50 is the lowest cost class.  The cost goes up as the freight class increases.
                    <br /><br />Another component of class is Freight All Kinds (FAK).   FAKs were set up to help shippers who ship items at multiple freight classes ship all those shipments at the same class or within a class structure.</li>
                    <li><span class="emphasis underline">Discounts</span> – JLS contracts with carriers to apply a discount to each shipment.  Discounts are negotiated by JLS with the carries.   Discounts take into effect the class of freight shipped, shipping lanes the carrier will use, dimensions of product, frequency of shipments, volume of freight shipped.  Examining all these parameters a negotiated discount will be applied on the CZARlite to create the rate.  Discounts are then negotiated between JLS and our clients. </li>
                    <li><span class="emphasis underline">Minimum charges</span> – each carrier will also negotiate with JLS a minimum charge for a shipment.  This is the lowest cost they are willing to accept to move the freight.</li>
                </ul>
            </li>
            <li>
                Additional charges for LTL shipments:
                <ul>
                    <li><span class="emphasis underline">Fuel Surcharge</span> – JLS has a negotiated fuel rate with the carriers.  The fuel rate is a percentage of the linehaul.    For example:  if linehaul is $100; Fuel is at 15%; the fuel surcharge will be $15 for a total of $115</li>
                    <li><span class="emphasis underline">Accessorials</span> – An accessorial charge is defined by charges made for additional, supplemental, or special services performed in addition to the basic transporation service charge.  Examples of common accessorial charges are: liftgates; inside delivery fees; limited access/residential delivery charges; reweigh fees.</li>
                </ul>
            </li>
        </ul>
    `
};

export const ABOUT = {
    copy: `
        <h2>RiteRouting provides transportation management and LTL shipping services for middle market manufacturers and distributors.</h2>
        <p>RiteRouting provides non-contractual LTL freight shipping services to improve your supply chain efficiency and to better manage your transportation costs. Utilizing our advanced Transportation Management System (TMS), our centralized routing center receives information daily on all inbound and outbound shipments to provide transparency into all of your shipments. We negotiate lower transportation costs because of our volume buying power and pass the savings on to you.</p>
        <p>Most importantly, we deliver peace-of-mind by handling each step of the logistics process, allowing you to focus on your business.</p>
        <h3>RiteRouting saves you time and money by handling your:</h3>
        <ul>
            <li>Carrier negotiations</li>
            <li>Carrier selection</li>
            <li>Calling/dispatching</li>
            <li>BOL generation</li>
            <li>Freight bill processing</li>
            <li>Report generation</li>
            <li>Vendor compliance</li>
            <li>General overhead expenses</li>
        </ul>
        <h3>RiteRouting Guarantees You:</h3>
        <ul>
            <li>Consistent LTL Contract Rates based on origin, destination and weight – regardless of carrier</li>
            <li>Lower Fuel Surcharge and Fewer General Rate Increases</li>
            <li>No Transactional or Management Fees</li>
            <li>No Contract Required</li>
        </ul>
        <h3>RiteRouting Promises:</h3>
        <p><strong>Quality</strong> – on all levels; in every service we provide</p>
        <p><strong>Great People</strong> – reliable and professional</p>
        <p><strong>Extreme Levels of Customer Service</strong> – dedicated to your satisfaction</p>
        <p><strong>Innovative Solutions</strong> – leading-edge technology and web-based software</p>

        <p>RiteRouting is part of the Jarrett family of companies that provides end-to-end supply chain solutions for organizations of all sizes, 
        from Fortune 500 companies to small, family owned businesses. RiteRouting’s sister companies include <a href="https://www.gojarrett.com/logistics" target="_blank">Jarrett Logistics Systems</a>, 
        <a href="https://www.gojarrett.com/warehousing" target="_blank">Jarrett Warehousing</a>, and <a href="https://www.gojarrett.com/fleet-services" target="_blank">Jarrett Fleet Services</a>.</p>
    `
};

export const PRIVACY_POLICY = {
    copy: `
        <h1>Privacy Policy</h1>
        <p>RiteRouting respects and is committed to protecting your privacy. RiteRouting may collect personally identifiable information when you visit our site. 
        RiteRouting also automatically receives and records information on our server logs from your browser including your IP address, cookie information and the page(s) you visited. 
        RiteRouting will not sell your personally identifiable information to anyone. RiteRouting uses Google Maps API as a part of a distance calculation. 
        By using RiteRouting, you are bound to Google's Privacy Policy.</p>
        <p><strong>Security Policy</strong></p>
        <p>RiteRouting has obtained an SSL digital certificate from a leading provider of Internet trust services. 
        When a secured portion of our site is entered, an image of a closed lock should appear in the bottom bar or the address bar of the browser window. 
        If this image is clicked, a small popup window displaying site-security information will appear. 
        This certificate certifies that your personal information is being transmitted in secure (encrypted) form to a RiteRouting server.</p>
    `
};

export const TERMS = {
    copy: `
    <h1>Terms &amp; Conditions</h1>
    <p>These Customer Terms and Conditions ("Terms and Conditions") apply to all shipments tendered to RiteRouting for which RiteRouting agrees to arrange transportation. </p>
    <p>RiteRouting is a transportation broker licensed by the Federal Motor Carrier Safety Administration ("FMCSA") in Docket Number MC 369240 to arrange for the transportation of certain general commodities ("Goods") by motor carriers ("Carriers").</p>
    <p>By tendering Goods to RiteRouting, the shipper (“Shipper”) agrees to be bound by these Terms and Conditions.</p>
    <p>The parties enter into these Terms and Conditions in accordance with 49 U.S.C. Section 14101(b)(1) and expressly waive any and all rights and remedies that each may have under 49 U.S.C. §§ 13101 through 14914 that are contrary to specific provisions of these Terms and Conditions.</p>
    <ol type="1">
    <li>
        <strong><em>Services</em></strong>
        <p>RiteRouting agrees to arrange for transportation services as set forth in these Terms and Conditions. These Terms and Conditions apply to all transportation services arranged by RiteRouting on behalf of Shipper. RiteRouting's responsibility under this agreement is limited to arranging for, but not actually performing, transportation of the Goods.</p>
    </li>
    <li>
        <strong><em>Independent Contractor Relationship </em></strong>
        <p>The Parties understand and agree that the relationship between the Parties is and will remain that of independent contractors and that no employer/employee or principal-agent relationship exists or is intended.</p>
    </li>
    <li>
        <strong><em>RiteRouting Compensation</em></strong><br />
        <ol type="A">
        <li><em>RiteRouting will invoice Shipper for its services based on these Terms and Conditions and negotiated rates and charges.</em></li>
        <li><em>Shipper agrees to pay RiteRouting within 15 calendar days of invoice date without deduction or setoff. RiteRouting may assess a service charge of 1 ½% per month (or the highest lawful rate, if less) on any late payments.</em></li>
        <li><em>If Shipper does not pay the invoiced amounts, RiteRouting may commence a civil action to recover such invoiced amounts within 24 months of delivery or tender of delivery of the shipments involved</em></li>
        </ol>
    </li>
    <li>
        <strong><em>Bills of Lading and Shipping Documentation</em></strong>
        <p>Shipper's insertion of RiteRouting's name on any bill of lading or shipping document will be for Shipper's convenience only and will not change RiteRouting's status as a broker. The terms and conditions of any documentation used by Shipper or a Carrier will not supplement, alter, or modify the terms of these Terms and Conditions.</p>
    </li>
    <li>
        <strong><em>Cargo Loss, Damage, and Delay</em></strong><br />
        <ol type="A">
        <li>Shipper understands and agrees that RiteRouting is not a motor carrier or freight forwarder, and that RiteRouting will not be liable for loss, damage, or delay in connection with the transportation of Goods.  If requested by Shipper and agreed to by RiteRouting, RiteRouting may assist Shipper in the filing and/or processing of claims with Carriers.</li>
        <li>
            Shipper acknowledges that Carriers will not be liable for the following:<br />
            <ol type="1">
            <li>damage to the Goods to the extent due to packaging, loading, unloading, blocking, bracing or securing of the Goods (unless Carrier has provided loading or unloading services at Shipper’s request, in which case such Carrier may be liable for cargo damage caused by such loading or unloading services),</li>
            <li>inherent vice or defect in the Goods, including rusting of metals, swelling of wood caused by humidity, moisture or condensation, or deterioration of perishable products;</li>
            <li>an act of God or the public enemy;</li>
            <li>any act or default of any Shipper, consignee, consignor, or beneficial owner of the Goods;</li>
            <li>any act taken under authority of law; or</li>
            <li>any act of war or terrorism.</li>
            </ol>
        </li>
        <li>
            Shipper agrees that liability of any Carrier for cargo loss, damage, and delay occurring within the United States will be the least of the following:<br />
            <ol type="1">
            <li>The actual value of the Goods,</li>
            <li>Repair cost of the Goods,</li>
            <li>Liability as defined by the carriers’ tariff,</li>
            <li>.10 per pound for any used or refurbished Goods,</li>
            <li>$5 per pound for new Goods or</li>
            <li>$100,000 per shipment. $50,000 per occurrence.</li>
            </ol>
        </li>
        <li>
            Shipper acknowledges that RiteRouting may offer Transactional Excess Liability Coverage, which they have secured through a Third Party, for LTL and TL shipments that can be purchased by the Shipper should they wish to cover the full value of the shipment in the event of loss or damage. The terms and conditions are as follows:<br />
            <ol type="1">
                <li>
                    This excess coverage would cover the value of the freight based on destination market value and the freight charge for the following goods: <br />
                    <ol type="a">
                        <li>Metal – Aluminum, Copper, Steel – New</li>
                        <li>Miscellaneous/Other</li>
                        <li>Furniture – No Glass</li>
                        <li>Plastic Goods – consumer items</li>
                        <li>Auto/Machinery Parts - Containerized</li>
                    </ol>
                </li>
                <li>The freight must be packaged in new packaging and be sufficiently packaged.</li>
                <li>Concealed damages must be reported within 30 days of delivery.</li>
                <li>Coverage will not include replacement costs.</li>
                <li>The shipper will be liable for a 5% deductible based on the claimed value.</li>
                <li>The maximum liability will be no more than $250,000 per occurrence.</li>
                <li>Shipper agrees that in no way will the Third Party, RiteRouting, or carrier be liable for rust, oxidation, discoloration, scratching, marring, denting, chipping, electrical or mechanical derangement.</li>
            </ol>
        </li>
        <li>Customer agrees that RiteRouting will not be responsible in any way for claims arising out of Customer negligence, carrier’s negligence, or the negligence of any third party. To the extent that RiteRouting is found negligent, RiteRouting’s liability will be no more than the refund of the total freight charge payment of the bill of lading in question.</li>
        <li>Shipper agrees that liability of any Carrier for cargo loss, damage, and delay occurring within the Republic of Mexico will be governed by Mexican law and not exceed $.10 per pound.</li>
        <li>Shipper acknowledges that any claim for cargo loss, damage, or delay must be filed against a carrier within 9 months of the date of delivery of Goods, or in the case of non-delivery, within 9 months of the date delivery should have been made.  Notice to RiteRouting does not constitute filing of claim with a carrier.  Any suit or other legal action to recover for cargo loss, damage, or delay, must be commenced against the Carrier no later than 2 years after declination of the cargo claim by Carrier.</li>
        </ol>
    </li>
    <li>
        <strong><em>Consequential Damages</em></strong>
        <p>Under no circumstances will RiteRouting or Carrier be liable to Shipper for any consequential, indirect, incidental, or punitive damages of any kind.</p>
    </li>
    <li>
        <strong><em>Shipper's Obligations.</em></strong><br />
        <ol type="A">
        <li>Shipper is responsible for ensuring that Goods are properly and safely loaded, supported, blocked, braced, and secured. Shipper will be responsible for expenses arising out of any load shift that occurs during transportation due to improper or insufficient loading, blocking, or bracing.</li>
        <li>Shipper must provide necessary shipping instructions and properly identify all Goods in the bill of lading or other shipping instructions. Shipper will not tender any restricted commodities, including but not limited to hazardous materials and waste, oversize or overweight shipments, coiled or rolled products or commodities requiring protection from heat or cold, without properly identifying such shipments and making necessary prior arrangements for transportation.</li>
        <li>Unless Shipper has requested that RiteRouting arrange for the Carrier to provide driver count services before dispatch and the Carrier performs such driver count services, Shipper is responsible for properly counting and recording the number of pieces transported and applying a protective seal to the loaded equipment.</li>
        <li>Shipper is responsible for checking all empty containers or trailers tendered for loading and rejecting any equipment that is not in apparent suitable condition to protect and preserve the Goods during transportation.</li>
        <li>If Shipper requests that RiteRouting arrange for equipment to be dropped at a location for Shipper's convenience and left unattended by Carrier, Shipper and its consignors or consignees will not lose, damage, or misuse the equipment and Shipper will pay for loss or damage to the equipment occurring during or as a result of such custody, control, possession or use of the equipment.</li>
        </ol>
    </li>
    <li>
        <strong><em>Hazardous Materials</em></strong>
        <p>Shipper will provide RiteRouting with advance notice of the proposed shipment of any hazardous material, as defined in 49 U.S.C. §5101, et seq. ("Hazardous Material") and provide a copy of the Material Safety Data Sheet for that Hazardous Material.  Shipper will indemnify, defend and hold harmless Carriers and  RiteRouting, their officers, employees, agents and insurers, against all claims, liabilities, losses, fines, legal fees and other expenses arising out of contact with, exposure to, or release of any Hazardous Material, including without limitation fines or expenses relating to the removal r treatment of that Hazardous Material or any other remedial action pertaining to that Hazardous Material under the Comprehensive Environmental Response, Compensation and Liability Act, 42 U.S.C. §9601, e seq. as amended ("CERCLA"), The Resource Conservation and Recovery Act, 42 U.S.C. §6901, et seq, as amended ("RCRA") or any comparable state law.</p>
    </li>
    <li>
        <strong><em>Indemnification</em></strong>
        <p>Shipper shall defend, indemnify and hold RiteRouting, RiteRouting's employees and agents, and Carriers harmless against any losses caused by or resulting from (i) Shipper's or Shipper's employees' or agents' negligence or intentional misconduct, (ii) Shipper's breach of these Terms and Conditions, or (iii) Shipper's or Shipper's employees'' or agents' violation of applicable laws or regulations. Shipper shall also indemnify RiteRouting from any attempts to recover from RiteRouting by Shipper's insurance carrier or any other party in regards to claims for loss, damage, or delay to Goods. The obligation to defend includes payment of all reasonable costs of defense, including attorney fees, as they accrue.</p>
    </li>
    <li>
        <em><strong>Information Systems</strong></em>
        <p>Any management information system or computer hardware or software used or supplied by RiteRouting in connection with the Services is and will remain RiteRouting's exclusive property.  All management information systems and related computer software and documentation used or supplied by RiteRouting are proprietary to RiteRouting, and Shipper will treat all such systems as confidential and not copy, use, or disclose them to third parties without RiteRouting's prior written consent, except as required by law.</p>
    </li>
    <li>
        <strong><em>Jurisdiction and Venue</em></strong><br />
        <ol type="A">
        <li>These Terms and Conditions are governed by and construed in accordance with the applicable federal laws of the United States, or, alternatively, and depending on jurisdiction, the laws of the State of Ohio.</li>
        <li>The parties agree to jurisdiction and venue in a United States Federal District Court located in Ohio, or if federal jurisdiction is not available, then in a State Court located in Ohio.</li>
        </ol>
    </li>
    <li>
        <strong><em>Force Majeure</em></strong>
        <p>If performance by one Party is affected by any condition beyond the reasonable control of such Party, the performance of obligations under the Terms and Conditions (other than Shipper’s obligation to pay for Services performed) affected by such condition will be suspended during the continuance of such condition. The Carriers engaged by RiteRouting will be permitted an extension period equal to the period of suspension in order to complete shipments adversely affected by the suspension. Neither Party will incur any liability for damages resulting from such suspensions.</p>
    </li>
    <li>
        <strong><em>Notice</em></strong>
        <p>Any notices and other communications required or permitted under the Terms and Conditions must be in writing and be (1) delivered personally, (2) sent by facsimile or e-mail transmission if confirmed by notice sent by one of the other notice methods permitted in this paragraph, (3) sent by nationally recognized overnight courier guaranteeing next business day delivery, or (4) mailed by registered or certified mail (return receipt requested), postage prepaid, to the Party at the following addresses (or at such other addresses as are specified by like notice):</p>
        <p style="margin-left:.5in;">If to RiteRouting:</p>
        <p style="margin-left:.5in;">RiteRouting, Inc.</p>
        <p style="margin-left:.5in;">1347 North Main Street</p>
        <p style="margin-left:.5in;">Orrville, Ohio 44667</p>
        <p>All such notices and other communications will be deemed to have been given and received (1) in the case of personal delivery, on the date of such delivery, (2) in the case of facsimile or e-mail transmission that is confirmed by notice sent on the same day by one of the other methods permitted, on the date of transmission if sent on a business day (or if sent on other than a business day, on the next business day after the date sent), (3) in the case of delivery by nationally recognized overnight courier, on the business day following dispatch if sent by guaranteed next day delivery, or (4) in the case of mailing, on the third business day following such mailing.</p>
    </li>
    <li>
        <strong><em>Confidentiality</em></strong><br />
        <ol type="A">
        <li>As part of the business relationship between Shipper and RiteRouting, either Party may be in or come into possession of information or data that constitutes trade secrets, know-how, confidential information, marketing plans, pricing, or anything else otherwise considered proprietary or secret by the other (“Confidential Information”).  In consideration of the receipt of such Confidential Information and potential business, each Party agrees to protect and maintain such Confidential Information in the utmost confidence, to use such Confidential Information solely in connection with their business relationship, and, to take all measures reasonably necessary to protect the Confidential Information.</li>
        <li>Shipper agrees that RiteRouting's costs for services is confidential and need not be disclosed to Shipper.  Shipper specifically waives any rights it may have under 49 CFR § 371.3.</li>
        <li>Terms and Conditions and information pertaining to any Services will not be disclosed by either Party to any other persons or entities, except to the directors, officers, employees, authorized contractors, attorneys, and accountants of each Party.</li>
        <li>This mutual obligation of confidentiality will remain in effect during the terms of the Terms and Conditions and for a period of two years following any termination.</li>
        </ol>
    </li>
    <li>
        <strong><em>Validity of Provisions</em> </strong>
        <p>If any part of the Terms and Conditions is declared by a court to be invalid, such decision shall not affect the validity of any remaining portion of the Terms and Conditions.</p>
    </li>
    <li>
        <strong><em>Effect of Waiver</em></strong>
        <p>The failure of either Party to enforce any of the rights given to it under the Terms and Conditions shall not be construed as a waiver of that right.</p>
    </li>
    <li>
        <strong><em>Terms of Use </em></strong><br />
        <p>RiteRouting uses Google Maps API as a part of a distance calculation. By using RiteRouting, you are bound to Google's Terms of Service.</p>
    </li>
    <li>
        <strong><em>Entire Understanding, Modifications </em></strong><br />
        <ol type="A">
        <li>These Terms and Conditions and individual Rate Confirmations contain the entire understanding and contractual agreement between the parties.</li>
        <li>These Terms and Conditions cannot be amended except in writing signed and dated by authorized representatives of RiteRouting.</li>
        </ol>
    </li>
    </ol>
    `
};
