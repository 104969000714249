
import {throwError as observableThrowError,  BehaviorSubject ,  Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Accessorial } from '../models';
import { ConfigService } from './config.service';

@Injectable()
export class AccessorialService {
  public accessorials$: BehaviorSubject<Accessorial[]> = new BehaviorSubject<Accessorial[]>([]);
  api: string;

  constructor(
    private config: ConfigService,
    private httpClient: HttpClient
  ) {
    this.api = this.config.getConfig('api');
    if (this.accessorials$.getValue().length === 0) {
      this.loadAccessorials()
        .then(res => this.accessorials$.next(res));
    }
  }

  private loadAccessorials() {
    return this.httpClient.get(`${this.api}api/quotes/accessorials`)
      .pipe(
        catchError(err => observableThrowError(err)),
        map((res: string) => JSON.parse(res).quote)
      )
      .toPromise();
  }

  get accessorials(): Accessorial[] {
    return this.accessorials$.getValue();
  }
}
