import { Component } from '@angular/core';

import { AccountFormComponent } from '../account-form/account-form.component';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rtrt-account-create-modal',
  templateUrl: './account-create-modal.component.html',
  styleUrls: ['./account-create-modal.component.scss']
})
export class AccountCreateModalComponent {
  constructor(public activeModal: NgbActiveModal) { }

  handleAccountCreation(acct: any) {
    this.activeModal.close(acct);
  }
}
