import { Component, Output, Input, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { NgbDateENParserFormatter } from './../../helpers/ngb-date-parser-formatter';
import { NgbDatePipe } from './../../helpers/ngb-date.pipe';
import { NgbDateStruct, NgbDatepickerConfig, NgbInputDatepicker , NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

import { OrderService } from '../../services/order.service';
const dt = new Date();
@Component({
  selector: 'rtrt-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderHistoryComponent implements OnInit{
  @Output() onSelectOrder: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDocRequest: EventEmitter<any> = new EventEmitter<any>();
  @Input() orders: any[] = [];
  @Input() loggedIn: boolean = false;
  @Input() carriers: any[] = [];
  searchDate: NgbDateStruct;
  search: string;
  subscriptions: Subscription[] = [];
  showDelivered = false;
  json = false;
  filterCarriers: any[] = [];
  dropdownSettings: IDropdownSettings = {};
  constructor(
    private orderService: OrderService
  ) { }
  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'carrierId',
      textField: 'carrierName',
      selectAllText: 'All Carriers',
      unSelectAllText: 'None',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText: "Carrier Name",
      showSelectedItemsAtTop: true
    };
  }
  viewDetails(o: any) {
    this.onSelectOrder.emit(o);
  }

  getBOL(o: any) {
    console.log(o);
    this.onDocRequest.emit({ jls: o.jlsNo, type: 'BillOfLadingRpt', carrierLine: o.carrierLine, idcShipper: (o.isFinalMile == "True" && o.insideDelivery == "True"), idcConsignee: (o.isFinalMile == "True" && o.insideDelivery != "True") });
  }

  getLabel(o: any) {
    this.onDocRequest.emit({ jls: o.jlsNo, type: 'labelSampleRpt', carrierLine: o.carrierLine, idcShipper: (o.isFinalMile == "True" && o.insideDelivery == "True"), idcConsignee: (o.isFinalMile == "True" && o.insideDelivery != "True") });
  }

  getBoth(o: any) {
    this.onDocRequest.emit({ jls: o.jlsNo, type: 'BolandLabel', carrierLine: o.carrierLine, idcShipper: (o.isFinalMile == "True" && o.insideDelivery == "True"), idcConsignee: (o.isFinalMile == "True" && o.insideDelivery != "True") });
  }

  clearFilters() {
    this.search = null;
    this.searchDate = null;
    this.filterCarriers = [];
    this.showDelivered = false;
  }

  toggleDelivered() {
    this.showDelivered = !this.showDelivered;
  }

  toggleJSON() {
    this.json = !this.json;
  }

  get carrierFilteredOrders() {
    if(this.loggedIn && this.filterCarriers.length == 0) {
      return this.orders;
    }
    else {
      return this.orders.filter(o => this.filterCarriers.some(f => f.carrierId == o.carrierId));
    }
  }
  get filteredOrders() {
    return this.carrierFilteredOrders.filter(o => o.jlsNo == this.search || o.proNo == this.search || o.mrNo == this.search);
  }
}
