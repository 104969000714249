export const HOME_META = {
  title:
    "LTL Freight Shipping Done Rite | RiteRouting",
    // "LTL Freight Shipping and Furniture Final Mile Done Rite | RiteRouting",
  meta: [
    {
      name: "description",
      content: `RiteRouting uses cutting-edge technology and a dedicated team of LTL shipping professionals to provide you the most competitive LTL shipping rates.`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "LTL Freight Shipping Done Rite | RiteRouting",
      // content: "LTL Freight Shipping and Furniture Final Mile Done Rite | RiteRouting",
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `RiteRouting uses cutting-edge technology and a dedicated team of LTL shipping professionals to provide you the most competitive LTL shipping rates.`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "LTL Freight Shipping Done Rite | RiteRouting"
      // content: "LTL Freight Shipping and Furniture Final Mile Done Rite | RiteRouting"
    }, {
      itemprop: "description", 
      content: `RiteRouting uses cutting-edge technology and a dedicated team of LTL shipping professionals to provide you the most competitive LTL shipping rates.`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};

export const SERVICES_META = {
  title: "LTL Freight Shipping | RiteRouting - Shipping Done Rite",
  meta: [
    {
      name: "description",
      content: `Our dedicated LTL shipping team helps you ship freight more efficiently and cost-effectively by utilizing our large LTL carrier network. Get a quote today!`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "LTL Freight Shipping | RiteRouting - Shipping Done Rite"
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `Our dedicated LTL shipping team helps you ship freight more efficiently and cost-effectively by utilizing our large LTL carrier network. Get a quote today!`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "LTL Freight Shipping | RiteRouting - Shipping Done Rite"
    }, {
      itemprop: "description", 
      content: `Our dedicated LTL shipping team helps you ship freight more efficiently and cost-effectively by utilizing our large LTL carrier network. Get a quote today!`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};
export const FINALMILE_META = {
  title: "Final Mile Delivery | RiteRouting - Shipping Done Rite",
  meta: [
    {
      name: "description",
      content: `RiteRouting provides unrivaled final mile delivery for furniture manufacturers, retailers, and online retailers, with a vast network of last mile carriers.`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "Final Mile Delivery | RiteRouting - Shipping Done Rite"
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `RiteRouting provides unrivaled final mile delivery for furniture manufacturers, retailers, and online retailers, with a vast network of last mile carriers.`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "Final Mile Delivery | RiteRouting - Shipping Done Rite"
    }, {
      itemprop: "description", 
      content: `RiteRouting provides unrivaled final mile delivery for furniture manufacturers, retailers, and online retailers, with a vast network of last mile carriers.`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};

export const CATEGORIES_META = {
  title: "Select an Option | RiteRouting - Shipping Done Rite",
  meta: [
    {
      name: "description",
      content: `RiteRouting provides transportation management and LTL shipping services for middle market manufacturers and distributors so you can focus on your business.`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "Select an Option | RiteRouting - Shipping Done Rite"
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `RiteRouting provides transportation management and LTL shipping services for middle market manufacturers and distributors so you can focus on your business.`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "Select an Option | RiteRouting - Shipping Done Rite"
    }, {
      itemprop: "description", 
      content: `RiteRouting provides transportation management and LTL shipping services for middle market manufacturers and distributors so you can focus on your business.`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};

export const ABOUT_META = {
  title: "Learn More About Us | RiteRouting - Shipping Done Rite",
  meta: [
    {
      name: "description",
      content: `RiteRouting provides transportation management and LTL shipping services for middle market manufacturers and distributors so you can focus on your business.`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "Learn More About Us | RiteRouting - Shipping Done Rite"
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `RiteRouting provides transportation management and LTL shipping services for middle market manufacturers and distributors so you can focus on your business.`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "Learn More About Us | RiteRouting - Shipping Done Rite"
    }, {
      itemprop: "description", 
      content: `RiteRouting provides transportation management and LTL shipping services for middle market manufacturers and distributors so you can focus on your business.`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};

export const PROCESS_META = {
  title: "Our Process | RiteRouting - Shipping Done Rite",
  meta: [
    {
      name: "description",
      content: `The RiteRouting freight brokerage platform provides LTL freight quotes to improve your company’s shipping costs and efficiency. It's as easy as 1, 2, 3!`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "Our Process | RiteRouting - Shipping Done Rite"
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `The RiteRouting freight brokerage platform provides LTL freight quotes to improve your company’s shipping costs and efficiency. It's as easy as 1, 2, 3!`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "Our Process | RiteRouting - Shipping Done Rite"
    }, {
      itemprop: "description", 
      content: `The RiteRouting freight brokerage platform provides LTL freight quotes to improve your company’s shipping costs and efficiency. It's as easy as 1, 2, 3!`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};

export const CONTACT_META = {
  title: "Contact Us | RiteRouting - Shipping Done Rite",
  meta: [
    {
      name: "description",
      content: `We are here to help you ship your freight. Feel free to contact us anytime to learn more, have your questions answered, or get a quote.`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "Contact Us | RiteRouting - Shipping Done Rite"
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `We are here to help you ship your freight. Feel free to contact us anytime to learn more, have your questions answered, or get a quote.`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "Contact Us | RiteRouting - Shipping Done Rite"
    }, {
      itemprop: "description", 
      content: `We are here to help you ship your freight. Feel free to contact us anytime to learn more, have your questions answered, or get a quote.`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};

export const FAQ_META = {
  title: "FAQ | RiteRouting - Shipping Done Rite",
  meta: [
    {
      name: "description",
      content: `We answer your Frequently Asked Questions about LTL freight shipping. When you need an expert to ship your LTL freight, it's RiteRouting!`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "FAQ | RiteRouting - Shipping Done Rite"
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `We answer your Frequently Asked Questions about LTL freight shipping. When you need an expert to ship your LTL freight, it's RiteRouting!`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "FAQ | RiteRouting - Shipping Done Rite"
    }, {
      itemprop: "description", 
      content: `We answer your Frequently Asked Questions about LTL freight shipping. When you need an expert to ship your LTL freight, it's RiteRouting!`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};

export const GLOSSARY_META = {
  title: "Shipping Terms Glossary | RiteRouting - Shipping Done Rite",
  meta: [
    {
      name: "description",
      content: `Shipping is a complex topic and has its own language. This  shipping terms glossary will help you understand all the terms so you can be a better shipper!`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "Shipping Terms Glossary | RiteRouting - Shipping Done Rite"
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `Shipping is a complex topic and has its own language. This  shipping terms glossary will help you understand all the terms so you can be a better shipper!`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "Shipping Terms Glossary | RiteRouting - Shipping Done Rite"
    }, {
      itemprop: "description", 
      content: `Shipping is a complex topic and has its own language. This  shipping terms glossary will help you understand all the terms so you can be a better shipper!`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};

export const ACCOUNT_META = {
  title: "Account Information",
  meta: [
    {
      name: "description",
      content: `RiteRouting uses cutting-edge technology and a dedicated team of LTL shipping professionals to provide you the most competitive LTL shipping rates.`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "LTL Freight Shipping Done Rite | RiteRouting",
      // content: "LTL Freight Shipping and Furniture Final Mile Done Rite | RiteRouting",
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `RiteRouting uses cutting-edge technology and a dedicated team of LTL shipping professionals to provide you the most competitive LTL shipping rates.`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "LTL Freight Shipping Done Rite | RiteRouting"
      // content: "LTL Freight Shipping and Furniture Final Mile Done Rite | RiteRouting"
    }, {
      itemprop: "description", 
      content: `RiteRouting uses cutting-edge technology and a dedicated team of LTL shipping professionals to provide you the most competitive LTL shipping rates.`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};

export const ADDRESS_META = {
  title: "Account Address Book",
  meta: [
    {
      name: "description",
      content: `RiteRouting uses cutting-edge technology and a dedicated team of LTL shipping professionals to provide you the most competitive LTL shipping rates.`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "LTL Freight Shipping Done Rite | RiteRouting",
      // content: "LTL Freight Shipping and Furniture Final Mile Done Rite | RiteRouting",
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `RiteRouting uses cutting-edge technology and a dedicated team of LTL shipping professionals to provide you the most competitive LTL shipping rates.`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "LTL Freight Shipping Done Rite | RiteRouting"
      // content: "LTL Freight Shipping and Furniture Final Mile Done Rite | RiteRouting"
    }, {
      itemprop: "description", 
      content: `RiteRouting uses cutting-edge technology and a dedicated team of LTL shipping professionals to provide you the most competitive LTL shipping rates.`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};

export const TRACKING_META = {
  title: "Track Your Freight | RiteRouting - Shipping Done Rite",
  meta: [
    {
      name: "description",
      content: `To track your LTL freight shipment, just enter your tracking number, PRO number, or Bill of Lading number and select the shipment’s carrier.`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "Track Your Freight | RiteRouting - Shipping Done Rite"
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `To track your LTL freight shipment, just enter your tracking number, PRO number, or Bill of Lading number and select the shipment’s carrier.`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "Track Your Freight | RiteRouting - Shipping Done Rite"
    }, {
      itemprop: "description", 
      content: `To track your LTL freight shipment, just enter your tracking number, PRO number, or Bill of Lading number and select the shipment’s carrier.`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};

export const QUOTEHISTORY_META = {
  title: "Quote History",
  meta: [
    {
      name: "description",
      content: `RiteRouting uses cutting-edge technology and a dedicated team of LTL shipping professionals to provide you the most competitive LTL shipping rates.`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "LTL Freight Shipping Done Rite | RiteRouting",
      // content: "LTL Freight Shipping and Furniture Final Mile Done Rite | RiteRouting",
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `RiteRouting uses cutting-edge technology and a dedicated team of LTL shipping professionals to provide you the most competitive LTL shipping rates.`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "LTL Freight Shipping Done Rite | RiteRouting"
      // content: "LTL Freight Shipping and Furniture Final Mile Done Rite | RiteRouting"
    }, {
      itemprop: "description", 
      content: `RiteRouting uses cutting-edge technology and a dedicated team of LTL shipping professionals to provide you the most competitive LTL shipping rates.`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};

export const BLOG_META = {
  title: "RiteRouting - Shipping Done Rite",
  // title: "Read Our Blog | RiteRouting - Shipping Done Rite",
  meta: [
    {
      name: "description",
      content: `The RiteRouting blog is your source for LTL shipping topics to make you a better shipper. LTL shipping is what we do every day and what we know best.`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "Read Our Blog | RiteRouting - Shipping Done Rite"
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `The RiteRouting blog is your source for LTL shipping topics to make you a better shipper. LTL shipping is what we do every day and what we know best.`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "Read Our Blog | RiteRouting - Shipping Done Rite"
    }, {
      itemprop: "description", 
      content: `The RiteRouting blog is your source for LTL shipping topics to make you a better shipper. LTL shipping is what we do every day and what we know best.`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};

export const PRIVACY_POLICY_META = {
  title: "Privacy Policy | RiteRouting - Shipping Done Rite",
  meta: [
    {
      name: "description",
      content: `Read the RiteRouting Privacy Policy.`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "Privacy Policy | RiteRouting - Shipping Done Rite"
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `Read the RiteRouting Privacy Policy.`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "Privacy Policy | RiteRouting - Shipping Done Rite"
    }, {
      itemprop: "description", 
      content: `Read the RiteRouting Privacy Policy.`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};

export const TERMS_META = {
  title: "Terms of Use | RiteRouting - Shipping Done Rite",
  meta: [
    {
      name: "description",
      content: `Read the RiteRouting Terms and Conditions.`
    }, {
      name: "twitter:card", 
      value: "summary_large_image"
    }, {
      property: "og:title",
      content: "Terms of Use | RiteRouting - Shipping Done Rite"
    }, {
      property: "og:type", 
      content: "website"
    }, {
      property: "og:url", 
      content: "https://www.riterouting.com/"
    }, {
      property: "og:image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }, {
      property: "og:description", 
      content: `Read the RiteRouting Terms and Conditions.`
    }, {
      property: "og:site_name", 
      content: "RiteRouting"
    }, {
      itemprop: "name", 
      content: "Terms of Use | RiteRouting - Shipping Done Rite"
    }, {
      itemprop: "description", 
      content: `Read the RiteRouting Terms and Conditions.`
    }, {
      itemprop: "image", 
      content: "https://www.riterouting.com/assets/images/updated_images/RiteRouting-Tagline-logo.png"
    }
  ]
};
