import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OnInit, EventEmitter, AfterViewInit, NgZone } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserFormComponent } from '../user-form/user-form.component';
import { Account } from '../account.model';
import { ConfigService } from '../../services/config.service';
import { AccountService } from '../../services/account.service';
import { UserService } from '../../services/user.service';
import { RtValidators } from './../../validators/rt-validators';
import { ZipService } from '../../services/zip.service';
import { PromoCode } from '../../models/promo-code.model';
import { QuoteService } from '../../services/quote.service';
var volumeQuantityOpts = new Array('0-1', '2-5', '6-10', '11-20', '21+');
var parcelQuantityOpts = new Array('0-150', '151-500', '501-1,000', '1,001+');
var volumePeriodOpts = new Array('Daily', 'Weekly', 'Monthly');
var availableModes = ['LTL', 'FTL', 'Expedite', 'Parcel'];
var modeOpts = new Array({
    selected: false,
    name: 'LTL',
    volumePeriod: '',
    volumeQuantity: ''
}, {
    selected: false,
    name: 'FTL',
    volumePeriod: '',
    volumeQuantity: ''
}, {
    selected: false,
    name: 'Expedite',
    volumePeriod: '',
    volumeQuantity: ''
}, {
    selected: false,
    name: 'Parcel',
    volumePeriod: '',
    volumeQuantity: ''
});
var AccountFormComponent = /** @class */ (function () {
    function AccountFormComponent(fb, config, as, us, zipService, zone, quoteService) {
        this.fb = fb;
        this.config = config;
        this.as = as;
        this.us = us;
        this.zipService = zipService;
        this.zone = zone;
        this.quoteService = quoteService;
        this.submitDisabled = false;
        this.agreedToTerms = false;
        this.account = new Account();
        this.saveText = 'Submit';
        this.onAccountCreation = new EventEmitter();
        this.onSubmission = new EventEmitter();
        this.availableModes = availableModes;
        this.accountPromoCode = new PromoCode();
        this.promoCodeField = '';
        this.promoCodeValid = true;
        this.modeOpts = modeOpts;
        this.selectedModes = [];
        this.volumePeriodOpts = volumePeriodOpts;
        this.volumeQuantityOpts = volumeQuantityOpts;
        this.parcelQuantityOpts = parcelQuantityOpts;
        this.errors = [];
        this.states = [];
        this.states = config.getConfig('states').filter(function (s) { return s.country == "USA"; });
    }
    AccountFormComponent.prototype.ngOnInit = function () {
        this.createForm();
    };
    AccountFormComponent.prototype.ngAfterViewInit = function () {
    };
    AccountFormComponent.prototype.createForm = function () {
        var _this = this;
        this.accountForm = this.fb.group({
            businessName: [this.account.businessName, [Validators.required]],
            phone: [this.account.phone, [Validators.required, RtValidators.phone]],
            address: [this.account.address, [Validators.required]],
            address2: [this.account.address2],
            city: [{ value: this.account.city, disabled: true }, [Validators.required]],
            state: [{ value: this.account.state, disabled: true }, [Validators.required]],
            postal: [this.account.postal, [Validators.required, RtValidators.zip]],
            country: [{ value: this.account.country, disabled: true }, [Validators.required]],
            promoCode: [this.promoCodeField],
            modes: this.fb.array(this.modeOpts.map(function (opt) {
                return _this.fb.group({
                    selected: [opt.selected],
                    name: [opt.name],
                    volumePeriod: [opt.volumePeriod],
                    volumeQuantity: [opt.volumeQuantity]
                });
            })),
            isDefaultBillTo: [true],
            isShipper: [true],
            isAccount: [true],
            isConsignee: [false]
        });
        // this.postal.valueChanges.debounceTime(700).subscribe(val => {
        //   this.handleZipLookup(val);
        // });
    };
    Object.defineProperty(AccountFormComponent.prototype, "formattedDiscount", {
        get: function () {
            if (this.promoCodeValid) {
                if (this.accountPromoCode.ArFlatCharge > 0) {
                    return '$' + this.round(this.accountPromoCode.ArFlatCharge, 2).toString();
                }
                else if (this.accountPromoCode.ArDiscount > 0) {
                    return this.round(this.accountPromoCode.ArDiscount * 100, 2).toString() + '%';
                }
            }
            else
                return '';
        },
        enumerable: true,
        configurable: true
    });
    AccountFormComponent.prototype.handlePromoCode = function () {
        var _this = this;
        var promoCodeField = this.accountForm.controls["promoCode"];
        if (promoCodeField.value == '') {
            this.accountPromoCode = new PromoCode();
            this.promoCodeValid = true;
        }
        else {
            // check if entered promo is valid
            this.quoteService.checkPromoCode(promoCodeField.value)
                .then(function (promo) {
                if (typeof (promo) === "string") {
                    var promoCode = JSON.parse(promo);
                    _this.accountPromoCode = promoCode;
                    _this.promoCodeValid = true;
                }
                else {
                    _this.accountPromoCode = new PromoCode();
                    _this.promoCodeValid = false;
                }
            })
                .catch(function (error) {
                console.log(error);
                _this.accountPromoCode = new PromoCode();
                _this.promoCodeValid = false;
            });
        }
    };
    AccountFormComponent.prototype.handleZipLookup = function () {
        var _this = this;
        var zip = this.postal.value.toUpperCase();
        if (!zip || zip === '')
            return;
        var regCan = new RegExp("^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][-s]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$", "i");
        var regStrictCan = new RegExp("^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][s][0-9][ABCEGHJ-NPRSTV-Z][0-9]$", "i");
        if (zip !== this.postal.value) {
            this.postal.setValue(zip);
        }
        if (regCan.test(zip) === true && regStrictCan.test(zip) === false) {
            this.postal.setValue(zip.substr(0, 3) + " " + zip.substr(zip.length - 3, 3));
            this.country.setValue("CAN");
        }
        this.zipService.checkZip(this.postal.value)
            .pipe(catchError(function (err) {
            console.log("catch err");
            _this.flagPostalError();
            return observableThrowError(err);
        }))
            .subscribe(function (v) {
            v = JSON.parse(v);
            console.log("v", v);
            if (v.err && v.err !== '') {
                _this.flagPostalError();
                return;
            }
            _this.states = _this.config.getConfig('states').filter(function (s) { return s.country == v.country; });
            if (v.cities.length > 0)
                _this.city.setValue(v.cities[0]);
            _this.state.setValue(v.stateprov);
            _this.country.setValue(v.country);
        });
    };
    AccountFormComponent.prototype.flagPostalError = function () {
        this.city.setValue('');
        this.country.setValue('');
        this.state.setValue(undefined);
        this.postal.setValue('');
        this.postal.setErrors({ zip: 'Invalid Postal Code' });
    };
    Object.defineProperty(AccountFormComponent.prototype, "modes", {
        get: function () {
            return this.accountForm.get('modes');
        },
        enumerable: true,
        configurable: true
    });
    AccountFormComponent.prototype.getModeQuantityControl = function (idx) {
        return (this.accountForm.get('modes.' + idx + '.volumeQuantity') || null);
    };
    AccountFormComponent.prototype.getModeQuantity = function (idx) {
        var c = this.getModeQuantityControl(idx);
        return ((c && c.value) || null);
    };
    AccountFormComponent.prototype.getModePeriodControl = function (idx) {
        return (this.accountForm.get('modes.' + idx + '.volumePeriod') || null);
    };
    AccountFormComponent.prototype.getModePeriod = function (idx) {
        var c = this.getModePeriodControl(idx);
        return ((c && c.value) || null);
    };
    AccountFormComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitDisabled = true;
        console.log(this.accountForm.value);
        var user = this.userForm.userForm.getRawValue();
        user.name = (user.firstname + ' ' + user.lastname).trim();
        if (this.accountForm.valid && this.userForm.userForm.valid && this.promoCodeValid) {
            // Log the account creation to HubSpot
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', window.location + '/createAccount']);
            _hsq.push(['identify', {
                    email: user.email,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    company: this.accountForm.value.businessName,
                    phone: user.phone
                }]);
            _hsq.push(['trackPageView']);
            // Create the account
            this.as.create({
                'account': tslib_1.__assign({}, this.accountForm.getRawValue(), { ContactName: user.name, ContactEmail: user.email, ContactPhone: user.phone }),
                'user': user
            })
                .pipe(catchError(function (err) {
                if (err.status !== 200) {
                    var errobj = JSON.parse(err.error);
                    if (typeof errobj.Data === 'string')
                        _this.errors = JSON.parse(errobj.Data);
                    else if (typeof errobj.data === 'string')
                        _this.errors = JSON.parse(errobj.data);
                    else if (errobj.Data !== null && typeof errobj.Data === 'object')
                        _this.errors = errobj.Data;
                    else if (errobj.data !== null && typeof errobj.data === 'object')
                        _this.errors = errobj.data;
                    else
                        _this.errors = ['Unhandled error, ' + errobj.Message]; // Not sure if this is right but it's better than nothing
                    console.log("there were errors creating an account");
                }
                _this.submitDisabled = false;
                return observableThrowError(err);
            }))
                .subscribe(function (res) {
                _this.errors = [];
                var results = JSON.parse(res);
                _this.onAccountCreation.emit({ 'id': results.data.user, 'username': _this.userForm.userForm.get('username').value });
            });
        }
        else {
            this.touchAllFields().then(function () {
                _this.submitDisabled = false;
                _this.onSubmission.emit(null);
                _this.scrollToError();
            });
        }
    };
    AccountFormComponent.prototype.touchAllFields = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Object.keys(this.userForm.userForm.controls)
                            .map(function (x) { return _this.userForm.userForm.controls[x]; })
                            .forEach(function (control) {
                            control.markAsTouched();
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, Object.keys(this.accountForm.controls)
                                .map(function (x) { return _this.accountForm.controls[x]; })
                                .forEach(function (control) {
                                control.markAsTouched();
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AccountFormComponent.prototype.scrollTo = function (el) {
        if (el) {
            if (el.previousElementSibling) {
                el.focus({
                    preventScroll: true
                });
                el.previousElementSibling.scrollIntoView({ behavior: 'smooth' });
            }
            else {
                el.focus({
                    preventScroll: true
                });
                el.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };
    AccountFormComponent.prototype.scrollToError = function () {
        var firstElementWithError = document.querySelector('.ng-invalid:not(form)');
        console.log(firstElementWithError);
        this.scrollTo(firstElementWithError);
    };
    Object.defineProperty(AccountFormComponent.prototype, "phone", {
        get: function () { return this.accountForm.get('phone'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountFormComponent.prototype, "postal", {
        get: function () { return this.accountForm.get('postal'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountFormComponent.prototype, "businessName", {
        get: function () { return this.accountForm.get('businessName'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountFormComponent.prototype, "address", {
        get: function () { return this.accountForm.get('address'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountFormComponent.prototype, "address2", {
        get: function () { return this.accountForm.get('address2'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountFormComponent.prototype, "city", {
        get: function () { return this.accountForm.get('city'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountFormComponent.prototype, "country", {
        get: function () { return this.accountForm.get('country'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountFormComponent.prototype, "state", {
        get: function () { return this.accountForm.get('state'); },
        enumerable: true,
        configurable: true
    });
    AccountFormComponent.prototype.handleStateSelection = function (e) {
        this.state.setValue(e.abbreviation);
    };
    AccountFormComponent.prototype.handleQuantitySelection = function (e, i) {
        var c = this.getModeQuantityControl(i);
        if (c && e) {
            c.setValue(e);
        }
        else {
            c.setValue('');
        }
    };
    AccountFormComponent.prototype.handlePeriodSelection = function (e, i) {
        var c = this.getModePeriodControl(i);
        if (c && e) {
            c.setValue(e);
        }
        else {
            c.setValue('');
        }
    };
    AccountFormComponent.prototype.fillAddress = function (a, fillName) {
        var _this = this;
        this.zone.run(function () {
            _this.states = _this.config.getConfig('states').filter(function (s) { return s.country == a.CountryString; });
            _this.accountForm.patchValue({
                address: a.AddressLineOne,
                city: a.City,
                state: a.StateOrProvince,
                postal: a.PostalCode,
                country: a.CountryString,
                phone: a.PhoneOne
            });
            if (fillName) {
                _this.accountForm.patchValue({
                    businessName: a.Name
                });
            }
        });
    };
    AccountFormComponent.prototype.round = function (value, decimalPlaces) {
        return Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    };
    return AccountFormComponent;
}());
export { AccountFormComponent };
