import { Location } from './location.model';

export class OrderClient {
    JlsNo: string;
    ID: number;
    ClientName: string;
    ClientCode: string;
    IsActive: boolean;
    ClientAddress: Location;

    constructor() {
        this.ClientAddress = new Location();
    }
}
