import { User } from './user.model';
import { Mode } from './mode.model';
import { Location } from '../models/location.model';
import { PromoCode } from '../models/promo-code.model';

export class Account {
    businessName? = '';
    phone? = '';
    address? = '';
    address2? = '';
    city? = '';
    state? = '';
    postal = '';
    country = '';
    onAccount = false;
    newAccount = false;
    modes: Mode[] = [];
    users: User[] = [];
}
