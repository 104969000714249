export class Commodity {
  JlsNo: string;
  CustomerLineNumber: number;
  IsActive: boolean;
  HandlingUnits: number;
  HandlingTypeID: number;
  HandlingType: string;
  PackagingTypeID: number;
  PackagingType: string;
  Pieces: number;
  Length: number;
  Width: number;
  Height: number;
  IsHazardous: boolean;
  Prefix: string;
  UnNumber: string;
  TechnicalShippingName: string;
  ProperShippingName: string;
  HazMatClass: string;
  PackagingGroup: string;
  EmergencyContactNumber: string;
  Registrant: string;
  ProtectFromFreeze: boolean;
  NetWeight: number;
  GrossWeight: number;
  NMFC: string;
  FreightClass: number;
  FAK: number;
  ApFAK: number;
  ArFAK: number;
  CommodityID: string;
  CommodityDescription: string;
  Volume: number;
  Density: number;
  Stackable: boolean = false;
  SKU: string;
  ValueOfGoods: number;
  FurnitureType: string;
  constructor() {
    this.HandlingUnits = 1;
    this.CommodityDescription = "";
    this.FreightClass = 0;
  }
}
