/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blog-tag-summary.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./blog-tag-summary.component";
import * as i5 from "../../../services/blog.service";
var styles_BlogTagSummaryComponent = [i0.styles];
var RenderType_BlogTagSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlogTagSummaryComponent, data: {} });
export { RenderType_BlogTagSummaryComponent as RenderType_BlogTagSummaryComponent };
function View_BlogTagSummaryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "post-item"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, [" ", " (", ") "]))], function (_ck, _v) { var currVal_2 = (("/blog/tag/" + _v.parent.context.$implicit.FomattedTagName) + "/page/1"); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.TagName; var currVal_4 = _v.parent.context.$implicit.PostCount; _ck(_v, 2, 0, currVal_3, currVal_4); }); }
function View_BlogTagSummaryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogTagSummaryComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.index < 5) || _co.showAllTags); _ck(_v, 2, 0, currVal_0); }, null); }
function View_BlogTagSummaryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-link btn-mini pl-0"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showAllTags = !_co.showAllTags) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["See All"]))], null, null); }
export function View_BlogTagSummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tags"])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogTagSummaryComponent_1)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogTagSummaryComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.blogTags; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.showAllTags; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_BlogTagSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-blog-tag-summary", [], null, null, null, View_BlogTagSummaryComponent_0, RenderType_BlogTagSummaryComponent)), i1.ɵdid(1, 114688, null, 0, i4.BlogTagSummaryComponent, [i5.BlogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlogTagSummaryComponentNgFactory = i1.ɵccf("rtrt-blog-tag-summary", i4.BlogTagSummaryComponent, View_BlogTagSummaryComponent_Host_0, {}, {}, []);
export { BlogTagSummaryComponentNgFactory as BlogTagSummaryComponentNgFactory };
