<div class="rt-card">
  <header>
      <div class="col-12 col-md-3" id="unitHeader">
        <h4 class="d-inline-block">Handling Unit #{{displayID}}</h4>
      </div>

      <div class="col-12 col-md-6">
        <div class="w-100">
          <p class="d-inline-block pl-3 pr-2" *ngIf="displayID != 1">Same Specifications as Unit</p>

          <div *ngIf="displayID != 1" ngbDropdown class="d-inline-block dropdown">
            <button class="form-control" type="button" id="copyDropDown" ngbDropdownToggle readonly>{{this.copyID}}</button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="copyDropDown" ngbDropdownMenu>
              <button type="button" *ngFor="let commoditiy of commodities; let i = index" class="dropdown-item" (click)="selectCopy(commoditiy, i)">{{i+1}}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-3">
        <button (click)="removeItem()" type="button" class="remove-item btn btn-link btn-secondary btn-flat float-right mt-1">Remove</button>
        <span class="pcf">
          {{item.Density | number}}
        </span>
      </div>    
  </header>
  <div class="rt-card-body pb-0">
    <div class="col-12 col-md-4 form-group bg-secondary required">
      <label>Total Weight <span class="uom">lbs</span></label>
      <input type="number" class="form-control" [(ngModel)]="item.GrossWeight" #weight="ngModel" name="weight" (change)="calcPCF()" required validatePositive validateDecimal />
      <div *ngIf="weight.invalid && (weight.dirty || weight.touched || touched)" class="form-helpers">
          <small *ngIf="weight.errors.required" class="text-danger">Weight is required.</small>
          <small *ngIf="!weight.errors.required && weight.errors.decimal" class="text-danger">Weight must be a number.</small>
          <small *ngIf="!weight.errors.required && weight.errors.positive" class="text-danger">Weight must be a positive number.</small>
      </div>
    </div>
    <div class="col-12 col-md-4 form-group bg-secondary required">
      <rtrt-bug>
        <p><strong>For assistance</strong></p>
        <p>Call us Toll Free at 833-725-RITE (7483)<br />
          Email us at <a href="mailto:customerservice@riterouting.com">customerservice@riterouting.com</a></p>
      </rtrt-bug>
      <label for="typeahead-focus">Freight Class</label>
      <input id="typeahead-focus" type="text" class="form-control" [(ngModel)]="item.FreightClass"
        [ngbTypeahead]="searchFreight" #class="ngModel" name="class" (focus)="CheckFreightClass($any($event).target.value)"
        (click)="clickFreight$.next($any($event).target.value)" (blur)="validateClass($any($event).target.value)" #instance="ngbTypeahead" required/>
      <div *ngIf="class.invalid && (class.dirty || class.touched || touched)" class="form-helpers">
        <small *ngIf="class.errors.required" class="text-danger">Freight class is required.</small>
        <small *ngIf="!class.errors.required" class="text-danger">Freight class is not valid.</small>
      </div>
    </div>
    <div
      [class]="addInsurance ? 'col-12 col-md-4 form-group bg-secondary required' : 'col-12 col-md-4 form-group bg-secondary'">
      <label>Value Of Goods</label>
      <input type="number" class="form-control" [(ngModel)]="item.ValueOfGoods" #valueofgoods="ngModel" name="valueofgoods"
        (required)="this.addInsurance" validatePositive validateDecimal />
      <div *ngIf="addInsurance && valueofgoods.invalid && (valueofgoods.dirty || valueofgoods.touched || touched)"
        class="form-helpers">
        <small *ngIf="addInsurance && valueofgoods.errors.required" class="text-danger">Value of goods is required.</small>
        <small *ngIf="addInsurance && !valueofgoods.errors.required && valueofgoods.errors.decimal"
          class="text-danger">Value of goods must
          be a
          number.</small>
        <small *ngIf="addInsurance && !valueofgoods.errors.required && valueofgoods.errors.positive"
          class="text-danger">Value of goods must
          be a
          positive number.</small>
      </div>
    </div>
    <div class="col-12 col-md-3 form-group bg-secondary required">
      <label>Length <span class="uom">in.</span></label>
      <input type="number" class="form-control" [(ngModel)]="item.Length" #length="ngModel" name="length" required validatePositive (change)="calcPCF()" />
      <div *ngIf="length.invalid && (length.dirty || length.touched || touched)" class="form-helpers">
          <small *ngIf="length.errors.required" class="text-danger">Length is required.</small>
          <small *ngIf="!length.errors.required && length.errors.positive" class="text-danger">Length must be a positive number.</small>
      </div>
    </div>
    <div class="col-12 col-md-3 form-group bg-secondary required">
      <label>Width <span class="uom">in.</span></label>
      <input type="number" class="form-control" [(ngModel)]="item.Width" #width="ngModel" name="width" required validatePositive (change)="calcPCF()" />
      <div *ngIf="width.invalid && (width.dirty || width.touched || touched)" class="form-helpers">
          <small *ngIf="width.errors.required" class="text-danger">Width is required.</small>
          <small *ngIf="!width.errors.required && width.errors.positive" class="text-danger">Width must be a positive number.</small>
      </div>
    </div>
    <div class="col-12 col-md-3 form-group bg-secondary required">
      <label>Height <span class="uom">in.</span></label>
      <input type="number" class="form-control" [(ngModel)]="item.Height" #height="ngModel" name="height" required validatePositive (change)="calcPCF()" />
      <div *ngIf="height.invalid && (height.dirty || height.touched || touched)" class="form-helpers">
          <small *ngIf="height.errors.required" class="text-danger">Height is required.</small>
          <small *ngIf="!height.errors.required && height.errors.positive" class="text-danger">Height must be a positive number.</small>
      </div>
    </div>
    <div class="col-12 col-md-3 form-group bg-secondary text-center required">
      <label>Stackable</label> <br />
      <label class="form-check-label text-center pt-3">
        <input type="checkbox" [(ngModel)]="item.Stackable" #stackable="ngModel" name="stackable" id="stackable">
        <span class="control"></span>
      </label>
    </div>
  </div>
</div>