import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  EventEmitter,
  OnDestroy,
  Output,
  AfterViewInit,
  NgZone
} from "@angular/core";
import { Router } from "@angular/router";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl
} from "@angular/forms";
import { Subscription, Subject } from "rxjs";

import { QuoteService } from "../../services/quote.service";
import { AuthenticationService } from "../../services/authentication.service";
import { ConfigService } from "../../services/config.service";
import { FlashService } from "../../shared/flash/flash.service";
import { AccountService } from "../../services/account.service";

import {
  Shipment,
  Location,
  OrderDateTime,
  OrderDateTimeType,
  LocationOpenTime,
  DaysOfTheWeek,
  OpenHoursType,
  Reference
} from "../../models";
import { RtValidators } from "../../validators/rt-validators";
import { isNumber } from "util";

import { NgbDateENParserFormatter } from "./../../helpers/ngb-date-parser-formatter";
import { NgbDatePipe } from "./../../helpers/ngb-date.pipe";
import {
  NgbTimeStruct,
  NgbDateStruct,
  NgbDatepickerConfig,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";
import { ZipService } from '../../services/zip.service';

@Component({
  selector: "rtrt-destination-form",
  templateUrl: "./destination-form.component.html",
  styleUrls: ["./destination-form.component.scss"]
})
export class DestinationFormComponent
  implements OnInit, AfterViewInit, OnDestroy {
  quote: Shipment;
  isAuthenticated: boolean;
  subscriptions: Subscription[] = [];
  private _deliveryDate: NgbDateStruct;
  _today: NgbDateStruct = null;
  form: FormGroup;
  states: any[] = [];
  private _addresses: Location[] = [];
  originalAddress: Location;
  notesArr = [];
  addressSuggestions: string[];
  onAddressChange = new Subject<void>();
  cities: string[] = [];

  constructor(
    private fb: FormBuilder,
    private quoteService: QuoteService,
    private authService: AuthenticationService,
    private config: ConfigService,
    private router: Router,
    private flash: FlashService,
    private accountService: AccountService,
    private zone: NgZone,
    private zipService: ZipService
  ) {
    this.subscriptions.push(
      this.authService.loggedIn.subscribe(res => (this.isAuthenticated = res))
    );
    this.subscriptions.push(
      this.quoteService.quote$.subscribe(q => {
        this.quote = q;
        if (
          this.quote &&
          this.quote.Consignee &&
          this.quote.Consignee.OpenHours &&
          this.quote.Consignee.OpenHours.length === 0
        ) {
          const oh = new LocationOpenTime();
          oh.Type = OpenHoursType.Receiving;
          this.quote.Consignee.OpenHours.push(oh);
          this.originalAddress = Object.apply({}, this.quote.Consignee);
        }
      })
    );
    const dt = new Date();
    this._today = {
      year: dt.getFullYear(),
      month: dt.getMonth() + 1,
      day: dt.getDate()
    };
    this.states = config.getConfig("states").filter(s => s.country == this.quote.Consignee.CountryString);
  }

  ngOnInit() {
    this.accountService.refreshAddresses();
    this.subscriptions.push(
      this.accountService.address$.subscribe(a => {
        this._addresses = a;
        this.instantiateForm();
        this.onFormChanges();
      })
    );

    this.zipService
      .checkZip(this.quote.Consignee.PostalCode)
      .pipe()
      .subscribe(
        (res: any) => {
          res = JSON.parse(res);
          this.cities = res.cities;
          console.log(this.cities);
        }
      );
  }

  ngAfterViewInit() {
    if (
      this.quote &&
      this.quote.Consignee &&
      this.quote.Consignee.AddressLineOne &&
      !this.quote.Consignee.Validated
    ) {
      this.onAddressChange.next();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  handleAddressValidation(street: string) {
    if (street) {
      this.address1.setValue(street);
      this.quote.Consignee.Validated = true;
    } else {
      this.quote.Consignee.Validated = false;
    }
  }

  get puDate() {
    const puDate = this.quote.DateTimes.find(
      dt => dt.TypeID === OrderDateTimeType.ShipDate
    );
    if (puDate) return new Date(puDate.ShortDateString);
  }

  get puNGBDate() {
    const dt = new Date();
    if (this.puDate)
      return {
        year: this.puDate.getFullYear(),
        month: this.puDate.getMonth() + 1,
        day: this.puDate.getDate() + 1
      };
    else
      return {
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        day: dt.getDate() + 1
      };
  }

  private isFilledLocation(l: Location) {
    return !!(l && l.PostalCode && l.PostalCode !== "");
  }

  get showAddressClear() {
    return (
      this.isFilledLocation(this.originalAddress) &&
      this.originalAddress !== this.quote.Consignee
    );
  }

  selectAddress(a: Location) {
    this.zone.run(() => {
      this.quote.Consignee = Object.assign({}, a);
      this.instantiateForm();
      this.onFormChanges();
      this.onAddressChange.next();
    });
  }

  get addresses() {
    if (!Array.isArray(this._addresses))
      return null;
    return this._addresses
      .filter(
        a =>
          ((this.isFilledLocation(this.quote.Consignee) &&
            a.PostalCode === this.quote.Consignee.PostalCode) ||
            (this.isFilledLocation(this.originalAddress) &&
              a.PostalCode === this.originalAddress.PostalCode)) &&
          a.IsDefaultBillTo &&
          a.IsConsignee
      )
      .concat(
        this._addresses
          .filter(
            a =>
              ((this.isFilledLocation(this.quote.Consignee) &&
                a.PostalCode === this.quote.Consignee.PostalCode) ||
                (this.isFilledLocation(this.originalAddress) &&
                  a.PostalCode === this.originalAddress.PostalCode)) &&
              !a.IsDefaultBillTo &&
              a.IsConsignee
          )
          .sort((n1, n2) => {
            if (n1.Name.toUpperCase() > n2.Name.toUpperCase()) {
              return 1;
            }

            if (n1.Name.toUpperCase() < n2.Name.toUpperCase()) {
              return -1;
            }

            return 0;
          })
      );
  }

  instantiateForm() {
    if (this.quote.JlsNotes.search(" ---- Delivery Notes:") > -1) {
      this.notesArr = this.quote.JlsNotes.split(" ---- Delivery Notes:");
      this.notesArr[0] = this.notesArr[0].replace("Pickup Notes: ", "");
    } else {
      this.notesArr[0] = this.quote.JlsNotes || "";
      this.notesArr[1] = "";
    }
    this.form = this.fb.group({
      name: [this.quote.Consignee.Name, [Validators.required]],
      address1: [this.quote.Consignee.AddressLineOne, [Validators.required]],
      address2: [this.quote.Consignee.AddressLineTwo],
      contact: [this.quote.Consignee.ContactName, [Validators.required]],
      phone: [
        this.quote.Consignee.PhoneOne,
        [Validators.required, RtValidators.phone]
      ],
      fax: [this.quote.Consignee.Fax || ""],
      email: [
        this.quote.Consignee.ContactEmail,
        [Validators.required, Validators.email]
      ],
      deliveryInstructions: [this.notesArr[1]],
      deliveryReferenceNo: [""]
    });
    this.form.get("fax").valueChanges.subscribe((fax: string) => {
      if (fax !== "" && fax !== null) {
        this.form.get("fax").setValidators([RtValidators.phone]);
      } else {
        this.form.get("fax").clearValidators();
      }
      this.form.get("fax").updateValueAndValidity({ emitEvent: false });
    });
  }

  onFormChanges() {
    this.subscriptions.push(
      this.name.valueChanges.subscribe(val => {
        this.quote.Consignee.Name = val;
      })
    );

    this.subscriptions.push(
      this.address1.valueChanges.subscribe(val => {
        if (this.quote.Consignee.AddressLineOne !== val) {
          this.quote.Consignee.Validated = false;
          this.quote.Consignee.AddressLineOne = val;
          this.onAddressChange.next();
        }
      })
    );

    this.subscriptions.push(
      this.address2.valueChanges.subscribe(val => {
        this.quote.Consignee.AddressLineTwo = val;
      })
    );

    this.subscriptions.push(
      this.phone.valueChanges.subscribe(val => {
        this.quote.Consignee.PhoneOne = val;
      })
    );

    this.subscriptions.push(
      this.fax.valueChanges.subscribe(val => {
        this.quote.Consignee.Fax = val;
      })
    );

    this.subscriptions.push(
      this.contact.valueChanges.subscribe(val => {
        this.quote.Consignee.ContactName = val;
      })
    );

    this.subscriptions.push(
      this.email.valueChanges.subscribe(val => {
        this.quote.Consignee.ContactEmail = val;
      })
    );
  }

  resetForm() {
    this.instantiateForm();
  }

  get name() {
    return this.form.get("name");
  }
  get address1() {
    return this.form.get("address1");
  }
  get address2() {
    return this.form.get("address2");
  }
  get contact() {
    return this.form.get("contact");
  }
  get phone() {
    return this.form.get("phone");
  }
  get fax() {
    return this.form.get("fax");
  }
  get email() {
    return this.form.get("email");
  }
  get deliveryInstructions() {
    return this.form.get("deliveryInstructions");
  }
  get saveInstructions() {
    return this.form.get("saveInstructions");
  }
  get deliveryReferenceNo() {
    return this.form.get("deliveryReferenceNo");
  }
  get deliveryNotes() {
    return this.notesArr[1];
  }
  set deliveryNotes(val: string) {
    this.notesArr[1] = val;
  }

  private _fillQuote() {
    const destForm = this.form;
    this.quote.Consignee.Name = destForm.get("name").value;
    this.quote.Consignee.AddressLineOne = destForm.get("address1").value;
    this.quote.Consignee.AddressLineTwo =
      destForm.get("address2").value === ""
        ? null
        : destForm.get("address2").value;
    this.quote.Consignee.ContactName = destForm.get("contact").value;
    this.quote.Consignee.PhoneOne = destForm.get("phone").value;
    this.quote.Consignee.Fax = destForm.get("fax").value;
    this.quote.Consignee.ContactEmail = destForm.get("email").value;
    this.quote.JlsNotes = this.notesArr[0];

    if (destForm.get("deliveryInstructions").value !== "")
      this.quote.JlsNotes +=
        " ---- Delivery Notes: " + destForm.get("deliveryInstructions").value;
  }

  onSubmit() {
    if (!this.authService.isLoggedIn())
      return;
    if (!this.form.valid || !this.quote.Consignee.Validated) {
      Object.keys(this.form.controls)
        .map(x => this.form.controls[x])
        .forEach(control => {
          control.markAsTouched();
        });
      this.flash.flashMessage(
        "Please fill out all required fields.",
        "",
        "danger"
      );
      return false;
    }
    this.flash.dismissMessage();
    this._fillQuote();
    this.quoteService.updateQuote(this.quote, true, false, true);
    this.router.navigate(["quote", "references"]);
  }
  selectCity(city: string) {
    this.quote.Consignee.City = city;
  }
}
