import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PRIVACY_POLICY } from '../content';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';
import { PRIVACY_POLICY_META } from '../meta';

@Component({
  selector: 'rtrt-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  headerBg: string;
  content = PRIVACY_POLICY;

  constructor(
    route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private metaService: MetaService
    ) {
    route.data.subscribe((data) => {
      this.headerBg = data.headerImage;
    });
  }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.title.setTitle(PRIVACY_POLICY_META.title);
    this.metaService.removeItemprop();
    PRIVACY_POLICY_META.meta.forEach(m => this.meta.updateTag(m));
  }

}
