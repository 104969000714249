/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./glossary.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../helpers/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../helpers/no-spaces.pipe";
import * as i5 from "@angular/common";
import * as i6 from "./glossary.component";
var styles_GlossaryComponent = [i0.styles];
var RenderType_GlossaryComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_GlossaryComponent, data: {} });
export { RenderType_GlossaryComponent as RenderType_GlossaryComponent };
function View_GlossaryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "dt", [], [[8, "innerHTML", 1], [8, "id", 0]], null, null, null, null)), i1.ɵppd(2, 1), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 1, "dd", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(5, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.label)); var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 1), _v.context.$implicit.label)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.definition)); _ck(_v, 4, 0, currVal_2); }); }
export function View_GlossaryComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), i1.ɵpid(0, i4.NoSpacesPipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlossaryComponent_1)), i1.ɵdid(5, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.terms; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_GlossaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-glossary", [], null, null, null, View_GlossaryComponent_0, RenderType_GlossaryComponent)), i1.ɵdid(1, 114688, null, 0, i6.GlossaryComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GlossaryComponentNgFactory = i1.ɵccf("rtrt-glossary", i6.GlossaryComponent, View_GlossaryComponent_Host_0, { terms: "terms" }, {}, []);
export { GlossaryComponentNgFactory as GlossaryComponentNgFactory };
