import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, FormControl, PatternValidator } from '@angular/forms';

const ZIP_REGEX: RegExp = /^\d{5,6}(?:[-\s]\d{4})?$/i;
const CAN_ZIP_REGEX: RegExp = /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][-\s]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/i;

export function validateZip(c: AbstractControl) {
  return (ZIP_REGEX.test(c.value.toUpperCase()) || CAN_ZIP_REGEX.test(c.value.toUpperCase())) ? null : {
    zip: {
      valid: false
    }
  };
}

@Directive({
    selector: '[validateZip][formControlName],[validateZip][formControl],[validateZip][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => PatternValidator), multi: true }
    ]
})
export class ZipValidator implements Validator {
  constructor() {}

  validate(c: AbstractControl) {
    return validateZip(c);
  }
}
