import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'rtrt-truckload',
  templateUrl: './truckload.component.html',
  styleUrls: ['./truckload.component.scss']
})
export class TruckloadComponent implements OnInit {
  headerBg: string;

  constructor(route: ActivatedRoute) {
    route.data.subscribe((data) => {
      this.headerBg = data.headerImage;
    });
  }

  ngOnInit() {
  }

}
