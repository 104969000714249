import { throwError, BehaviorSubject, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ConfigService } from "./config.service";
import { Shipment } from "../quote/shipment.model";
import { Estimate } from "../quote/estimate.model";
import { Carrier } from "../quote/carrier.model";
import { FreightItem } from "../quote/freight-item.model";

@Injectable()
export class OrderService {
  private api: string;
  private _order: any = false;
  public order$: BehaviorSubject<any> = new BehaviorSubject<any>(this._order);
  private _history: any[] = [];
  public history$: BehaviorSubject<any> = new BehaviorSubject<any>(
    this._history
  );

  constructor(private httpClient: HttpClient, private config: ConfigService) {
    this.api = this.config.getConfig("api");
  }

  loadOrder(jlsno: String): void {
    this.httpClient
      .get(`${this.api}api/orders/load/${jlsno}`)
      .pipe(
        catchError(err => {
          this._order = err;
          this.order$.next(this._order);
          return throwError(err);
        })
      )
      .subscribe((res: any) => {
        this._order = JSON.parse(res);
        this.order$.next(this._order);
      });
  }

  clearOrder() {
    this._order = false;
    this.order$.next(this._order);
  }

  loadHistory() {
    this.httpClient
      .get(`${this.api}api/orders/history`)
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      )
      .subscribe((res: any) => {
        this._history = JSON.parse(res);
        this.history$.next(this._history);
      });
  }

  loadDocument(jls: string, type: string = "BillOfLadingRpt") {
    return this.httpClient
      .get(`${this.api}api/orders/document/${type}/${jls}`, {
        responseType: "blob"
      })
      .pipe(
        catchError(err => {
          console.log("Error getting document ... ", err);
          return throwError(err);
        }),
        map(res => {
          const file = new Blob([res], { type: "application/pdf" });
          return file;
        })
      )
      .toPromise<Blob>();
  }
}
