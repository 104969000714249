import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { QuoteService } from '../../services/quote.service';
import { PasswordResetFormComponent } from '../../account/password-reset-form/password-reset-form.component';


@Component({
  selector: 'rtrt-password-reset',
  templateUrl: './password-reset.component.html'
})
export class PasswordResetComponent {
  headerBg: string;
  id: string;
  token: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    activatedRoute.data.subscribe((data) => {
      this.headerBg = data.headerImage;
    });
    this.activatedRoute.queryParamMap
      .subscribe((paramMap: any) => {
        this.id = paramMap.params['id'];
        this.token = paramMap.params['token'].split(' ').join('+');
      });
  }

  handleReset(e: any) {
    if (e) {
      this.router.navigate(['/']);
    }
  }

}
