import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { MetaService } from '../../services/meta-service.service';

import { PROCESS_META } from "../meta";
import { AccountCreateModalComponent } from "../../account/account-create-modal/account-create-modal.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const stepLinks = [
  {
    id: "quote",
    display: "Quote Your Shipment",
    mobileDisplay: "Quote",
    body: `
      <p>
        Login into your RiteRouting account and get multiple LTL carrier quotes to choose from in a matter of moments. 
        Simply enter your shipment characteristics, any special handling requirements needed, and RiteRouting will provide multiple carrier options, 
        costs and transit times immediately.
      </p>
      `
  },
  {
    id: "book",
    display: "Book Your Shipment",
    mobileDisplay: "Book",
    body: `
      <p>
        Evaluate your LTL carrier options and select the best cost and service freight carrier for your shipment. 
        RiteRouting allows you to quickly complete and confirm your order information, create your Bill of Lading and Shipping Labels, 
        and tender the shipment to the carrier for easy completion of your order. 
      </p>
    `
  },
  {
    id: "repeat",
    display: "Repeat",
    mobileDisplay: "Repeat",
    body: `
      <p>
      The next time you have an LTL shipment, quote it and book it at RiteRouting for Shipping Done Rite! 
      </p>
    `
  }
];

@Component({
  selector: "rtrt-our-process",
  templateUrl: "./our-process.component.html",
  styleUrls: ["./our-process.component.scss"]
})
export class OurProcessComponent implements OnInit {
  headerBg: string;
  step: string;
  activeLink: any;
  subscription: any;
  stepLinks = stepLinks;

  constructor(
    private route: ActivatedRoute,
    private title: Title,
    private modalService: NgbModal,
    private meta: Meta,
    private metaService: MetaService

  ) {
    route.data.subscribe(data => {
      this.headerBg = data.headerImage;
    });
  }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.subscription = this.route.params.subscribe(params => {
      this.step = this.stepLinks[0].id;
      this.activeLink = this.getProcessLinkByID(this.step);
    });
    this.title.setTitle(PROCESS_META.title);
    this.metaService.removeItemprop();
    PROCESS_META.meta.forEach(m => this.meta.updateTag(m));
  }

  handleStepClick(s: any) {
    this.activeLink.active = false;
    this.activeLink = s;
    this.activeLink.active = true;
  }

  getProcessLinkByID(id: string) {
    return this.stepLinks.find(sl => sl.id === id);
  }
  open(target: any) {
    console.log("opening");
    if (typeof target === "string" && target === "account") {
      const modalRef = this.modalService
        .open(AccountCreateModalComponent, { size: "lg" })
        .result.then(
          result => {
            this.handleModalConfirm(result);
          },
          reason => {
            console.log(reason);
          }
        );
    } else if (typeof target === "string" && target === "password") {

    } else {
    }
  }
  handleModalConfirm(target: any) {
    if (
      (typeof target === "string" &&
        (target === "account" ||
          target === "login" ||
          target === "password")) ||
      (target && target.username)
    ) {
      this.open(target);
    }
  }
}
