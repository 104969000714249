/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./carrier-review-detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/carrier-review-stars/carrier-review-stars.component.ngfactory";
import * as i3 from "../../../shared/carrier-review-stars/carrier-review-stars.component";
import * as i4 from "./carrier-review-detail.component";
var styles_CarrierReviewDetailComponent = [i0.styles];
var RenderType_CarrierReviewDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarrierReviewDetailComponent, data: {} });
export { RenderType_CarrierReviewDetailComponent as RenderType_CarrierReviewDetailComponent };
export function View_CarrierReviewDetailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "col-12 mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "d-inline-block mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["id", "ratingStarContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "rtrt-carrier-review-stars", [], null, null, null, i2.View_CarrierReviewStarsComponent_0, i2.RenderType_CarrierReviewStarsComponent)), i1.ɵdid(6, 114688, null, 0, i3.CarrierReviewStarsComponent, [], { rating: [0, "rating"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "subText"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.review.rating; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.review.name; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.review.formattedDate; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.review.comments; _ck(_v, 11, 0, currVal_3); }); }
export function View_CarrierReviewDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-carrier-review-detail", [], null, null, null, View_CarrierReviewDetailComponent_0, RenderType_CarrierReviewDetailComponent)), i1.ɵdid(1, 114688, null, 0, i4.CarrierReviewDetailComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CarrierReviewDetailComponentNgFactory = i1.ɵccf("rtrt-carrier-review-detail", i4.CarrierReviewDetailComponent, View_CarrierReviewDetailComponent_Host_0, { review: "review" }, {}, []);
export { CarrierReviewDetailComponentNgFactory as CarrierReviewDetailComponentNgFactory };
