import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReviewService } from '../../services/review.service';
import { CarrierReview } from '../../models';
var CarrierReviewsComponent = /** @class */ (function () {
    function CarrierReviewsComponent(route, reviewService) {
        var _this = this;
        this.route = route;
        this.reviewService = reviewService;
        this.subscriptions = [];
        this.carrierReviews = [];
        this.subscriptions.push(this.route.params.subscribe(function (params) {
            if (params['carrierName']) {
                _this.carrierName = decodeURIComponent(params['carrierName']);
            }
        }));
    }
    CarrierReviewsComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.carrierName);
        this.reviewService.loadReviews(this.carrierName)
            .then(function (results) {
            if (typeof results === "undefined" ||
                results === null ||
                typeof results.data === "undefined" ||
                results.data === null)
                _this.carrierReviews = [];
            else
                _this.carrierReviews = results.data.map(function (e) { return Object.assign(new CarrierReview(), e); });
        });
    };
    Object.defineProperty(CarrierReviewsComponent.prototype, "ReviewCount", {
        get: function () {
            return this.carrierReviews.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CarrierReviewsComponent.prototype, "AverageRating", {
        get: function () {
            return this.ReviewCount > 0 ? (Math.round((this.carrierReviews.map(function (r) { return parseFloat(r.rating.toString()); }).reduce(this.sum) / this.carrierReviews.length) * 10) / 10).toFixed(1) : '0';
        },
        enumerable: true,
        configurable: true
    });
    CarrierReviewsComponent.prototype.sum = function (accumulator, val) {
        if (!isNaN(val))
            return accumulator + val;
        else
            return accumulator;
    };
    CarrierReviewsComponent.prototype.getRatingPercentage = function (rating) {
        return (this.ReviewCount > 0 ? (Math.round((this.carrierReviews.filter(function (r) { return r.rating == rating; }).length / this.ReviewCount) * 100)).toString() : '0') + '%';
    };
    return CarrierReviewsComponent;
}());
export { CarrierReviewsComponent };
