/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/shipment-summary/shipment-summary.component.ngfactory";
import * as i2 from "../../shared/shipment-summary/shipment-summary.component";
import * as i3 from "@angular/router";
import * as i4 from "../../services/quote.service";
import * as i5 from "./quote-details.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
var styles_QuoteDetailsComponent = [];
var RenderType_QuoteDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QuoteDetailsComponent, data: {} });
export { RenderType_QuoteDetailsComponent as RenderType_QuoteDetailsComponent };
function View_QuoteDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Start New Quote"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.dismiss("cancel") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "container-fluid pt-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Please confirm you would like to abandon your current quote and start over."])), (_l()(), i0.ɵeld(10, 0, null, null, 4, "div", [["class", "modal-footer d-flex justify-content-between align-items-center p-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.dismiss("cancel") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Never Mind"])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.close("confirm") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Confirm"]))], null, null); }
export function View_QuoteDetailsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "col-12 pt-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "button", [["class", "btn btn-link btn-mini pl-0"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm(i0.ɵnov(_v, 6)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Start Over"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "rtrt-shipment-summary", [], null, [[null, "onEdit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onEdit" === en)) {
        var pd_0 = (_co.edit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ShipmentSummaryComponent_0, i1.RenderType_ShipmentSummaryComponent)), i0.ɵdid(5, 49152, null, 0, i2.ShipmentSummaryComponent, [i3.Router, i4.QuoteService], { isEditable: [0, "isEditable"], shipment: [1, "shipment"] }, { onEdit: "onEdit" }), (_l()(), i0.ɵand(0, [["confirmContent", 2]], null, 0, null, View_QuoteDetailsComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.quote; _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
export function View_QuoteDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "rtrt-quote-details", [], null, null, null, View_QuoteDetailsComponent_0, RenderType_QuoteDetailsComponent)), i0.ɵdid(1, 180224, null, 0, i5.QuoteDetailsComponent, [i4.QuoteService, i3.Router, i6.NgbModal], null, null)], null, null); }
var QuoteDetailsComponentNgFactory = i0.ɵccf("rtrt-quote-details", i5.QuoteDetailsComponent, View_QuoteDetailsComponent_Host_0, {}, {}, []);
export { QuoteDetailsComponentNgFactory as QuoteDetailsComponentNgFactory };
