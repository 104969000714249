
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FormHelpers } from './../../helpers/form-helpers';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';



import { UserFormComponent } from '../user-form/user-form.component';
import { Account } from '../account.model';
import { Location } from '../../models';
import { User } from '../user.model';
import { Mode } from '../mode.model';
import { ConfigService } from '../../services/config.service';
import { AccountService } from '../../services/account.service';
import { UserService } from '../../services/user.service';
import { RtValidators } from './../../validators/rt-validators';
import { ZipService } from '../../services/zip.service';
import { PromoCode } from '../../models/promo-code.model';
import { QuoteService } from '../../services/quote.service';

const volumeQuantityOpts = new Array(
  '0-1',
  '2-5',
  '6-10',
  '11-20',
  '21+'
);
const parcelQuantityOpts = new Array(
  '0-150',
  '151-500',
  '501-1,000',
  '1,001+'
);

const volumePeriodOpts = new Array(
  'Daily',
  'Weekly',
  'Monthly'
);

const availableModes = ['LTL', 'FTL', 'Expedite', 'Parcel'];

const modeOpts = new Array(
  {
    selected: false,
    name: 'LTL',
    volumePeriod: '',
    volumeQuantity: ''
  }
  , {
    selected: false,
    name: 'FTL',
    volumePeriod: '',
    volumeQuantity: ''
  }
  , {
    selected: false,
    name: 'Expedite',
    volumePeriod: '',
    volumeQuantity: ''
  }
  , {
    selected: false,
    name: 'Parcel',
    volumePeriod: '',
    volumeQuantity: ''
  }
);

@Component({
  selector: 'rtrt-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss']
})
export class AccountFormComponent implements OnInit, AfterViewInit {
  public submitDisabled: Boolean = false;
  public agreedToTerms: Boolean = false;
  @ViewChild(UserFormComponent, { static: false })
  userForm: UserFormComponent;

  @Input()
  account: Account = new Account();

  @Input()
  saveText = 'Submit';

  @Output()
  onAccountCreation: EventEmitter<any> = new EventEmitter();

  @Output()
  onSubmission = new EventEmitter();

  availableModes = availableModes;
  accountPromoCode: PromoCode = new PromoCode();
  promoCodeField: string = '';
  promoCodeValid: boolean = true;
  modeOpts = modeOpts;
  selectedModes: Mode[] = [];
  volumePeriodOpts = volumePeriodOpts;
  volumeQuantityOpts = volumeQuantityOpts;
  parcelQuantityOpts = parcelQuantityOpts;
  accountForm: FormGroup;
  errors: any[] = [];
  states: any[] = [];

  constructor(
    private fb: FormBuilder,
    private config: ConfigService,
    private as: AccountService,
    private us: UserService,
    private zipService: ZipService,
    private zone: NgZone,
    private quoteService: QuoteService

  ) { this.states = config.getConfig('states').filter(s => s.country == "USA"); }

  ngOnInit() {
    this.createForm();
  }

  ngAfterViewInit() {

  }

  createForm() {
    this.accountForm = this.fb.group({
      businessName: [this.account.businessName, [Validators.required]],
      phone: [this.account.phone, [Validators.required, RtValidators.phone]],
      address: [this.account.address, [Validators.required]],
      address2: [this.account.address2],
      city: [{ value: this.account.city, disabled: true }, [Validators.required]],
      state: [{ value: this.account.state, disabled: true }, [Validators.required]],
      postal: [this.account.postal, [Validators.required, RtValidators.zip]],
      country: [{ value: this.account.country, disabled: true }, [Validators.required]],
      promoCode: [this.promoCodeField],
      modes: this.fb.array(
        this.modeOpts.map(opt => {
          return this.fb.group({
            selected: [opt.selected],
            name: [opt.name],
            volumePeriod: [opt.volumePeriod],
            volumeQuantity: [opt.volumeQuantity]
          });
        })
      ),
      isDefaultBillTo: [true],
      isShipper: [true],
      isAccount: [true],
      isConsignee: [false]
    });

    // this.postal.valueChanges.debounceTime(700).subscribe(val => {
    //   this.handleZipLookup(val);
    // });
  }
  get formattedDiscount() {
    if(this.promoCodeValid) {
      if (this.accountPromoCode.ArFlatCharge > 0) {
        return '$' + this.round(this.accountPromoCode.ArFlatCharge, 2).toString();
      }
      else if (this.accountPromoCode.ArDiscount > 0) {
        return this.round(this.accountPromoCode.ArDiscount * 100, 2).toString() + '%';
      }
    }
    else 
      return '';
  }
  handlePromoCode() {
    let promoCodeField = this.accountForm.controls["promoCode"];

    if(promoCodeField.value == '') {
      this.accountPromoCode = new PromoCode();
      this.promoCodeValid = true;
    }
    else {
      // check if entered promo is valid
      this.quoteService.checkPromoCode(promoCodeField.value)
        .then(promo => {
          if (typeof (promo) === "string") {
            let promoCode = JSON.parse(promo);
            this.accountPromoCode = promoCode;
            this.promoCodeValid = true;
          }
          else {
            this.accountPromoCode = new PromoCode();
            this.promoCodeValid = false;
          }
        })
        .catch(error => {
          console.log(error);
          this.accountPromoCode = new PromoCode();
          this.promoCodeValid = false;
        });
    }
  }

  handleZipLookup() {
    const zip = this.postal.value.toUpperCase();
    if (!zip || zip === '')
      return;
    const regCan = new RegExp(
      "^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][-s]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$",
      "i"
    );
    const regStrictCan = new RegExp(
      "^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][s][0-9][ABCEGHJ-NPRSTV-Z][0-9]$",
      "i"
    );

    if (zip !== this.postal.value) {
      this.postal.setValue(zip);
    }
    if (regCan.test(zip) === true && regStrictCan.test(zip) === false) {
      this.postal.setValue(zip.substr(0, 3) + " " + zip.substr(zip.length - 3, 3));
      this.country.setValue("CAN");
    }

    this.zipService.checkZip(this.postal.value)
      .pipe(
        catchError((err: any) => {
          console.log("catch err");
          this.flagPostalError();
          return observableThrowError(err);
        })
      )
      .subscribe((v: any) => {
        v = JSON.parse(v);
        console.log("v", v);

        if (v.err && v.err !== '') {
          this.flagPostalError();
          return;
        }
        this.states = this.config.getConfig('states').filter(s => s.country == v.country);

        if (v.cities.length > 0)
          this.city.setValue(v.cities[0]);
        this.state.setValue(v.stateprov);
        this.country.setValue(v.country);
      });
  }

  private flagPostalError() {
    this.city.setValue('');
    this.country.setValue('');
    this.state.setValue(undefined);
    this.postal.setValue('');
    this.postal.setErrors({ zip: 'Invalid Postal Code' });
  }

  get modes(): FormArray {
    return this.accountForm.get('modes') as FormArray;
  }

  getModeQuantityControl(idx: number) {
    return (this.accountForm.get('modes.' + idx + '.volumeQuantity') || null);
  }

  getModeQuantity(idx: number) {
    const c = this.getModeQuantityControl(idx);
    return ((c && c.value) || null);
  }

  getModePeriodControl(idx: number) {
    return (this.accountForm.get('modes.' + idx + '.volumePeriod') || null);
  }

  getModePeriod(idx: number) {
    const c = this.getModePeriodControl(idx);
    return ((c && c.value) || null);
  }

  onSubmit() {
    this.submitDisabled = true;
    console.log(this.accountForm.value);
    const user = this.userForm.userForm.getRawValue();
    user.name = (user.firstname + ' ' + user.lastname).trim();
    if (this.accountForm.valid && this.userForm.userForm.valid && this.promoCodeValid) {
      // Log the account creation to HubSpot
      var _hsq = window._hsq = window._hsq || [];
          _hsq.push(['setPath', window.location + '/createAccount']);
          _hsq.push(['identify', {
              email: user.email, 
              firstname: user.firstname,
              lastname: user.lastname,
              company: this.accountForm.value.businessName,
              phone: user.phone
            }]);
          _hsq.push(['trackPageView']);
      // Create the account
      this.as.create({
        'account': {...this.accountForm.getRawValue(), ...{ContactName: user.name, ContactEmail: user.email, ContactPhone: user.phone }},
        'user': user
      })
        .pipe(catchError((err) => {
            if (err.status !== 200) {
              const errobj = JSON.parse(err.error);
              if (typeof errobj.Data === 'string')
                this.errors = JSON.parse(errobj.Data);
              else if (typeof errobj.data === 'string')
                this.errors = JSON.parse(errobj.data);
              else if (errobj.Data !== null && typeof errobj.Data === 'object')
                this.errors = errobj.Data;
              else if (errobj.data !== null && typeof errobj.data === 'object')
                this.errors = errobj.data;
              else
                this.errors = [ 'Unhandled error, ' + errobj.Message ]; // Not sure if this is right but it's better than nothing
              console.log("there were errors creating an account");
            }
            this.submitDisabled = false;
            return observableThrowError(err);
          }))
        .subscribe((res: string) => {
          this.errors = [];
          const results = JSON.parse(res);
          this.onAccountCreation.emit({'id': results.data.user, 'username': this.userForm.userForm.get('username').value});
        });
    } else {
      this.touchAllFields().then(() => {
        this.submitDisabled = false;
        this.onSubmission.emit(null);

        this.scrollToError();
      });
    }
  }
  async touchAllFields(): Promise<any> {

    await Object.keys(this.userForm.userForm.controls)
      .map(x => this.userForm.userForm.controls[x])
      .forEach(control => {
        control.markAsTouched();
      });
    await Object.keys(this.accountForm.controls)
      .map(x => this.accountForm.controls[x])
      .forEach(control => {
        control.markAsTouched();
      });

  }
  scrollTo(el: Element): void {
    if (el) {
      if (el.previousElementSibling) {
        (el as HTMLElement).focus({
          preventScroll: true
        });
        el.previousElementSibling.scrollIntoView({ behavior: 'smooth' });
      }
      else {
        (el as HTMLElement).focus({
          preventScroll: true
        });
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid:not(form)');
    console.log(firstElementWithError);
    this.scrollTo(firstElementWithError);
  }

  get phone() { return this.accountForm.get('phone'); }
  get postal() { return this.accountForm.get('postal'); }
  get businessName() { return this.accountForm.get('businessName'); }
  get address() { return this.accountForm.get('address'); }
  get address2() { return this.accountForm.get('address2'); }
  get city() { return this.accountForm.get('city'); }
  get country() { return this.accountForm.get('country'); }
  get state() { return this.accountForm.get('state'); }

  handleStateSelection(e: any) {
    this.state.setValue(e.abbreviation);
  }

  handleQuantitySelection(e: any, i: number) {
    const c = this.getModeQuantityControl(i);
    if (c && e) {
      c.setValue(e);
    } else {
      c.setValue('');
    }
  }

  handlePeriodSelection(e: any, i: number) {
    const c = this.getModePeriodControl(i);
    if (c && e) {
      c.setValue(e);
    } else {
      c.setValue('');
    }
  }
  fillAddress(a: Location, fillName: boolean) {
    this.zone.run(() => {
      this.states = this.config.getConfig('states').filter(s => s.country == a.CountryString);

      this.accountForm.patchValue({
        address: a.AddressLineOne,
        city: a.City,
        state: a.StateOrProvince,
        postal: a.PostalCode,
        country: a.CountryString,
        phone: a.PhoneOne
      });

      if (fillName) {
        this.accountForm.patchValue({
          businessName: a.Name
        });

      }
    });
  }
  round(value: number, decimalPlaces: number) {
    return Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
  }
}
