import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';

import { ABOUT_META } from '../meta';
import { ABOUT } from '../content';

@Component({
  selector: 'rtrt-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  headerBg: string;
  content = ABOUT;

  constructor(
    route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private metaService: MetaService
  ) {
    route.data.subscribe((data) => {
      this.headerBg = data.headerImage;
    });
  }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.title.setTitle(ABOUT_META.title);
    this.metaService.removeItemprop();
    ABOUT_META.meta.forEach(m => this.meta.updateTag(m));
  }

}
