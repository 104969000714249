import {
  throwError as observableThrowError,
  BehaviorSubject,
  Observable
} from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Location } from "../models";

import { ConfigService } from "./config.service";

@Injectable()
export class SmartyStreetsService {
  private _api: string;

  constructor(private httpClient: HttpClient, private config: ConfigService) {
    this._api = this.config.getConfig("api");
  }

  validateAddress(zip: string, street: string) {
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .post(`${this._api}api/validate/smartystreets`, { zip, street })
        .pipe(
          map((res: any) => {
            const data = JSON.parse(res);
            if (data && data.results && data.results.length > 0)
              return data.results;
            else return [];
          })
        )
        .toPromise()
        .then(res => {
          resolve(res);
        });
    });
  }
}
