/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/contact-form/contact-form.component.ngfactory";
import * as i3 from "../../shared/contact-form/contact-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../services/email.service";
import * as i6 from "../../shared/flash/flash.service";
import * as i7 from "@angular/router";
import * as i8 from "./contact.component";
import * as i9 from "../../services/authentication.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../../services/meta-service.service";
var styles_ContactComponent = [i0.styles];
var RenderType_ContactComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactComponent, data: {} });
export { RenderType_ContactComponent as RenderType_ContactComponent };
export function View_ContactComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "article", [["class", "page page-contact"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "text-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contact Us"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "section", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "row pt-5 pb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "rtrt-contact-form", [], null, null, null, i2.View_ContactFormComponent_0, i2.RenderType_ContactFormComponent)), i1.ɵdid(11, 114688, null, 0, i3.ContactFormComponent, [i4.FormBuilder, i5.EmailService, i6.FlashService, i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 11, 0); }, null); }
export function View_ContactComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-contact", [], null, null, null, View_ContactComponent_0, RenderType_ContactComponent)), i1.ɵdid(1, 114688, null, 0, i8.ContactComponent, [i9.AuthenticationService, i7.ActivatedRoute, i10.Title, i10.Meta, i11.MetaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactComponentNgFactory = i1.ɵccf("rtrt-contact", i8.ContactComponent, View_ContactComponent_Host_0, {}, {}, []);
export { ContactComponentNgFactory as ContactComponentNgFactory };
