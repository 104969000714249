import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TERMS } from '../content';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta-service.service';
import { TERMS_META } from '../meta';

@Component({
  selector: 'rtrt-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  headerBg: string;
  content = TERMS;

  constructor(
    route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private metaService: MetaService
    ) {
    route.data.subscribe((data) => {
      this.headerBg = data.headerImage;
    });
  }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.title.setTitle(TERMS_META.title);
    this.metaService.removeItemprop();
    TERMS_META.meta.forEach(m => this.meta.updateTag(m));
  }

}
