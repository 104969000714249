import { throwError as observableThrowError, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BlogPost } from '../models';
import { ConfigService } from './config.service';
import { BlogTag } from '../models/blog-tag.model';
import * as i0 from "@angular/core";
import * as i1 from "./config.service";
import * as i2 from "@angular/common/http";
var BlogService = /** @class */ (function () {
    function BlogService(config, httpClient) {
        var _this = this;
        this.config = config;
        this.httpClient = httpClient;
        this.posts$ = new BehaviorSubject([]);
        this.tags$ = new BehaviorSubject([]);
        this.postPages = [];
        this._currentPage = 0;
        this.totalPages = 0;
        this._blogTagFilter = "";
        this.api = this.config.getConfig('api');
        if (this.tags$.getValue().length === 0) {
            this.loadBlogTags()
                .then(function (results) {
                if (typeof results === "undefined" ||
                    results === null ||
                    typeof results.data === "undefined" ||
                    results.data === null)
                    _this.posts$.next([]);
                else
                    _this.tags$.next(results.data.map(function (e) { return Object.assign(new BlogTag(), e); }));
            });
        }
    }
    BlogService.prototype.ngOnInit = function () {
        this.currentPage = 1;
    };
    Object.defineProperty(BlogService.prototype, "currentPage", {
        get: function () {
            return this._currentPage;
        },
        set: function (newPage) {
            var _this = this;
            // Make sure we're not reloading the existing page
            if (newPage !== this._currentPage) {
                // See if we have the page cached and use the cached version
                if (typeof this.postPages[newPage] !== "undefined" &&
                    this.postPages[newPage] !== null &&
                    typeof this.postPages[newPage].data !== "undefined" &&
                    this.postPages[newPage].data !== null) {
                    // this._currentPage = newPage;
                    var results = this.postPages[newPage];
                    this._currentPage = results.CurrentPage;
                    this.totalPages = results.TotalPages;
                    this.posts$.next(results.data.map(function (e) { return Object.assign(new BlogPost(), e); }));
                }
                else {
                    // Get the page from the server
                    this._currentPage = newPage;
                    this.loadBlogPosts()
                        .then(function (results) {
                        if (typeof results === "undefined" ||
                            results === null ||
                            typeof results.data === "undefined" ||
                            results.data === null)
                            _this.posts$.next([]);
                        else {
                            _this.postPages[newPage] = results;
                            _this._currentPage = results.CurrentPage;
                            _this.totalPages = results.TotalPages;
                            _this.posts$.next(results.data.map(function (e) { return Object.assign(new BlogPost(), e); }));
                        }
                    });
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BlogService.prototype, "blogTagFilter", {
        get: function () {
            return this._blogTagFilter;
        },
        set: function (newTagFilter) {
            var _this = this;
            // Make sure we're not reloading the existing filter
            if (newTagFilter !== this._blogTagFilter) {
                this._blogTagFilter = newTagFilter;
                // Clear out any cached blogs since the filter changes everything
                this.postPages = [];
                this.posts$.next([]);
                // Load new blog pages
                this.loadBlogPosts()
                    .then(function (results) {
                    if (typeof results === "undefined" ||
                        results === null ||
                        typeof results.data === "undefined" ||
                        results.data === null)
                        _this.posts$.next([]);
                    else {
                        _this.currentPage = results.CurrentPage;
                        _this.totalPages = results.TotalPages;
                        _this.posts$.next(results.data.map(function (e) { return Object.assign(new BlogPost(), e); }));
                    }
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    BlogService.prototype.startOver = function () {
        if (this._blogTagFilter !== "") {
            this._currentPage = 1;
            this.blogTagFilter = "";
        }
        else {
            this.currentPage = 1;
        }
    };
    BlogService.prototype.FilterAndPageChange = function (newTagFilter, newPage) {
        if (this.blogTagFilter !== newTagFilter) {
            this._currentPage = parseInt(newPage);
            this.blogTagFilter = newTagFilter;
        }
        else {
            this.currentPage = parseInt(newPage);
        }
        return true;
    };
    BlogService.prototype.loadBlogPosts = function () {
        if (this._blogTagFilter !== "") {
            return this.httpClient.get(this.api + "api/blogs/load/" + this.currentPage + "/tag/" + this.blogTagFilter)
                .pipe(catchError(function (err) { return throwError(err); }))
                .toPromise();
        }
        else {
            return this.httpClient.get(this.api + "api/blogs/load/" + this.currentPage)
                .pipe(catchError(function (err) { return throwError(err); }))
                .toPromise();
        }
    };
    BlogService.prototype.loadBlogTags = function () {
        return this.httpClient.get(this.api + "api/blogs/tags/load")
            .pipe(catchError(function (err) { return throwError(err); }))
            .toPromise();
    };
    BlogService.prototype.loadBlogPost = function (id) {
        return this.httpClient.get(this.api + "api/blogs/search/" + id)
            .pipe(catchError(function (err) {
            return observableThrowError(err);
        }), map(function (res) {
            return Object.assign(new BlogPost(), JSON.parse(res));
        }))
            .toPromise();
    };
    BlogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BlogService_Factory() { return new BlogService(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.HttpClient)); }, token: BlogService, providedIn: "root" });
    return BlogService;
}());
export { BlogService };
