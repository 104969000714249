<div *ngIf="show" class="address-validator">
  <div *ngIf="suggestions" class="address-validator__container">
    <div *ngIf="suggestions.length > 0; else noSuggestions">
      Please select a valid address:
      <div *ngFor="let suggestion of suggestions; let idx = index" class="address-validator__item" (click)="onSelect(idx)">
        {{ suggestion }}
      </div>
    </div>
    <ng-template #noSuggestions>
      <div>No address suggestions</div>
    </ng-template>
  </div>
</div>
