<article class="page page-about">
  <header>
    <div class="container">
      <h1 class="text-light">About Us</h1>
    </div>
  </header>
  <section class="container-fluid">
    <div class="row">
      <div class="container">
        <div class="row pt-5">
          <div class="col-sm-10 col-12">
            <div class="aboutHeading" [innerHtml]="content.copy | safeHtml"></div>
            <p>For Information and Quotes:</p>
            <p><a routerLink="/contact-us">Contact Us</a></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>