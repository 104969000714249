import { Component, OnInit } from '@angular/core';
import { BlogPost } from '../../../models/blog-post.model';
import { Subscription } from 'rxjs';
import { BlogService } from '../../../services/blog.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BlogTag } from '../../../models/blog-tag.model';

@Component({
  selector: 'rtrt-blog-tag-summary',
  templateUrl: './blog-tag-summary.component.html',
  styleUrls: ['./blog-tag-summary.component.scss']
})
export class BlogTagSummaryComponent implements OnInit {
  blogTags: BlogTag[] = [];
  subscriptions: Subscription[] = [];
  showAllTags: boolean = false;

  constructor(
    private blogService: BlogService,
  ) {
    this.subscriptions.push(this.blogService.tags$.subscribe(tags => {
      this.blogTags = tags;
    }));
  }

  ngOnInit() {
  }
}
