<header>
  <section
    id="callout-row"
    class="container-full bg-dark text-light pt-2 pb-2 d-sm-none text-center"
    *ngIf="!isHome"
  >
    <div class="col-12">
      <a routerLink="/categories" routerLinkActive="active"
        >Start a Quote</a
      >
    </div>
  </section>
  <section id="eyebrow" class="container-full bg-primary text-light pt-0 pb-0">
    <section class="container">
      <section class="row">
        <nav
          class="col-12 mb-0 text-right horizontal ruled d-flex align-items-center justify-content-end"
        >
          <span>833-725-RITE (7483)</span>
          <a routerLink="/freight-tracking" routerLinkActive="active" 
            class="d-none d-md-inline-block">Freight Tracking</a>
          <button
            (click)="open('account')"
            *ngIf="!authService.isAuthenticated"
            class="btn btn-link btn-nav d-none d-md-inline-block"
          >
            Create My Account
          </button>
          <button
            (click)="open('login')"
            *ngIf="!isAuthenticated || !authService.isAuthenticated"
            class="btn btn-link btn-nav"
          >
            Login
          </button>
          <a
            routerLink="/account"
            routerLinkActive="active"
            *ngIf="authService.isAuthenticated"
            class="d-none d-md-inline-block"
            >Account</a
          >
          <button
            (click)="logout()"
            *ngIf="isAuthenticated && authService.isAuthenticated"
            class="btn btn-link btn-nav"
          >
            Logout
          </button>
          <div
            class="d-none d-sm-flex d-lg-none callout justify-content-center align-items-center mb-0 mt-0"
            *ngIf="!isHome"
          >
            <a routerLink="/categories" routerLinkActive="active"
              ><strong>Start a Quote</strong></a
            >
          </div>
        </nav>
      </section>
    </section>
  </section>
  <section id="mainnav" class="container-full">
    <section class="container">
      <section
        class="nav-row row pt-0 pb-0 align-items-center align-items-md-start mobile-nav"
        style="max-height: 65px;"
      >
        <!-- Mobile Nav -->
        <div class="col-12 d-flex justify-content-space-between d-xl-none">
          <div class="logo">


            <div style="background-color: white; width:80px; height: 80px; border-radius:80%; transform: translate(-10px, -20px);">
              <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <img width="310px" height="70px" class="img-fluid" style="transform: translate(10px, 25px); width: auto; height: auto;"
                  src="assets/images/updated_images/RiteRouting-Tagline-logo.png" alt="Rite Routing Logo" />
              </a>
            </div>



          </div>
          <button
            class="btn btn-icon"
            type="button"
            (click)="toggleMobileMenu()"
            style="height: 65px;"
          >
            <img width="27px" height="27px" alt="Mobile Navigation Menu"
              class="img-fluid"
              src="assets/images/icon-red-naviburger.svg"
            />
          </button>
        </div>
        <nav
          class="col-12 pb-4 text-center d-xl-none d-flex flex-column justify-content-center mobile-nav"
          *ngIf="mobileMenuOpen"
        >
          <a routerLink="/ltl-freight-shipping" routerLinkActive="active">LTL Freight Shipping</a>
          <a routerLink="/about-us" routerLinkActive="active">About Us</a>
          <a routerLink="/blog/page/1" routerLinkActive="active">Blog</a>
          <div class="nav-item dropdown d-inline-block">
            <a class="nav-link dropdown-toggle" (click)="$event.preventDefault()" href id="navbarDropdownMenuLink"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Resources </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li>
                  <a class="dropdown-item" routerLink="/resources/faq" routerLinkActive="active">FAQ</a>
                  <a class="dropdown-item" routerLink="/resources/shipping-terms-glossary" routerLinkActive="active">Shipping Terms Glossary</a>
                  <a class="dropdown-item" routerLink="/how-to-ship-freight" routerLinkActive="active">How to Ship Freight</a>
              </li>
            </ul>
          </div>
          <a routerLink="/contact-us" routerLinkActive="active">Contact Us</a>
          <a routerLink="/freight-tracking" routerLinkActive="active"
            >Freight Tracking</a
          >
          <button
            class="btn btn-link btn-nav"
            (click)="open('account')"
            *ngIf="!(isAuthenticated | async)"
          >
            Create My Account
          </button>
          <a
            routerLink="/account"
            routerLinkActive="active"
            *ngIf="(isAuthenticated | async)"
            >Account</a
          >
        </nav>
        <!-- End Mobile Nav -->
        <nav class="col-12 mb-0 d-none d-xl-flex justify-content-space-between" style="max-height: 65px;">
          <div class="logo" style="margin: .5rem;">
            <div
              style="background-color: white; width:80px; height: 80px; border-radius:80%; transform: translate(-10px, -25px);">
              <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="text-left">
                <img width="310px" height="70px" class="img-fluid" style="transform: translate(10px, 25px); width: auto; height: auto;"
                  src="assets/images/updated_images/RiteRouting-Tagline-logo.png" alt="Rite Routing Logo" />
              </a>
            </div>  
          </div>
          <div class="text-right">
            <!-- <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Home</a> -->
            <a routerLink="/ltl-freight-shipping" routerLinkActive="active">LTL Freight Shipping</a>
            <a routerLink="/about-us" routerLinkActive="active">About Us</a>
            <a routerLink="/blog/page/1" routerLinkActive="active">Blog</a>
            <div class="nav-item dropdown d-inline-block">
              <a class="nav-link dropdown-toggle" (click)="$event.preventDefault()" href id="navbarDropdownMenuLink" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"> Resources </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li>
                  <a class="dropdown-item" routerLink="/resources/faq" routerLinkActive="active">FAQ</a>
                  <a class="dropdown-item" routerLink="/resources/shipping-terms-glossary" routerLinkActive="active">Shipping Terms Glossary</a>
                  <a class="dropdown-item" routerLink="/how-to-ship-freight" routerLinkActive="active">How to Ship Freight</a>
                </li>
              </ul>
            </div>
            <a routerLink="/contact-us" routerLinkActive="active">Contact Us</a>
            <a class="callout d-none d-lg-inline-block mb-1" routerLink="/categories" routerLinkActive="active" *ngIf="!isHome">Start a Quote</a>
          </div>
        </nav>
      </section>
    </section>
  </section>
</header>
