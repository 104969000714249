import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, Route, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { LoginModalComponent } from "../../account/login-modal/login-modal.component";
import { AccountCreateModalComponent } from "../../account/account-create-modal/account-create-modal.component";
import { ForgotPasswordModalComponent } from "../../account/forgot-password-modal/forgot-password-modal.component";
import { AuthenticationService } from "../../services/authentication.service";
import { AccountService } from "../../services/account.service";

import {
  NgbModal,
  NgbActiveModal,
  NgbModalRef
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "rtrt-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"]
})
export class NavComponent implements OnInit, OnDestroy {
  mobileMenuOpen = false;
  isAuthenticated: Observable<boolean>;
  isHome = false;
  subscriptions: Subscription[] = [];
  isOpen = false;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private modalService: NgbModal,
    public authService: AuthenticationService,
    public accountService: AccountService
  ) {
    this.isAuthenticated = this.authService.loggedIn;
  }

  ngOnInit() {
    try {
      // Not sure why we check to see if we're logged in but let's not let the error role to the console
      this.authService.isLoggedIn();
      this.subscriptions.push(
        this.router.events
          .pipe(filter(event => event instanceof NavigationEnd))
          .subscribe(
            (r: NavigationEnd) => (this.isHome = r.urlAfterRedirects === "/")
          )
      );
      this.subscriptions.push(
        this.accountService.loginExpired
          .subscribe(
            () => {
              console.log("Nav AccountService LoginExpired Emit Received");
              this.isAuthenticated = this.authService.loggedIn;
              // var loc = document.location.pathname.toString();
              // if ((loc.substr(0,19) === '/ltl-shipping-quote' ||
              //     loc.substr(0,20) === '/final-mile-delivery' ||
              //     loc.substr(0,17) === '/freight-tracking') &&
              //     loc !== '/ltl-shipping-quote' &&
              //     loc !== '/ltl-shipping-quote/carrier' && 
              //     loc !== '/final-mile-delivery' &&
              //     loc !== '/final-mile-delivery/start' &&
              //     loc !== '/final-mile-delivery/carrier'
              //     )
              //   this.open("login");
            }
          )
      );
      this.subscriptions.push(
        this.authService.loginExpired
          .subscribe(
            () => {
              console.log("Nav AuthService LoginExpired Emit Received");
              this.isAuthenticated = this.authService.loggedIn;
              // var loc = document.location.pathname.toString();
              // if ((loc.substr(0,19) === '/ltl-shipping-quote' ||
              //     loc.substr(0,20) === '/final-mile-delivery' ||
              //     loc.substr(0,17) === '/freight-tracking') &&
              //     loc !== '/ltl-shipping-quote' &&
              //     loc !== '/ltl-shipping-quote/carrier' && 
              //     loc !== '/final-mile-delivery' &&
              //     loc !== '/final-mile-delivery/start' &&
              //     loc !== '/final-mile-delivery/carrier'
              //     )
              //   this.open("login");
            }
          )
      );
    } catch (ex) {
      console.log("nav.component init: ", ex);
    }
  }

  open(target: any) {
    if (typeof target === "string" && target === "account") {
      const modalRef = this.modalService
        .open(AccountCreateModalComponent, { size: "lg" })
        .result.then(
          result => {
            this.handleModalConfirm(result);
          },
          reason => {
            console.log(reason);
          }
        );
    } else if (typeof target === "string" && target === "password") {
      const modalRef = this.modalService.open(ForgotPasswordModalComponent);
      modalRef.result.then(
        result => {
          this.handleModalConfirm(result);
        },
        reason => {
          console.log(reason);
        }
      );
    } else {
      if (this.modalService.hasOpenModals())
        return false;
      const modalRef = this.modalService.open(LoginModalComponent);
      modalRef.componentInstance.username = target.username;
      modalRef.result.then(
        result => {
          this.handleModalConfirm(result);
        },
        reason => {
          console.log(reason);
        }
      );
    }
  }

  toggleMobileMenu() {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  handleModalConfirm(target: any) {
    if (
      (typeof target === "string" &&
        (target === "account" ||
          target === "login" ||
          target === "password")) ||
      (target && target.username)
    ) {
      this.open(target);
    }
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(["/"]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
