<div [formGroup]="formGroup" class="rt-autocomplete">
    <mat-form-field [hideRequiredMarker]="true" appearance="standard">
        <input type="text" autocomplete="off" class="form-control w-100" required matInput 
            [formControlName]="controlName" 
            [id]="controlName" 
            [placeholder]="placeholderText" 
            [matAutocomplete]="auto" 
            (keyup)="search($event.target.value)" 
        />
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="fillAddress($event.option.value)">
        <mat-option *ngFor="let location of matchedLocations$" [value]="location">
            <strong>{{ location.Name }}</strong>, {{ location.AddressLineOne }}
        </mat-option>
    </mat-autocomplete>
</div>
