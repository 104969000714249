import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { FinalMileRoutingModule } from './final-mile-routing.module';

import { FinalMileQuoteComponent } from './final-mile-quote.component';

import { PaymentService } from '../services/payment.service';
import { QuoteService } from '../services/quote.service';
import { AccessorialService } from '../services/accessorials.service';
import { EstimateService } from '../services/estimate.service';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuoteModule } from '../quote/quote.module';
import { FinalMileQuoteFormComponent } from './final-mile-quote-form/final-mile-quote-form.component';
import { VendorComponent } from './vendor/vendor.component';
import { FinalMileFreightItemComponent } from './final-mile-freight-item/final-mile-freight-item.component';
import { FinalMileCarrierSelectionComponent } from './final-mile-carrier-selection/final-mile-carrier-selection.component';
import { FinalMileQuoteDetailsComponent } from './final-mile-quote-details/final-mile-quote-details.component';
import { FinalMileShipmentSummaryComponent } from './final-mile-shipment-summary/final-mile-shipment-summary.component';
import { FinalMileVendorSummaryComponent } from './final-mile-vendor-summary/final-mile-vendor-summary.component';
import { FinalMileItemDetailsComponent } from './final-mile-item-details/final-mile-item-details.component';
import { FinalMileVendorFreightDetailComponent } from './final-mile-vendor-freight-detail/final-mile-vendor-freight-detail.component';
import { FinalMileFreightItemDetailComponent } from './final-mile-freight-item-detail/final-mile-freight-item-detail.component';
import { FinalMileOriginFormComponent } from './final-mile-origin-form/final-mile-origin-form.component';
import { FinalMileVendorOriginFormComponent } from './final-mile-vendor-origin-form/final-mile-vendor-origin-form.component';
import { FinalMileDestinationFormComponent } from './final-mile-destination-form/final-mile-destination-form.component';
import { FinalMileVendorReferencesComponent } from './final-mile-vendor-references/final-mile-vendor-references.component';
import { FinalMileReferencesComponent } from './final-mile-references/final-mile-references.component';
import { FinalMilePaymentFormComponent } from './final-mile-payment-form/final-mile-payment-form.component';
import { FinalMileConfirmationComponent } from './final-mile-confirmation/final-mile-confirmation.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
    , NgbModule
    , SharedModule
    , FinalMileRoutingModule
    , QuoteModule
  ],
  declarations: [
    FinalMileQuoteComponent,
    FinalMileQuoteFormComponent,
    VendorComponent,
    FinalMileFreightItemComponent,
    FinalMileCarrierSelectionComponent,
    FinalMileQuoteDetailsComponent,
    FinalMileShipmentSummaryComponent,
    FinalMileVendorSummaryComponent,
    FinalMileItemDetailsComponent,
    FinalMileVendorFreightDetailComponent,
    FinalMileFreightItemDetailComponent,
    FinalMileOriginFormComponent,
    FinalMileVendorOriginFormComponent,
    FinalMileDestinationFormComponent,
    FinalMileVendorReferencesComponent,
    FinalMileReferencesComponent,
    FinalMilePaymentFormComponent,
    FinalMileConfirmationComponent
  ],
  exports: [
    FinalMileQuoteComponent,
    FinalMileShipmentSummaryComponent,
    FinalMileVendorSummaryComponent,
    FinalMileQuoteDetailsComponent
  ],
  providers: [
    PaymentService
    , AccessorialService
  ]
})
export class FinalMileModule { }
