var Estimate = /** @class */ (function () {
    function Estimate() {
        this.LiabilityCPP = 0;
        this.MaxLiability = 0;
        this.VolumeLiabilityCPP = 0;
        this.ShipmentLiability = 0;
        this.CarrierReviews = [];
    }
    Object.defineProperty(Estimate.prototype, "Liability", {
        get: function () {
            if (this.QuoteType !== null && this.QuoteType !== undefined) {
                if (this.QuoteType === 'eQuote') {
                    return formatCurrency((Math.round(this.LiabilityCPP * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2));
                }
                else if (this.QuoteType === 'P44Volume') {
                    return formatCurrency((Math.round(this.VolumeLiabilityCPP * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2));
                }
                else {
                    return '0.00';
                }
            }
            else {
                return '0.00';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Estimate.prototype, "Max", {
        get: function () {
            return formatCurrency((Math.round(this.MaxLiability * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Estimate.prototype, "TotalLiability", {
        get: function () {
            return formatCurrency((Math.round(this.ShipmentLiability * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Estimate.prototype, "Cost", {
        get: function () {
            return formatCurrency((Math.round(this.TotalCost * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Estimate.prototype, "Logo", {
        get: function () {
            // return "data:image/jpg;base64," + this.CarrierLogo.toString();
            return this.CarrierLogoFilename;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Estimate.prototype, "ReviewCount", {
        get: function () {
            return this.CarrierReviews.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Estimate.prototype, "AverageRating", {
        get: function () {
            return this.ReviewCount > 0 ? this.CarrierReviews.map(function (r) { return parseFloat(r.rating.toString()); }).reduce(sum) / this.CarrierReviews.length : 0;
        },
        enumerable: true,
        configurable: true
    });
    return Estimate;
}());
export { Estimate };
function formatCurrency(x) {
    return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function sum(accumulator, val) {
    if (!isNaN(val))
        return accumulator + val;
    else
        return accumulator;
}
