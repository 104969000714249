import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Shipment, ClientCommodity } from '../../models';
import { QuoteService } from '../../services/quote.service';
import { FlashService } from '../../shared/flash/flash.service';
import { AccountService } from '../../services/account.service';
import { AuthenticationService } from '../../services/authentication.service';
var ItemDetailsComponent = /** @class */ (function () {
    function ItemDetailsComponent(quoteService, accountService, authService, router, flash) {
        var _this = this;
        this.quoteService = quoteService;
        this.accountService = accountService;
        this.authService = authService;
        this.router = router;
        this.flash = flash;
        this.subscriptions = [];
        this.handlingUnits = [];
        this.items = [];
        this.isSubmitted = new Subject();
        this._clientCommodities = [];
        this.subscriptions.push(this.authService.loggedIn.subscribe(function (res) { return (_this.isAuthenticated = res); }));
        this.subscriptions.push(this.quoteService.quote$
            .subscribe(function (q) {
            _this.quote = q;
            _this.quote.Commodities.forEach(function (c, idx) { return _this.items.push({ item: idx, isValid: false }); });
        }));
        this.subscriptions.push(this.quoteService.handlingUnits$
            .subscribe(function (hu) { return _this.handlingUnits = hu; }));
        this.accountService.refreshCommodities();
        this.subscriptions.push(this.accountService.commodity$.subscribe(function (c) {
            _this._clientCommodities = c.map(function (c1) { return Object.assign(new ClientCommodity(), c1); });
        }));
        this.submission$ = this.isSubmitted.asObservable();
    }
    Object.defineProperty(ItemDetailsComponent.prototype, "clientCommodities", {
        get: function () {
            return this._clientCommodities;
        },
        enumerable: true,
        configurable: true
    });
    ItemDetailsComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    ItemDetailsComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!this.authService.isLoggedIn())
            return;
        // this.estimateService.updateQuote(this.quote);
        this.isSubmitted.next(true);
        var promises = [];
        if (this.items.filter(function (vi) { return !vi.isValid; }).length > 0) {
            return false;
        }
        else {
            this.quote.Commodities.map(function (comm) {
                promises.push(_this.quoteService.saveCommodity(comm).then(function (res) {
                    comm.CommodityID = res.CommodityID;
                }));
            });
            Promise.all(promises)
                .then(function (response) {
                // console.log('item-details updateQuote');
                _this.quoteService.updateQuote(_this.quote, true);
            });
        }
        this.router.navigate(['/quote', 'origin']);
    };
    ItemDetailsComponent.prototype.handleValidationResults = function (event) {
        if (event.isValid) {
            var idx = this.items.findIndex(function (vi) { return vi.item === event.item; });
            if (idx > -1)
                this.items[idx].isValid = true;
        }
    };
    return ItemDetailsComponent;
}());
export { ItemDetailsComponent };
