
<div *ngIf="history?.length > 0" class="quote-history">
    <header>
      <p><strong>Quote Started</strong></p>
      <p><strong>Quote Number</strong></p>
      <p><strong>MR Number</strong></p>
      <p><strong>Origin</strong></p>
      <p><strong>Destination</strong></p>
      <p>&nbsp;</p>
    </header>
    <div *ngFor="let quote of history;">
      <p class="date">
        {{quote.orderDate}}
      </p>
      <p class="id">
        {{quote.jlsNo}}
      </p>
      <p class="mrno">
        {{quote.mrNo}}
      </p>
      <p class="origin">
        {{quote.shipperZipCode}}
      </p>
      <p class="destination">
        {{quote.consigneeZipCode}}
      </p>
      <p class="action">
        <!-- <button type="button" [routerLink]="quote.isFinalMile ? ('/final-mile-delivery/' + quote.jlsNo) : ('/quote/' + quote.jlsNo)" class="btn btn-mini">Continue quote</button> -->
        <button type="button" [routerLink]="('/quote/' + quote.jlsNo)" class="btn btn-mini">Continue quote</button>
      </p>
    </div>
  </div>