import { throwError, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OnInit, OnDestroy, EventEmitter, NgZone } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ConfigService } from '../services/config.service';
import { AccountService } from '../services/account.service';
import { Location } from '../models';
import { ZipService } from '../services/zip.service';
var AddressFormComponent = /** @class */ (function () {
    function AddressFormComponent(config, accountService, zipService, zone) {
        this.config = config;
        this.accountService = accountService;
        this.zipService = zipService;
        this.zone = zone;
        this.onSave = new EventEmitter();
        this.onCancel = new EventEmitter();
        this.isNew = false;
        this.states = [];
        this.subscriptions = [];
    }
    AddressFormComponent.prototype.submit = function () {
        if (!this.form.valid) {
            // this.touchAllFormFields();
            // this.form.markAsDirty();
            console.log('Submitted .... ', this.form);
            return false;
        }
        if (!this.model.ID) {
            this.accountService.addAddress(this.model);
        }
        else {
            this.accountService.updateAddress(this.model);
        }
        this.model = new Location();
        this.onSave.emit();
    };
    AddressFormComponent.prototype.handleZipLookup = function (control) {
        var _this = this;
        var zip = control.value;
        if (!zip || zip === '') {
            this.model.City = '';
            this.model.StateOrProvince = null;
            return;
        }
        this.zipService.checkZip(zip)
            .pipe(catchError(function (err) {
            _this.flagPostalError(control);
            return throwError(err);
        }))
            .subscribe(function (v) {
            console.log(v);
            v = JSON.parse(v);
            if (v.err && v.err !== '') {
                _this.flagPostalError(control);
                return;
            }
            _this.states = _this.config.getConfig('states').filter(function (s) { return s.country == v.country; });
            if (v.cities.length > 0)
                _this.model.City = v.cities[0];
            _this.model.StateOrProvince = v.stateprov;
            _this.setCountry(v.country);
        });
    };
    AddressFormComponent.prototype.flagPostalError = function (control) {
        this.model.City = '';
        this.model.StateOrProvince = null;
        this.model.PostalCode = '';
    };
    AddressFormComponent.prototype.handleCancel = function () {
        this.onCancel.emit();
    };
    AddressFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.model) {
            this.model = new Location();
            this.isNew = true;
        }
        this.states = this.config.getConfig('states').filter(function (s) { return s.country == _this.model.CountryString; });
    };
    AddressFormComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    Object.defineProperty(AddressFormComponent.prototype, "addressType", {
        get: function () {
            return (this.model.IsConsignee ? 'Destination' : 'Shipper');
        },
        enumerable: true,
        configurable: true
    });
    AddressFormComponent.prototype.setAddressType = function (s) {
        this.model.IsConsignee = (s === 'consignee') ? true : false;
        this.model.IsShipper = (s === 'shipper') ? true : false;
    };
    Object.defineProperty(AddressFormComponent.prototype, "country", {
        get: function () {
            switch (this.model.CountryString) {
                case 'CAN':
                case 'Canada':
                case 'CA':
                    return 'Canada';
                case 'USA':
                case 'US':
                    return 'United States';
                default:
                    return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    AddressFormComponent.prototype.setCountry = function (c) {
        switch (c) {
            case 'CAN':
            case 'Canada':
            case 'CA':
                this.model.CountryString = "CAN";
                return;
            case 'USA':
            case 'US':
                this.model.CountryString = "USA";
                return;
            default:
                this.model.CountryString = "";
                return;
        }
    };
    AddressFormComponent.prototype.fillAddress = function (a, fillName) {
        var _this = this;
        this.zone.run(function () {
            _this.states = _this.config.getConfig('states').filter(function (s) { return s.country == a.CountryString; });
            _this.model.Name = (fillName ? a.Name : _this.model.Name);
            _this.model.AddressLineOne = a.AddressLineOne;
            _this.model.City = a.City;
            _this.model.StateOrProvince = a.StateOrProvince;
            _this.model.PostalCode = a.PostalCode;
            _this.model.CountryString = a.CountryString;
        });
    };
    return AddressFormComponent;
}());
export { AddressFormComponent };
