import { PhoneValidator } from './phone.directive';
import { ValidatorFn, AbstractControl, FormControl, FormGroup, FormArray } from '@angular/forms';
import { IntegerValidator } from './integer.directive';
import { DecimalValidator } from './decimal.directive';
import { PositiveValidator } from './positive.directive';
import { ZipValidator } from './zip.directive';
import { PasswordValidator } from './password.directive';
import { CreditCardValidator } from './credit-card.directive';
import { isNumber } from 'util';

export class RtValidators {
    public static phone: ValidatorFn = new PhoneValidator().validate;
    public static zip: ValidatorFn = new ZipValidator().validate;
    public static integer: ValidatorFn = new IntegerValidator().validate;
    public static decimal: ValidatorFn = new DecimalValidator().validate;
    public static password: ValidatorFn = new PasswordValidator().validate;
    public static cc: ValidatorFn = new CreditCardValidator().validate;
    public static positive: ValidatorFn = new PositiveValidator().validate;
    public static time: ValidatorFn = timeValidator;
    public static touchFormFields = validateAllFormFields;
}

export class PasswordMatchValidation {
    static validate(ac: AbstractControl, pw: string = 'password', conf: string = 'passwordConfirm') {
        try {
            const password = ac.get(pw).value;
            const confirm = ac.get(conf).value;
            if (password !== confirm) {
                ac.get(conf).setErrors( { MatchPassword: true });
            } else {
                return null;
            }
        } catch (ex) {
            return null;
        }
    }
}

function timeValidator(control: FormControl) {
    if (!control.value) {
        return null;
    }

    if (!isNumber(control.value.hour) || !isNumber(control.value.minute)) {
        return { number: true };
    }
    return null;
}

function validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
            RtValidators.touchFormFields(control);
        } else if (control instanceof FormArray) {
            control.controls.forEach(item => {
                if (item instanceof FormGroup) {
                    RtValidators.touchFormFields(item);
                }
            });
        }
    });
}
