import { InjectionToken } from '@angular/core';
export var WINDOW = new InjectionToken('window');
var ɵ0 = function () { return window; };
var windowProvider = {
    provide: WINDOW,
    useFactory: ɵ0
};
export var WINDOW_PROVIDERS = [
    windowProvider
];
export { ɵ0 };
