/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./core/nav/nav.component.ngfactory";
import * as i3 from "./core/nav/nav.component";
import * as i4 from "@angular/router";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "./services/authentication.service";
import * as i7 from "./services/account.service";
import * as i8 from "./core/footer/footer.component.ngfactory";
import * as i9 from "./core/footer/footer.component";
import * as i10 from "./services/config.service";
import * as i11 from "./shared/flash/flash.component.ngfactory";
import * as i12 from "./shared/flash/flash.component";
import * as i13 from "./shared/flash/flash.service";
import * as i14 from "./app.component";
import * as i15 from "@angular/common";
import * as i16 from "./services/analytics.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: { "animation": [{ type: 7, name: "routerTransition", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }], options: { optional: true } }, { type: 11, selector: ":leave", animation: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "0.5s" }], options: { optional: true } }, { type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "0.5s" }], options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-nav", [], null, null, null, i2.View_NavComponent_0, i2.RenderType_NavComponent)), i1.ɵdid(1, 245760, null, 0, i3.NavComponent, [i4.Router, i4.ActivatedRoute, i5.NgbModal, i6.AuthenticationService, i7.AccountService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, [["o", 4]], 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "rtrt-footer", [], null, null, null, i8.View_FooterComponent_0, i8.RenderType_FooterComponent)), i1.ɵdid(6, 114688, null, 0, i9.FooterComponent, [i10.ConfigService, i4.Router, i4.ActivatedRoute, i5.NgbModal, i6.AuthenticationService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "rtrt-flash", [], null, null, null, i11.View_FlashComponent_0, i11.RenderType_FlashComponent)), i1.ɵdid(8, 180224, null, 0, i12.FlashComponent, [i13.FlashService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rtrt-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i14.AppComponent, [i10.ConfigService, i4.Router, i15.Location, i16.AnalyticsService, i7.AccountService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("rtrt-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
