import { Component, Output, Input, EventEmitter, OnDestroy } from '@angular/core';

import { FlashMessage } from './flash.model';
import { FlashService } from './flash.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rtrt-flash',
  templateUrl: './flash.component.html',
  styleUrls: ['./flash.component.scss']
})
export class FlashComponent implements OnDestroy {
  subscriptions: Subscription[] = [];
  f: FlashMessage;

  constructor(private flash: FlashService) {
    this.subscriptions.push(
      this.flash.flash$
        .subscribe(fl => {
          this.f = fl;
        })
    );
  }

  dismiss() {
    this.flash.dismissMessage();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
