import { PromoCode } from './promo-code.model';

export class Payment {
  JlsNo: string;
  Amount: number;
  Currency: string;
  Last4Digits: number;
  Brand: string;
  Status: string;
  Description: string;
  TransactionDate: string;
  ReceiptEmail: string;
  PromoCode: PromoCode;

  get DiscountAmount(): number {
    if (this.PromoCode == null || (this.PromoCode.ArFlatCharge == 0 && this.PromoCode.ArDiscount == 0))
      return 0;
    else
      return this.PromoCode.ArFlatCharge > 0 ? this.PromoCode.ArFlatCharge : this.round((this.Amount / (1 - this.PromoCode.ArDiscount) - this.Amount), 2);
  }
  round(value: number, decimalPlaces: number) {
    return Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
  }
  constructor() {}
}
