import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnInit
} from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject, BehaviorSubject, Subscription, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { FormsModule } from "@angular/forms";

import { SpinnerComponent } from "../../shared/spinner/spinner.component";
import { ConfigService } from "./../../services/config.service";
import { Location, Payment } from "../../models";
import { PaymentService } from "../../services/payment.service";
import { FlashService } from "../../shared/flash/flash.service";
import { AccountService } from "../../services/account.service";
import { FormGroup } from "@angular/forms";
import { ZipService } from "../../services/zip.service";
import { AuthenticationService } from "../../services/authentication.service";

const cards = [
  { number: "4242424242424242", description: "Visa" },
  {
    number: "4000000000000077",
    description:
      "Charge succeeds and funds will be added directly to your available balance (bypassing your pending balance)."
  },
  {
    number: "4000000000000093",
    description:
      "Charge succeeds and domestic pricing is used (other test cards use international pricing). This card is only significant in countries with split pricing."
  },
  {
    number: "4000000000000010",
    description:
      "The address_line1_check and address_zip_check verifications fail. If your account is blocking payments that fail ZIP code validation, the charge is declined."
  },
  {
    number: "4000000000000028",
    description:
      "Charge succeeds but the address_line1_check verification fails."
  },
  {
    number: "4000000000000036",
    description:
      "The address_zip_check verification fails. If your account is blocking payments that fail ZIP code validation, the charge is declined."
  },
  {
    number: "4000000000000044",
    description:
      "Charge succeeds but the address_zip_check and address_line1_check verifications are both unavailable."
  },
  {
    number: "4000000000000101",
    description:
      "If a CVC number is provided, the cvc_check fails. If your account is blocking payments that fail CVC code validation, the charge is declined."
  },
  {
    number: "4000000000000341",
    description:
      "Attaching this card to a Customer object succeeds, but attempts to charge the customer fail."
  },
  {
    number: "4000000000009235",
    description:
      "Charge succeeds with a risk_level of elevated and placed into review."
  },
  {
    number: "4000000000000002",
    description: "Charge is declined with a card_declined code."
  },
  {
    number: "4100000000000019",
    description:
      "Results in a charge with a risk level of highest. The charge is blocked as it's considered fraudulent."
  },
  {
    number: "4000000000000127",
    description: "Charge is declined with an incorrect_cvc code."
  },
  {
    number: "4000000000000069",
    description: "Charge is declined with an expired_card code."
  },
  {
    number: "4000000000000119",
    description: "Charge is declined with a processing_error code."
  },
  {
    number: "4242424242424241",
    description:
      "Charge is declined with an incorrect_number code as the card number fails the Luhn check."
  }
];
@Component({
  selector: "rtrt-stripe",
  templateUrl: "./stripe.component.html",
  styleUrls: ["./stripe.component.scss"]
})
export class StripeComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild("checkout", { static: false }) form: FormGroup;
  @ViewChild("cardInfo", { static: false }) cardInfo: ElementRef;
  @Output() onPaymentSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPaymentError: EventEmitter<any> = new EventEmitter<any>();
  @Input() contact: Location = new Location();
  @Input() charge: number;
  @Input() isFinalMile: boolean = false;
  @Input() paymentIntentSecret: string = null;
  @Input() quote: any;
  @Input() buttonText: string = null;
  @Input() verifyTokenNotExpired: Function;
  freightClasses: string[] = [];
  touched = false;
  subscriptions: Subscription[] = [];
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  loading = false;
  useOriginAddress = true;
  cards = cards;
  style = {
    base: {
      color: "#000",
      fontWeight: 400,
      fontFamily: "'Titillium Web', sans-serif",
      fontSize: "19px",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#ccc"
      }
    },
    invalid: {
      color: "#b40028"
    }
  };
  isProd = true;
  showTestCards = false;
  testToggleText = "Show";
  states: any[] = [];
  billingType: string ;
  _addresses: Location[] = [];
  defaultAddress: Location;
  originAddress: Location;
  billingAddress: Location = new Location();
  hasDefaultBilling: boolean = false;
  zips = {
    billing: {
      loading: false,
      isValid: true
    }
  };

  constructor(
    private router: Router,
    private zipService: ZipService,
    private cd: ChangeDetectorRef,
    private config: ConfigService,
    private flash: FlashService,
    private paymentService: PaymentService,
    private accountService: AccountService,
    public authService: AuthenticationService
  ) {
    this.isProd = this.config.getEnv() === "production";
    this.states = config.getConfig("states").filter(s => s.country == "USA");
    this.subscriptions.push(
      this.accountService.address$.subscribe(a => {
        this._addresses = a;
        this.setDefaultBillingAddress();
      })
    );
  }

  ngOnInit(): void {
    this.billingAddress = Object.assign({}, this.contact);
    this.billingType = this.isFinalMile ? "default" : "origin";
  }

  ngAfterViewInit() {
    this.card = elements.create("card", {
      style: this.style,
      hidePostalCode: true
    });
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener("change", this.cardHandler);
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  setDefaultBillingAddress() {
    if (this._addresses.findIndex(a => a.IsDefaultBillTo) > -1) {
      this.defaultAddress = Object.assign(
        {},
        this._addresses.find(a => a.IsDefaultBillTo)
      );
      this.hasDefaultBilling = true;
    } else {
      this.defaultAddress = new Location();
    }
  }

  updateBillingAddress(type: string) {
    switch (type) {
      case "origin":
        this.billingAddress = Object.assign({}, this.contact);
        break;
      case "default":
        this.billingAddress = Object.assign({}, this.defaultAddress);
        break;
      case "enter":
        this.billingAddress = new Location();
        break;
      default:
        this.billingAddress = Object.assign({}, this.originAddress);
        break;
    }
  }

  toggleTestInfo() {
    this.showTestCards = !this.showTestCards;
    this.testToggleText = this.showTestCards ? "Hide" : "Show";
  }

  private _getPaymentFromResponse(res: any) {
    const p = new Payment();
    p.Amount = res.paymentIntent.amount * 0.01;
    //p.Brand = res.paymentIntent.brand;
    p.Currency = res.paymentIntent.currency;
    p.Description = res.paymentIntent.description;
    //p.Last4Digits = res.paymentIntent.last4;
    p.Status = res.paymentIntent.status;
    p.TransactionDate = new Date().toLocaleString();
    p.ReceiptEmail = this.billingAddress.ContactEmail;
    return p;
  }

  validateZip() {
    if (this.billingAddress.PostalCode === "") {
      this.zips.billing.isValid = false;
      return false;
    }
    this.zips.billing.loading = true;
    this.zipService
      .checkZip(this.billingAddress.PostalCode)
      .pipe(catchError(err => of(err)))
      .subscribe(
        (res: any) => {
          res = JSON.parse(res);
          this.zips.billing.loading = false;
          if (res.err && res.err !== "") {
            this.zips.billing.isValid = false;
            return;
          }
          this.states = this.config.getConfig('states').filter(s => s.country == res.country);

          this.billingAddress.City = res.cities[0];
          this.billingAddress.StateOrProvince = res.stateprov;
          this.zips.billing.isValid = true;
          return;
        },
        (err: any) => {
          this.zips.billing.loading = false;
          this.zips.billing.isValid = false;
        }
      );
  }

  onSubmit() {
    this.verifyTokenNotExpired(true);
    if (this.form.invalid) {
      Object.keys(this.form.controls)
        .map(x => this.form.controls[x])
        .forEach(control => {
          control.markAsTouched();
        });
      this.touched = true;
      this.flash.flashMessage(
        "Please fill out all required fields.",
        "",
        "danger"
      );
      this.verifyTokenNotExpired(false);
      return false;
    }

    if ((this.quote.FinalMileShipment === false &&
        this.quote.Carriers.length === 0) ||
        (this.quote.FinalMileShipment === true &&
        this.quote.Vendors.length === 0)) {
      this.flash.flashMessage(
        "Unexpected problem, there are no carriers on this quote.",
        "ERROR",
        "error"
      );
      this.verifyTokenNotExpired(false);
      return false;
    }

    if (!this.charge || this.charge === 0) {
      this.onPaymentError.emit({
        error: true,
        message: "No charge found",
        errCode: 1
      });
      this.verifyTokenNotExpired(false);
      return false;
    }
    this.paymentService.setCharge(this.charge);

    this.loading = true;
    // console.log("*** PAYMENT STEP 1 ***");
    this.paymentService.makeCardPayment(
          this.quote,
          this.card, 
          this.billingAddress)
      .then(res => {
        var jres;
        if (res) {
          try { jres = JSON.parse(res) } catch {}
        }
        // this.loading = false;
        this.flash.dismissMessage();
        if (res && (!jres || !jres.error)) {
          // console.log("*** PAYMENT STEP 6 SUCCESS ***");
          this.flash.flashMessage("Payment succeeded, redirecting to summary...")
          console.log("Payment Succeeded...");
          console.log(res);

          this.onPaymentSuccess.emit({
            contact: this.defaultAddress,
            payment: this._getPaymentFromResponse(res)
          });
        } else {
          // console.log("*** PAYMENT STEP 6 ERR ***");
          this.loading = false;
          if (res && jres && jres.error && jres.error.message) {
            console.log(jres.error.message);
            this.flash.flashMessage(
              jres.error.message.toString(),
              "There Was a Problem (2)",
              "danger"
            );
          } else {
            var errMsg = "There was no response from paymentService.makeCardPayment";
            console.log(errMsg);
            this.flash.flashMessage(errMsg);
          }
          this.verifyTokenNotExpired(false);
        }
      })
      .catch(err => {
        // console.log("*** PAYMENT STEP 1 ERR ***");
        this.verifyTokenNotExpired(false);
        this.loading = false;
        if (!!err && !!err.message) {
          console.log(err.message);
          this.flash.flashMessage(
            err.message.toString(),
            "There Was a Problem",
            "danger"
          );
        } else {
          console.log("Caught error in paymentService.makeCardPayment but there is no error message");
          this.flash.flashMessage(
            "Caught error in paymentService.makeCardPayment but there is no error message"
          );
        }
      });    
  }

  ngOnDestroy() {
    this.card.removeEventListener("change", this.cardHandler);
    this.card.destroy();
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

// TESTING DATA
// https://stripe.com/docs/testing
// 4242424242424242	Visa
// 4000000000000077	Charge succeeds and funds will be added directly to your available balance (bypassing your pending balance).
// 4000000000000093	Charge succeeds and domestic pricing is used (other test cards use international pricing).
// This card is only significant in countries with split pricing.
// 4000000000000010	The address_line1_check and address_zip_check verifications fail.
// If your account is blocking payments that fail ZIP code validation, the charge is declined.
// 4000000000000028	Charge succeeds but the address_line1_check verification fails.
// 4000000000000036	The address_zip_check verification fails.
// If your account is blocking payments that fail ZIP code validation, the charge is declined.
// 4000000000000044	Charge succeeds but the address_zip_check and address_line1_check verifications are both unavailable.
// 4000000000000101	If a CVC number is provided, the cvc_check fails.
// If your account is blocking payments that fail CVC code validation, the charge is declined.
// 4000000000000341	Attaching this card to a Customer object succeeds, but attempts to charge the customer fail.
// 4000000000009235	Charge succeeds with a risk_level of elevated and placed into review.
// 4000000000000002	Charge is declined with a card_declined code.
// 4100000000000019	Results in a charge with a risk level of highest. The charge is blocked as it's considered fraudulent.
// 4000000000000127	Charge is declined with an incorrect_cvc code.
// 4000000000000069	Charge is declined with an expired_card code.
// 4000000000000119	Charge is declined with a processing_error code.
// 4242424242424241	Charge is declined with an incorrect_number code as the card number fails the Luhn check.
