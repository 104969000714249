import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { SmartyStreetsService } from "../../services/smartystreets.service";
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: "rtrt-address-validator",
  templateUrl: "./address-validator.component.html",
  styleUrls: ["./address-validator.component.scss"]
})
export class AddressValidatorComponent implements OnInit, OnDestroy {
  @Input() show: boolean = true;
  @Input() street: string = "";
  @Input() postal: string = "";
  @Input() addressChange: Subject<any>;
  @Input() debounce: number = 350;
  @Output() select: EventEmitter<string> = new EventEmitter<string>();
  suggestions: string[];
  subscriptions: Subscription[] = [];

  constructor(private smartyStreets: SmartyStreetsService) {}

  ngOnInit() {
    if (!this.addressChange || this.street === "" || this.postal === "")
      this.select.emit(undefined);

    this.subscriptions.push(
      this.addressChange
        .pipe(debounceTime(this.debounce))
        .subscribe(() => this.validate())
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  validate() {
    this.smartyStreets
      .validateAddress(this.postal, this.street)
      .then((res: any[]) => {
        if (!res || res.length === 0) {
          this.select.emit(undefined);
          this.suggestions = undefined;
          return;
        }
        const match = res.find(
          r => (r.hasOwnProperty("delivery_line_1") && r.delivery_line_1.toLowerCase() === this.street.toLowerCase()) || (r.hasOwnProperty("address1") && r.address1.toLowerCase() === this.street.toLowerCase())
        );
        console.log(match);
        if (match) {
          if (match.hasOwnProperty("delivery_line_1"))
            this.select.emit(match.delivery_line_1);
          else if (match.hasOwnProperty("address1"))
            this.select.emit(match.address1);
          return;
        }

        this.suggestions = res.map(r => r.hasOwnProperty("delivery_line_1") ? r.delivery_line_1 : (r.hasOwnProperty("address1") ? r.address1 : ""));
      });
  }

  onSelect(idx: number) {
    if (!idx) this.select.emit(undefined);
    this.select.emit(this.suggestions[idx]);
  }
}
