import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


export class NgbDateENParserFormatter extends NgbDateParserFormatter {
    constructor(private locale = 'en') {
        super();
    }

    format(date: NgbDateStruct): string {
        if (date === null) return '';
        const d = new Date();
        d.setDate(date.day);
        d.setFullYear(date.year);
        d.setMonth(date.month - 1);
        return d.toLocaleDateString(this.locale);
    }

    parse(value: string): NgbDateStruct {
        if (!value) return null;
        const d = new Date(value);
        if (d) {
            return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
        } else {
            return null;
        }
    }
}
