<article class="page page-about">
  <header [style.background-image]="'url(' + headerBg + ')'">
    <div class="container">
      <h1 class="text-light">Truckload</h1>
    </div>
  </header>
  <section class="container-fluid">
    <div class="row">
      <div class="container">
        <div class="row pt-5">
          <div class="col-sm-10">
            <p>Ut eu augue tincidunt velit rutrum euismod. Morbi posuere libero in metus aliquet, vel iaculis urna lobortis.
              Nullam vulputate gravida arcu eu cursus. Praesent eget nisl sit amet lacus facilisis vulputate. Etiam a fringilla
              turpis. Proin aliquam purus at facilisis faucibus. Vestibulum suscipit, mi eget bibendum placerat, ligula dolor
              efficitur augue, a dapibus orci mauris sagittis erat. Phasellus rutrum, tortor non ultrices convallis, erat
              turpis laoreet lorem. Eget nisl sit amet lacus facilisis vulputate. Etiam a fringilla turpis. Proin aliquam
              purus demisa facilisis faucibus. Vestibulum suscipit, mi eget bibendum. Aliquam facilisis a urna vitae sollicitudin.
              Etiam at odio lectus. Aliquam sed bibendum dolor. Pellentesque dignissim blandit vulputate. Pellentesque nec
              lorem sit amet nibh placerat condimentum.</p>
          </div>
          <div class="col-sm-2">
            <img class="img-fluid rounded-circle" src="https://via.placeholder.com/480x480?text=ICON">
          </div>
        </div>
      </div>
    </div>
  </section>
</article>