import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoggerService } from './logger.service';
import { NavComponent } from './nav/nav.component';
import { throwIfAlreadyLoaded } from './module-import.guard';
import { FooterComponent } from './footer/footer.component';
import { LoginModalComponent } from '../account/login-modal/login-modal.component';
import { ForgotPasswordModalComponent } from '../account/forgot-password-modal/forgot-password-modal.component';
import { AccountCreateModalComponent } from '../account/account-create-modal/account-create-modal.component';

import { AccountModule } from '../account/account.module';
import { QuoteModule } from '../quote/quote.module';
import { SharedModule } from './../shared/shared.module';
import { NavLinksComponent } from './nav/nav-links/nav-links.component';
import { FinalMileModule } from '../final-mile/final-mile.module';


@NgModule({
  imports: [
    AccountModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    QuoteModule,
    SharedModule,
    FinalMileModule
  ],
  exports: [NavComponent, FooterComponent],
  declarations: [NavComponent, FooterComponent, NavLinksComponent],
  providers: [LoggerService],
  entryComponents: [AccountCreateModalComponent, LoginModalComponent, ForgotPasswordModalComponent]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
 }
