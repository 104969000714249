<article class="page page-help">
  <!-- <header [style.background-image]="'url(' + headerBg + ')'">
    <div class="container">
      <h1 class="text-light">Shipment Tracking</h1>
    </div>
  </header> -->
  <section class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="container pt-5 pb-5" *ngIf="error">
          <p>
            Sorry, there was a problem loading your shipment information. Please
            <a [routerLink]="['/help', 'contact', jlsNo]">contact us</a> if you
            need assistance.
          </p>
        </div>
        <div class="container pt-5 pb-5" *ngIf="!error">
          <rtrt-spinner [visible]="loading" [width]="'100%'"></rtrt-spinner>
          <rtrt-order-history
            *ngIf="!loading && showHistory"
            [orders]="orderHistory"
            [loggedIn] = "isAuthenticated"
            [carriers]="trackingCarriers"
            (onSelectOrder)="handleOrderSelection($event)"
            (onDocRequest)="handleDocumentRequest($event)"
          ></rtrt-order-history>
          <rtrt-shipment-tracking
            *ngIf="!loading && !showHistory"
            [shipment]="order"
            [tracking]="tracking"
            (onDocRequest)="handleDocumentRequest($event)"
          ></rtrt-shipment-tracking>
          <!-- <pre>{{ tracking | json}}</pre>
          <br />
          {{order | json}} -->
        </div>
      </div>
    </div>
  </section>
</article>
