import * as tslib_1 from "tslib";
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
function padNumber(value) {
    if (isNumber(value)) {
        return ("0" + value).slice(-2);
    }
    else {
        return '';
    }
}
function padYear(value) {
    if (!isNumber(value)) {
        return '';
    }
    if (value > 999) {
        return "" + value;
    }
    if (value > 99) {
        return "1" + value;
    }
    if (value > 9) {
        if (value < 31) {
            return "20" + value;
        }
        else {
            return "19" + value;
        }
    }
    return "200" + value;
}
function isNumber(value) {
    return !isNaN(toInteger(value));
}
function toInteger(value) {
    return parseInt("" + value, 10);
}
var NgbDateENParserFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(NgbDateENParserFormatter, _super);
    function NgbDateENParserFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NgbDateENParserFormatter.prototype.parse = function (value) {
        var delimiter = (value.search('/') > -1) ? '/' : '-';
        if (value) {
            var dateParts = value.trim().split(delimiter);
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return { year: toInteger(dateParts[0]), month: null, day: null };
            }
            else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return { year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null };
            }
            else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return { year: toInteger(dateParts[2]), month: toInteger(dateParts[0]), day: toInteger(dateParts[1]) };
            }
        }
        return null;
    };
    NgbDateENParserFormatter.prototype.format = function (date) {
        var stringDate = '';
        if (date) {
            stringDate += isNumber(date.month) ? padNumber(date.month) + '/' : '';
            stringDate += isNumber(date.day) ? padNumber(date.day) + '/' : '';
            stringDate += padYear(date.year);
        }
        return stringDate;
    };
    return NgbDateENParserFormatter;
}(NgbDateParserFormatter));
export { NgbDateENParserFormatter };
