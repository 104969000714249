<article class="page page-help">
  <header>
    <div class="container">
      <h1 class="text-light">Help</h1>
    </div>
  </header>
  <section class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="container">
          <div class="row b-top-secondary mt-5 pb-5">
            <div class="col-12 col-lg-2 pt-md-5 pt-3" id="linkDiv">
              <nav class="side-nav">
                <a class="text-center text-lg-left" *ngFor="let link of subLinks; let i=index" [routerLink]="['/resources', link.id]" routerLinkActive="active last first">
                  {{link.display}}
                </a>
              </nav>
            </div>
            <div class="col-12 col-lg-10 b-left-lg-secondary pt-3 pt-md-5 pl-lg-5">
                <rtrt-faq *ngIf="active.id === 'faq'"></rtrt-faq>
                <rtrt-glossary *ngIf="active.id === 'shipping-terms-glossary'" [terms]="terms"></rtrt-glossary>
                <rtrt-contact-form *ngIf="active.id === 'contact-us'"></rtrt-contact-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>