import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { User } from '../user.model';
import { RtValidators, PasswordMatchValidation } from '../../validators/rt-validators';
var UserFormComponent = /** @class */ (function () {
    function UserFormComponent(fb) {
        this.fb = fb;
        this.user = new User();
        this.saveText = 'Submit';
        this.onSubmission = new EventEmitter();
    }
    UserFormComponent.prototype.ngOnInit = function () {
        this.createForm();
    };
    UserFormComponent.prototype.createForm = function () {
        this.userForm = this.fb.group({
            title: [this.user.title],
            username: [this.user.username, [Validators.required]],
            name: [this.user.name, []],
            firstname: [this.user.firstname, [Validators.required]],
            lastname: [this.user.lastname, [Validators.required]],
            email: [this.user.email, [Validators.required, Validators.email]],
            phone: [this.user.phone, [Validators.required, RtValidators.phone]],
            password: ['', [Validators.required]],
            passwordConfirm: ['', [Validators.required]],
        }, {
            validator: PasswordMatchValidation.validate
        });
    };
    UserFormComponent.prototype.onSubmit = function () {
        this.user.name = (this.firstname + ' ' + this.lastname).trim();
        if (this.userForm.valid) {
            this.onSubmission.emit(this.userForm.value);
        }
        else {
            this.onSubmission.emit(null);
        }
    };
    UserFormComponent.prototype.onNameChange = function () {
        console.log("onNameChange");
        this.user.name = (this.user.firstname + ' ' + this.user.lastname).trim();
    };
    Object.defineProperty(UserFormComponent.prototype, "title", {
        get: function () { return this.userForm.get('title'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserFormComponent.prototype, "username", {
        get: function () { return this.userForm.get('username'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserFormComponent.prototype, "name", {
        get: function () { return this.userForm.get('name'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserFormComponent.prototype, "firstname", {
        get: function () { return this.userForm.get('firstname'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserFormComponent.prototype, "lastname", {
        get: function () { return this.userForm.get('lastname'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserFormComponent.prototype, "email", {
        get: function () { return this.userForm.get('email'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserFormComponent.prototype, "phone", {
        get: function () { return this.userForm.get('phone'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserFormComponent.prototype, "password", {
        get: function () { return this.userForm.get('password'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserFormComponent.prototype, "passwordConfirm", {
        get: function () { return this.userForm.get('passwordConfirm'); },
        enumerable: true,
        configurable: true
    });
    return UserFormComponent;
}());
export { UserFormComponent };
