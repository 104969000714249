import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Shipment, OrderDateTimeType } from '../../models';
import { QuoteService } from '../../services/quote.service';
var ShipmentSummaryComponent = /** @class */ (function () {
    function ShipmentSummaryComponent(router, quoteService) {
        this.router = router;
        this.quoteService = quoteService;
        this.show = "collapse";
        this.detailStatus = "Show";
        this.loading = false;
        this.isEditable = false;
        this.processLocation = '';
        this.onEdit = new EventEmitter();
        this.sum = function (accumulator, val) {
            if (!isNaN(val))
                return accumulator + val;
            else
                return accumulator;
        };
    }
    Object.defineProperty(ShipmentSummaryComponent.prototype, "shipment", {
        get: function () {
            return this._shipment;
        },
        set: function (s) {
            this._shipment = s;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShipmentSummaryComponent.prototype, "grossWeight", {
        get: function () {
            var gw = this.shipment.Commodities.map(function (w) { return !isNaN(w.GrossWeight) ? parseFloat(w.GrossWeight.toString()) : 0; }).reduce(this.sum);
            if (gw && gw > 0)
                return (gw === 1) ? gw + ' lb' : gw + ' lbs';
            else
                return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShipmentSummaryComponent.prototype, "totalItems", {
        get: function () {
            return this.shipment.Commodities.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShipmentSummaryComponent.prototype, "totalUnits", {
        get: function () {
            return this.shipment.Commodities.map(function (w) { return w.HandlingUnits; }).reduce(this.sum);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShipmentSummaryComponent.prototype, "pickupDate", {
        get: function () {
            var pdate = this.shipment.DateTimes.find(function (dt) { return dt.TypeID === OrderDateTimeType.ShipDate; });
            if (pdate)
                return pdate.ShortDateString;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShipmentSummaryComponent.prototype, "deliveryDate", {
        get: function () {
            var ddate = this.shipment.DateTimes.find(function (dt) { return dt.TypeID === OrderDateTimeType.RequestedDeliveryDate; });
            if (ddate)
                return ddate.ShortDateString;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShipmentSummaryComponent.prototype, "allAccessorials", {
        get: function () {
            var accs = this.shipment.AccessorialAR.concat(this.shipment.Carriers[0].AccessorialAR)
                .filter(function (obj, pos, arr) {
                return arr.map(function (mapObj) { return mapObj["Type"]; }).indexOf(obj["Type"]) === pos;
            });
            if (accs)
                return accs;
        },
        enumerable: true,
        configurable: true
    });
    ShipmentSummaryComponent.prototype.edit = function (dest) {
        this.onEdit.emit(dest);
    };
    ShipmentSummaryComponent.prototype.showToggle = function () {
        if (this.show == "") {
            this.show = "d-none";
            this.detailStatus = "Show";
        }
        else {
            this.show = "";
            this.detailStatus = "Hide";
        }
    };
    ShipmentSummaryComponent.prototype.printQuote = function () {
        var _this = this;
        this.loading = true;
        this.quoteService.printQuote(this.shipment.JlsNo, this.shipment.Carriers[0].CarrierID.toString(), this.shipment.eQuoteNumber)
            .then(function (file) {
            _this.loading = false;
            _this.serveFile(file);
        })
            .catch(function (err) {
            _this.loading = false;
            console.log(err);
        });
    };
    ShipmentSummaryComponent.prototype.serveFile = function (file) {
        var fileUrl = window.URL.createObjectURL(file);
        window.open(fileUrl);
    };
    return ShipmentSummaryComponent;
}());
export { ShipmentSummaryComponent };
