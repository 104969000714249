export class BlogTag {
    PostCount: number;
    TagName: string;

    get FomattedTagName() {
        return this.TagName.replace(/\W+(?!$)/g, "-")
            .replace(/\W$/, "")
            .toLowerCase();
    }
    constructor() {
        this.PostCount = 0;
        this.TagName = "";
    }
}
